
    import { Component, PropSync, Vue } from 'vue-property-decorator';
    import { DataTableHeader } from 'vuetify';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { InfoFileDesc } from '@/types';

    @Component({
        components: { ButtonTooltip },
    })
    export default class CertificateVersion extends Vue {
        @PropSync('infoFile', { type: Object })
        infoFileDesc!: InfoFileDesc;

        get headers(): DataTableHeader[] {
            return [
                { text: this.$t('common.versions') as string, value: 'CreatedAt', width: '100%' },
            ];
        }

        get items() {
            if (!this.infoFileDesc.VersionSignatureFiles) return [];
            return this.infoFileDesc.VersionSignatureFiles.map(x => ({
                ...x,
                CreatedAt: new Date(x.CreatedAt).toLocaleString(),
            }));
        }
    }
