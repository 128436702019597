import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import createCache from 'vuex-cache';
import auth from './auth/module';
import reftables from './reftables/module';
import document from './document/module';
import drawings from './drawings/module';
import settings from './settings';
import topMenu from './topmenu/module';
import register from './register/module';
import report from './report/module';
import { RootState } from '@/store/types';

Vue.use(Vuex);

const isDebug = process.env.NODE_ENV !== 'production';

const options: StoreOptions<RootState> = {
    plugins: [
        createCache(),
    ],
    modules: {
        auth,
        reftables,
        document,
        drawings,
        settings,
        topMenu,
        register,
        report,
    },
    strict: isDebug,
};

const store = new Vuex.Store(options);

export default store;
