
    import { Component, PropSync, Ref, Vue } from 'vue-property-decorator';
    import * as api from '@/api/auth';
    import { required } from '@/components/common/validation-rules';
    import { UserPasswordRequest, VForm } from '@/types';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { PasswordErrors, validateHandler } from '@/components/admin/roles-model/users/helpers';

    @Component({
        components: { ButtonTooltip },
        methods: {
            required,
        },
    })
    export default class AppBarChangePasswordPopup extends Vue {
        @Ref() form!: VForm;
        @PropSync('modal', { type: Boolean, default: () => false })
        isVisible!: boolean;

        valid = true;
        isLoading = false;
        formData: Partial<UserPasswordRequest> = {};
        rePassword = '';
        reCheck = (v: string) => this.formData.newPassword === v || this.$t('password.doesn-match');

        get passwordErrors(): PasswordErrors[] {
            const v = this.formData.newPassword || '';
            return validateHandler(v);
        }

        get isPasswordErrors() {
            return this.passwordErrors.some(error => !error.isValid);
        }

        errorsIcon(item: PasswordErrors) {
            if (!item.isValid || !this.formData.newPassword) return 'mdi-alert-circle-outline';
            return 'mdi-check-circle-outline';
        }

        errorColors(item: PasswordErrors) {
            if (!this.formData.newPassword) return '';
            if (!item.isValid) return 'error--text';
            return 'success--text';
        }

        close() {
            this.form.reset();
            this.form.resetValidation();
            this.isVisible = false;
        }

        async submit() {
            if (!this.form.validate()) return;
            this.isLoading = true;
            try {
                await api.auth.changePassword(this.formData as UserPasswordRequest);
                this.$root.$notify.show('success', this.$t('password-change.passwordchanged') as string);
                this.close();
            } catch (error) {
                this.$root.$notify.show('error', error);
            } finally {
                this.isLoading = false;
            }
        }
    }
