
    import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
    import CustomDialog from '@/components/dynamic/common/custom-dialog.vue';
    import DragFileUpload from '@/components/files/drag-file-upload.vue';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { DynamicEditorOptions, DynamicReftableItem, FieldType, IField, RowTable, TypeFieldEditor } from '@/types/dynamicDoc';
    import { DataTableHeader } from 'vuetify';
    import * as Papa from 'papaparse';
    import * as api from '@/api/dynamic';

    @Component({
        components: {
            DynamicFieldEditor: () => import('@/components/dynamic/common/dynamic-field-editor.vue'),
            DragFileUpload,
            CustomDialog,
            ButtonTooltip,
        },
    })
    export default class ImportCsvDialog extends Vue {
        @PropSync('modal', { type: Boolean, required: true }) isVisible!: boolean;

        @Prop({ type: Array, default: () => [] })
        headerText!: string[];

        @Prop({ type: Array, default: () => [] })
        fields!: IField[];

        @Prop({ type: Object, default: {} })
        reftables!: Record<string, DynamicReftableItem[]>;

        @Prop({ type: Object, default: () => null })
        requestParams!: RowTable;

        @Prop({ type: Object })
        readonly options?: DynamicEditorOptions;

        csvItems: Record<string, string | number>[] = [];

        @Watch('isVisible', { immediate: true })
        onVisibleChanged(value: boolean) {
            if (!value) return;
            this.csvItems = [];
        }

        @Watch('csvItems', { immediate: true, deep: true })
        async onChangeValue() {
            this.csvItems.forEach(item => {
                Object.keys(item).forEach(key => {
                    const field = this.fields.find(i => i.Title === key);
                    if (field && field.Type === FieldType.Reftable && field.ReftableSchemaId) {
                        const refs = this.reftables[field.ReftableSchemaId as string];
                        const value = refs.find(ref => Object.values(ref.Data).includes(item[key]));
                        if (value) item[key] = value.Id;
                    }
                });
            });
        }

        get tableHeaders() {
            if (!this.fields) return [];
            const header = this.fields.map(x => ({
                ...x,
                text: x.Title,
                value: x.Title,
            })) as DataTableHeader[];
            header.push({ text: '', value: 'actions', width: '6em', sortable: false });
            return header;
        }

        handleFileUpload(files: File[]) {
            try {
                const file = files[0];
                Papa.parse<Record<string, string>>(file, {
                    header: true,
                    worker: true,
                    skipEmptyLines: true,
                    delimiter: ';',
                    complete: (results) => {
                        this.csvItems = results.data.map(x => {
                            const values = Object.values(x).map(val =>
                                val.replace(/\\/g, '')
                                    .replace(/\n/g, ''));
                            return this.convertItem(values);
                        });
                    },
                });
            } catch (error) {
                console.error(error);
            }
        }

        convertItem(items: string[]) {
            const obj: Record<string, string | number> = {};
            for (let j = 0; j < this.headerText.length; j++) {
                const field = this.fields.find(i => i.Title === this.headerText[j]);
                if (field && field.Type === FieldType.Date) {
                    obj[this.headerText[j]] = this.dateToTimestamp(items[j]) as number;
                } else {
                    obj[this.headerText[j]] = items[j] as string;
                }
            }
            return obj;
        }

        dateToTimestamp(dateString: string): number | null {
            if (!dateString) return null;

            const regex = /^\d{2}\.\d{2}\.\d{4}$/;
            if (!regex.test(dateString)) {
                this.$root.$notify.show('error', this.$t('error.invalid-date-format'));
                return null;
            }

            const dateParts = dateString.split('.');
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);

            const dateObj = new Date(year, month, day);
            const timestamp = dateObj.getTime();

            if (isNaN(timestamp) || dateObj.getMonth() !== month || dateObj.getFullYear() !== year) {
                this.$root.$notify.show('error', this.$t('error.invalid-date-format'));
                return null;
            }

            return timestamp;
        }

        close() {
            this.isVisible = false;
        }

        async save() {
            if (this.options?.type !== TypeFieldEditor.Journal) {
                this.$emit('input', this.csvItems);
                return;
            }
            try {
                await api.journal.addRowInTable({
                    ...this.requestParams,
                    Data: [ ...this.csvItems ],
                });
                this.$emit('updated');
                this.$root.$notify.show('success');
                this.close();
            } catch (e) {
                this.$root.$notify.show('error');
            }
        }
    }
