import { authInstance } from './auth-instance';
import { UsersSession, UsersSessionOptions } from '@/components/security/types';
import { FilterItemsResponse } from '@/types';

export const usersSession = {
    getById: (id: number): Promise<UsersSession> =>
        authInstance.get<UsersSession>(`UserSession/${id}`).then(x => x.data),

    getByFilter: (options: UsersSessionOptions): Promise<FilterItemsResponse<UsersSession>> =>
        authInstance.post<FilterItemsResponse<UsersSession>>('UserSession/GetByFilter', options).then(x => x.data),

    invalidateToken: (token: string): Promise<boolean> =>
        authInstance.post<boolean>('UserSession/InvalidateToken', { token: token }).then(x => x.data),
};
