import { BaseApi } from '@/api/BaseApi';

/** Избранные системные отчеты */
export default class FavoriteSystemReportApi extends BaseApi<string, string> {
    getAvailable(): Promise<string[]> {
        return this.instance.get<string[]>(`${this.entityName}/`).then(x => x.data);
    }

    setFavorite(id: string): Promise<string> {
        return this.instance.post(`${this.entityName}/setFavorite/${id}`).then(x => x.data);
    }

    removeFavorite(id: string): Promise<string> {
        return this.instance.post(`${this.entityName}/removeFavorite/${id}`).then(x => x.data);
    }
}
