
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { DataTableHeader } from 'vuetify';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { DynamicDocumentErrors } from '@/types/dynamicDoc';
    import Document from '@/components/documents-journals/documents/document.vue';
    import CustomDialog from '@/components/dynamic/common/custom-dialog.vue';

    @Component({
        components: {
            ButtonTooltip,
            Document,
            CustomDialog,
        },
    })
    export default class ChartDocumentsErrors extends Vue {
        @Prop({ type: String, required: true })
        readonly title!: string;

        @Prop({ type: Array, required: true })
        readonly items!: DynamicDocumentErrors[];

        documentId: string | null = null;
        isVisibleDocumentDialog = false;
        itemExpanded = [];

        get headers(): DataTableHeader[] {
            return [
                { text: this.$t('common.document-number') as string, value: 'Number' },
                { text: this.$t('common.document-outline') as string, value: 'Type' },
                { text: this.$t('common.date') as string, value: 'Date' },
                { text: '', value: 'Actions', align: 'end' },
            ];
        }

        get itemsFormat() {
            return this.items.map(error => {
                const errorsMessageList = [ ...error.Errors, ...error.LeadErrors ].flatMap(err => err.Message);
                return {
                    ...error,
                    Date: new Date(error.Date).toLocaleDateString(),
                    Errors: errorsMessageList,
                };
            });
        }

        edit(id: string) {
            this.documentId = id;
            this.isVisibleDocumentDialog = true;
        }
    }
