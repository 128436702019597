import { BaseApi } from '@/api/BaseApi';
import { ExecuteRequest, ExecuteResponse, ConditionItem, ConditionItemFilterRequest, ConditionFilter, ConditionData } from '@/types/report';
import { getByFilter } from '@/types/dynamicDoc';

/** Фильтр отчёта */
export default class ConditionItemApi extends BaseApi<ConditionItem, ConditionItem> {
    getByFilter(data: ConditionItemFilterRequest): Promise<getByFilter<ConditionItem>> {
        return this.instance.post<getByFilter<ConditionItem>>(`${this.entityName}/getByFilter`, data).then(x => x.data);
    }

    executeQuery(data: ExecuteRequest): Promise<ExecuteResponse> {
        return this.instance.post<ExecuteResponse>(`${this.entityName}/executeQuery`, data).then(x => x.data);
    }

    executeQueryByFilter(data: ConditionFilter): Promise<{Items: ConditionData[], TotalItems: number}> {
        return this.instance
            .post<{Items: ConditionData[], TotalItems: number}>(`${this.entityName}/ExecuteQueryByFilter`, data)
            .then(x => x.data);
    }

    executeQueryByFilterAll(data: ConditionFilter): Promise<ConditionData[]> {
        return this.instance
            .post<ConditionData[]>(`${this.entityName}/ExecuteQueryByFilterAll`, data)
            .then(x => x.data);
    }

    executeQueryById(id: string): Promise<ConditionData[]> {
        return this.instance
            .get<ConditionData[]>(`${this.entityName}/ExecuteQueryById/${id}`)
            .then(x => x.data);
    }

    getFlatConditionItems(id: string): Promise<ConditionItem[]> {
        return this.instance.get<ConditionItem[]>(`${this.entityName}/GetFlatConditionItems/${id}`).then(x => x.data);
    }
}
