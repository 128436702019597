import Vue from 'vue';
import VueRouter, { Location, RawLocation, Route } from 'vue-router';
import routes from './routes';
import store from '@/store';
import { UserResponse } from '@/types';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: '/',
    linkActiveClass: 'active',
    routes,
});

function getUserInfo(): Promise<UserResponse> {
    return new Promise(resolve => {
        let watcher: (() => void) | null = null;
        watcher = store.watch(
            (state, getters) => getters.authStatus,
            (value) => {
                console.debug('[router] user status === ' + value);
                if (value !== 'loading') {
                    resolve(store.getters.getUser);
                    if (watcher) watcher();
                }
            }, {
                immediate: true,
            },
        );
    });
}

router.beforeEach(async(to, from, next) => {
    // check if user is logged in
    // if role is specified, also check if user has it

    console.debug('[router] checking auth');

    const userInfo = await getUserInfo();

    if (to.matched.some(record => record.meta.requiresAuth)) {
        const requiredPermissions = to.matched.map((record) => {
            const r = typeof record.meta.requirePermission === 'string'
                ? record.meta.requirePermission
                : record.meta.requirePermission?.join('|');
            if (typeof r === 'string') return [ r ];
            else if (Array.isArray(r)) return r;
            else return [];
        }).reduce((acc, val) => {
            val.forEach(x => acc.push(x));
            return acc;
        }, []);
        if (userInfo && (!requiredPermissions.length || requiredPermissions.every(p => Vue.gates.hasAnyPermission(p)))) {
            next();
        } else if (userInfo) {
            next(from.path);
        } else {
            next('/login');
        }
    } else {
        next();
    }
});

const baseResolve = router.resolve.bind(router);

router.resolve = (to: RawLocation, current?: Route, append?: boolean) => {
    const toWithName = (to as { name?: string });

    if (!toWithName.name) return baseResolve(to, current, append);

    return baseResolve(to, current, append);
};

router.beforeEach((to, from, next) => {
    const matchedLength = to.matched?.length || 0;
    const isMatchingSomeRoute = matchedLength > 0;

    if (isMatchingSomeRoute) {
        next();
        return;
    }

    const resolvedRoute = router.resolve((to as Location), from);
    next(resolvedRoute.location);
});

export default router;
