
    import { Component, Prop } from 'vue-property-decorator';
    import { DrawingPackage, Notification } from '@/api/model/interfaces';
    import * as Odata from '@/api/odata';
    import NotificationItem from '@/components/common/notification-item.vue';

    @Component
    export default class DrawingPackageHasIssues extends NotificationItem {
        @Prop({ required: true })
        notification!: Notification;

        register: DrawingPackage | null = null;

        async mounted() {
            this.register = await Odata.DrawingPackage.setParams({}).getById(this.notification.EntityId.toString());
            this.link = this.register ? `/working-drawings/packages/${this.register.Id}` : null;
        }
    }
