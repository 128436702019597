import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import {
    DocumentSchemaShort,
    DynamicDocSchemaApi,
    DynamicDocumentSchema,
    DynamicDocumentSchemaNew,
    DynamicDocumentSchemaWithFile,
    DynamicResponse,
    getByFilter,
    LinkResponse,
    Options,
    SchemaLinkRequest,
    SchemaSearchRequest,
} from '@/types/dynamicDoc';

/** Схемы документов */
export const documentSchema: DynamicDocSchemaApi<DynamicDocumentSchema, DynamicDocumentSchemaNew> = {
    getAll: (): Promise<DynamicDocumentSchema[]> =>
        dynamicDocInstance.get<DynamicDocumentSchema[]>('DocumentSchema').then(x => x.data),

    getByFilter: (options: Options) =>
        dynamicDocInstance.post<getByFilter<DynamicDocumentSchema>>('/DocumentSchema/getByFilter', options)
            .then(x => x.data),

    getById: (id: string): Promise<DynamicDocumentSchema> =>
        dynamicDocInstance.get<DynamicDocumentSchema>(`DocumentSchema/${id}`).then(x => x.data),

    create: (data: DynamicDocumentSchemaNew): Promise<DynamicDocumentSchema> => {
        const bodyFormData = new FormData();
        bodyFormData.append('Title', data.Title);
        bodyFormData.append('TemplateFile', data.TemplateFile);
        bodyFormData.append('Category', data.Category);
        bodyFormData.append('TechnicalDocumentationLink', data.TechnicalDocumentationLink ?? '');
        bodyFormData.append('IsIncludedFinalArchive', data.IsIncludedFinalArchive ? 'true' : 'false');
        if (data.IsSystem) bodyFormData.append('IsSystem', data.IsSystem ? 'true' : 'false');
        if (data.DefaultPageCount) bodyFormData.append('DefaultPageCount', data.DefaultPageCount.toString());
        if (data.DefaultPageFormat) bodyFormData.append('DefaultPageFormat', data.DefaultPageFormat.toString());
        return dynamicDocInstance.post<DynamicDocumentSchema>('DocumentSchema', bodyFormData).then(x => x.data);
    },

    createWithFile: (data: DynamicDocumentSchemaWithFile): Promise<DynamicDocumentSchema> => {
        return dynamicDocInstance.post<DynamicDocumentSchema>('DocumentSchema/CreateWithoutFile', data).then(x => x.data);
    },

    update: (id: string, data: DynamicDocumentSchema): Promise<DynamicDocumentSchema> =>
        dynamicDocInstance.put<DynamicDocumentSchema>(`DocumentSchema/${id}`, data).then(x => x.data),

    remove: (id: string): Promise<void> =>
        dynamicDocInstance.delete(`DocumentSchema/${id}`).then(x => x.data),

    getPage: (page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DynamicDocumentSchema>>(`/DocumentSchema/page?page=${page}&pageSize=${pageSize}`)
            .then(x => x.data),

    getCategoryPage: (id: string, page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DynamicDocumentSchema>>(
            `/DocumentSchema/ByCategoryPage?categoryId=${id}&page=${page}&pageSize=${pageSize}`)
            .then(x => x.data),

    getByCategory: (categoryId: string, showOnlyActive: boolean) =>
        dynamicDocInstance.get<DynamicDocumentSchema[]>(`/DocumentSchema/bycategory?categoryId=${categoryId}&showOnlyActive=${showOnlyActive}`)
            .then(x => x.data),

    searchByTitle: (options: SchemaSearchRequest): Promise<DocumentSchemaShort[]> =>
        dynamicDocInstance.get<DocumentSchemaShort[]>(`/DocumentSchema/SearchByTitle?search=${options.search}`)
            .then(x => x.data),

    searchByTitleAndCategory: (options: SchemaSearchRequest) =>
        dynamicDocInstance.get<DynamicDocumentSchema[]>(`/DocumentSchema/SearchByTitleAndCategory?search=${options.search}&categoryId=${options.categoryId}`)
            .then(x => x.data),

    getRelatedDocumentType: (documentSchemaId: string, formula?: string) =>
        dynamicDocInstance.post<DynamicDocumentSchema[]>(`/DocumentSchema/RelatedDocumentType?id=${documentSchemaId}`, { formula })
            .then(x => x.data),

    getDocumentLinks: (data: SchemaLinkRequest) =>
        dynamicDocInstance.post<LinkResponse[]>('/DocumentSchema/GetDocumentLinks', data).then(x => x.data),

    getSystemSchemas: () =>
        dynamicDocInstance.get<DynamicDocumentSchema[]>('/DocumentSchema/getSystemSchemas').then(x => x.data),

    changeCategory: (id: string, categoryId: string): Promise<boolean> => {
        return dynamicDocInstance.post<boolean>(`/DocumentSchema/ChangeCategory?id=${id}&categoryId=${categoryId}`).then(x => x.data);
    },

    getFullEditErrors: (id: string): Promise<string[]> => {
        return dynamicDocInstance.get<string[]>(`/DocumentSchema/GetFullEditErrors/${id}`).then(x => x.data);
    },

    downloadTemplateFileSchema: (id: string): Promise<Blob> =>
        dynamicDocInstance.get(`/DocumentSchema/downloadTemplateFileSchema/${id}`, { responseType: 'blob' }).then(x => x.data),
};
