import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import {
    IdWithTitle,
    DynamicJournal,
    DynamicJournalListItem,
    DynamicJournalPartitionInfo,
    DynamicJournalResponse,
    DynamicResponse,
    getByFilter,
    JournalFilter,
    JournalTableFilter,
    RowTableNew,
    RowTableUpdate,
    RowTable,
    DynamicJournalFromFileNew,
    RenumberJournalTable,
} from '@/types/dynamicDoc';

/** Динамический журнал */
export const journal = {
    getPage: (page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DynamicJournalListItem>>(`/DynamicJournal/page?page=${page}&pageSize=${pageSize}`).then(x => x.data),

    getByFilter: (options: JournalFilter) =>
        dynamicDocInstance.post<getByFilter<DynamicJournalResponse>>('/DynamicJournal/getByFilter', options)
            .then(x => x.data),

    getByIdRange: (ids: string[]) =>
        dynamicDocInstance.post<DynamicJournalResponse[]>('/DynamicJournal/GetByIdRange', ids)
            .then(x => x.data),

    addRowInTable: (row: RowTableNew) =>
        dynamicDocInstance.post('/DynamicJournal/AddRowInTable', row)
            .then(x => x.data),

    updateRowInTable: (row: RowTableUpdate) =>
        dynamicDocInstance.post('/DynamicJournal/UpdateRowInTable', row)
            .then(x => x.data),

    deleteRowInTable: (row: RowTableUpdate) =>
        dynamicDocInstance.post('/DynamicJournal/DeleteRowInTable', row)
            .then(x => x.data),

    renumberColumnInTable: (model: RenumberJournalTable) =>
        dynamicDocInstance.post('/DynamicJournal/RenumberColumnInTable', model)
            .then(x => x.data),

    clearTable: (row: RowTable) =>
        dynamicDocInstance.post('/DynamicJournal/ClearTable', row)
            .then(x => x.data),

    getTablePagination: (options: JournalTableFilter) =>
        dynamicDocInstance.post<getByFilter<Record<string, string|number>>>('/DynamicJournal/GetTablePagination', options)
            .then(x => x.data),

    getCategoryPage: (id: string, page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DynamicJournalListItem>>(
            `/DynamicJournal/ByCategoryPage?categoryId=${id}&page=${page}&pageSize=${pageSize}`)
            .then(x => x.data),

    getById: (key: string) =>
        dynamicDocInstance.get(`/DynamicJournal/${key}`).then(x => x.data),

    getInfoById: (key: string) =>
        dynamicDocInstance.get(`/DynamicJournal/GetInfo/${key}`).then(x => x.data),

    getByDocumentSchema: (documentSchemaId: string) : Promise<DynamicJournal[]> =>
        dynamicDocInstance.get<DynamicJournal[]>(`/DynamicJournal/GetByDocumentSchema?documentSchemaId=${documentSchemaId}`).then(x => x.data),

    getByDocumentSchemaIdRange: (documentSchemaIds: string[]) : Promise<DynamicJournal[]> =>
        dynamicDocInstance.post<DynamicJournal[]>('/DynamicJournal/GetByDocumentSchemaIdRange', documentSchemaIds).then(x => x.data),

    download: (key: string) =>
        dynamicDocInstance.get(`/DynamicJournal/Download?id=${key}`, { responseType: 'blob' }).then(x => x.data),

    update: (key: string, document: DynamicJournal) =>
        dynamicDocInstance.put(`/DynamicJournal/${key}`, document).then(x => x.data),

    create: (document: DynamicJournal) =>
        dynamicDocInstance.post('/DynamicJournal', document).then(x => x.data),

    copy: (key: string) : Promise<string> =>
        dynamicDocInstance.post<string>(`/DynamicJournal/Copy/${key}`).then(x => x.data),

    createFromFile: (model: DynamicJournalFromFileNew): Promise<string> => {
        const bodyFormData = new FormData();
        bodyFormData.append('Number', model.Number);
        bodyFormData.append('SchemaId', model.SchemaId);
        bodyFormData.append('File', model.File);
        return dynamicDocInstance.post<string>('/DynamicJournal/CreateFromFile', bodyFormData).then(x => x.data);
    },

    import: (id: string, file: File): Promise<string> => {
        const bodyFormData = new FormData();
        bodyFormData.append('File', file);
        return dynamicDocInstance.post<string>(`DynamicJournal/Import/${id}`, bodyFormData).then(x => x.data);
    },

    remove: (key: string) =>
        dynamicDocInstance.delete(`/DynamicJournal/${key}`).then(x => x.data),

    checkIsUpdatedJournal: (id: string, updatedAt: number) =>
        dynamicDocInstance.get(`/DynamicJournal/CheckIsUpdatedJournal?id=${id}&updatedAt=${updatedAt}`)
            .then(x => x.data),

    updateJournalNumber: (model: IdWithTitle) =>
        dynamicDocInstance.post('/DynamicJournal/UpdateJournalNumber/', model)
            .then(x => x.data),

    getPartitionInfo: (id: string): Promise<DynamicJournalPartitionInfo> =>
        dynamicDocInstance.get(`/DynamicJournalPartition/GetJournalPartitionInfo/${id}`)
            .then(x => x.data),

    duplicatePartitionFrom: (model: IdWithTitle) =>
        dynamicDocInstance.post('/DynamicJournalPartition/DuplicatePartitionFrom/', model)
            .then(x => x.data),

    changePartitionTitle: (model: IdWithTitle) =>
        dynamicDocInstance.post('/DynamicJournalPartition/ChangePartitionTitle/', model)
            .then(x => x.data),

    removePartition: (id: string) =>
        dynamicDocInstance.delete(`/DynamicJournalPartition/${id}`)
            .then(x => x.data),
};
