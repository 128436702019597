
    import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
    import { DocumentAudit, DocumentAuditOptions, DocumentHistoryTabs } from '@/types/dynamicDoc';
    import { DataOptions, DataTableHeader } from 'vuetify';
    import { AutocompleteItem, DataTableHeaderCustom, UserResponse } from '@/types';
    import * as documentApi from '@/api/dynamic';
    import MenuFilter from '@/components/reports/menu-filter.vue';
    import {getUserFullName} from "@/components/admin/roles-model/users/helpers";

    @Component({
        components: { MenuFilter },
    })
    export default class DocumentHistoryActions extends Vue {
        @Prop({ type: Array, default: () => [] })
        readonly auditTypes!: AutocompleteItem[];

        @Prop({ type: String, required: true })
        readonly documentId!: string;

        @Prop({ type: Boolean, required: true })
        readonly isVisible!: boolean;

        @PropSync('tab', { type: Number, required: true })
        tabIndex!: DocumentHistoryTabs;

        loading = false;
        totalDocuments = 0;
        timer: any = null;
        audits: DocumentAudit[] = [];
        options: Partial<DocumentAuditOptions> = {
            Search: {},
        };

        optionsTable = {
            page: 1,
            itemsPerPage: 25,
            sortBy: [ 'Timestamp' ],
            sortDesc: [ true ],
        } as DataOptions;

        @Watch('isVisible', { immediate: true })
        async changeTabIndex() {
            if (!this.isVisible) return;
            await this.getAudits();
        }

        @Watch('optionsTable')
        async changedOptionsTable() {
            if (this.loading || !this.optionsTable) return;
            await this.getAudits();
        }

        get users(): UserResponse[] {
            return this.$store.state.reftables.Users;
        }

        get items() {
            return this.audits.map(x => ({
                ...x,
                OldValues: x.Changes?.map(y => {
                    return typeof y.OldValues === 'string' ? { [y.Title]: y.OldValues } : y.OldValues;
                }) || null,
                NewValues: x.Changes?.map(y => {
                    return typeof y.NewValues === 'string' ? { [y.Title]: y.NewValues } : y.NewValues;
                }) || null,
                AppUserName: getUserFullName(x.AppUserId),
                Timestamp: new Date(x.Timestamp).toLocaleString(),
                AuditType: this.auditTypes.find(type => type.value === x.AuditType)?.text,
            }));
        }

        get headerTable(): DataTableHeader[] {
            return [
                { text: '№', value: 'Id' },
                { text: this.$t('common.fullname') as string, value: 'AppUserName' },
                { text: this.$t('common.type') as string, value: 'AuditType' },
                { text: 'Ip', value: 'UserIp' },
                { text: this.$t('common.date') as string, value: 'Timestamp' },
            ];
        }

        menuFilterHandler(item: { value: any, header: DataTableHeaderCustom }) {
            if (item.header?.filterIsSearch) {
                this.options.Search[item.header?.value as keyof DocumentAuditOptions] = item.value;
                this.searchTimeRequest(this.getAudits);
            } else {
                this.options[item.header?.value as keyof DocumentAuditOptions] = item.value;
                this.deleteEmptyValue(this.options);
                this.searchTimeRequest(this.getAudits);
            }
        }

        deleteEmptyValue(item: any) {
            for (const key in item) {
                if (!item[key]?.toString()) delete item[key];
            }
        }

        searchTimeRequest(req: () => Promise<void>) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async() => {
                this.optionsTable.page = 1;
                await req();
            }, 2000);
        }

        async getAudits() {
            if (this.loading) return;
            this.loading = true;
            const res = await documentApi.audits.getByFilter({
                ...this.options,
                PageSize: this.optionsTable.itemsPerPage,
                PageNumber: this.optionsTable.page,
                OrderBy: this.optionsTable.sortBy[0],
                IsDesc: this.optionsTable.sortDesc[0],
                ValueId: this.documentId,
            });
            this.audits = res.Items;
            this.totalDocuments = res.TotalItems;
            this.loading = false;
        }
    }
