/* Generated file. Do not edit. */
export enum AccessContractType {
    FirstVisibleSecond = 'FirstVisibleSecond',
    SecondVisibleFirst = 'SecondVisibleFirst',
    VisibleBoth = 'VisibleBoth',
    NotVisible = 'NotVisible',
}

export enum AnalysisErrorCode {
    MissingDocuments = 'MissingDocuments',
    ChronologyComparison = 'ChronologyComparison',
    ChronologySequenceStages = 'ChronologySequenceStages',
    NameCustomer = 'NameCustomer',
    NameContractor = 'NameContractor',
}

export enum AuditType {
    None = 'None',
    Create = 'Create',
    Update = 'Update',
    Delete = 'Delete',
}

export enum CalendarType {
    Base = 'Base',
    Tour = 'Tour',
}

export enum DataAnalysisOperationType {
    AttributesParsing = 'AttributesParsing',
}

export enum Difference {
    New = 'New',
    Changed = 'Changed',
    Equal = 'Equal',
    Deleted = 'Deleted',
}

export enum DocumentPageFormat {
    A0 = 'A0',
    A1 = 'A1',
    A2 = 'A2',
    A3 = 'A3',
    A4 = 'A4',
    A5 = 'A5',
}

export enum FieldType {
    String = 'String',
    Integer = 'Integer',
    Date = 'Date',
    Reftable = 'Reftable',
    Document = 'Document',
    Table = 'Table',
    PhysicalVolume = 'PhysicalVolume',
}

export enum Locale {
    Ru = 'Ru',
    En = 'En',
}

export enum NotificationStatus {
    New = 'New',
    Read = 'Read',
}

export enum ParagraphExtractionStatus {
    Done = 'Done',
    DoneInvalid = 'DoneInvalid',
}

export enum PeriodCheckRegistry {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
}

export enum RegisterLogType {
    Register = 'Register',
}

export enum RegisterTaskStatus {
    New = 'New',
    InProgress = 'InProgress',
    Completed = 'Completed',
    ExpiredCompleted = 'ExpiredCompleted',
}

export enum RegisterTaskType {
    ResolveIssues = 'ResolveIssues',
}

export enum ReminderPeriodCheckRegistry {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Hour = 'Hour',
}

export enum ReviewStage {
    RegisterCheck = 'RegisterCheck',
    RegisterApproval = 'RegisterApproval',
    RegisterExpert = 'RegisterExpert',
    DrawingPackageCheck = 'DrawingPackageCheck',
    DrawingPackageApproval = 'DrawingPackageApproval',
    KS2Check = 'KS2Check',
    KS2Approval = 'KS2Approval',
}

