import { EventDescriptor } from './event-descriptor';
import getAsBuiltEventDescriptors from '@/components/asbuilt-docs/notifications/asbuilt-event-descriptors';
import getDrawingPackagesEventDescriptors from '@/components/drawing-docs/notifications/drawing-packages-event-descriptors';
import getKs2EventDescriptors from '@/components/ks2/notifications/ks2-event-descriptors';

export default function getEventSettings() : EventDescriptor[] {
    return [
        ...getAsBuiltEventDescriptors(),
        ...getDrawingPackagesEventDescriptors(),
        ...getKs2EventDescriptors(),
    ];
}
