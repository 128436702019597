
    import { Component, Vue } from 'vue-property-decorator';
    import VueI18n from 'vue-i18n';

    @Component({})
    export default class Notify extends Vue {
        showSnackbar = false;
        message = '';
        color = '';
        icon = '';
        timer = 3000;

        show(type: string, text?: VueI18n.TranslateResult, timeout?: number) {
            if (timeout) this.timer = timeout;
            if (type === 'error') {
                this.color = 'error';
                this.message = text ? `${this.$t('errors.error')}: ${text}` : `${this.$t('errors.error')}`;
                this.icon = 'mdi-close-circle-outline';
            } else if (type === 'warning') {
                this.color = 'warning';
                this.message = text ? `${this.$t('common.warning')}: ${text}` : `${this.$t('common.warning')}`;
                this.icon = 'mdi-information-outline';
            } else {
                this.color = 'success';
                this.message = text ? `${this.$t('notify.success')}: ${text}` : `${this.$t('notify.success')}`;
                this.icon = 'mdi-check-circle-outline';
            }
            this.showSnackbar = true;
        }
    }
