import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { getByFilter, MaterialElement, MaterialElementNew, MaterialElementOptions } from '@/types/dynamicDoc';

const controllerName = 'MaterialElement';
export const materialElement = {
    getByFilter: (options: MaterialElementOptions) =>
        dynamicDocInstance.post<getByFilter<MaterialElement>>(`/${controllerName}/GetByFilter`, options)
            .then(x => x.data),

    getById: (id: string): Promise<MaterialElement> =>
        dynamicDocInstance.get<MaterialElement>(`${controllerName}/${id}`).then(x => x.data),

    getByDrawingPackage: (id: string): Promise<MaterialElement[]> =>
        dynamicDocInstance.get<MaterialElement[]>(`${controllerName}/GetByDrawingPackage/${id}`).then(x => x.data),

    getByDrawingPackageRange: (ids: string[]): Promise<MaterialElement[]> =>
        dynamicDocInstance.post<MaterialElement[]>(`${controllerName}/GetByDrawingPackageRange`, ids).then(x => x.data),

    create: (data: MaterialElementNew): Promise<MaterialElement> =>
        dynamicDocInstance.post<MaterialElement>(controllerName, data).then(x => x.data),

    update: (id: string, data: MaterialElement): Promise<MaterialElement> =>
        dynamicDocInstance.put<MaterialElement>(`${controllerName}/${id}`, data).then(x => x.data),

    remove: (id: string): Promise<void> =>
        dynamicDocInstance.delete(`${controllerName}/${id}`).then(x => x.data),
};
