
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import {
        DynamicEditorOptions,
        DynamicJournalPartitions,
        DynamicJournalSchemaPartitions,
        DynamicReftableItem,
        FieldType, IField,
    } from '@/types/dynamicDoc';
    import * as api from '@/api/dynamic';
    import { DataOptions, DataTableHeader } from 'vuetify';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { DataTableHeaderCustom, MenuFilterType } from '@/types';
    import JournalTableItemsDialog from '@/components/dynamic/common/editors/journal-table-items-dialog.vue';

    @Component({
        components: {
            ButtonTooltip,
            JournalTableItemsDialog,
        },
    })
    export default class JournalTableItems extends Vue {
        @Prop({ type: Array, default: () => [] })
        value!: Record<string, string|number>[];

        @Prop({ type: String, required: true })
        private readonly label!: string;

        @Prop({ type: Object, required: true })
        private readonly field!: IField;

        @Prop({ type: Object, required: true })
        private readonly part!: DynamicJournalPartitions;

        @Prop({ type: Object, default: null })
        private readonly schemaPart!: DynamicJournalSchemaPartitions | null;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Object })
        readonly options?: DynamicEditorOptions;

        tableFullPopupVisible = false;
        loading = false;
        totalItems = 0;
        dateJanuary2000 = 946674000000;
        table: Record<string, string|number>[] = [];
        reftables: Record<string, DynamicReftableItem[]> = {};
        optionsTable = {
            page: 1,
            itemsPerPage: 10,
        } as DataOptions;

        @Watch('value', { immediate: true })
        async onChangeValue() {
            await this.getReftable();
        }

        get isCreate() {
            if (!this.options) return false;
            return this.options.isCreated;
        }

        get tableHeaders(): DataTableHeaderCustom[] {
            if (!this.field.TableFields) return [];
            return this.field.TableFields.map(x => ({
                text: x.Title,
                value: x.Title,
                filterVisible: true,
                filterType: MenuFilterType.String,
                filterIsSearch: true,
            })) as DataTableHeader[];
        }

        get items() {
            const arr: Record<string, string | number>[] = [];
            this.table.map(x => ({ ...x }))
                .forEach(item => {
                    Object.keys(item).forEach(key => {
                        const tabField = this.field.TableFields.find(i => i.Title === key);
                        if (tabField && tabField.Type === FieldType.Reftable && tabField.ReftableSchemaId) {
                            const reftable = this.reftables[tabField.ReftableSchemaId as string];
                            const value = reftable?.find(x => x.Id === item[key]);
                            item[key] = value?.Data?.$Display;
                        }
                    });
                    arr.push(this.checkDate(item));
                });
            return arr;
        }

        get orderByField() {
            return this.field.TableFields.find(i => i.IsSortBy);
        }

        get orderBy() {
            return this.orderByField?.Title ?? '';
        }

        checkDate(item: Record<string, string | number>) {
            for (const key in item) {
                if (typeof item[key] === 'number') {
                    item[key] = !isNaN(new Date(item[key]).getTime())
                        && item[key] > this.dateJanuary2000
                        ? new Date(item[key]).toLocaleDateString()
                        : item[key];
                }
            }
            return item;
        }

        async getReftable() {
            for (const field of this.field.TableFields) {
                if (field && field.Type === FieldType.Reftable && field.ReftableSchemaId) {
                    this.reftables[field.ReftableSchemaId as string] = await api.reftableItem
                        .getRelatedSchema(field.ReftableSchemaId, field.Formula as string);
                }
            }
        }

        async getDataTable() {
            if (!this.part.Id || !this.field.TemplateCode || this.loading) return;
            this.loading = true;
            try {
                const { Items, TotalItems } = await api.journal.getTablePagination({
                    DynamicJournalPartitionId: this.part.Id,
                    TableTemplateCode: this.field.TemplateCode,
                    PageNumber: this.optionsTable.page,
                    PageSize: this.optionsTable.itemsPerPage,
                    IsDesc: this.optionsTable.sortDesc[0],
                    OrderBy: this.optionsTable.sortBy[0],
                });
                this.table = Items;
                this.totalItems = TotalItems;
            } finally {
                this.loading = false;
            }
        }

        async mounted() {
            await this.getDataTable();
        }
    }
