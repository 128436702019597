export enum RightConstants {
    RegisterUpdate = 2,
    RegisterAddReviewer = 3,
    RegisterUpdateReviewer = 4,
    RegisterDeleteReviewer = 5,
    RegisterAddDocument = 6,
    RegisterDeleteDocument = 7,
    RegisterTransferForCheck = 8,
    StartCheck = 9,
    FinishCheck = 10,
    AddIssue = 11,
    RegisterGetExperts = 12,
    IssueComments = 13,
    OpenIssue = 14,
    UpdateIssue = 15,
    AddNoteToIssue = 16,
    CancelIssue = 17,
    TransferEliminationIssue = 18,
    StartEliminationIssue = 19,
    FixIssue = 20,
    RegisterCancel = 21,
    RegisterDelete = 22,
    SubmitForReview = 23,
    ApproveIssue = 24,
    StartApproval = 25,
    FinishApproval = 26,
    RegisterReturnStatus = 27,
    SubmitForApproval = 28,
    RequestReviewers = 29,
    RegisterSetSigned = 30,
    RegisterSetWaitingForConstruction = 31,
    PhysicalVolumeGet = 32,
    AccessToDirectories = 33,
    SigningAccess = 34,
    AccessAdministrationModule = 36,
    // РЧ
    DrawingPackageUpdate = 38,
    SubmitForApprovalDrawingPackage = 39,
    SubmitForReviewDrawingPackage = 40,
    StartEliminationIssueDrawingPackage = 41,
    SetArchivedDrawingPackage = 42,
    StartCheckDrawingPackage = 43,
    FinishCheckDrawingPackage = 44,
    AddReviewerDrawingPackage = 45,
    UpdateReviewerDrawingPackage = 46,
    DeleteReviewerDrawingPackage = 47,
    AddIssueDrawingPackage = 48,
    UpdateIssueDrawingPackage = 49,
    ApproveIssueDrawingPackage = 50,
    CancelIssueDrawingPackage = 51,
    FixIssueDrawingPackage = 52,
    IssueCommentsDrawingPackage = 53,
    AddDocumentDrawingPackage = 54,
    DeleteDocumentDrawingPackage = 55,
    // КС-2
    Ks2Update = 57,
    SubmitForApprovalKs2 = 58,
    SubmitForReviewKs2 = 59,
    StartEliminationIssueKs2 = 60,
    SetArchivedKs2 = 61,
    StartCheckKs2 = 62,
    FinishCheckKs2 = 63,
    AddReviewerKs2 = 64,
    UpdateReviewerKs2 = 65,
    DeleteReviewerKs2 = 66,
    AddIssueKs2 = 67,
    UpdateIssueKs2 = 68,
    ApproveIssueKs2 = 69,
    CancelIssueKs2 = 70,
    FixIssueKs2 = 71,
    IssueCommentsKs2 = 72,
    AddDocumentKs2 = 73,
    DeleteDocumentKs2 = 74,
    // Modules
    ExecutiveDocumentationModule = 75,
    WorkingDrawingsModule = 76,
    Ks2Module = 77,
    PermissiveDocumentationModule = 78,
    ReferencesModule = 79,
    TmcModule = 80,
    DocumentsAndJournalsModule = 81,
    SecurityModule = 82,
    GeneralDocumentationModule = 83,
    AdministrationModule = 84,
    // Top menu
    RegistersAsBuilt = 85,
    PhysicalVolumeAsBuilt = 86,
    ReportsAsBuilt = 87,
    TermsWorkAsBuilt = 88,
    ListDrawingPackage = 89,
    ReportsDrawingPackage = 90,
    ListKs2 = 91,
    DocumentsKs2 = 92,
    ReportsKs2 = 93,
    DictionariesPermissiveDocumentation = 94,
    DocumentSchemasPermissiveDocumentation = 95,
    DocumentsPermissiveDocumentation = 96,
    RequirementsPermissiveDocumentation = 97,
    CheckPermissiveDocumentation = 98,
    DictionariesTmc = 99,
    RequirementsTmc = 100,
    IncomingMaterialsTmc = 101,
    ActsEntranceControlTmc = 102,
    ListDocumentsAndJournals = 103,
    DictionariesDocumentsAndJournals = 104,
    DocumentSchemasDocumentsAndJournals = 105,
    DocumentTemplatesDocumentsAndJournals = 106,
    DocumentLinksDocumentsAndJournals = 107,
    StagesDocumentsAndJournals = 108,
    JournalSchemasDocumentsAndJournals = 109,
    JournalsDocumentsAndJournals = 110,
    CompletenessRequirementsDocumentsAndJournals = 111,
    ActiveUsersSecurity = 112,
    ChronologyGeneralDocumentation = 114,
    RolesModelAdministration = 115,
    TemplatesAdministration = 116,
    ActionUsersAdministration = 117,
    SettingsAdministration = 118,
    ProductionCalendarAdministration = 119,
    ReportsAdministration = 120,
    JournalRemove = 121,
}
