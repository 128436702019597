
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { FinalBook, RegisterIssue, RegisterIssueStatus } from '@/api/model/interfaces';
    import { DataTableHeader } from 'vuetify';
    import { localizeDescription } from '@/util/field-translation';
    import { Employee } from '@/types';
    import * as Odata from '@/api/odata';
    import { AsBuiltReportActionName } from '@/components/asbuilt-docs/constants';
    import xlsx, { IJsonSheet } from 'json-as-xlsx';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';

    @Component({
        components: {
            ButtonTooltip,
        },
    })
    export default class IssuesReport extends Vue {
        @Prop({ type: Array, default: () => [] })
        registerIssueStatuses!: RegisterIssueStatus[];

        @Prop({ type: Array, default: () => [] })
        finalBooks!: FinalBook[];

        @Prop({ type: String })
        actionName!: AsBuiltReportActionName;

        registerIssues: RegisterIssue[] = [];
        finalBookId: string | null = null;
        loading = false;

        @Watch('actionName', { immediate: true })
        async onActionName() {
            await this.getReport();
        }

        @Watch('queryParams')
        async onQueryParams() {
            await this.getReport();
        }

        get header(): DataTableHeader[] {
            return [
                { text: this.$t('common.issue') as string, value: 'Code' },
                { text: this.$t('common.header') as string, value: 'Title' },
                { text: this.$t('common.description') as string, value: 'Description' },
                { text: this.$t('common.register') as string, value: 'RegisterCode' },
                { text: this.$t('doc-bundles.final-book') as string, value: 'FinalBook' },
                { text: this.$t('common.document-number') as string, value: 'DocumentCode' },
                { text: this.$t('common.status') as string, value: 'Status' },
            ];
        }

        get items() {
            return this.registerIssues.map(x => {
                const status = this.registerIssueStatuses.find(c => c.Id.toString() === x.StatusId.toString());
                const finalBook = this.finalBooks.find(c => c.Id.toString() === x.Register?.FinalBookId?.toString());
                return {
                    ...x,
                    RegisterCode: x.Register?.Code,
                    FinalBook: finalBook ? localizeDescription(finalBook) : '',
                    DocumentCode: x.Document?.Code,
                    Status: status ? localizeDescription(status) : '',
                };
            });
        }

        get user() {
            return this.$store.state.auth.user;
        }

        get finalBooksFormated() {
            return this.finalBooks.map(x => ({ text: localizeDescription(x), value: x.Id.toString() }));
        }

        get queryParams() {
            return {
                FinalBookId: this.finalBookId,
                AppUserId: this.user.guidId,
                $expand: 'Register,Document',
            };
        }

        async getReport() {
            this.loading = true;
            this.registerIssues = await Odata.AsBuiltReports
                .runFunction('Issues', null, this.queryParams);
            this.loading = false;
        }

        onRowClick(issue: RegisterIssue) {
            if (!issue.RegisterId) return;
            this.$router.push({
                name: 'register-page',
                params: { registerId: issue.RegisterId.toString() },
            });
        }

        download() {
            const data: IJsonSheet[] = [
                {
                    sheet: this.$t('common.report') as string,
                    columns: this.header.map(x => ({
                        label: x.text,
                        value: x.value,
                    })),
                    content: this.items
                        .sort((a: any, b: any) => a.Code - b.Code)
                        .map(x => ({
                            Code: x.Code || '',
                            Title: x.Title || '',
                            Description: x.Description || '',
                            RegisterCode: x.RegisterCode || '',
                            FinalBook: x.FinalBook || '',
                            DocumentCode: x.DocumentCode || '',
                            Status: x.Status || '',
                        })),
                },
            ];
            xlsx(data, { fileName: `report_${new Date().toLocaleString()}` });
        }
    }
