import { reportInstance } from '@/api/report/report-instance';
import ReportApi from '@/api/report/ReportApi';
import ReportTypeApi from '@/api/report/ReportTypeApi';
import SubscriptionApi from '@/api/report/SubscriptionApi';
import LetterApi from '@/api/report/LetterApi';
import ConditionItemApi from '@/api/report/ConditionItemApi';
import FavoriteSystemReportApi from './FavoriteSystemReportApi';

/** Отчеты */
export const report = new ReportApi('Report', reportInstance);
export const favoriteSystemReport = new FavoriteSystemReportApi('FavoriteSystemReport', reportInstance);
export const reportType = new ReportTypeApi('ReportType', reportInstance);
export const subscription = new SubscriptionApi('Subscription', reportInstance);
export const letter = new LetterApi('Letter', reportInstance);
export const condition = new ConditionItemApi('ConditionItem', reportInstance);
