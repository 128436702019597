
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { RegisterGroupByReviewerUserIdResponse, RegisterWidgetResponse, WidgetType } from '@/types/cis/widget';
    import { DataOptions, DataTableHeader } from 'vuetify';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { UserResponse } from '@/types';
    import { getUserFullName } from '@/components/admin/roles-model/users/helpers';

    @Component({
        components: { ButtonTooltip },
    })
    export default class ChartRegisterInfo extends Vue {
        @Prop({ type: String, required: true })
        readonly title!: string;

        @Prop({ type: Number, required: true })
        readonly type!: WidgetType;

        @Prop({ type: String, required: true })
        readonly color!: string;

        @Prop({ type: Array, required: true })
        readonly items!: RegisterWidgetResponse[] | RegisterGroupByReviewerUserIdResponse[];

        readonly user = this.$store.getters.getUser;

        showTable = false;

        get isChief(): boolean {
            return this.user.isChief;
        }

        get isAdmin(): boolean {
            return this.$store.getters.isAdmin;
        }

        get users(): UserResponse[] {
            return this.$store.state.reftables.Users;
        }

        get count(): number {
            return this.itemsFormat.length;
        }

        get countForUser(): number {
            if (this.user.isAdmin || this.user.isChief) {
                return (this.items as RegisterGroupByReviewerUserIdResponse[])
                    .filter(x => this.user.guidId === x.ReviewerUserId).length;
            }

            if (!this.items.length) return 0;
            return (this.items as RegisterGroupByReviewerUserIdResponse[])
                [0].Registers.length;
        }

        get options(): Partial<DataOptions> {
            return this.isType.error
                ? {
                    groupBy: [ 'name' ],
                }
                : {};
        }

        get headers(): DataTableHeader[] {
            return [
                { text: this.$t('common.title') as string, value: 'text' },
                { text: '', value: 'actions', align: 'end' },
            ];
        }

        get itemsFormat() {
            if (!this.isType.error) {
                return (this.items as RegisterWidgetResponse[]).map(x => ({
                    text: `${x.Code} ${this.$t('common.register')}`,
                    value: x.Id,
                }));
            }
            return (this.items as RegisterGroupByReviewerUserIdResponse[]).filter(x =>
                this.isChief || this.isAdmin || this.user.guidId === x.ReviewerUserId)
                .reduce((arr: any[], item) => [
                    ...arr,
                    ...item.Registers.map(reg => ({
                        name: getUserFullName(item.ReviewerUserId),
                        text: this.$tc('common.n-register', Number(reg.Code)),
                        value: reg.Id,
                        date: reg.ReviewEndDate ? new Date(reg.ReviewEndDate).toLocaleDateString() : null,
                    })),
                ], []);
        }

        get isType() {
            return {
                expert: this.type === WidgetType.Expert,
                issue: this.type === WidgetType.Issue,
                error: this.type === WidgetType.Error,
            };
        }

        outsideClick() {
            this.showTable = false;
        }
    }
