import { authInstance } from './auth-instance';
import { EntityApi, FilterItemsResponse, OptionsTable, PositionRequest, PositionResponse } from '@/types';

export const position: EntityApi<PositionResponse, PositionRequest> = {
    getAll: (): Promise<PositionResponse[]> =>
        authInstance.get<PositionResponse[]>('positions/all').then(x => x.data),

    getByFilter: (options: OptionsTable): Promise<FilterItemsResponse<PositionResponse>> =>
        authInstance.post<FilterItemsResponse<PositionResponse>>('positions/byFilter', options).then(x => x.data),

    getById: (id: number): Promise<PositionResponse> =>
        authInstance.get<PositionResponse>(`positions/${id}`).then(x => x.data),

    create: (data: PositionRequest): Promise<PositionResponse> =>
        authInstance.post<PositionResponse>('positions', data).then(x => x.data),

    update: (id: number, data: PositionRequest): Promise<PositionResponse> =>
        authInstance.put<PositionResponse>(`positions/${id}`, data).then(x => x.data),

    remove: (id: number): Promise<void> =>
        authInstance.delete(`positions/${id}`).then(x => x.data),
};
