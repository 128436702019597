
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import TooltipWrapper from '@/components/dynamic/common/tooltip-wrapper.vue';

    @Component({
        components: { TooltipWrapper },
    })
    export default class BooleanInput extends Vue {
        @Prop({ type: String, required: true })
        private readonly label!: string;

        @Prop([ Boolean, String ])
        private readonly value!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly disabled!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly validate!: boolean;

        formatValue($event: any) {
            this.$emit('input', Boolean($event));
        }
    }
