
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { FinalBook, Register, RegisterStatus } from '@/api/model/interfaces';
    import { DataTableHeader } from 'vuetify';
    import { localizeDescription, localizeName } from '@/util/field-translation';
    import { ContragentResponse, Employee } from '@/types';
    import * as Odata from '@/api/odata';
    import { ReviewStage } from '@/api/model/enums';
    import { AsBuiltReportActionName } from '@/components/asbuilt-docs/constants';
    import xlsx, { IJsonSheet } from 'json-as-xlsx';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';

    @Component({
        components: {
            ButtonTooltip,
        },
    })
    export default class RegistersWithCheckReport extends Vue {
        @Prop({ type: Array, default: () => [] })
        registerStatuses!: RegisterStatus[];

        @Prop({ type: Array, default: () => [] })
        finalBooks!: FinalBook[];

        @Prop({ type: String })
        actionName!: AsBuiltReportActionName;

        registers: Register[] = [];
        finalBookId: string | null = null;
        contractorId: number | null = null;
        loading = false;

        @Watch('actionName', { immediate: true })
        async onActionName() {
            await this.getReport();
        }

        @Watch('queryParams')
        async onQueryParams() {
            await this.getReport();
        }

        get header(): DataTableHeader[] {
            return [
                { text: this.$t('common.register-number') as string, value: 'Code' },
                { text: this.$t('common.date') as string, value: 'RegisterDate' },
                { text: this.$t('common.contractor') as string, value: 'Contractor' },
                { text: this.$t('common.subcontractor') as string, value: 'SubContractor' },
                { text: this.$t('common.status') as string, value: 'Status' },
                { text: this.$t('common.status-date') as string, value: 'LastStatusDate' },
                { text: this.$t('doc-bundles.final-book') as string, value: 'FinalBook' },
            ];
        }

        get items() {
            return this.registers.map(x => {
                const contractor = this.contragents.find(c => c.id === x.ContractorId);
                const subContractor = this.contragents.find(c => c.id === x.SubContractorId);
                const status = this.registerStatuses.find(c => c.Id.toString() === x.StatusId.toString());
                const finalBook = this.finalBooks.find(c => c.Id.toString() === x.FinalBookId?.toString());
                return {
                    ...x,
                    RegisterDate: x.RegisterDate ? new Date(x.RegisterDate.toString()).toLocaleDateString() : '',
                    Contractor: contractor ? localizeName(contractor) : '',
                    SubContractor: contractor ? localizeName(subContractor) : '',
                    Status: status ? localizeDescription(status) : '',
                    LastStatusDate: x.LastStatusDate ? new Date(x.LastStatusDate).toLocaleDateString() : null,
                    FinalBook: finalBook ? localizeDescription(finalBook) : '',
                };
            });
        }

        get user() {
            return this.$store.state.auth.user;
        }

        get contragents(): ContragentResponse[] {
            return this.$store.state.reftables.Contragents;
        }

        get contragentsFormated() {
            return this.contragents.map(x => ({ text: localizeName(x), value: x.id }));
        }

        get finalBooksFormated() {
            return this.finalBooks.map(x => ({ text: localizeDescription(x), value: x.Id.toString() }));
        }

        get queryParams() {
            return {
                ReviewStage: ReviewStage.RegisterCheck,
                FinalBookId: this.finalBookId,
                ContragentId: this.contractorId,
                EmployeeId: this.user.guidId,
            };
        }

        async getReport() {
            this.loading = true;
            this.registers = await Odata.AsBuiltReports
                .runFunction('RegistersWithCheck', null, this.queryParams);
            this.loading = false;
        }

        goToRegister(registerId: string) {
            this.$router.push({
                name: 'register-page',
                params: { registerId },
            });
        }

        onRowClick(register: Register) {
            this.goToRegister(register.Id.toString());
        }

        download() {
            const data: IJsonSheet[] = [
                {
                    sheet: this.$t('common.report') as string,
                    columns: this.header.map(x => ({
                        label: x.text,
                        value: x.value,
                    })),
                    content: this.items
                        .sort((a: any, b: any) => a.Code - b.Code)
                        .map(x => ({
                            Code: x.Code || '',
                            RegisterDate: x.RegisterDate || '',
                            Contractor: x.Contractor || '',
                            SubContractor: x.SubContractor || '',
                            Status: x.Status || '',
                            LastStatusDate: x.LastStatusDate || '',
                            FinalBook: x.FinalBook || '',
                        })),
                },
            ];
            xlsx(data, { fileName: `report_${new Date().toLocaleString()}` });
        }
    }
