import { authInstance } from './auth-instance';
import { FilterItemsResponse, Module, OptionsModule } from '@/types';

export const module = {
    getAll: (): Promise<Module[]> =>
        authInstance.get<Module[]>('Module').then(x => x.data),

    getByFilter: (options: OptionsModule): Promise<FilterItemsResponse<Module>> =>
        authInstance.post<FilterItemsResponse<Module>>('Module/byFilter', options).then(x => x.data),

    getById: (id: number): Promise<Module> =>
        authInstance.get<Module>(`Module/${id}`).then(x => x.data),

    create: (data: Module): Promise<Module> =>
        authInstance.post<Module>('Module', data).then(x => x.data),

    update: (id: number, data: Module): Promise<Module> =>
        authInstance.put<Module>(`Module/${id}`, data).then(x => x.data),

    remove: (id: number): Promise<void> =>
        authInstance.delete(`Module/${id}`).then(x => x.data),
};
