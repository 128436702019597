
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
    import { DynamicReftableItem, IField } from '@/types/dynamicDoc';
    import { required, requiredMany } from '@/components/common/validation-rules';
    import * as api from '@/api/dynamic/';
    import TooltipWrapper from '@/components/dynamic/common/tooltip-wrapper.vue';

    @Component({
        components: { TooltipWrapper },
    })
    export default class MultiReftableItemSelector extends Vue {
        @Prop([ String, Array ])
        private readonly value!: string | string[];

        @Prop({ type: Object, required: true })
        private readonly field!: IField;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly disabled!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly validate!: boolean;

        @Prop({ type: Number, default: null })
        private readonly documentDate!: number;

        relatedSchemas: DynamicReftableItem[] = [];

        rules = { required, requiredMany }

        @Watch('field', { immediate: true })
        async onFieldChange() {
            if (!this.field.MultiReftables?.length) return;
            this.relatedSchemas = await api.reftableItem.multiRelatedSchema(this.field.MultiReftables);
        }

        get rulesData() {
            if (!this.validate) return [];
            const rule = this.field.IsMultiple
                ? this.rules.requiredMany
                : this.rules.required;
            return [ rule ];
        }

        getFullName(item: DynamicReftableItem, index: number) {
            return typeof this.value === 'object' && this.value.length !== index + 1
                ? item.Data.$DisplayFullName + ','
                : item.Data.$DisplayFullName;
        }
    }

