import * as Odata from '@/api/odata';
import { DrawingPackagesAppSettingsId } from './constants';
import { DrawingPackage } from '@/api/model/interfaces';
import i18n from '@/plugins/i18n';
import { DrawingPackageIssueStatus, DrawingPackageStatus } from '@/api/constants';

export default function getDrawingPackagesAppSettings(): Promise<DrawingPackagesAppSettings> {
    return Odata.DrawingPackagesAppSettings.getById(DrawingPackagesAppSettingsId) as any;
}

export interface DrawingPackagesAppSettings {
    SignatureEnabled: boolean;
    CheckEnabled: boolean;
}

export function drawingPackageTitle(dp: DrawingPackage) {
    return dp.MainCode;
}

interface IssueStatusInfo {
    status: string;
    name: string;
}

const statusSet: IssueStatusInfo[] = [
    { status: DrawingPackageIssueStatus.Draft, name: i18n.t('common.draft') as string },
    { status: DrawingPackageIssueStatus.Issued, name: i18n.t('common.issued') as string },
    { status: DrawingPackageIssueStatus.Approved, name: i18n.t('common.approved') as string },
    { status: DrawingPackageIssueStatus.Fixed, name: i18n.t('common.fixed') as string },
    { status: DrawingPackageIssueStatus.Canceled, name: i18n.t('common.canceled') as string },
];

export const drawingIssueStatusName = (status: string): string => {
    return statusSet.find(x => x.status === status)?.name || '';
};

export const drawingStatusBtnVisible = (dp: DrawingPackage): boolean | void => {
    return (dp.StatusId.toString() === DrawingPackageStatus.Draft
    || dp.StatusId.toString() === DrawingPackageStatus.ResolvingIssues);
};
