import i18n from '@/plugins/i18n';
import { GetByIdFunction, IBaseApi } from '@/types/cis/IBaseApi';
import { DocumentPhysicalVolume, DrawingPackage, DynamicDocumentItem, FileDesc, IEntity } from '@/api/model/interfaces';
import { RegisterIssueStatus } from '@/api/constants';
import { ITimeStampRange, WorkType } from '@/types';
import { EntityData } from '@/api/entity/manager';

export const enum TemplateReftableConstant {
    Nostroy = '84be79a7-3bf9-4262-8a82-c620b4625881',
}

export const enum NostroyTemplateNames {
    Number = 'Идентификационный номер',
    SurName = 'Фамилия',
    FirstName = 'Имя',
    Patronymic = 'Отчество',
    DateStart = 'Дата внесения',
    DateEnd = 'Дата исключения',
    Status = 'Статус',
}

export enum DocumentTabs {
    Main,
    File,
    Viewer,
    Related,
    Links,
    ExternalLinks,
}

export const enum NostroyStatus {
    Active = 'Действует',
    Inactive = 'Исключен',
}

export enum FieldType {
    String = 0,
    Integer = 1,
    Date = 2,
    Reftable = 3,
    Document = 4,
    Table = 5,
    PhysicalVolume = 6,
    MultiReftable = 7,
    PermitDocument = 8,
    Boolean = 9,
}

export enum DocumentSchemaDialogType {
    NotFile,
    File
}

export enum DocumentsColumn {
    DocType,
    Document
}

export enum TemplateColumn {
    Group,
    Template
}

export enum SchemaColumn {
    DocType,
    Schema
}

export enum JournalColumn {
    DocType,
    Journal
}

export enum IssueStatusTypeBtn {
    Issued,
    Cancel,
    Approve,
    Fixed,
}

export enum DocumentRegisterStatus {
    NotRegister = '255d63f9-10ef-4b5e-a6bd-85fa396b1f47'
}

/** Не удалять и не менять местами, собъет логику в текущих схемах */
export enum DocumentFormHandler {
    PackageAuthorSupervisionChangeHandler,
}

export interface IObsoletable {
    IsObsolete?: boolean;
}

export interface DynamicDocumentPhysicalVolume extends IEntity, EntityData {
    CreatedAt: Date;
    DynamicDocumentItem?: DynamicDocumentItem | null;
    DynamicDocumentItemId: string | null;
    MeasurementUnit?: MeasurementUnit | null;
    MeasurementUnitId: string | null;
    Value: number;
    WorkType?: WorkType | null;
    WorkTypeId: string | null;
}

export interface IssueBtnOption {
    Name: string;
    Color: string;
    Visible: boolean;
    Disabled: boolean;
    Tooltip: string | null;
    Status: RegisterIssueStatus | null;
    Icon: string | null;
}

export enum TypeControl {
    Nostroy,
    NostroySro,
    NostroySroMember,
    GosnadzorSro,
    Naks,
    SroNopriz,
    WeldingMaterial,
    MemberSroNopriz,
    WeldingEquipment,
    CertifiedWeldingOrganization,
    SpecialistNopriz,
    CisContragent,
}

export interface TypeControlSelectItem {
    text: string;
    value: TypeControl;
}

export const typeControls: TypeControlSelectItem[] = [
    { text: `${i18n.t('common.check')} nostroy.ru`, value: TypeControl.Nostroy },
];

export enum StatusControl {
    New,
    Valid,
    Invalid,
}

export enum DateType {
    Default,
    Document,
    StartWork,
    EndWork,
}
export interface IMultiReftable {
    Id?: string;
    FieldId?: string | null;
    IsMultiple?: boolean;
    ReftableSchemaId: string | null;
    ReftableSchema?: ReftableSchema | null;
    Formula: string | null;
    FormulaSmall: string | null;
}

export interface IField {
    Id?: string;
    SchemaId?: string;
    Title: string;
    Type: FieldType;
    IsMultiple: boolean;
    IsSortBy?: boolean;
    DateType: DateType;
    ReftableSchemaId: string | null;
    ReftableSchema?: ReftableSchema | null;
    MultiReftables: IMultiReftable[];
    Formula: string | null;
    FormulaSmall: string | null;
    TableFields: IField[];
    IsControl: boolean;
    IsGeneratedBySideService: boolean;
    TypeControl: TypeControl | null;
    IsGenerate?: boolean;
    Position: number;
    RelatedFieldId: string | null;
    TemplateCode?: string;
    RelatedReftableId?: string | null;
    RelationType?: RelationType;
    DefaultValue?: any;
    IsCount?: boolean;
}

export interface RelatedReftableRequest {
    DocumentFieldId: string;
    DynamicReftableItemId: string;
    TemplateCode: string;
    Formula: string;
    FormulaFullName: string;
}

export enum RelationType {
    One = 1,
    Many = 2,
}

export interface DocumentField extends IField {
    TemplateCode: string;
}

export interface IdWithTitle extends IObsoletable {
    Id: string;
    Title: string;
}

export interface DynamicDocumentCategory {
    Id?: string;
    Title: string;
    RootId: string | null;
    UserId?: number;
    IsHidden: boolean;
}

export interface DocumentCategoryTree {
    id: string;
    type: string;
    name: string;
    rootId: string | null;
    isObsolete?: boolean;
    isHidden?: boolean;
    children: DocumentCategoryTree[] | null;
}

export interface SchemaLinkRequest {
    CurrentSchemaId: string;
    LinkDocumentSchemaId: string;
}

export interface RelatedField {
    Id?: string | null;
    Name: string | null;
    SchemaId: string | null;
    ReftableItemId: string | null;
    ReftableSchemas?: IdWithTitle[];
}

export interface RelatedFieldItem {
    Id?: string | null;
    FieldsStr: string | null;
    RelatedFieldId: string | null;
    ReftableSchemaId: string | null;
    DynamicReftableItemId: string | null;
    DocumentId: string | null;
    Fields: string[];
}

export interface FormulaItemQuery {
    DocumentFieldId: string;
    Formula: string;
    FormulaFullName: string;
    Title: string;
}

export interface FormulaRangeQuery {
    ReftableItemId: string;
    Items: FormulaItemQuery[];
}

export interface DataFormulaResponse {
    DocumentFieldId: string;
    ValueFormula: string;
    ValueFormulaFull: string;
    Title: string;
}

export interface DynamicDocumentSchema {
    Id?: string;
    Number?: string;
    CategoryId?: string;
    FieldSet?: DocumentField[];
    TemplateFilePath?: string;
    Title: string;
    TechnicalDocumentationLink: string | null;
    RelatedFields: RelatedField[];
    IsIncludedFinalArchive: boolean;
    DefaultPageCount?: number;
    DefaultPageFormat?: DocumentPageFormat;
    FormHandlers?: DocumentFormHandler[];
    IsSystem: boolean;
    IsObsolete: boolean;
}

export interface DocumentSchemaShort {
    Id: string;
    Title: string;
    IsIncludedFinalArchive?: boolean;
    CategoryId: string;
    IsObsolete?: boolean;
    RootId?: string;
    FormHandlers?: DocumentFormHandler[];
}

export interface DynamicDocumentSchemaWithFile extends DynamicDocumentSchema {
    Number: string;
    CategoryId: string;
    FieldSet: DocumentField[];
    DocumentFile: any;
    TechnicalDocumentationLink: string | null;
}

export interface DynamicDocumentSchemaNew {
    Category: string;
    Title: string;
    TemplateFile: any;
    TechnicalDocumentationLink?: string;
    IsIncludedFinalArchive: boolean;
    DefaultPageCount?: number;
    DefaultPageFormat?: DocumentPageFormat;
    FormHandlers?: DocumentFormHandler[];
    IsSystem?: boolean;
}

export type DynamicJournalCategory = DynamicDocumentCategory;

export type DynamicJournalFieldSet = DocumentField;

export interface DynamicJournalListItem extends DynamicDocumentListItem {
    IsClosed: boolean;
    ClosedDate: number | null;
    ClosedUserId: string | null;
}

export interface DynamicJournalFromFileNew {
    Number: string,
    SchemaId: string,
    File: any,
}

export interface DynamicJournalSchemaPartitions {
    Id: string;
    Title: string;
    FieldSet?: DynamicJournalFieldSet[];
    Data?: any;
    JournalSchemaId: string;
    Position: number;
    CanDuplicateDynamicJournalPartition?: boolean;
}

export interface DynamicJournalPartitions {
    Id: string;
    DynamicJournalId: string;
    Position: number;
    Title: string;
    SchemaId: string;
    Data?: any;
}

export interface DynamicJournal {
    Id: string;
    Code: number;
    SchemaId: string;
    Schema: DynamicJournalSchema;
    Partitions: DynamicJournalPartition[];
    Number: string;
    CreatedAt: number;
    ClosedUserId: string | null;
    ContragentId?: number;
    UpdatedAt: number;
    Data: any;
    IsClosed: boolean;
    ClosedDate: number | null;
    CreateUserId: string;
    JournalSplitAccess: JournalSplitAccess | null;
}
export interface DynamicJournalPartition {
    Id: string;
    DynamicJournalId: string;
    DynamicJournal?: DynamicJournal;
    SchemaId: string;
    Title: string;
    Position: number;
    Data?: any;
    ParentId?: string;
    Parent?: DynamicJournalPartition;
    DynamicJournalTableFields: DynamicJournalTableField[];
}

export interface DynamicJournalPartitionInfo {
    JournalId: string,
    Partition: DynamicJournalPartition,
    SchemaPartition: DynamicJournalSchemaPartitions,
    SchemaNumber: string,
}

export interface DynamicJournalTableField {
    DynamicJournalId: string;
    DynamicJournalPartitionId: string;
    Title: string;
    CountRow: number;
    DataTableRow: Record<string, object>;
}

export interface JournalSplitAccess {
    DynamicJournalId: string;
    DynamicJournal: DynamicJournal;
    RestrictedAccessJournal: RestrictedAccessJournal;
}

export interface RestrictedAccessJournal {
    AccessRow: Access;
    AccessPartitions: AccessPartitions[];
}

export interface Access {
    IsAccess: boolean;
    UserChangesId: string;
}

export interface AccessPartitions {
    Id: string;
    AccessTables: Record<string, Access>
}
export interface JournalCategoryFilter extends GetByFilterOptions {
    Title?: string;
}

export interface DynamicJournalSchema extends DynamicDocumentSchema {
    Partitions: DynamicJournalSchemaPartitions[];
    TemplateFilePath: string;
    CreatedAt?: Date | string;
}

export interface Options {
    PageNumber?: number;
    PageSize?: number;
    IsDesc?: boolean;
    Search?: any;
    SearchDocumentData?: any;
    CategoryId?: string;
    Id?: string;
    AuditType?: number;
    TableName?: string;
    AppUserId?: string;
    ValueId?: string;
    SchemaId?: string;
    DrawingPackageId?: string | null;
    WorkTypeId?: string | null;
    SearchMainStr?: string | null;
    SearchResult?: SearchResult | null;
    IsPersonal?: boolean;
    IsAddedInRegister?: boolean;
    DateRange?: ITimeStampRange;
    OrderBy?: string;
    ContragentId?: string | null;
    IsSystem?: boolean;
}

export interface IssueOptions {
    Id?: string;
    Code?: number;
    DynamicDocumentId?: string;
    StatusId?: string;
    RegisterId?: string;
    CreatedAtIterationId?: string;
    ReviewerId?: string;
    ReviewId?: string;
}

export interface OptionsPage {
    Page: number;
    TotalPages: number;
    TotalItems?: number;
    PageSize: number;
    Search?: any;
    SearchDocumentData?: any;
}

export interface getByFilter<T> {
    PageSize: number;
    TotalItems: number;
    OrderBy: string;
    Items: T[];
    TotalPages: number;
}

export interface DynamicJournalSchemaNew extends DynamicDocumentSchemaNew
{
    CopyFromId?: string | null;
}

export interface DynamicDocumentListItem {
    Id: string;
    Code: number;
    Type: string;
    Number: string;
    IsAddedInRegister: boolean;
    RegisterId: string;
    RegisterStatus: string;
    DrawingPackageIds: string[] | null;
    WorkTypeIds: string[];
    IsErrorIn: boolean;
    IsErrorReason: boolean;
    Date: Date | string;
    CreatedAt: Date | string;
    StartDate: Date | string | null;
    EndDate: Date | string | null;
    UserId: number;
    ContragentId: number | null;
    ArchiveName: string | null;
    SchemaIsObsolete: boolean;
}

export interface DynamicDocument {
    Id: string;
    SchemaId: string | null;
    Data: any;
    Code: number;
    Number: string;
    IsAddedInRegister: boolean;
    RegisterId: string;
    RegisterStatus: string;
    DrawingPackageIds: string[];
    WorkTypeIds: string[];
    CoordinateAxisIds: string[];
    WorkTypes: Work[];
    DrawingPackages: DrawingPackage[];
    IsGenerated?: boolean;
    IsBlock: boolean;
    TechnicalDocumentationLink?: string | null;
    FileDescId?: string | null;
    AltitudeMarks: AltitudeMark[];
    CoordinateAxis: CoordinateAxi[];
    Date: number | null;
    CreatedAt: number | null;
    StartDate: number | null;
    EndDate: number | null;
    UserId: number;
    LeadErrors: DocumentLeadErrors[] | null;
    RelatedFieldItems: RelatedFieldItem[];
    Schema?: IdWithTitle;
    RootId: string | null;
    SignatoryUsers: SignatoryUser[];
    PageCount: number;
    PageFormat: DocumentPageFormat,
    ArchiveName: string | null,
}

export enum DocumentPageFormat {
    A0,
    A1,
    A2,
    A3,
    A4,
    A5,
}

export interface RegisterDocumentPagesInfo {
    MaxCount?: number,
    CurrentCount: number,
}

export interface DocumentLeadErrors {
    Id: string;
    DateCreate: number;
    Message: string;
    TypeInId: string;
    TypeInName: string;
    TypeReasonId: string;
    TypeReasonName: string;
}

export interface DynamicDocumentWithLinkName extends DynamicDocument {
    LinkName: string;
}

export interface DocumentShort {
    Id: string;
    Code: number;
    Number: string;
    IsAddedInRegister: boolean;
    RegisterId: string;
    RegisterStatus: string;
    SchemaName: string;
    IsErrorIn: boolean;
    IsErrorReason: boolean;
    FileDescId: string;
    IsDeprecated: boolean;
    CurrentRegisterId: string | null;
    DrawingPackageIds?: string[];
    WorkTypeIds?: string[];
    Date: number;
    CreatedAt: number;
}

export interface AltitudeMarkGroupByWorkType {
    Id: string;
    AltitudeMark: string;
}

export interface CoordinateAxiGroupByWorkType {
    Id: string;
    CoordinateAxi: string;
    AltitudeMarks: AltitudeMarkGroupByWorkType[];
}

export interface DynamicDocumentFilterRequest {
    Id?: string;
    SchemaId?: string;
    SchemaName?: string;
    Number?: string;
    Code?: number;
    CategoryId?: string;
    ContragentId?: string;
    WorkTypeId?: string;
    AltitudeMarkId?: string;
    CoordinateAxi?: string;
    DrawingPackageId?: string;
    DrawingPackageIds?: string[] | null;
    RegisterIds?: string[] | null;
    PageNumber?: number;
    PageSize?: number;
    OrderBy?: string | null;
    IsDesc?: boolean | null;
}

export interface DynamicDocumentFile {
    file: File;
    signature?: File;
}

export interface DocumentTemplate {
    Title: string;
    SchemaId: string;
    Data: any;
    IsPersonal: boolean;
    UserId?: number | null;
    DrawingPackageIds: string[];
}

export interface DocumentAuditChanges {
    Title: string;
    OldValues: any;
    NewValues: any;
}
export enum AuditType {
    Get,
    Create,
    Update,
    Delete,
    Export,
    NewVersion
}
export enum DocumentHistoryTabs {
    edited,
    actions
}

export interface Audit {
    Id: string;
    AppUserId: number;
    AppUserName?: string;
    DisplayName: string;
    OldValues?: any[];
    NewValues?: any[];
    AuditType: AuditType;
    TableName: string;
    ValueId: string;
    Changes: DocumentAuditChanges[] | null;
    Timestamp: number;
    UserIp: string;
    GuidId: string;
    Email: string;
    FirstName: string;
    LastName: string;
    TypeName: string;
    Message: string;
}
export interface DocumentAudit extends Audit {
    SchemaId: string;
    SchemaTitle: string;
    VersionNumber: number;
}

export interface ReftableItemAudit extends Audit {
    SchemaId: string;
    SchemaTitle: string;
}

export interface GetByFilterOptions {
    PageNumber?: number;
    PageSize?: number;
    OrderBy?: string;
    IsDesc?: boolean;
    Search?: any,
    Id?: string;
}

export interface DocumentAuditOptions extends GetByFilterOptions{
    AuditType?: number;
    TypeName?: string;
    AppUserId?: number;
    ValueId?: string;
    IsChanged?: boolean;
}
export interface ReftableItemAuditOptions extends GetByFilterOptions {
    SchemaId?: string;
    AuditType?: number;
    TypeName?: string;
    AppUserId?: number;
    ValueId?: string;
    IsChanged?: boolean;
}

export interface MaterialElementOptions extends GetByFilterOptions{
    Name?: string;
    DrawingPackageId: string;
}

export interface DocumentTemplateAndType {
    DocumentTemplateId: string;
    TemplateIsDeleted: boolean;
    DocumentTemplate: string;
    DocumentType: string;
    UserId?: number | null;
}

export interface DynamicDocumentItemResponse {
    Id: string;
    ItemId?: string;
    RootId: string;
    DynamicDocumentId: string;
    Code: number;
    Title: string;
    CreatedAt: Date;
    VersionNumber: number;
    Number: string;
    FileDescId: string | null;
    FileDesc: FileDesc | null;
    ExpertCommentariesSet: string[];
    IsDeprecated?: boolean;
    SortOrder: number;
    IsIncludedFinalArchive: boolean;
    IsSystem: boolean;
}

export interface DynamicJournalResponse {
    Id: string;
    Code: number
    Type: string,
    SchemaIsObsolete: boolean,
    Number: string,
    IsGenerated: boolean;
    UpdatedAt: number
    ClosedDate: string | null;
    IsClosed: boolean;
    ClosedUserId: string | null;
    CreateUserId: string;
    ContragentId?: number;
    CreatedAt: number;
    Schema?: DynamicJournalSchema;
    CategoryId: string;
}

export interface NostroyUpdate {
    DynamicReftableItemId: string;
    Number: string;
}

export interface RefreshDynamicItemFromApiRequest {
    DynamicReftableItemId: string;
    SearchData: string;
    TypeControl: TypeControl;
}

export interface DynamicReftableFieldValue {
    StringValue: string;
    NumberValue?: number;
    DataValue?: string;
    IntervalMinValue?: number;
    IntervalMaxValue?: number;
    ReftableItemId?: string;
    DynamicDocumentId?: string;
}

export interface DynamicReftableField {
    DynamicItemId: string;
    ReftableFieldId: string;
    ReftableField: ReftableField;
    Title: string;
    FieldType: FieldType;
    IsMultiple: boolean;
    DynamicFieldValues: DynamicReftableFieldValue[];
}

export interface DynamicReftableItem extends IObsoletable {
    Id: string;
    SchemaId: string;
    Data: any;
    DynamicReftableFields: ReftableField[];
}

export interface DynamicReftableItemOptions {
    PageNumber?: number;
    PageSize?: number;
    OrderBy?: string | null;
    IsDesc?: boolean | null;
    Search?: any;
    Id?: string;
    Value?: number;
    SchemaId: string;
    IsObsolete?: boolean | null;
}

export interface DynamicReftableItemResponse {
    Items: DynamicReftableItem[];
    CountDocuments: number;
}
export enum DataParseType {
    String,
    Number,
    Date,
    PhotoLink
}

export interface DataParseItem {
    ParseType: DataParseType;
    Title: string;
    Value: string;
}

export interface ApiServiceParseData {
    DataParseItems: DataParseItem[];
    Url: string;
}

export interface DynamicDocServiceParse {
    DataFromService: ApiServiceParseData;
    DataParseResult: Record<string, string | number>
}

export interface ReftableSchema extends IObsoletable {
    Id: string;
    Title: string;
    TemplateId: string | null;
    FieldSet: ReftableField[];
    DisplayExpression: string;
}

export interface ReftableSchemaValidationResult {
    DocumentSchemaId: string;
    DocumentSchemaTitle: string;
    DocumentFields: DocumentField[];
}

export interface ReftableFormulaDesign {
    Title: string;
    SubReftable: ReftableFormulaDesign[] | null;
}

export interface ReftableField extends IField {
    Title: string;
    Type: FieldType;
    IsMultiple: boolean;
    ReftableSchemaId: string;
    IsNew?: boolean;
    DynamicFieldValues: DynamicFieldValues[];
}

export interface DynamicFieldValues {
    DynamicReftableFieldId: string;
    Id: string;
    ReftableItemId: string;
}

export enum LinkFieldType {
    Schema,
    Document,
}

export interface LinkResponse {
    Field: string;
    LinkFieldType: LinkFieldType;
    Description: string;
    IsActive: boolean;
}

export interface DocumentLink {
    Id?: string;
    Name: string;
    DocumentSchemaId: string;
    LinkDocumentSchemaId: string;
    SchemaFields: string[];
    DocumentFields: string[];
}

export interface DynamicPhysicalVolume {
    id?: number;
    workType: string | null;
    value: number | null;
    measurementUnit: string | null;
}

export enum VolumeCategory {
    Project,
    Defect,
}

export interface PhysicalVolumeOptions {
    PageNumber?: number;
    PageSize?: number;
    OrderBy?: string | null;
    IsDesc?: boolean | null;
    Search?: any;
    Id?: string;
    Value?: number;
    Type?: VolumeCategory;
    IsActual?: boolean;
    PhysicalVolumeId?: string;
    DrawingPackageId?: string;
    SubObjectId?: string | null;
    MaterialElementId?: string | null;
}

export interface VolumeTypeAndDrawingPackageRequest {
    Type?: VolumeCategory;
    DrawingPackageId?: string;
}

export interface ActualPhysicalVolumeOptions {
    PageNumber?: number;
    PageSize?: number;
    OrderBy?: string | null;
    IsDesc?: boolean | null;
    Search?: any;
    Id?: string;
    Value?: number;
    Type?: VolumeCategory | null;
    IsActual?: boolean;
    WorkTypeId?: string | null;
    ContractId?: string | null;
    IsCompleted?: boolean | null;
    IsWithExcess?: boolean | null;
    IsActive?: boolean | null;
}

export interface Contract {
    Id: string;
    ContractorId: number | null;
    Description_en: string | null;
    Description_ru: string | null;
    IsObsolete: boolean;
    PhysicalVolumeSet?: PhysicalVolume[] | null;
    SortOrder: number;
    Title: string;
}

export interface MeasurementUnit {
    Id: string;
    Description_en: string | null;
    Description_ru: string | null;
    DocumentPhysicalVolumeSet?: DocumentPhysicalVolume[] | null;
    DynamicDocumentPhysicalVolumes?: DynamicDocumentPhysicalVolume[] | null;
    IsObsolete: boolean;
    PhysicalVolumeSet?: PhysicalVolume[] | null;
    SortOrder: number;
    Title: string;
}

export interface PhysicalVolume {
    Id: string;
    ActualValue: number;
    Contract?: Contract | null;
    ContractId: string | null;
    MeasurementUnit?: MeasurementUnit | null;
    MeasurementUnitId: string | null;
    PhysicalVolumeItemSet?: PhysicalVolumeItem[] | null;
    WorkType?: WorkType | null;
    WorkTypeId: string | null;
}

export interface PhysicalVolumeNew {
    contractId: string;
    workTypeId: string;
    measurementUnitId: string;
}

export interface DocumentPhysicalVolumeNew {
    Id?: number;
    Value: number | null;
    ItemValue: number | null;
    CountValue: number | null;
    PhysicalVolumeId: string | null;
    SubObjectId: string | null;
    MaterialElementId: string | null;
}

export interface PhysicalVolumeItemNew {
    Value: number;
    Type: number;
    DrawingPackageId: string;
    MaterialElementId: string;
    SubObjectId: string;
    PhysicalVolume: PhysicalVolumeNew;
}

export interface UpdateActualValue {
    ActualValue: number;
    StartDate: string;
    EndDate: string;
}

export interface PhysicalVolumeHistory {
    Id: string;
    AppUserId: string;
    CreateAt: Date;
    EndDate: Date | null;
    NewValue: number;
    OldValue: number;
    PhysicalVolumeId: string;
    PhysicalVolumeItem?: PhysicalVolumeItem | null;
    StartDate: Date | null;
    WorkTypeId: string;
    MeasurementUnitId: string;
}

export interface PhysicalVolumeItem {
    Id: string;
    DrawingPackage?: DrawingPackage | null;
    DrawingPackageId: string | null;
    IsActual: boolean;
    PhysicalVolume?: PhysicalVolume | null;
    PhysicalVolumeHistorySet?: PhysicalVolumeHistory[] | null;
    PhysicalVolumeId: string | null;
    Type: VolumeCategory;
    /* РД и TEQ */
    Value: number;
    StartValue: number;
    CreatedAt: number;
    CurrentProgressReportSchemes: DocumentPhysicalVolumeHistory[] | null;
    MaterialElementId: string | null;
    MaterialElement: MaterialElement | null;
    SubObjectId: string | null;
    SubObject: SubObject | null;
    VolumeRdId: number | null;
    MaterialElementCount: number | null;
    MaterialElementValue: number | null;
    KfoId: string | null;
    KfoMeasurementUnitId: string | null;
    TopKfoMeasurementUnitId: string | null;
    TopKfoId: string | null;
    TopKfoCoefficient: number | null;
    TopKfoValue: number | null;
    VolumeTypeId: string | null;
    TeqValue: number | null;
    KfoRdValue: number | null;
    KfoTeqValue: number | null;
    RdValue: number | null;
    KfoTeqRdValue: number | null;
}

export interface DocumentPhysicalVolumeHistory {
    PhysicalVolumeItemId: string;
    PhysicalVolumeItem: PhysicalVolumeItem;
    PhysicalVolumeProgressReportItem: any;
}

export interface DynamicIssue {
    Id?: string;
    Code?: number;
    ClosedAtIterationId?: string;
    ModifiedById?: string;
    CreatedAt?: Date;
    ModifiedAt?: Date;
    StatusId?: string | null;
    DynamicDocumentId: string | null;
    DynamicDocumentCode: number | null;
    RegisterId: string | null;
    CreatedAtIterationId: string;
    ReviewerId: string | null;
    RespUserId: string | null;
    ReviewId: string | null;
    CreatedById: string | null;
    Title: string | null;
    Description: string | null;
    RevisionNumber: number | null;
}

export interface IssueComment {
    Id?: string;
    CreatedAt?: Date;
    DynamicIssueId: string | null;
    Text: string | null;
    CreatedById: string | null;
}

export interface BusinessError {
    Id?: string;
    TypeInId: string;
    TypeReasonId: string;
    TypeInName: string;
    TypeReasonName: string;
    Message: string;
    DateCreate: Date;
}

export interface BusinessErrorShortResponse {
    Id?: string;
    TypeReasonId: string;
    Message: string;
}

export interface DynamicResponse<TEntity> {
    Items: TEntity[];
    CountDocuments: number;
}

export enum SortOrderType {
    Up,
    Down,
}

export interface DynamicWorkType {
    Id?: string;
    WorkTypeId: string;
    SortOrder: number;
    DocumentSchemas: DynamicDocumentSchema[];
}

export interface CompletenessRequirement {
    Id?: string;
    DrawingPackageId: string | null;
    DrawingPackageTitle?: string | null;
    NameRu: string | null;
    NameEn: string | null;
    WorkTypeRequirements: DynamicWorkType[];
}

export interface DocumentSchemaResult {
    DocumentSchemaId: string;
    Title: string;
    IsIncluded: boolean;
}

export interface WorkTypeResult {
    WorkTypeId: string;
    DocumentSchemaResults: DocumentSchemaResult[];
}

export interface AnalysisResult {
    DrawingPackageId: string;
    WorkTypeResults: WorkTypeResult[];
}

export interface Work {
    Id: string;
    WorkTypeId: string | null;
    AltitudeMarkStr: string | null;
    CoordinateAxiStr: string | null;
    SortOrder: number;
    CoordinateAxisSet?: string[];
}

export interface WorkWithChildren {
    Id: string;
    Name: string;
    AltitudeMarkStr: string | null;
    CoordinateAxiStr: string | null;
    SortOrder: number;
    Children: WorkWithChildren[];
}
export interface StageChangePosition {
    Id: string | null;
    ParentId: string | null;
    OrderNumber: number | null;
}

export interface WorkNew {
    WorkTypeId: string | null;
    AltitudeMarkStr: string | null;
    CoordinateAxiStr: string | null;
}

export interface AltitudeMark {
    Id: string | null;
    Name: string | null;
    WorkId: string | null;
    WorkType: WorkType | null;
    WorkTypeId: string | null;
}

export interface CoordinateAxi {
    Id: string | null;
    Name: string | null;
    WorkId: string | null;
    WorkType: WorkType | null;
    WorkTypeId: string | null;
}

export interface SearchResult {
    Id: string;
    DocumentFieldIds: string[];
    DocumentTableFieldIds: string[];
    ReftableTitle: string;
    SchemaTitle: string;
    DynamicReftableItemId: string;
    Value: string;
}

export interface ServiceTemplate {
    Id: string;
    NameEn: string;
    NameRu: string;
}

export interface TemplateReftable {
    Id: string;
    NameEn: string;
    NameRu: string;
    Fields: IField[];
}

export interface RowsItemServiceGenerateNew {
    ServiceKey: string;
    TypeControl: TypeControl;
    SchemaId: string;
}

export interface DynamicDocumentErrors {
    Errors: DynamicDocumentError[];
    LeadErrors: DynamicDocumentError[];
    Id: string;
    Code: number;
    Type: string;
    Number: string;
    IsAddedInRegister: boolean;
    RegisterId: string | null;
    IsGenerated: boolean;
    RegisterStatus: string;
    DrawingPackageIds: string[];
    WorkTypeIds: string[];
    Date: number;
    StartDate: string;
    EndDate: string;
    IsErrorIn: boolean;
    IsErrorReason: boolean;
}

export interface DynamicDocumentError {
    TypeInId: string;
    TypeReasonId: string;
    TypeInName: string;
    TypeReasonName: string;
    Message: string;
    DateCreate: number;
    Id: string;
}

export interface SignatoryUser {
    Id?: string;
    UserId: number;
    UserName?: string;
    DocumentId: string;
    Document?: DynamicDocument;
    Status?: boolean;
    UpdatedAt?: number;
    UpdatedUserId?: number;
}

export interface SignatoryUserNew {
    UserId: number;
    DocumentId: string;
}

export interface SignatoryUserMultiple {
    DocumentId: string;
    SignatoryUsers: SignatoryUser[];
}

export interface DynamicDocumentErrorsCount {
    Items: DynamicDocumentError[];
    CountDocuments: number;
}

type SearchFunction<TEntity> = (search: string) => Promise<TEntity[]>;

export interface DynamicDocCategoryApi<TEntity, TModel> extends IBaseApi<TEntity, TModel> {
    search: SearchFunction<TEntity>;
    getPage: GetPageFunction<TEntity>;
    getByFilter: GetByFilterFunctionBase<TEntity, JournalCategoryFilter>;
}

export interface ItemWithIdAndTitle {
    Id: string;
    Title: string;
}

export interface JournalFilter {
    PageNumber?: number;
    PageSize?: number;
    OrderBy?: string;
    IsDesc?: boolean;
    Search: Record<string, string>
    Id?: string;
    SchemaId?: string;
    CategoryId?: string;
    Number?: string;
    Code?: string;
    CreatedAt?: FilterDateRange;
    UpdatedAt?: FilterDateRange;
}

export interface FilterDateRange {
    start?: number | string | null;
    end?: number | string | null;
}
export interface JournalTableFilter {
    PageNumber?: number;
    PageSize?: number;
    OrderBy?: string;
    IsDesc?: boolean;
    Search?: Record<string, string>
    DynamicJournalPartitionId: string;
    TableTemplateCode: string;
}
export interface JournalTableResponse {
    Items: any;
    CountDocuments: number;
}
export interface RowTable {
    JournalPartitionId: string;
    DynamicJournalId: string;
    DynamicJournalPartitionId: string;
    TableTemplateCode: string;
}
export interface RowTableNew extends RowTable {
    Data: Record<string, string|number>[];
}
export interface RowTableUpdate extends RowTable {
    Data: Record<string, string|number>;
}

export interface RenumberJournalTable extends RowTable {
    Column: string;
    StartFrom: number;
    Step: number;
}

// eslint-disable-next-line no-undef
export type Timer = NodeJS.Timeout | null;

export enum TypeFieldEditor {
    Document = 'Document',
    Journal = 'Journal',
    Reftable = 'Reftable',
}

export interface DynamicEditorOptions {
    isCreated: boolean;
    type: TypeFieldEditor;
    data?: any;
    relatedFieldItems?: RelatedFieldItem[];
    drawingPackages?: DrawingPackage[];
    document?: DynamicDocument;
    updatePhysicalVolumeOnly?: () => Promise<void>;
}

export interface MaterialElement {
    Id: string | null;
    Name: string | null;
    DrawingPackageId: string | null;
    SubObjectId: string | null;
    CreatedAt?: number;
    CreatedUserId?: number;
}

export interface SubObject {
    Id: string | null;
    Name: string | null;
    DrawingPackageId: string | null;
    MaterialElements: MaterialElement[];
    PhysicalVolumeItems: PhysicalVolumeItem[];
    CreatedAt?: number;
    CreatedUserId?: number;
}

export interface MaterialElementNew {
    Name: string | null;
    DrawingPackageId: string | null;
    SubObjectId: string | null;
}

export interface SubObjectNew {
    Name: string | null;
    DrawingPackageId: string | null;
}

export interface SchemaSearchRequest {
    search: string;
    categoryId?: string | null;
    showOnlyActive?: boolean;
}

type GetPageFunction<TEntity> = (page: number, pageSize: number) => Promise<DynamicResponse<TEntity>>;
type GetByFilterFunction<TEntity> = (options: Options) => Promise<getByFilter<TEntity>>;
type GetByFilterFunctionBase<TEntity, TFilter> = (options: TFilter) => Promise<getByFilter<TEntity>>;
type GetIssueByFilterFunction<TEntity> = (options: IssueOptions) => Promise<TEntity[]>;
type GetCategoryPageFunction<TEntity> = (id: string, page: number, pageSize: number) => Promise<DynamicResponse<TEntity>>;
type GetByCategoryFunction<TEntity> = (categoryId: string, showOnlyActive: boolean) => Promise<TEntity[]>;
type SearchByTitleFunction<TEntity> = (options: SchemaSearchRequest) => Promise<TEntity[]>;
type RelatedDocumentTypeFunction<TEntity> = (documentSchemaId: string, formula?: string) => Promise<TEntity[]>;
type CreateWithFileFunction<TEntity> = (data: DynamicDocumentSchemaWithFile) => Promise<TEntity>;
type CreateFunction<TEntity> = (data: TEntity) => Promise<TEntity>;
type GetByIdReturnManyFunction<TEntity> = (id: string) => Promise<TEntity[]>;
type ChangeWorkTypePositionFunction<TEntity> = (data: TEntity) => Promise<boolean>;
type GetDocumentLinksFunction = (options: SchemaLinkRequest) => Promise<LinkResponse[]>;
type ChangeCategoryFuntion = (id: string, categoryId: string) => Promise<boolean>;
type GetFullEditErrorsFunction = (id: string) => Promise<string[]>;
type GetPartitionFunction<TEntity> = (partitionId: string) => Promise<TEntity>;
type getInfoById<TEntity> = (id: string) => Promise<TEntity>;
type GetSustemSchemasFunction<TEntity> = () => Promise<TEntity[]>;
type DownloadByIdFunction = (id: string) => Promise<Blob>;

export interface DynamicDocSchemaApi<TEntity, TModel> extends IBaseApi<TEntity, TModel> {
    getPage: GetPageFunction<TEntity>;
    getCategoryPage: GetCategoryPageFunction<TEntity>;
    getByCategory: GetByCategoryFunction<TEntity>;
    searchByTitle: SearchByTitleFunction<DocumentSchemaShort>;
    searchByTitleAndCategory: SearchByTitleFunction<TEntity>;
    getRelatedDocumentType: RelatedDocumentTypeFunction<TEntity>;
    createWithFile: CreateWithFileFunction<TEntity>;
    getByFilter: GetByFilterFunction<TEntity>;
    getSystemSchemas: GetSustemSchemasFunction<TEntity>;
    getDocumentLinks: GetDocumentLinksFunction;
    changeCategory: ChangeCategoryFuntion;
    getFullEditErrors: GetFullEditErrorsFunction;
    downloadTemplateFileSchema: DownloadByIdFunction;
}

export interface DynamicJournalSchemaApi<TEntity, TModel> extends IBaseApi<TEntity, TModel> {
    getPage: GetPageFunction<TEntity>;
    getCategoryPage: GetCategoryPageFunction<TEntity>;
    getByCategory: GetByCategoryFunction<DocumentSchemaShort>;
    searchByTitleAndCategory: SearchByTitleFunction<TEntity>;
    searchByTitle: SearchByTitleFunction<DocumentSchemaShort>;
    getRelatedDocumentType: RelatedDocumentTypeFunction<TEntity>;
    getByFilter: GetByFilterFunction<TEntity>;
    getPartition: GetPartitionFunction<DynamicJournalSchemaPartitions>
    getInfoById: getInfoById<TEntity>
    changeCategory: ChangeCategoryFuntion
    download: DownloadByIdFunction
    downloadTemplateFileSchema: DownloadByIdFunction
    getFullEditErrors: GetFullEditErrorsFunction;
}

export type ReftableSchemaWithNested = ReftableSchema & { ReftableSchemas: ReftableSchema[] };

type GetByDocumentSchemaFunction<TEntity> = (documentSchemaId: string) => Promise<TEntity[]>;

export interface DocumentLinkApi<TEntity, TModel> extends IBaseApi<TEntity, TModel> {
    getByDocumentSchema: GetByDocumentSchemaFunction<TEntity>;
}

export interface DynamicIssueApi<TEntity> extends IBaseApi<TEntity, TEntity> {
    getByFilter: GetIssueByFilterFunction<TEntity>;
    setBackStatus: GetByIdFunction<TEntity>;
}

export interface IssueCommentApi<TEntity> {
    getByIssueId: GetByIdReturnManyFunction<TEntity>;
    create: CreateFunction<TEntity>;
}

export interface WorkApi<TEntity, TModel> extends IBaseApi<TEntity, TModel> {
    changeWorkTypePosition: ChangeWorkTypePositionFunction<StageChangePosition>;
}
