
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DataTableHeaderCustom, MenuFilterType } from '@/types';
    import DatePickerRange from '@/components/common/date-picker-range.vue';
    import DatePickerSingle from '@/components/asbuilt-docs/registers/date-picker-single.vue';

    @Component({
        components: { DatePickerRange, DatePickerSingle },
        data: () => ({ MenuFilterType }),
    })
    export default class MenuFilter extends Vue {
        @Prop()
        private readonly value?: any;

        @Prop({ type: Object, required: true })
        private readonly header!: DataTableHeaderCustom;

        data: any = null;

        @Watch('value')
        async onChanged() {
            if (!this.value?.toString()) return;
            this.data = this.value;
        }

        @Watch('data', { immediate: true })
        updateData() {
            this.$emit('input', {
                value: this.data,
                header: this.header,
            });
        }
    }
