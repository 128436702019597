
    import Vue from 'vue';
    import Dashboard from '@/components/dashboard/index.vue';

    export default Vue.extend({
        functional: true,
        render() {
            return (
                <Dashboard />
            );
        },
    });
