import * as api from '@/api/dynamic';
import { createHash } from 'crypto-pro';
import { identifiers } from '@/crypto/oids';

export async function getHash(fileDescId: string) {
    const file = await api.fileDesc.download(fileDescId);
    const fileArrayBuffer = await blobToArrayBuffer(file as Blob);
    return await createHash(fileArrayBuffer);
}

async function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result as ArrayBuffer);
        };

        reader.onerror = () => {
            reject(reader.error);
        };

        reader.readAsArrayBuffer(blob);
    });
}

const dnFieldNames: Record<string, string> = {
    commonName: 'CN',
    surName: 'SN',
    givenName: 'G',
    title: 'T',
    organizationName: 'O',
    organizationalUnitName: 'OU',
};

export function formatDn(dn: Map<string, string> | Record<string, string>): string {
    const values = dn instanceof Map ? dn : new Map(Object.entries(dn));

    const parts = Object.keys(dnFieldNames);
    const display = parts.reduce((result, part) => {
        let key = identifiers[part];
        if (!values.has(key)) {
            key = dnFieldNames[part];
        }

        if (values.has(key)) {
            result.push(values.get(key)!);
        }

        return result;
    }, [] as string[]).join(', ');

    return display
      || Array.from(values.entries()).map(x => `${x[0]}=${x[1]}`).join(', ');
}

export const parseDnString = (dn: string): Map<string, string> => dn.split(', ')
    .reduce((result, part) => {
        const strings = part.split('=', 2);
        result.set(strings[0], strings[1]);
        return result;
    }, new Map<string, string>());
