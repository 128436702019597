import { authInstance } from './auth-instance';
import { FilterItemsResponse, OptionsRight, RightApi, RightRequest, RightResponse } from '@/types';

export const right: RightApi<RightResponse, RightRequest> = {
    getAll: (): Promise<RightResponse[]> =>
        authInstance.get<RightResponse[]>('rights/all').then(x => x.data),

    getByFilter: (options: OptionsRight): Promise<FilterItemsResponse<RightResponse>> =>
        authInstance.post<FilterItemsResponse<RightResponse>>('rights/byFilter', options).then(x => x.data),

    getRightsByRoleId: (options: OptionsRight): Promise<RightResponse[]> =>
        authInstance.post<RightResponse[]>('rights/GetRightsByRoleId', options).then(x => x.data),

    getById: (id: number): Promise<RightResponse> =>
        authInstance.get<RightResponse>(`rights/${id}`).then(x => x.data),

    create: (data: RightRequest): Promise<RightResponse> =>
        authInstance.post<RightResponse>('rights', data).then(x => x.data),

    update: (id: number, data: RightRequest): Promise<RightResponse> =>
        authInstance.put<RightResponse>(`rights/${id}`, data).then(x => x.data),

    remove: (id: number): Promise<void> =>
        authInstance.delete(`rights/${id}`).then(x => x.data),
};
