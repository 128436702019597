
    import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
    import { ReadSignature, Signature } from '@/types';
    import { formatDn } from '@/crypto/utils';

    @Component({
        methods: {
            formatDn,
        },
    })
    export default class CertificateSignatureList extends Vue {
        @PropSync('signList', { type: Array, default: () => [] })
        readSignature!: ReadSignature[];

        @Prop({ type: Object, required: true })
        signature!: Signature;
    }
