import { cisInstance } from './cis-instance';
import { MeasurementUnit, MeasurementUnitApi } from '@/types';

/** Единицы измерения */
export const measurementUnit: MeasurementUnitApi<MeasurementUnit, MeasurementUnit> = {
    getAll: (): Promise<MeasurementUnit[]> =>
        cisInstance.get<MeasurementUnit[]>('MeasurementUnitApi').then(x => x.data),

    getById: (id: string): Promise<MeasurementUnit> =>
        cisInstance.get<MeasurementUnit>(`MeasurementUnitApi/${id}`).then(x => x.data),

    create: (data: MeasurementUnit): Promise<MeasurementUnit> =>
        cisInstance.post<MeasurementUnit>('MeasurementUnitApi', data).then(x => x.data),

    update: (id: string, data: MeasurementUnit): Promise<MeasurementUnit> =>
        cisInstance.put<MeasurementUnit>(`MeasurementUnitApi/${id}`, data).then(x => x.data),

    remove: (id: string): Promise<void> =>
        cisInstance.delete(`MeasurementUnitApi/${id}`).then(x => x.data),
};
