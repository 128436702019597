
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DynamicDocumentWithLinkName, IField } from '@/types/dynamicDoc';
    import DocumentListDialog from '@/components/documents-journals/documents/document-list-dialog.vue';
    import * as api from '@/api/dynamic';
    import { CHANGE_DOCUMENT_LIST, GET_DOCUMENT } from '@/store/document/actions';
    import { AutocompleteItem } from '@/types';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import TooltipWrapper from '@/components/dynamic/common/tooltip-wrapper.vue';

    @Component({
        components: { ButtonTooltip, DocumentListDialog, TooltipWrapper },
    })
    export default class DocumentTypeSelector extends Vue {
        @Prop({ type: String, required: true })
        private readonly label!: string;

        @Prop({ type: Object })
        private readonly field!: IField;

        @Prop(String)
        private readonly formula!: string;

        @Prop([ String, Array ])
        private readonly value!: string | string[];

        @Prop({ type: Boolean, default: false })
        private readonly isMultiple!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly disabled!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly validate!: boolean;

        visibleDialog = false;

        @Watch('documentsWithLinkName')
        onItems() {
            if (!this.documentsWithLinkName.length) return;
            const docs = this.items.length
                ? this.items.concat(this.documentsWithLinkName)
                : this.documentsWithLinkName;
            this.$store.commit(`document/${CHANGE_DOCUMENT_LIST}`, docs);
        }

        get items(): AutocompleteItem[] {
            return this.$store.state.document.documentsList;
        }

        get documentsWithLinkName() {
            return this.$store.getters['document/documentsWithLinkName']
                ?.map((x: DynamicDocumentWithLinkName) => ({
                    value: x.Id,
                    text: `${x.Schema?.Title} №${x.Number} от ${new Date(x.Date as number).toLocaleDateString()}`,
                }));
        }

        get hasBlockedVersions() {
            return !!this.$store.state.document.documentsList?.find((x: any) => x.isBlock);
        }

        get documentId() {
            return this.$store.state.document?.document?.Id;
        }

        async onSelected(items: string[]) {
            if (!items.length) return;
            const documentIds = this.items.map(x => x.value as string).concat(items);
            const documents = await api.document.getByIdRange(documentIds);
            const documentsList = documents.map(doc => ({
                text: `${doc.Schema?.Title} №${doc.Number} от ${new Date(doc.Date as number).toLocaleDateString()}`,
                value: doc.Id,
            }));
            this.$store.commit(`document/${CHANGE_DOCUMENT_LIST}`, documentsList);

            if (this.value) {
                this.$emit('input', [ ...new Set((this.value as string[]).concat(items)) ]);
                return;
            }
            this.$emit('input', [ ...new Set(items) ]);
        }

        open() {
            this.visibleDialog = true;
        }

        get documentHasNewVersionTooltip() {
            return `<span>${this.$t('common.document-has-new-version')}</span> <br>
            <b>${this.$t('common.press-for-update')}</b>`;
        }

        async updateRelatedDocument() {
            if (!this.documentId) return;
            try {
                await api.document.updateRelatedDocument(this.documentId, this.field.TemplateCode!);
                this.$root.$notify.show('success');
                this.$store.commit(`document/${GET_DOCUMENT}`, this.documentId);
            } catch (e) {
                this.$root.$notify.show('error', e);
            }
        }
    }
