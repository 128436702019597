
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DataTableHeaderCustom, MenuFilterType } from '@/types';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import DatePickerRange from '@/components/common/date-picker-range.vue';

    @Component({
        components: { DatePickerRange, ButtonTooltip },
        data: () => ({ MenuFilterType }),
    })
    export default class DataTableFilterItem extends Vue {
        @Prop({ default: null })
        readonly value: any | any[];

        @Prop({ type: Object, required: true })
        readonly header!: DataTableHeaderCustom;

        @Prop({ type: Boolean, default: true })
        readonly isChip!: boolean;

        @Prop({ type: String, default: 'bottom' })
        readonly location!: string;

        @Prop({ type: Boolean, default: false })
        readonly store!: boolean;

        open = false;
        isLoading = true;
        data: any | any[] = null;

        @Watch('value', { immediate: true })
        updateData() {
            if (this.value === this.data) return;
            this.data = this.value;
        }

        get headerText() {
            return this.header.text + (this.valuesCount > 1 ? ` (${this.valuesCount})` : '');
        }

        get valuesCount() {
            return Array.isArray(this.value) && this.header.multiple ? this.value.length : 1;
        }

        get isApplyDisabled() {
            return this.data === ''
                || (Array.isArray(this.data) && this.data.length === 0)
                || this.data === undefined
                || this.data === null;
        }

        apply() {
            this.open = false;
            this.$emit('updated', { value: this.data, header: this.header });
        }

        close() {
            this.open = false;
        }

        clear() {
            this.open = false;
            this.data = null;
            this.$emit('updated', { value: null, header: this.header });
        }
    }
