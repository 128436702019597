
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import DatePicker from 'v-calendar/lib/components/date-picker.umd';
    import VueI18n from 'vue-i18n';
    import { IDateRange, ITimeStampRange } from '@/types';

    @Component({
        components: { DatePicker },
    })
    export default class DatePickerRange extends Vue {
        @Prop({ default: () => ({ start: null, end: null }) })
        private readonly value!: ITimeStampRange;

        @Prop({ type: Boolean, default: false })
        private readonly disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly clearable!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly hideDetails!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly dense!: boolean;

        @Prop({ type: String, default: 'mdi-calendar' })
        private readonly icon!: string;

        @Prop({ default: '' })
        private readonly placeholder!: string | VueI18n.TranslateResult;

        @Prop({ default: '' })
        private readonly label!: string | VueI18n.TranslateResult;

        menu = false;
        dates: IDateRange = {
            start: null,
            end: null,
        };

        modelConfig = {
            timeAdjust: '12:00:00',
        };

        @Watch('value', { immediate: true })
        onChangeValue() {
            if (!this.value?.start || !this.value?.end) {
                this.dates = {
                    start: null,
                    end: null,
                };
                return;
            }
            this.dates = {
                start: new Date(this.value?.start),
                end: new Date(this.value?.end),
            };
        }

        get formatDate() {
            if (!this.dates.start || !this.dates.end) return '';
            return `${this.dates.start.toLocaleDateString()} -> ${this.dates.end.toLocaleDateString()}`;
        }

        onInputDate(dates: IDateRange | null) {
            if (!dates || !dates.start || !dates.end) return;
            this.menu = false;
            this.$emit('input', {
                start: dates.start.getTime(),
                end: dates.end.getTime(),
            });
        }

        clear() {
            this.$emit('input', { start: null, end: null });
            this.$emit('clear');
        }
    }
