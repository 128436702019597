import { cisInstance } from './cis-instance';
import { ReviewerTemplate, ReviewerTemplateApi, ReviewerTemplateFilter, ReviewerTemplateType } from '@/types';

/** Шаблоны проверяжщих */
export const reviewerTemplate: ReviewerTemplateApi<ReviewerTemplate, ReviewerTemplate> = {
    getAll: (): Promise<ReviewerTemplate[]> =>
        cisInstance.get<ReviewerTemplate[]>('ReviewerTemplate').then(x => x.data),

    getById: (id: string): Promise<ReviewerTemplate> =>
        cisInstance.get<ReviewerTemplate>(`ReviewerTemplate/${id}`).then(x => x.data),

    create: (data: ReviewerTemplate): Promise<ReviewerTemplate> =>
        cisInstance.post<ReviewerTemplate>('ReviewerTemplate', data).then(x => x.data),

    update: (id: string, data: ReviewerTemplate): Promise<ReviewerTemplate> =>
        cisInstance.put<ReviewerTemplate>(`ReviewerTemplate/${id}`, data).then(x => x.data),

    remove: (id: string): Promise<void> =>
        cisInstance.delete(`ReviewerTemplate/${id}`).then(x => x.data),

    getByType: (type: ReviewerTemplateType): Promise<ReviewerTemplate[]> =>
        cisInstance.get<ReviewerTemplate[]>(`ReviewerTemplate/GetByType/${type}`).then(x => x.data),

    getByFilter: (filter: ReviewerTemplateFilter): Promise<ReviewerTemplate[]> =>
        cisInstance.post<ReviewerTemplate[]>('ReviewerTemplate/GetByFilter', filter).then(x => x.data),
};
