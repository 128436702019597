import { authInstance } from './auth-instance';
import {
    FilterItemsResponse,
    ContragentRequest,
    ContragentResponse,
    ContragentWithChildren,
    OptionsContragent,
} from '@/types';

export const contragent = {
    getAll: (): Promise<ContragentResponse[]> =>
        authInstance.get<ContragentResponse[]>('contragents/all').then(x => x.data),

    getByFilter: (options: OptionsContragent): Promise<FilterItemsResponse<ContragentResponse>> =>
        authInstance.post<FilterItemsResponse<ContragentResponse>>('contragents/byFilter', options).then(x => x.data),

    getById: (id: number): Promise<ContragentResponse> =>
        authInstance.get<ContragentResponse>(`contragents/${id}`).then(x => x.data),

    create: (data: ContragentRequest): Promise<ContragentResponse> =>
        authInstance.post<ContragentResponse>('contragents', data).then(x => x.data),

    update: (id: number, data: ContragentRequest): Promise<ContragentResponse> =>
        authInstance.put<ContragentResponse>(`contragents/${id}`, data).then(x => x.data),

    remove: (id: number): Promise<void> =>
        authInstance.delete(`contragents/${id}`).then(x => x.data),

    getContragentWithChildren: (): Promise<ContragentWithChildren[]> =>
        authInstance.get<ContragentWithChildren[]>('contragents/GetContragentWithChildren').then(x => x.data),
};
