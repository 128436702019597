
    import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator';
    import { required, requiredPositiveNumber } from '@/components/common/validation-rules';
    import { VForm } from '@/types';
    import { RowTable, RenumberJournalTable, FieldType } from '@/types/dynamicDoc/types';
    import { IField } from '@/types/dynamicDoc';
    import * as api from '@/api/dynamic';
    import CustomDialog from '../custom-dialog.vue';

    @Component({ components: { CustomDialog } })
    export default class JournalTableRenumberDialog extends Vue {
        @Ref() form!: VForm;
        @PropSync('visible', { type: Boolean, default: () => false })
        isVisible!: boolean;

        @Prop({ type: Array, default: () => [] })
        fields!: IField[];

        @Prop({ type: Object, default: () => null })
        requestParams!: RowTable;

        valid = true;
        loading = false;
        rules = { required, requiredPositiveNumber };
        model: Partial<RenumberJournalTable> = {
            StartFrom: 1,
            Step: 1,
        }

        get availableFields(): IField[] {
            return this.fields
                .filter(x => x.Type === FieldType.Integer || x.Type === FieldType.String);
        }

        close() {
            this.form.resetValidation();
            this.isVisible = false;
        }

        async save() {
            if (!this.form.validate()) return;

            try {
                this.loading = true;
                await api.journal.renumberColumnInTable({
                    ...this.requestParams,
                    ...this.model,
                } as RenumberJournalTable);
                this.$root.$notify.show('success');
                this.$emit('success');
                this.close();
            } catch (ex) {
                this.$root.$notify.show('error', ex);
            } finally {
                this.loading = false;
            }
        }
    }
