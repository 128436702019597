import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Locale } from '@/util/locale';

/**
 * Отвечает за настройку принудительной единственной локали во всём приложении.
 * Если равно Locale.Ru или Locale.En, то поля для выбора языка скроются,
 *  и применится указанный язык.
 * Если равно null, настройки останутся прежними.
 */
export const forcedLocale: Locale | null = null;

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: any = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

function russianPluralization(choice: number, choicesLength: number): number {
    // выбор 1, 2, 5
    const variant = ((choice % 10 === 1) && (choice % 100 !== 11))
        ? 0
        : (((choice % 10 >= 2)
                && (choice % 10 <= 4)
                && ((choice % 100 < 10)
                    || (choice % 100 >= 20)))
            ? 1
            : 2
        );

    if (choicesLength === 2) {
        // 2 варианта - дерево, деревья
        return choice === 1 ? 0 : 1;
    } else if (choicesLength === 4) {
        // 4 варианта - нет деревьев, 1 дерево, 2 дерева, 5 деревьев
        return choice === 0 ? 0 : variant + 1;
    } else {
        // 3 варианта - 1 дерево, 2 дерева, 5 деревьев
        return variant;
    }
}

export default new VueI18n({
    locale: forcedLocale || Locale.Ru,
    pluralizationRules: {
        ru: russianPluralization,
    },
    fallbackLocale: forcedLocale || Locale.Ru,
    messages: loadLocaleMessages(),
});
