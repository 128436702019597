
    import { Component, Vue } from 'vue-property-decorator';
    import { GET_COMMON_APP_SETTINGS } from '@/store/settings/types';
    import { AppBarMenu } from '@/store/topmenu/types';
    import { UPDATE_MENU } from '@/store/topmenu/module';

    @Component({})
    export default class AsbuiltIndex extends Vue {
        get menu(): AppBarMenu[] {
            return [
                {
                    id: 1,
                    title: 'common.registers',
                    to: { name: 'registers' },
                    right: 'Cis.RegistersAsBuilt',
                },
                {
                    id: 3,
                    title: 'volume.physical',
                    to: {
                        name: 'volume',
                        params: { subItem: 'actual' },
                    },
                    right: 'Cis.PhysicalVolumeAsBuilt',
                },
                {
                    id: 4,
                    title: 'common.reports',
                    to: {
                        name: 'asbuilt-reports',
                        params: { reportName: 'Registers' },
                    },
                    right: 'Cis.ReportsAsBuilt',
                },
                {
                    id: 5,
                    title: 'common.terms-of-work',
                    to: { name: 'terms-of-work' },
                    right: 'Cis.TermsWorkAsBuilt',
                },
            ];
        }

        async mounted() {
            this.$store.commit(UPDATE_MENU, this.menu);
            await this.$store.dispatch(GET_COMMON_APP_SETTINGS);
        }
    }
