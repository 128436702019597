import { authInstance } from './auth-instance';
import { LoginForm, LoginSuccessResponse, UserPasswordRequest } from '@/types';

export const auth = {
    signIn: (data: LoginForm): Promise<LoginSuccessResponse> =>
        authInstance.post<LoginSuccessResponse>('auth/login', data).then(x => x.data),

    refreshToken: (token: string): Promise<LoginSuccessResponse> =>
        authInstance.post<LoginSuccessResponse>('auth/refreshtoken', { RefreshToken: token }).then(x => x.data),

    logout: (token: string): Promise<LoginSuccessResponse> =>
        authInstance.post<LoginSuccessResponse>('auth/logout', { RefreshToken: token }).then(x => x.data),

    changePassword: (data: UserPasswordRequest): Promise<void> =>
        authInstance.post('auth/changePassword', data).then(x => x.data),
};
