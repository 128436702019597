import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import {
    getByFilter,
    DataFormulaResponse,
    DynamicReftableItem,
    DynamicReftableItemOptions,
    DynamicReftableItemResponse,
    FormulaRangeQuery,
    IMultiReftable,
    ItemWithIdAndTitle,
    RefreshDynamicItemFromApiRequest,
    ReftableFormulaDesign,
    RelatedReftableRequest,
    RowsItemServiceGenerateNew,
} from '@/types/dynamicDoc';

/** Справочник  */
export const reftableItem = {
    getPage: (schemaId: string, page: number, pageSize: number, formula?: string) =>
        dynamicDocInstance.post<DynamicReftableItemResponse>(`/DynamicReftableItem/page?schemaId=${schemaId}&page=${page}&pageSize=${pageSize}`, { Formula: formula })
            .then(x => x.data),

    getByFilter: (options: DynamicReftableItemOptions) =>
        dynamicDocInstance.post<getByFilter<DynamicReftableItem>>('/DynamicReftableItem/GetMany/getByFilter', options)
            .then(x => x.data),

    getRelatedSchema: (schemaId: string, formula?: string, formulaFullName?: string) =>
        dynamicDocInstance.post<DynamicReftableItem[]>(`/DynamicReftableItem/RelatedSchema?schemaId=${schemaId}`, {
            Formula: formula,
            FormulaFullName: formulaFullName,
        }).then(x => x.data),

    getDataByFormula: (data: FormulaRangeQuery) =>
        dynamicDocInstance.post<DataFormulaResponse[]>('/DynamicReftableItem/GetDataByFormula', data).then(x => x.data),

    getById: (key: string) =>
        dynamicDocInstance.get<DynamicReftableItem>(`/DynamicReftableItem/get?id=${key}`).then(x => x.data),

    getFormulaSchema: (schemaId: string) =>
        dynamicDocInstance.get<ReftableFormulaDesign[]>(`/DynamicReftableItem/RelatedFormulaSchema?schemaId=${schemaId}`).then(x => x.data),

    update: (key: string, document: DynamicReftableItem) =>
        dynamicDocInstance.patch(`/DynamicReftableItem/patch?id=${key}`, document).then(x => x.data),

    create: (document: DynamicReftableItem) =>
        dynamicDocInstance.post<{ Id: string }>('/DynamicReftableItem/post', document).then(x => x.data),

    multiRelatedSchema: (multiReftables: IMultiReftable[]): Promise<DynamicReftableItem[]> =>
        dynamicDocInstance.post<DynamicReftableItem[]>('/DynamicReftableItem/MultiRelatedSchema', multiReftables).then(x => x.data),

    refreshDynamicItemFromApi: (data: RefreshDynamicItemFromApiRequest) =>
        dynamicDocInstance.post('/DynamicReftableItem/RefreshDynamicItemFromApi', data).then(x => x.data),

    fillRowGenerateService: (data: RowsItemServiceGenerateNew) =>
        dynamicDocInstance.post('/DynamicReftableItem/FillRowGenerateService', data).then(x => x.data),

    remove: (key: string) =>
        dynamicDocInstance.delete(`/DynamicReftableItem/Delete/${key}`).then(x => x.data),

    getDocumentsUseReferenceItem: (key: string) =>
        dynamicDocInstance.get(`/DynamicReftableItem/GetDocumentsUseReferenceItem/${key}`).then(x => x.data),

    searchPermitFiles: (key: string): Promise<ItemWithIdAndTitle[]> =>
        dynamicDocInstance.get(`/DynamicReftableItem/SearchPermitFiles?search=${key}`).then(x => x.data),

    getRelatedSchemaByReftable: (data: RelatedReftableRequest) =>
        dynamicDocInstance.post('/DynamicReftableItem/GetRelatedSchemaByReftable', data).then(x => x.data),
};
