
    import Vue from 'vue';
    import LoadingPage from './layouts/loading-page.vue';

    const defaultLayout = 'default';

    export default Vue.extend({
        name: 'App',
        render(h: any) {
            const rootRoute = this.$route.matched?.[0];
            if (!rootRoute) return h(LoadingPage);

            const layout = `${rootRoute.meta?.layout || defaultLayout}-layout`;
            return h(layout, h('router-view'));
        },
    });
