import { render, staticRenderFns } from "./documents-journals-document-template-popup.vue?vue&type=template&id=318194e1&scoped=true&lang=pug&"
import script from "./documents-journals-document-template-popup.vue?vue&type=script&lang=ts&"
export * from "./documents-journals-document-template-popup.vue?vue&type=script&lang=ts&"
import style0 from "./documents-journals-document-template-popup.vue?vue&type=style&index=0&id=318194e1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318194e1",
  null
  
)

export default component.exports