
    import { Component, Prop } from 'vue-property-decorator';
    import { Notification } from '@/api/model/interfaces';
    import { registerTaskApi } from '@/api/cis/register-task';
    import NotificationItem from '@/components/common/notification-item.vue';
    import { RegisterTaskResponse } from '@/types/cis/register-task';

    @Component
    export default class RegisterTaskNotification extends NotificationItem {
        @Prop({ required: true })
        notification!: Notification;

        task: RegisterTaskResponse | null = null;

        async mounted() {
            this.task = await registerTaskApi.getTask(this.notification.EntityId.toString());
            this.link = this.task ? `/asbuilt/registers/${this.task.RegisterId}` : null;
        }
    }
