import { EventDescriptor } from '@/components/notification/store/event-descriptor';
import events from '@/api/model/events';
import IssueIssued from './issue-issued.vue';
import RegisterReviewNew from './register-review-new.vue';
import RegisterReviewCompletionStage from './register-review-completion-stage.vue';
import RegisterHasIssues from './register-has-issues.vue';
import RegisterRequestToReviewers from './register-request-to-reviewers.vue';
import CorrectRevieEndDateNotification from '@/components/asbuilt-docs/notifications/correct-revie-end-date-notification.vue';
import RegisterTaskNotification from '@/components/asbuilt-docs/notifications/register-task.vue';

export default function getAsBuiltEventDescriptors() : EventDescriptor[] {
    return [
        {
            id: events.RegisterIssueIssued,
            i18keyName: 'events.RegisterIssueIssued',
            descriptionComponent: IssueIssued,
        },
        {
            id: events.RegisterReviewNewApprove,
            i18keyName: 'events.RegisterReviewNewApprove',
            descriptionComponent: RegisterReviewNew,
        },
        {
            id: events.RegisterReviewNewCheck,
            i18keyName: 'events.RegisterReviewNewCheck',
            descriptionComponent: RegisterReviewNew,
        },
        {
            id: events.RegisterReviewFailedCheck,
            i18keyName: 'events.RegisterReviewFailedCheck',
            descriptionComponent: RegisterReviewCompletionStage,
        },
        {
            id: events.RegisterReviewSuccessCheck,
            i18keyName: 'events.RegisterReviewSuccessCheck',
            descriptionComponent: RegisterReviewCompletionStage,
        },
        {
            id: events.RegisterReviewSuccessApprove,
            i18keyName: 'events.RegisterReviewSuccessApprove',
            descriptionComponent: RegisterReviewCompletionStage,
        },
        {
            id: events.RegisterReviewOthersDoneCheck,
            i18keyName: 'events.RegisterReviewOthersDoneCheck',
            descriptionComponent: RegisterReviewCompletionStage,
        },
        {
            id: events.RegisterReviewRefuseApprove,
            i18keyName: 'events.RegisterReviewRefuseApprove',
            descriptionComponent: RegisterReviewCompletionStage,
        },
        {
            id: events.RegisterReviewLittleCheckTime,
            i18keyName: 'events.RegisterReviewLittleCheckTime',
            descriptionComponent: RegisterReviewCompletionStage,
        },
        {
            id: events.RegisterReviewLittleApproveTime,
            i18keyName: 'events.RegisterReviewLittleApproveTime',
            descriptionComponent: RegisterReviewCompletionStage,
        },
        {
            id: events.RegisterHasIssues,
            i18keyName: 'events.RegisterHasIssues',
            descriptionComponent: RegisterHasIssues,
        },
        {
            id: events.RegisterSendRequestToReviewers,
            i18keyName: 'events.RegisterSendRequestToReviewers',
            descriptionComponent: RegisterRequestToReviewers,
        },
        {
            id: events.SendNotifyToExpert,
            i18keyName: 'events.SendNotifyToExpert',
            descriptionComponent: RegisterRequestToReviewers,
        },
        {
            id: events.CorrectReviewEndDate,
            i18keyName: 'events.CorrectReviewEndDate',
            descriptionComponent: CorrectRevieEndDateNotification,
        },
        {
            id: events.ApproveCorrectDate,
            i18keyName: 'events.ApproveCorrectDate',
            descriptionComponent: CorrectRevieEndDateNotification,
        },
        {
            id: events.RefuseCorrectDate,
            i18keyName: 'events.RefuseCorrectDate',
            descriptionComponent: CorrectRevieEndDateNotification,
        },
        {
            id: events.RegisterTaskLittleResolveIssuesTime,
            i18keyName: 'events.RegisterTaskLittleResolveIssuesTime',
            descriptionComponent: RegisterTaskNotification,
        },
        {
            id: events.RegisterTaskChangeEndDateRequest,
            i18keyName: 'events.RegisterTaskChangeEndDateRequest',
            descriptionComponent: RegisterTaskNotification,
        },
        {
            id: events.RegisterTaskApproveChangeEndDate,
            i18keyName: 'events.RegisterTaskApproveChangeEndDate',
            descriptionComponent: RegisterTaskNotification,
        },
        {
            id: events.RegisterTaskCancelChangeEndDate,
            i18keyName: 'events.RegisterTaskCancelChangeEndDate',
            descriptionComponent: RegisterTaskNotification,
        },
    ];
}
