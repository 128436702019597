
    import { Component, Prop } from 'vue-property-decorator';
    import { DrawingPackageIssue, Notification } from '@/api/model/interfaces';
    import * as Odata from '@/api/odata';
    import NotificationItem from '@/components/common/notification-item.vue';

    @Component
    export default class IssueIssued extends NotificationItem {
        @Prop({ required: true })
        notification!: Notification;

        issue: DrawingPackageIssue | null = null;

        async mounted() {
            this.issue = await Odata.DrawingPackageIssue.setParams({
                expand: [ 'DrawingPackage' ],
            }).getById(this.notification.EntityId.toString());
            this.link = this.issue.DrawingPackage
                ? `/working-drawings/packages/${this.issue.DrawingPackage.Id}`
                : null;
        }
    }
