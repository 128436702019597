export enum AsBuiltGroups {
    CheckersId = '7ba521bf-8034-41c6-84e6-5b1de50a4ba0', // Проверяющие
    ApproversId = '585ab651-bee0-4977-ad3b-701d60d31598', // Утверждающие
    ClientsId = '7078ebe7-5c3d-41af-b156-ee31666e9fde', // Заказчики
    GeneralContractorsId = '5592889b-02cb-4cb7-a508-fab0422a6544', // Генподрядчики
    WorkPerformersId = '7d45cc66-1d88-4188-bb35-0712806532c2', // Исполнители работ
    PhysicalVolumeGroupId = 'b2fc9a87-eb2f-4976-836b-f8e78368e3ab', // Физ. объёмы
}

export const AsBuiltAppSettingsId = 'A0FF8FBF-C0F5-4983-AFC3-3D9765414F93';
export const WidgetAppSettingsId = '8FA38B96-ED57-4DD0-AEA8-03C119E447C6';

export enum AsBuiltReportActionName {
    Registers = 'Registers',
    RegistersWithCheck = 'RegistersWithCheck',
    RegistersWithApproval = 'RegistersWithApproval',
    RegistersForGenApprover = 'RegistersForGenApprover',
    Issues = 'Issues',
}

export enum AsBuiltTabs {
    Registers,
    Documents,
    Issues,
    Logs
}

export enum ReportTabs {
    FavoriteReports,
    Reports,
    Subscriptions,
    History,
}
