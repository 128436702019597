import { authInstance } from './auth-instance';
import { FilterItemsResponse, OptionsTable, RoleApi, RoleModuleRequest, RoleRequest, RoleResponse } from '@/types';

export const role: RoleApi<RoleResponse, RoleRequest> = {
    getAll: (): Promise<RoleResponse[]> =>
        authInstance.get<RoleResponse[]>('Roles/all').then(x => x.data),

    getByFilter: (options: OptionsTable): Promise<FilterItemsResponse<RoleResponse>> =>
        authInstance.post<FilterItemsResponse<RoleResponse>>('roles/byFilter', options).then(x => x.data),

    getById: (id: number): Promise<RoleResponse> =>
        authInstance.get<RoleResponse>(`roles/${id}`).then(x => x.data),

    create: (data: RoleRequest): Promise<RoleResponse> =>
        authInstance.post<RoleResponse>('roles', data).then(x => x.data),

    update: (id: number, data: RoleRequest): Promise<RoleResponse> =>
        authInstance.put<RoleResponse>(`roles/${id}`, data).then(x => x.data),

    remove: (id: number): Promise<void> =>
        authInstance.delete(`roles/${id}`).then(x => x.data),

    addModule: (data: RoleModuleRequest): Promise<void> =>
        authInstance.post('roles/addModule', data).then(x => x.data),
};
