import * as Papa from 'papaparse';

export function exportCSV(headers: string[], fileName: string) {
    const csvContent = [ headers ].map(row => row.map(x => `"${x}"`).join(';')).join('\n');
    const encodeToUtf8 = new TextEncoder().encode('\uFEFF' + csvContent);
    const blob = new Blob([ encodeToUtf8 ], { type: 'application/octet-stream' });
    downloadBlob(blob, `${fileName || 'template'}.csv`);
}

export function exportTableCSV(items: Record<string, string>[], fileName: string) {
    const csvContent = Papa.unparse(items, {
        header: true,
        delimiter: ';',
    });
    const encodeToUtf8 = new TextEncoder().encode('\uFEFF' + csvContent);
    const blob = new Blob([ encodeToUtf8 ], { type: 'application/octet-stream' });
    downloadBlob(blob, `${fileName || 'template'}.csv`);
}

function downloadBlob(blob: Blob, fileName: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
