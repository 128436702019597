import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { DynamicDocCategoryApi, DynamicDocumentCategory, DynamicResponse, getByFilter, Options } from '@/types/dynamicDoc';

/** Категории динамических документов */
export const documentCategory: DynamicDocCategoryApi<DynamicDocumentCategory, DynamicDocumentCategory> = {
    getByFilter: (options: Options) =>
        dynamicDocInstance.post<getByFilter<DynamicDocumentCategory>>('/DocumentCategory/getByFilter', options)
            .then(x => x.data),

    getAll: (): Promise<DynamicDocumentCategory[]> =>
        dynamicDocInstance.get<DynamicDocumentCategory[]>('documentCategory').then(x => x.data),

    getPage: (page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DynamicDocumentCategory>>(`DocumentCategory/Page?page=${page}&pageSize=${pageSize}`).then(x => x.data),

    getById: (id: string): Promise<DynamicDocumentCategory> =>
        dynamicDocInstance.get<DynamicDocumentCategory>(`documentCategory/${id}`).then(x => x.data),

    create: (data: DynamicDocumentCategory): Promise<DynamicDocumentCategory> =>
        dynamicDocInstance.post<DynamicDocumentCategory>('documentCategory', data).then(x => x.data),

    update: (id: string, data: DynamicDocumentCategory): Promise<DynamicDocumentCategory> =>
        dynamicDocInstance.put<DynamicDocumentCategory>(`documentCategory/${id}`, data).then(x => x.data),

    remove: (id: string): Promise<void> =>
        dynamicDocInstance.delete(`documentCategory/${id}`).then(x => x.data),

    search: (search: string) =>
        dynamicDocInstance.get<DynamicDocumentCategory[]>(`/documentCategory/Search?search=${search}`)
            .then(x => x.data),
};
