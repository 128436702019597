import {
    InProgressRegisterGlobalStatisticModel,
    UserReviewCountInfo,
    ReviewerCountInfoFilter,
    UserReviewsDetailsResponse,
    GeneralInformationResponse,
    GeneralInformationFilter,
} from '@/types/report/system';
import { cisInstance } from './cis-instance';
import { dynamicDocInstance } from '../dynamic/dynamic-document-instance';
import { getByFilter } from '@/types/dynamicDoc';

/** Отчеты */
export const report = {
    getInProgressRegisterGlobalStatistic: (): Promise<InProgressRegisterGlobalStatisticModel> =>
        cisInstance.get<InProgressRegisterGlobalStatisticModel>('/Report/InProgressRegisterGlobalStatistic').then(x => x.data),

    getExpiredCurrentStepUsersInfo: (filter: ReviewerCountInfoFilter): Promise<getByFilter<UserReviewCountInfo>> =>
        cisInstance.post<getByFilter<UserReviewCountInfo>>('/Report/ExpiredCurrentStepUsersInfo', filter).then(x => x.data),

    getExpiredCurrentStepByUser: (id: string): Promise<UserReviewsDetailsResponse[]> =>
        cisInstance.get<UserReviewsDetailsResponse[]>('/Report/ExpiredCurrentStepByUser/' + id).then(x => x.data),

    getGeneralInformation: (filter: GeneralInformationFilter): Promise<GeneralInformationResponse[]> =>
        dynamicDocInstance.post<GeneralInformationResponse[]>('/Report/GetGeneralInformationReport', filter).then(x => x.data),
};
