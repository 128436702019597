
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DataTableHeaderCustom } from '@/types';
    import { HeaderFilterValue } from '@/components/common/data-table/short-header-filter.vue';
    import DataTableFilterItem from '@/components/common/data-table-filter-item.vue';
    import { Timer } from '@/types/dynamicDoc';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { DataOptions } from 'vuetify';

    @Component({
        components: { ButtonTooltip, DataTableFilterItem },
    })
    export default class DataTableFilter extends Vue {
        @Prop({ type: Object, required: true })
        value!: any | any[];

        @Prop() header!: DataTableHeaderCustom;

        @Prop({ type: Boolean, default: false })
        readonly storage!: boolean;

        @Prop({ type: String })
        readonly storageId?: string;

        @Prop({ type: Object })
        readonly optionsTable?: DataOptions;

        timer: Timer = null;
        storageLoaded = false;

        @Watch('headers', { immediate: true })
        onHeaderChanges() {
            this.loadStorage();
        }

        get isDesc() {
            if (!this.optionsTable?.sortBy.length || !this.optionsTable?.sortDesc.length) return null;
            return this.optionsTable.sortBy[0] === this.header.value
                ? !this.optionsTable.sortDesc[0]
                : null;
        }

        get iconDesc() {
            if (this.isDesc === null) return null;
            return this.isDesc ? 'mdi-arrow-up' : 'mdi-arrow-down';
        }

        loadStorage() {
            if (!this.storage || this.storageLoaded) return;
            const storageValue = this.getStorage(this.header);
            if (storageValue) {
                this.onInput({ value: storageValue, header: this.header });
            }
            this.storageLoaded = true;
        }

        remove(header: DataTableHeaderCustom) {
            this.onInput({ value: null, header });
        }

        onInput(item: HeaderFilterValue) {
            if (item.header?.filterIsSearch) {
                if (this.value.Search[item.header.value] === item.value) return;
                this.value.Search[item.header.value] = item.value;
                this.deleteEmptyValue(this.value.Search);
            } else {
                if (this.value[item.header.value] === item.value) return;
                this.value[item.header.value] = item.value;
            }
            this.updateTimeRequest();
            this.setStorage(item);
        }

        deleteEmptyValue(item: any) {
            for (const key in item) {
                if (!item[key]?.toString()) delete item[key];
            }
        }

        updateTimeRequest() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => this.$emit('updated'), 1000);
        }

        storageKey(header: DataTableHeaderCustom) {
            return `${this.storageId || this.$route.name}-${header.value}-filter`;
        }

        setStorage(item: HeaderFilterValue) {
            if (!this.storage) return;
            if (!item.value) {
                this.removeStorage(item.header);
                return;
            }
            localStorage.setItem(this.storageKey(item.header), JSON.stringify(item.value));
        }

        removeStorage(header: DataTableHeaderCustom) {
            if (!this.storage) return;
            localStorage.removeItem(this.storageKey(header));
        }

        getStorage(header: DataTableHeaderCustom) {
            const localStorageItem = localStorage.getItem(this.storageKey(header));
            return localStorageItem ? JSON.parse(localStorageItem) : null;
        }
    }
