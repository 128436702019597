
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
    import * as api from '@/api/dynamic';
    import {
        DocumentField,
        DocumentFormHandler,
        DynamicDocumentSchema,
        DynamicEditorOptions,
        DynamicReftableItem,
        IField,
        NostroyTemplateNames,
        RelatedReftableRequest,
        TypeControl,
    } from '@/types/dynamicDoc';
    import { required, requiredMany } from '@/components/common/validation-rules';
    import TooltipWrapper from '@/components/dynamic/common/tooltip-wrapper.vue';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { PropType } from 'vue';
    import { reftableTemplates } from '@/components/reftables/helper';
    import * as Odata from '@/api/odata';

    @Component({
        components: { TooltipWrapper, ButtonTooltip },
    })
    export default class ReftableItemSelector extends Vue {
        @Prop({ type: String, required: false })
        private readonly schemaId!: string;

        @Prop({ type: String, required: true })
        private readonly label!: string;

        @Prop({ type: String, required: true })
        private readonly formula!: string;

        @Prop({ type: String, required: true })
        private readonly formulaFull!: string;

        @Prop([ String, Array ])
        private readonly value!: string | string[];

        @Prop({ type: Boolean, default: false })
        private readonly isMultiple!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly disabled!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly validate!: boolean;

        @Prop({ type: Number, default: null })
        private readonly documentDate!: number;

        @Prop({ type: Object, default: null })
        private readonly documentSchema!: DynamicDocumentSchema;

        @Prop({ type: Array, default: () => [] })
        private readonly drawingPackageIds!: string[];

        @Prop({ type: Object })
        private readonly field!: IField;

        @Prop({ type: Object as PropType<DynamicEditorOptions> })
        options!: DynamicEditorOptions;

        relatedSchemas: DynamicReftableItem[] = [];
        loading = false;

        rules = { required, requiredMany }

        @Watch('relatedFieldValue', { immediate: true })
        async onRelatedFieldChange(val: string | null) {
            if (!this.field || !this.relatedField || !this.options.relatedFieldItems) return;
            for (const relatedFieldItem of this.options.relatedFieldItems) {
                if (relatedFieldItem.RelatedFieldId === this.field.RelatedFieldId) {
                    relatedFieldItem.DynamicReftableItemId = val;
                }
            }
            this.setData(val);
        }

        @Watch('relatedReftableValue', { immediate: true })
        async onRelatedReftableChange(val: string | null) {
            if (!val) {
                await this.getRelatedSchema();
                return;
            }
            await this.getRelatedSchemaByReftable();
            if (this.items.length === 1) this.$emit('input', this.items[0].Id);
        }

        @Watch('schemaId', { immediate: true })
        async onSchemaChange() {
            await this.getRelatedSchema();
        }

        get drawingPackageHandleTrigger(): boolean {
            return this.drawingPackageIds?.length > 0 && this.items.length > 0;
        }

        @Watch('drawingPackageHandleTrigger', { immediate: true })
        async packageDesignerChangeHandler() {
            if (!this.documentSchema?.FormHandlers
                || this.documentSchema.FormHandlers.findIndex(x => x === DocumentFormHandler.PackageAuthorSupervisionChangeHandler) === -1
                || this.field.ReftableSchema?.TemplateId !== reftableTemplates.CisContragentTemplate) return;

            const selectedItem = this.drawingPackageIds?.at(0);
            if (!selectedItem && !this.value) return;
            if (!selectedItem) {
                this.reset();
                return;
            }

            const dp = await Odata.DrawingPackage.setParams({ select: [ 'Id', 'AuthorSupervisionId' ] }).getById(selectedItem);
            if (!dp || !dp.AuthorSupervisionId) return;

            const reftableItem = this.items.find(x => x.Data?.ContragentId === dp.AuthorSupervisionId);
            if (!reftableItem) return;

            const value = this.field.IsMultiple ? [ reftableItem.Id ] : reftableItem.Id;
            if (value === this.value) return;
            this.$emit('input', value);
        }

        get items(): DynamicReftableItem[] {
            const items = this.readonly
                ? this.relatedSchemas
                : this.relatedSchemas.filter(x => !x.IsObsolete || x.Id === this.value);

            return this.isNostroy && this.documentDate
                ? items.filter(this.nostroyFilter)
                : items;
        }

        get isNostroy() {
            return this.documentSchema?.FieldSet
                ?.find(x => x.TemplateCode === this.label)?.TypeControl === TypeControl.Nostroy;
        }

        get rulesData() {
            if (!this.validate) return [];
            const rule = this.isMultiple
                ? this.rules.requiredMany
                : this.rules.required;
            return [ rule ];
        }

        get relatedField() {
            if (!this.documentSchema
                || !this.documentSchema.FieldSet
                || !this.field
                || !this.field.RelatedFieldId) return null;
            return this.documentSchema.FieldSet.find(x => x.RelatedFieldId === this.field.RelatedFieldId) || null;
        }

        get relatedFieldValue(): string | null {
            if (!this.relatedField) return null;
            return this.options.data[this.relatedField.TemplateCode] || null;
        }

        get relatedReftableField() {
            if (!this.documentSchema
                || !this.documentSchema.FieldSet
                || !this.field
                || !this.field.RelatedReftableId
                || !this.field.RelationType) return null;
            return this.documentSchema.FieldSet.find(x => x.ReftableSchemaId === this.field.RelatedReftableId && x.RelationType) || null;
        }

        get relatedReftableValue(): string | null {
            if (!this.relatedReftableField) return null;
            return this.options.data[this.relatedReftableField.TemplateCode] || null;
        }

        get relatedRequest(): RelatedReftableRequest | null {
            const { Id, ReftableSchema, FormulaSmall, Formula } = this.field;
            if (!Id || !ReftableSchema?.Title
                || !this.relatedReftableField
                || !Object.keys(this.options.data).length
                || !this.options.data[this.relatedReftableField.TemplateCode]) return null;
            return {
                DocumentFieldId: this.relatedReftableField.Id as string,
                DynamicReftableItemId: this.options.data[this.relatedReftableField.TemplateCode],
                TemplateCode: ReftableSchema.Title,
                Formula: FormulaSmall || '',
                FormulaFullName: Formula || '',
            };
        }

        get relatedFields(): DocumentField[] {
            if (!this.documentSchema.FieldSet) return [];
            return this.documentSchema.FieldSet.filter(f => f.RelatedFieldId);
        }

        setData($event: string | null) {
            this.$emit('input', $event);
            if (this.relatedField) {
                this.relatedFields.filter(x => x.RelatedFieldId === this.field.RelatedFieldId)
                    .forEach(x => {
                        this.options.data[x.TemplateCode] = $event;
                    });
            }
        }

        getFullName(item: DynamicReftableItem, index: number) {
            return typeof this.value === 'object' && this.value?.length !== index + 1
                ? item.Data.$DisplayFullName + ','
                : item.Data.$DisplayFullName;
        }

        formatDateStrToTimeStamp(dateStr: string) {
            const items = dateStr.split('.').map(x => parseInt(x));
            return new Date(items[2], items[1] - 1, items[0])
                .setHours(0, 0, 0, 0);
        }

        nostroyFilter(item: DynamicReftableItem) {
            const start = item?.Data[NostroyTemplateNames.DateStart];
            if (!start) return false;

            const documentTimeStamp = new Date(this.documentDate).setHours(0, 0, 0, 0);
            const startTimeStamp = this.formatDateStrToTimeStamp(start);
            if (startTimeStamp > documentTimeStamp) return false;

            const end = item?.Data[NostroyTemplateNames.DateEnd];
            if (!end) return true;

            const endTimeStamp = this.formatDateStrToTimeStamp(end);
            return startTimeStamp < endTimeStamp
                && endTimeStamp >= documentTimeStamp
                && startTimeStamp <= documentTimeStamp;
        }

        reset() {
            this.$emit('input', this.field.IsMultiple ? [] : null);
            if (this.relatedReftableField && this.relatedReftableValue) {
                this.getRelatedSchema();
                this.options.data[this.relatedReftableField.TemplateCode] = null;
            }
        }

        async getRelatedSchema() {
            if (!this.schemaId || this.loading) return;
            this.loading = true;
            this.relatedSchemas = await api.reftableItem
                .getRelatedSchema(this.schemaId, this.formula, this.formulaFull);
            this.loading = false;
        }

        async getRelatedSchemaByReftable() {
            if (!this.relatedRequest || this.loading) return;
            this.loading = true;
            this.relatedSchemas = await api.reftableItem
                .getRelatedSchemaByReftable(this.relatedRequest);
            this.loading = false;
        }
    }

