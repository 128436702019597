import apiBaseUrl from '@/api/base-url';
import axios from 'axios';

export interface OperationRequest {
    Name: string,
    Arguments: OperationArguments,
}

export interface OperationStatus extends OperationRequest {
    Available: boolean,
    Enabled: boolean,
    Problems: string[] | null,
    Warnings: string[] | null,
}

export type OperationArguments = Record<string, any>;

export type OperationsByKey = Record<string, OperationsByName>;

export type OperationsByName = Record<string, OperationStatus>;

export async function getOperationsForKeys(operationNames: string[], keys: Array<string>): Promise<OperationsByKey> {
    const operationStatuses = await getOperationsRequest(
        operationNames.flatMap(opName =>
            keys.map(key => ({
                Name: opName,
                Arguments: { key: key },
            })),
        ),
    );

    return operationStatuses.reduce((opsByKey, opStatus) => {
        const opKey: string = opStatus.Arguments.key;
        if (!opsByKey.hasOwnProperty(opKey)) opsByKey[opKey] = {};

        opsByKey[opKey][opStatus.Name] = opStatus;
        return opsByKey;
    }, {} as OperationsByKey);
}

export async function getOperations(operationNames: string[], key?: string): Promise<OperationsByName> {
    const operationStatuses = await getOperationsRequest(
        operationNames.map(n => ({
            Name: n,
            Arguments: key ? { key: key } : {},
        })),
    );

    return operationStatuses.reduce((result, opStatus) => {
        result[opStatus.Name] = opStatus;
        return result;
    }, {} as OperationsByName);
}

export function getOperationsRequest(requests: OperationRequest[]): Promise<OperationStatus[]> {
    if (!requests.length) return Promise.resolve([]);
    return axios.post(`${apiBaseUrl}api/Operation/GetOperations`, requests)
        .then(resp => resp.data);
}
