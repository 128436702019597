
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component({})
    export default class More extends Vue {
        @Prop() options: any;
        observer: any = null;

        mounted() {
            const options = this.options || {};
            this.observer = new IntersectionObserver(([ entry ]) => {
                if (entry && entry.isIntersecting) {
                    this.$emit('intersect');
                }
            }, options);

            this.observer.observe(this.$el);
        }
    }
