import axios from 'axios';
import { interceptors } from '@/api/helpers';

export const apiDocumentServiceUrl = process.env.VUE_APP_API_DOCUMENT_SERVICE;

export const dynamicDocInstance = axios.create({
    baseURL: `${apiDocumentServiceUrl}api/`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') },
});

// Перехватчик запросов axios
interceptors(dynamicDocInstance);
