
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { RegisterReviewStatus, RegisterStatus as RegisterStatusConstant } from '@/api/constants';
    import { getOperations, OperationsByName } from '@/api/entity/operations';
    import OperationButton from '@/components/common/operation-button.vue';
    import { localizeDescription, localizeName } from '@/util/field-translation';
    import { RightConstants } from '@/types/right-constants';
    import { AutocompleteItem, ContragentResponse, DataTableHeaderCustom, MenuFilterType, OptionsDataTable, UserResponse } from '@/types';
    import { DocumentCategory, Marka, Register, RegisterStatus, TitleObject } from '@/api/model/interfaces';
    import * as Odata from '@/api/odata';
    import RegisterDialog from '@/components/asbuilt-docs/registers/register-dialog.vue';
    import { GET_ASBUILT_APP_SETTINGS } from '@/store/settings/types';
    import { useLocalStorage, useStorage } from '@vueuse/core';
    import { registerStatuses } from '@/components/asbuilt-docs/registers/helpers';
    import { exportTableCSV } from '@/components/dynamic/common/editors/table-items-helper';
    import VerticalMenu, { MenuItem } from '@/components/common/v-menu.vue';
    import { getUserFullName, userFullNameObj } from '@/components/admin/roles-model/users/helpers';
    import { cisInstance } from '@/api/cis/cis-instance';
    import DataTableFilter from '@/components/common/data-table-filter.vue';
    import * as VueType from 'vue';
    import DataTableSetting from '@/components/common/data-table-setting.vue';

    @Component({
        components: {
            DataTableSetting,
            DataTableFilter,
            OperationButton,
            RegisterDialog,
            VerticalMenu,
        },
        data: () => ({
            MenuFilterType,
            registerStatuses,
        }),
    })
    export default class Registers extends Vue {
        loading = false;
        operations: OperationsByName | null = null;
        registers: Register[] = [];
        registerMarks: { [key: string]: string[] } = {};
        registerTitleObjects: { [key: string]: string[] } = {};
        totalRegisters = 0;
        dialogVisible = false;
        selectedItems = [];
        selectMode = false;
        sheetGenerateInProgress = false;

        optionsFilters: any = {
            Code: '',
            Name: '',
            StatusId: [],
            CustomerId: [],
            ContractorId: [],
            SubContractorId: [],
            RespUserId: [],
            RegisterDate: '',
            MarkaIds: [],
            TitleObjectIds: [],
            DocumentCategoryId: [],
            RegisterDynamicDocumentSetCount: '',
        };

        @Watch('registerStatusId', { immediate: true })
        async init() {
            if (!this.routeParamsExist) return;
            localStorage.setItem('registers-StatusId-filter', JSON.stringify(this.registerStatusId ? [ this.registerStatusId ] : []));
        }

        @Watch('registerSubContractorId', { immediate: true })
        async initSubContractor() {
            if (!this.routeParamsExist) return;
            localStorage.setItem('registers-SubContractorId-filter', JSON.stringify(this.registerSubContractorId ? [ this.registerSubContractorId ] : []));
        }

        @Watch('optionsTable.value', { immediate: true })
        async changedOptionsTable() {
            if (this.loading) return;
            await this.getRegisters();
        }

        @Watch('filter', { immediate: true })
        async updateFilter() {
            if (this.loading) return;
            await this.getRegisters();
        }

        @Watch('registers', { immediate: true })
        async loadFieldsData() {
            if (!this.registers.length) return;

            const iterations = this.registers.map((r: Register) => r.CurrentIterationId);
            this.registerMarks = await Odata.RegisterItem
                .runAction('GetIterationsMarks', null, {
                    ids: iterations,
                });
            this.registerTitleObjects = await Odata.RegisterItem
                .runAction('GetIterationsTitleObjects', null, {
                    ids: iterations,
                });
        }

        get optionsToolbarFilter() {
            return useLocalStorage(`${this.$route.name}-optionsToolbarFilter`, {
                hideReviewedRegisters: false,
                showOnlyWhereReviewRequired: false,
                showOnlyActive: true,
            }, {}).value;
        }

        get optionsTable() {
            return useStorage(`${this.$route.name}-optionsTable`, {
                page: 1,
                itemsPerPage: 25,
                sortBy: [ 'RegisterDate' ],
                sortDesc: [ true ],
                showColumns: this.tableHeaders.map((h) => h.value),
            }, sessionStorage) as VueType.Ref<OptionsDataTable>;
        }

        get registerStatusId() {
            return this.$route.params.statusId;
        }

        get registerSubContractorId(): string {
            return this.$route.params.subContractorId;
        }

        get routeParamsExist() {
            return Object.values(this.$route.params).length > 0;
        }

        get skip() {
            return (this.optionsTable.value.page - 1) * this.optionsTable.value.itemsPerPage;
        }

        get sorting() {
            if (!this.optionsTable.value.sortBy[0]) return null;
            return `${this.optionsTable.value.sortBy[0]} ${this.optionsTable.value.sortDesc[0] ? 'Desc' : ''}`;
        }

        get tableHeaders(): DataTableHeaderCustom[] {
            return [
                {
                    text: this.$t('common.number') as string,
                    value: 'Code',
                    width: '110px',
                    filterVisible: true,
                    filterType: MenuFilterType.Number,
                },
                {
                    text: this.$t('common.title') as string,
                    value: 'Name',
                    filterVisible: true,
                    filterType: MenuFilterType.String,
                },
                {
                    text: this.$t('common.status') as string,
                    value: 'StatusId',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.registerStatus,
                    multiple: true,
                },
                {
                    text: this.$t('common.markas') as string,
                    value: 'MarkaIds',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.markas,
                    multiple: true,
                    sortable: false,
                },
                {
                    text: this.$t('common.title-objects') as string,
                    value: 'TitleObjectIds',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.titleObjects,
                    multiple: true,
                    sortable: false,
                },
                {
                    text: this.$t('reftable.document-category') as string,
                    value: 'DocumentCategoryId',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.documentCategories,
                    multiple: true,
                },
                {
                    text: this.$t('common.customer') as string,
                    value: 'CustomerId',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.contragents,
                    multiple: true,
                },
                {
                    text: this.$t('common.gencontractor') as string,
                    value: 'ContractorId',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.contragents,
                    multiple: true,
                },
                {
                    text: this.$t('common.subcontractor') as string,
                    value: 'SubContractorId',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.contragents,
                    multiple: true,
                },
                {
                    text: this.$t('common.responsible') as string,
                    value: 'RespUserId',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.users,
                    multiple: true,
                },
                {
                    text: this.$t('common.documents-count') as string,
                    value: 'RegisterDynamicDocumentSetCount',
                    filterVisible: true,
                    sortable: false,
                    filterType: MenuFilterType.Number,
                    width: '100px',
                },
                {
                    text: this.$t('common.date') as string,
                    value: 'RegisterDate',
                    width: '100px',
                    filterVisible: true,
                    filterType: MenuFilterType.Date,
                },
            ];
        }

        get tableHeaderFilter() {
            return this.tableHeaders.filter(x => this.optionsTable.value.showColumns?.includes(x.value));
        }

        get items() {
            return this.formatToItems(this.registers);
        }

        get registerStatus() {
            return this.$store.state.reftables.RegisterStatus.map((x: RegisterStatus) => ({
                text: localizeDescription(x),
                value: x.Id,
            }));
        }

        get contragents(): AutocompleteItem[] {
            return this.$store.state.reftables.Contragents.map((x: ContragentResponse) => ({
                text: x.code ? x.code : localizeName(x),
                value: x.id,
            }));
        }

        get users(): AutocompleteItem[] {
            return this.$store.state.reftables.Users.map((x: UserResponse) => ({
                text: userFullNameObj(x),
                value: x.guidId,
            }));
        }

        get markas(): AutocompleteItem[] {
            return this.$store.state.reftables.Marka.map((x: Marka) => ({
                text: x.Title,
                value: x.Id,
            }));
        }

        get titleObjects(): AutocompleteItem[] {
            return this.$store.state.reftables.TitleObjects.map((x: TitleObject) => ({
                text: `${x.PlanCode}. ${localizeDescription(x)}`,
                value: x.Id,
            }));
        }

        get documentCategories(): AutocompleteItem[] {
            return this.$store.state.reftables.DocumentCategories
                .map((x: DocumentCategory) => ({
                    text: x.Code,
                    value: x.Id,
                }));
        }

        get userId() {
            return this.$store.getters.getUser.guidId;
        }

        get filter() {
            const activeStatus = [
                RegisterStatusConstant.Draft,
                RegisterStatusConstant.Canceled,
                RegisterStatusConstant.Signed,
            ];

            const filter = Object.keys(this.optionsFilters)
                .filter(key => Array.isArray(this.optionsFilters[key]) ? this.optionsFilters[key].length : this.optionsFilters[key])
                .map(key => {
                    switch (key) {
                        case 'Code': return `Code eq ${this.optionsFilters[key]}`;
                        case 'Name': return `contains(tolower(Name),'${this.optionsFilters[key].toLowerCase()}')`;
                        case 'StatusId': return `StatusId in (${this.optionsFilters[key].join(',')})`;
                        case 'DocumentCategoryId': return `DocumentCategoryId in (${this.optionsFilters[key].join(',')})`;
                        case 'CustomerId': return `CustomerId in (${this.optionsFilters[key].join(',')})`;
                        case 'ContractorId': return `ContractorId in (${this.optionsFilters[key].join(',')})`;
                        case 'SubContractorId': return `SubContractorId in (${this.optionsFilters[key].join(',')})`;
                        case 'RespUserId': return `RespUserId in (${this.optionsFilters[key].join(',')})`;
                        case 'RegisterDate': return `(RegisterDate ge ${this.formatDate(this.optionsFilters[key].start)})`
                            + ` and (RegisterDate le ${this.formatDate(this.optionsFilters[key].end)})`;
                        case 'MarkaIds': return `CurrentIteration
                            /RegisterDynamicDocumentSet/any(r:
                            r/DynamicDocumentItem
                            /DynamicDocumentItemDrawingPackageSet/any(d:
                            d/DrawingPackage
                            /DrawingPackage_MarkaSet/any(m: m/MarkaId in (${this.optionsFilters[key].join(',')}))))`;
                        case 'TitleObjectIds': return `CurrentIteration
                            /RegisterDynamicDocumentSet/any(r:
                            r/DynamicDocumentItem
                            /DynamicDocumentItemDrawingPackageSet/any(d:
                            d/DrawingPackage
                            /DrawingPackage_TitleObjectSet/any(m: m/TitleObjectId in (${this.optionsFilters[key].join(',')}))))`;
                        case 'RegisterDynamicDocumentSetCount': return `CurrentIteration
                            /RegisterDynamicDocumentSet/$count eq ${this.optionsFilters[key]}`;
                        default: return '';
                    }
                })
                .concat(Object.keys(this.optionsToolbarFilter)
                    .filter(key => this.optionsToolbarFilter[key as keyof typeof this.optionsToolbarFilter])
                    .map(key => {
                        switch (key) {
                            case 'hideReviewedRegisters': return `CurrentIteration/ReviewSet/any(r:r/Reviewer/RespUserId eq ${this.userId}
                                and r/StatusId eq ${RegisterReviewStatus.Done} ) eq ${false}`;
                            case 'showOnlyWhereReviewRequired': return `CurrentIteration/ReviewSet/any(r: r/Reviewer/RespUserId eq ${this.userId}
                                and r/StatusId in (${RegisterReviewStatus.New}, ${RegisterReviewStatus.InProgress})) eq ${true}`;
                            case 'showOnlyActive': return `StatusId in (${activeStatus.join(',')}) eq false`;
                            default: return '';
                        }
                    }));

            return filter.length ? filter : null;
        }

        get employees(): UserResponse[] {
            return this.$store.state.reftables.Users;
        }

        get isReviewer() {
            return this.$store.getters.isAdmin
                || !!this.$store.state.auth.user?.rights.includes(RightConstants.StartCheck)
                || !!this.$store.state.auth.user?.rights.includes(RightConstants.StartApproval);
        }

        markaTitle(id: string) {
            return this.markas.find(i => i.value === id)?.text || '';
        }

        titleObjectTitle(id: string) {
            return this.titleObjects.find(i => i.value === id)?.text || '';
        }

        registerStatusColor(statusId: string) {
            return registerStatuses[statusId] ? registerStatuses[statusId].color : '#9ca3af';
        }

        formatDate(date: string) {
            return new Date(date).toISOString().slice(0, 10);
        }

        contragentFilter(id: number | null) {
            if (!id) return 'Не заполнено';
            return this.contragents.find(x => x.value === id)?.text;
        }

        changeSortBy(sortBy: string) {
            this.optionsTable.value.sortBy = [ sortBy ];
        }

        changeSortDesc(desc: boolean) {
            this.optionsTable.value.sortDesc = [ desc ];
        }

        formatToItems(registers: Register[]) {
            return registers.map(x => {
                return {
                    ...x,
                    Code: Number(x.Code),
                    CustomerId: this.contragentFilter(x.CustomerId),
                    ContractorId: this.contragentFilter(x.ContractorId),
                    SubContractorId: this.contragentFilter(x.SubContractorId),
                    RespUserId: getUserFullName(x.RespUserId as string),
                    RegisterDate: new Date(x.RegisterDate!).toLocaleDateString(),
                    MarkaIds: this.registerMarks[x.CurrentIterationId]
                        ? this.registerMarks[x.CurrentIterationId].map(i => this.markaTitle(i)).join(', ')
                        : '',
                    TitleObjectIds: this.registerTitleObjects[x.CurrentIterationId]
                        ? this.registerTitleObjects[x.CurrentIterationId].map(i => this.titleObjectTitle(i)).join(', ')
                        : '',
                    DocumentCategoryId: x.DocumentCategory?.Code || '',
                    RegisterDynamicDocumentSetCount: (x as any).CurrentIteration['RegisterDynamicDocumentSet@odata.count'],
                };
            });
        }

        handleMouseDown(mouseEvent: MouseEvent, item: Register) {
            if (mouseEvent.button === 1) {
                const registerId = item.Id;
                const route = this.$router.resolve({
                    name: 'register-page',
                    params: { registerId },
                });
                window.open(route.href, '_blank')?.blur();
                window.focus();
            }
        }

        async created() {
            await this.$store.dispatch(GET_ASBUILT_APP_SETTINGS);
            this.operations = await getOperations([ 'RegisterCreate' ]);
        }

        async getRegisters() {
            try {
                this.loading = true;
                this.totalRegisters = await Odata.Register.setParams({ filter: this.filter }).count();
                this.registers = await Odata.Register.setParams({
                    top: this.optionsTable.value.itemsPerPage > 0 ? this.optionsTable.value.itemsPerPage : undefined,
                    skip: this.optionsTable.value.itemsPerPage > 0 ? this.skip : undefined,
                    filter: this.filter,
                    expand: [
                        'DocumentCategory',
                        'CurrentIteration($expand=RegisterDynamicDocumentSet($count=true; $top=0);$select=Id)',
                    ],
                    orderBy: this.sorting,
                    select: [
                        'Id',
                        'Code',
                        'Name',
                        'StatusId',
                        'CustomerId',
                        'ContractorId',
                        'SubContractorId',
                        'RespUserId',
                        'RegisterDate',
                        'CurrentIterationId',
                    ],
                }).getAll();
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        }

        async onNewRegister(register: Register) {
            this.loading = true;

            const newRegister = await Odata.Register.create(register);
            this.onNewRegisterCreated(newRegister);
        }

        goToRegister(registerId: string) {
            this.$router.push({
                name: 'register-page',
                params: { registerId },
            });
        }

        onNewRegisterCreated(data: any) {
            this.loading = false;
            this.goToRegister(data.Id.toString());
        }

        openDialog() {
            this.dialogVisible = true;
        }

        onRowClick(e: Register) {
            this.goToRegister(e.Id);
        }

        get topMenu(): MenuItem[] {
            return [
                {
                    title: this.$t('common.export-csv'),
                    subTitle: this.$t('common.export-csv-desc'),
                    icon: 'mdi-file-export-outline',
                    event: this.exportToCSV,
                    visible: true,
                    disabled: this.totalRegisters === 0,
                },
                {
                    title: this.$t('register-page.statement-of-volumes-sheet'),
                    subTitle: this.$t('register-page.statement-of-volumes-sheet-desc'),
                    icon: 'mdi-file-excel',
                    event: this.onRegisterVolumesSheetClick,
                    visible: true,
                    disabled: this.totalRegisters === 0,
                },
            ];
        }

        onRegisterVolumesSheetClick() {
            this.selectedItems = [];
            this.optionsFilters.StatusId = [
                RegisterStatusConstant.Signing,
                RegisterStatusConstant.Signed,
            ];
            this.selectMode = true;
        }

        cancelSelectMode() {
            this.selectMode = false;
            this.optionsFilters.StatusId = [];
        }

        async onVolumesSheetGenerateClick() {
            if (!this.selectedItems.length || !this.selectMode) return;
            const ids = this.selectedItems.map((x: any) => x.Id);

            try {
                this.sheetGenerateInProgress = true;
                const url = Odata.Register.getUrl('DownloadVolumesByRegisters', null);
                const resp = await cisInstance.post(url, { ids: ids }, { responseType: 'blob' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(resp.data);
                link.download = `${this.$t('register-page.statement-of-volumes-sheet')}-${new Date().toLocaleDateString()}.xlsx`;
                link.click();
                this.cancelSelectMode();
            } catch (ex) {
                this.$root.$notify.show('error', ex);
            } finally {
                this.sheetGenerateInProgress = false;
            }
        }

        async exportToCSV() {
            this.loading = true;
            const registers = await Odata.Register.setParams({
                expand: [
                    'Status',
                    'DocumentCategory',
                    'CurrentIteration($expand=RegisterDynamicDocumentSet($count=true; $top=0);$select=Id)',
                ],
                select: [
                    'Id',
                    'Code',
                    'Name',
                    'StatusId',
                    'CustomerId',
                    'ContractorId',
                    'SubContractorId',
                    'RespUserId',
                    'RegisterDate',
                    'CurrentIterationId',
                ],
            }).getAll();

            const items = this.formatToItems(registers).map((item: any) => {
                const sortedItem: Record<string, string> = {};
                this.tableHeaders.forEach(key => {
                    if (key.value === 'StatusId') {
                        sortedItem[key.text] = registerStatuses[item.StatusId].title;
                    } else {
                        sortedItem[key.text] = item[key.value];
                    }
                });
                return sortedItem;
            });

            exportTableCSV(items, `${this.$t('common.registers')}: ${new Date().toLocaleDateString()}`);
            this.loading = false;
        }
    }
