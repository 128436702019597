import { EventDescriptor } from '@/components/notification/store/event-descriptor';
import events from '@/api/model/events';
import IssueIssued from './issue-issued.vue';
import Ks2ReviewNew from './ks2-review-new.vue';
import Ks2HasIssues from './ks2-has-issues.vue';

export default function getKs2EventDescriptors() : EventDescriptor[] {
    return [
        {
            id: events.PackageKS2IssueIssued,
            i18keyName: 'events.PackageKS2IssueIssued',
            descriptionComponent: IssueIssued,
        },
        {
            id: events.PackageKS2NewApprove,
            i18keyName: 'events.PackageKS2NewApprove',
            descriptionComponent: Ks2ReviewNew,
        },
        {
            id: events.PackageKS2NewCheck,
            i18keyName: 'events.PackageKS2NewCheck',
            descriptionComponent: Ks2ReviewNew,
        },
        {
            id: events.PackageKS2HasIssues,
            i18keyName: 'events.PackageKS2HasIssues',
            descriptionComponent: Ks2HasIssues,
        },
        {
            id: events.PackageKS2Approval,
            i18keyName: 'events.PackageKS2Approval',
            descriptionComponent: Ks2HasIssues,
        },
    ];
}
