import * as Odata from '@/api/odata';
import { AutocompleteItem } from '@/types';
import { AccessContractType } from '@/api/model/enums';
import i18n from '@/plugins/i18n';

export function reftableDatasourceConf(modelName: string) {
    return getOdata(modelName).setParams({
        orderBy: 'SortOrder,Title',
    });
}

export function getOdata(modelName: string) {
    return Odata[modelName as keyof typeof Odata];
}

export async function getReftableMetadata(modelName: string) {
    const refDs = await Odata.Reftable.setParams({
        orderBy: 'Title',
        filter: [ `ModelName eq '${modelName}'` ],
    }).getAll();
    return refDs[0];
}

export const visibleType: AutocompleteItem[] = [
    { value: AccessContractType.FirstVisibleSecond, text: i18n.t('common.first-contractor-sees-second') as string },
    { value: AccessContractType.SecondVisibleFirst, text: i18n.t('common.second-contractor-sees-first') as string },
    { value: AccessContractType.NotVisible, text: i18n.t('common.contractor-not-see') as string },
    { value: AccessContractType.VisibleBoth, text: i18n.t('common.contractor-sees-both') as string },
];

export const reftableTemplates = {
    CisContragentTemplate: '3741a8d5-94e1-419d-a15f-c43cf572d44c',
};

export const registerCreateFlexFields: AutocompleteItem[] = [
    { value: 'DrawingPackageId', text: i18n.t('common.drawing-package') as string },
    { value: 'SystemType', text: i18n.t('common.system-type') as string },
    { value: 'SubSystemCode', text: i18n.t('common.subsystem-code') as string },
    { value: 'PnrDocumentTypeId', text: i18n.t('common.pnr-document-type') as string },
    { value: 'PnrTitleObjectId', text: i18n.t('common.pnr-title-object') as string },
];
