import { render, staticRenderFns } from "./default.vue?vue&type=template&id=f15c5a4e&scoped=true&lang=pug&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f15c5a4e",
  null
  
)

export default component.exports