import { TabsConfiguration } from '@/api/dashboard/types';
import { PeriodCheckRegistry, ReminderPeriodCheckRegistry } from '@/api/model/enums';

export const GET_COMMON_APP_SETTINGS = 'GET_COMMON_APP_SETTINGS';
export const SET_COMMON_APP_SETTINGS = 'SET_COMMON_APP_SETTINGS';
export const GET_ASBUILT_APP_SETTINGS = 'GET_ASBUILT_APP_SETTINGS';
export const SET_ASBUILT_APP_SETTINGS = 'SET_ASBUILT_APP_SETTINGS';

export interface CommonAppSettings {
    TsaServiceUrl: string;
    FullTextSearchEnabled: boolean;
    ReportsGeneratorEnabled: boolean;
    TabsConfiguration: TabsConfiguration;
    GlossaryLink: string;
}

export interface AgreementSettings {
    TextRu: string;
    TextEn: string;
}

export interface Settings {
    common: CommonAppSettings | null;
    asBuilt: AsBuiltAppSettings | null;
}

export interface SettingData {
    Data: string;
}

export interface AnalysisConfiguration {
    IsAnalyzedOnCheck: boolean;
    IsAnalyzedOnApproval: boolean;
}

export interface RegisterDocumentsWeightConfiguration {
    MaxPages?: number | null;
    WeightA0: number;
    WeightA1: number;
    WeightA2: number;
    WeightA3: number;
    WeightA4: number;
    WeightA5: number;
}
export interface RegisterDocumentsHandlerSettings {
    HandlerId: string;
    SchemaId?: string;
    Enabled: boolean;
    Settings?: { Key: string; Value: string }[];
}

export interface AsBuiltAppSettings {
    CheckEnabled: boolean;
    SignatureEnabled: boolean;
    IsReviewersRequestingEnabled: boolean;
    AddLinkedDocumentsInRegister: boolean;
    StagesList: string | null;
    AnalysisConfiguration: AnalysisConfiguration;
    NormalRegisterVerificationTimeDays: number;
    TimeCheckRegistry: number;
    TimeApproveRegistry: number;
    PeriodCheckRegistry: PeriodCheckRegistry;
    PeriodApproveRegistry: PeriodCheckRegistry;
    ReminderTimeCheckRegistry: number;
    ReminderTimeApproveRegistry: number;
    ReminderPeriodCheckRegistry: ReminderPeriodCheckRegistry;
    ReminderPeriodApproveRegistry: ReminderPeriodCheckRegistry;
    TimeReCheckRegistry: number;
    PeriodReCheckRegistry: PeriodCheckRegistry;
    TimeReApproveRegistry: number;
    PeriodReApproveRegistry: PeriodCheckRegistry;
    ReminderTimeResolvingIssues: number;
    ReminderPeriodResolvingIssues: ReminderPeriodCheckRegistry
    TimeResolvingIssues: number;
    PeriodResolvingIssues: PeriodCheckRegistry;
    DefaultCheckersTemplateId: string;
    DefaultApproversTemplateId: string;
    CustomerDefaultId: number | null;
    ContractorDefaultId: number | null;
    RegisterDocWeightConfig: RegisterDocumentsWeightConfiguration;
    RegisterDocumentsHandlers: RegisterDocumentsHandlerSettings[];
}

export interface DrawingPackagesSetting {
    SignatureEnabled: boolean;
    CheckEnabled: boolean;
    DefaultApproversTemplateId: string | null;
    DefaultCheckersTemplateId: string | null;
}

export interface Ks2Setting {
    CipherFromReference: boolean;
    SignatureEnabled: boolean;
    CheckEnabled: boolean;
    DefaultApproversTemplateId: string | null;
    DefaultCheckersTemplateId: string | null;
}
