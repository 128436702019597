// https://stackoverflow.com/a/53508547/5512932

import { AxiosInstance } from 'axios';
import store from '@/store';
import { AUTH_LOGOUT } from '@/store/auth';

export function mapAsync<T, U>(array: T[], filterExpr: (value: T, index: number, array: T[]) => Promise<U> | U): Promise<U[]> {
    return Promise.all(array.map(filterExpr));
}

export async function filterAsync<T>(array: T[], filterExpr: (value: T, index: number, array: T[]) => Promise<boolean> | boolean): Promise<T[]> {
    const filterMap = await mapAsync(array, filterExpr);
    return array.filter((value, index) => filterMap[index]);
}

export function interceptors(instance: AxiosInstance) {
    instance.interceptors.response.use(function(response) {
        return response;
    }, async function(error) {
        const { response: { status } } = error;
        if (status === 401) {
            await store.dispatch(AUTH_LOGOUT);
            location.pathname = '/login';
        } else {
            return Promise.reject(error);
        }
    });
}
