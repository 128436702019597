
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { PhysicalVolumeHistory } from '@/types/dynamicDoc';
    import { DataOptions } from 'vuetify';
    import { PhysicalVolumeHistoryOptions } from '@/components/asbuilt-docs/volume/utils';
    import { AutocompleteItem, DataTableHeaderCustom, MenuFilterType, UserResponse, WorkType } from '@/types';
    import { MeasurementUnit } from '@/api/model/interfaces';
    import { localizeDescription } from '@/util/field-translation';
    import * as apiDynamic from '@/api/dynamic';
    import { userFullNameObj } from '@/components/admin/roles-model/users/helpers';
    import DataTableFilter from '@/components/common/data-table-filter.vue';
    import { useStorage } from '@vueuse/core';
    import * as VueType from 'vue';

    @Component({
        components: { DataTableFilter },
    })
    export default class ProgressVolume extends Vue {
        volumeHistory: PhysicalVolumeHistory[] = [];
        totalItems = 0;
        loading = false;

        options: Partial<PhysicalVolumeHistoryOptions> = {
            Search: {},
        };

        @Watch('$route', { immediate: true })
        async init() {
            if (this.$route.params.subItem === 'progress') {
                await this.getPhysicalVolumeHistory();
            }
        }

        @Watch('optionsTable', { immediate: true })
        async changedOptionsTable() {
            if (this.loading) return;
            await this.getPhysicalVolumeHistory();
        }

        get optionsTable() {
            return useStorage(`${this.$route.path}-progress-optionsTable`, {
                page: 1,
                itemsPerPage: 25,
                sortBy: [ 'CreateAt' ],
                sortDesc: [ true ],
            }, sessionStorage) as VueType.Ref<DataOptions>;
        }

        get users(): AutocompleteItem[] {
            return this.$store.state.reftables.Users.map((x: UserResponse) => ({
                text: userFullNameObj(x),
                value: x.guidId,
            }));
        }

        get measurementUnit(): AutocompleteItem[] {
            return this.$store.state.reftables.MeasurementUnit.map((x: MeasurementUnit) => ({
                text: localizeDescription(x),
                value: x.Id.toString(),
            }));
        }

        get workTypes(): AutocompleteItem[] {
            return this.$store.state.reftables.WorkTypes.map((x: WorkType) => ({
                text: x.Code.length ? `${x.Code}. ${localizeDescription(x)}` : localizeDescription(x),
                value: x.Id,
            }));
        }

        get headers(): DataTableHeaderCustom[] {
            return [
                {
                    text: this.$t('common.work-type') as string,
                    value: 'WorkTypeId',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.workTypes,
                },
                {
                    text: this.$t('volume.change') as string,
                    value: 'Change',
                    sortable: false,
                },
                {
                    text: this.$t('common.measurement-units-short') as string,
                    value: 'MeasurementUnitId',
                    filterVisible: false,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.measurementUnit,
                },
                {
                    text: this.$t('common.start-date') as string,
                    value: 'DateStartRange',
                    filterVisible: true,
                    filterType: MenuFilterType.Date,
                },
                {
                    text: this.$t('common.end-date') as string,
                    value: 'DateEndRange',
                    filterVisible: true,
                    filterType: MenuFilterType.Date,
                },
                {
                    text: this.$t('common.fullname') as string,
                    value: 'AppUserId',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.users,
                },
                {
                    text: this.$t('common.date-create') as string,
                    value: 'CreateAtRange',
                    filterVisible: true,
                    filterType: MenuFilterType.Date,
                },
            ];
        }

        get items() {
            if (!this.volumeHistory) return [];
            return this.volumeHistory.map((x) => {
                const workType = this.workTypes
                    .find(workType => workType.value === x.PhysicalVolumeItem?.PhysicalVolume?.WorkTypeId);
                const measurementUnit = this.measurementUnit
                    .find(measure => measure.value === x.PhysicalVolumeItem?.PhysicalVolume?.MeasurementUnitId);
                const user = this.users.find(user => user.value === x.AppUserId);
                return {
                    ...x,
                    WorkTypeId: workType?.text,
                    Change: x.NewValue - x.OldValue,
                    MeasurementUnitId: measurementUnit?.text,
                    DateStartRange: new Date(x.StartDate!).toLocaleDateString(),
                    DateEndRange: new Date(x.EndDate!).toLocaleDateString(),
                    AppUserId: user?.text,
                    CreateAtRange: new Date(x.CreateAt).toLocaleDateString(),
                };
            });
        }

        deleteEmptyValue(item: any) {
            for (const key in item) {
                if (!item[key]?.toString()) delete item[key];
            }
        }

        changeSortBy(sortBy: string) {
            this.optionsTable.value.sortBy = [ sortBy ];
        }

        changeSortDesc(desc: boolean) {
            this.optionsTable.value.sortDesc = [ desc ];
        }

        async getPhysicalVolumeHistory() {
            const res = await apiDynamic.physicalVolume.getHistory({
                ...this.options,
                PageSize: this.optionsTable.value.itemsPerPage,
                PageNumber: this.optionsTable.value.page,
                OrderBy: this.optionsTable.value.sortBy[0],
                IsDesc: this.optionsTable.value.sortDesc[0],
            });
            this.volumeHistory = res.Items;
            this.totalItems = res.TotalItems;
        }
    }
