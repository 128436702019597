
    import { Component, Vue } from 'vue-property-decorator';
    import Notify from '@/components/common/notify.vue';
    import NavigationMenu from '@/components/home/navigation-menu.vue';
    import AppBar from '@/components/home/app-bar.vue';
    import AgreementUserDialog from '@/components/common/agreement-user-dialog.vue';
    import Confirm from '@/components/common/confirm.vue';
    import * as ReftablesActions from '@/store/reftables/actions';
    import axios from 'axios';
    import i18n from '@/plugins/i18n';

    @Component({
        components: {
            Notify,
            NavigationMenu,
            AppBar,
            AgreementUserDialog,
            Confirm,
        },
    })
    export default class Default extends Vue {
        miniMenu = true;

        mounted() {
            this.$root.$notify = this.$refs.notification as Notify;
            this.$root.$confirm = this.$refs.confirmation as Confirm;
            this.setResponseInterceptors(this.$root.$notify);
        }

        async created() {
            for (const action of Object.keys(ReftablesActions)) {
                await this.$store.dispatch(`reftables/${action}`);
            }
        }

        setResponseInterceptors($notify: Notify) {
            axios.interceptors.response.use(function(response) {
                return response;
            }, async function(error) {
                const { response: { status } } = error;
                if (status === 409) {
                    $notify.show('error', i18n.t('response-errors.409'));
                } else if (status === 429) {
                    $notify.show('error', i18n.t('response-errors.429'));
                } else {
                    return Promise.reject(error);
                }
            });
        }
    }
