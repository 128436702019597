
    import { Component, Prop } from 'vue-property-decorator';
    import { Notification, PackageKS2Issue } from '@/api/model/interfaces';
    import * as Odata from '@/api/odata';
    import NotificationItem from '@/components/common/notification-item.vue';

    @Component
    export default class IssueIssued extends NotificationItem {
        @Prop({ required: true })
        notification!: Notification;

        issue: PackageKS2Issue | null = null;

        async mounted() {
            this.issue = await Odata.PackageKS2Issue.setParams({
                expand: [ 'PackageKS2' ],
            }).getById(this.notification.EntityId.toString());
            this.link = this.issue.PackageKS2 ? `/ks2/packages/${this.issue.PackageKS2.Id}` : null;
        }
    }
