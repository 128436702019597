
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import { UserResponse } from '@/types';
    import { Notification } from '@/api/model/interfaces';
    import { HubConnection } from '@microsoft/signalr';
    import { NotificationStatus } from '@/api/model/enums';
    import getEventSettings from '@/components/notification/store/event-store';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import NotificationItem from '@/components/common/notification-item.vue';
    import * as Odata from '@/api/odata';
    import { getUserFullName } from '@/components/admin/roles-model/users/helpers';

    @Component({
        components: {
            ButtonTooltip,
        },
    })
    export default class AppBarNotificationItem extends Vue {
        @Prop({ required: true })
        notification!: Notification;

        @Prop({ required: true })
        readonly notificationConnection!: HubConnection;

        @Ref()
        notificationItem!: NotificationItem;

        get senderName() {
            if (!this.notification.SenderId) return this.$t('common.system-notification');
            return getUserFullName(this.notification.SenderId);
        }

        get componentItem() {
            return getEventSettings().find(i => i.id === this.notification.EventId.toString());
        }

        get isNewNotificationStatus() {
            return this.notification.Status === NotificationStatus.New;
        }

        get statusIcon() {
            return this.isNewNotificationStatus ? 'mdi-email' : 'mdi-email-open-outline';
        }

        get titleTooltip() {
            return this.isNewNotificationStatus
                ? this.$t('notifications.mark-as-read')
                : this.$t('notifications.read');
        }

        get link() {
            return this.notificationItem.link;
        }

        async markAsRead() {
            if (!this.isNewNotificationStatus) return;
            await Odata.Notification
                .runAction('MarkAsRead', this.notification.Id, this.notification);
            this.$emit('update');
        }

        async goTo() {
            await Odata.Notification
                .runAction('MarkAsRead', this.notification.Id, this.notification);
            this.$emit('update');
            if (!this.link) return;
            await this.$router.push(this.link);
        }
    }
