import { render, staticRenderFns } from "./document-tab-files.vue?vue&type=template&id=5b0abf43&scoped=true&lang=pug&"
import script from "./document-tab-files.vue?vue&type=script&lang=ts&"
export * from "./document-tab-files.vue?vue&type=script&lang=ts&"
import style0 from "./document-tab-files.vue?vue&type=style&index=0&id=5b0abf43&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0abf43",
  null
  
)

export default component.exports