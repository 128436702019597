
    import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { ContragentResponse, DataTableHeaderCustom, UserResponse } from '@/types';
    import { DynamicDocument } from '@/types/dynamicDoc';
    import * as api from '@/api/dynamic';
    import { userFullNameObj } from '@/components/admin/roles-model/users/helpers';
    import { localizeName } from '@/util/field-translation';
    import * as Odata from '@/api/odata';
    import { Register } from '@/api/model/interfaces';
    import { registerStatuses } from '@/components/asbuilt-docs/registers/helpers';
    import CustomDialog from '@/components/dynamic/common/custom-dialog.vue';

    @Component({
        components: {
            ButtonTooltip,
            CustomDialog,
        },
    })
    export default class PhysicalVolumeDocumentsDialog extends Vue {
        @PropSync('visible', { type: Boolean, required: true })
        visibleDialog!: boolean;

        @Prop({ type: String })
        physicalVolumeItemId!: string | null;

        documents: DynamicDocument[] = [];
        registers: Register[] = [];

        @Watch('visibleDialog')
        async onVisibleDialog() {
            if (!this.visibleDialog || !this.physicalVolumeItemId) {
                this.documents = [];
                return;
            }

            this.documents = await api.physicalVolumeItem
                .getDocumentsByPhysicalVolumeItemId(this.physicalVolumeItemId);
        }

        @Watch('registerIds')
        async onRegisterIds() {
            await this.getRegisters();
        }

        get headers(): DataTableHeaderCustom[] {
            return [
                {
                    text: this.$t('common.document-title') as string,
                    value: 'Number',
                },
                {
                    text: this.$t('common.document-type') as string,
                    value: 'Type',
                },
                {
                    text: this.$t('common.register') as string,
                    value: 'Register',
                },
                {
                    text: this.$t('common.author') as string,
                    value: 'Author',
                },
                {
                    text: this.$t('common.contragent') as string,
                    value: 'Contragent',
                },
                {
                    text: this.$t('common.date-create') as string,
                    value: 'CreatedAt',
                },
                {
                    text: this.$t('common.document-date') as string,
                    value: 'Date',
                },
                {
                    text: '',
                    value: 'Actions',
                    align: 'end',
                    width: 70,
                },
            ];
        }

        get items() {
            return this.documents.map(x => {
                const author = this.$store.state.reftables.Users
                    .find((u: UserResponse) => u.id === x.UserId);
                const contragent = this.$store.state.reftables?.Contragents
                    .find((c: ContragentResponse) => c.id === author?.contragent?.id);
                const register = this.registers.find(y => y.Id.toString() === x.RegisterId);
                return {
                    ...x,
                    Type: x.Schema?.Title,
                    Register: register ? `${this.$t('common.register')} ${register!.Code}` : '',
                    RegisterStatus: register ? ` (${registerStatuses[register.StatusId].title})` : '',
                    Author: author ? userFullNameObj(author) : '',
                    Contragent: contragent ? localizeName(contragent) : '',
                    CreatedAt: x.CreatedAt ? new Date(x.CreatedAt).toLocaleDateString() : '',
                    Date: x.Date ? new Date(x.Date).toLocaleDateString() : '',
                };
            });
        }

        get registerIds() {
            return this.documents.map(x => x.RegisterId).filter(x => !!x);
        }

        async getRegisters() {
            if (!this.registerIds.length) return;
            this.registers = await Odata.Register
                .runAction('GetRegistersByIdRange', null, { ids: this.registerIds })
                .then(x => x.value);
        }

        goToDocument(item: DynamicDocument) {
            const route = this.$router.resolve({
                name: 'document-page',
                params: { documentId: item.Id },
            });
            window.open(route.href, '_blank');
        }

        close() {
            this.visibleDialog = false;
        }
    }
