import { render, staticRenderFns } from "./physical-volume-items.vue?vue&type=template&id=54fb6f65&scoped=true&lang=pug&"
import script from "./physical-volume-items.vue?vue&type=script&lang=ts&"
export * from "./physical-volume-items.vue?vue&type=script&lang=ts&"
import style0 from "./physical-volume-items.vue?vue&type=style&index=0&id=54fb6f65&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54fb6f65",
  null
  
)

export default component.exports