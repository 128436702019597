import axios from 'axios';
import store from '@/store';
import { authInstance } from '@/api/auth/auth-instance';
import { cisInstance, cisOdataInstance } from '@/api/cis/cis-instance';
import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { reportInstance } from '@/api/report/report-instance';

function setAuthHeaders(token: string) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    authInstance.defaults.headers.Authorization = 'Bearer ' + token;
    cisInstance.defaults.headers.Authorization = 'Bearer ' + token;
    cisOdataInstance.defaults.headers.Authorization = 'Bearer ' + token;
    dynamicDocInstance.defaults.headers.Authorization = 'Bearer ' + token;
    reportInstance.defaults.headers.Authorization = 'Bearer ' + token;
}

function getAuthHeaders() {
    const token = store.getters.authToken;
    if (token) {
        return { Authorization: 'Bearer ' + token };
    } else {
        return {};
    }
}

export {
    getAuthHeaders,
    setAuthHeaders,
};
