
    import { Component, Vue } from 'vue-property-decorator';
    import { Locale } from '@/util/locale';
    import { AUTH_LOCALE } from '@/store/auth';

    @Component({})
    export default class AppBarLocale extends Vue {
        get langs() {
            return Object.values(Locale);
        }

        get locale() {
            return this.$store.getters.getLocale || 'ru';
        }

        async localeChanged(locale: Locale) {
            await this.$store.dispatch(AUTH_LOCALE, locale);
        }
    }
