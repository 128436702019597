
    import { Component, Prop } from 'vue-property-decorator';
    import { Notification, Register } from '@/api/model/interfaces';
    import * as Odata from '@/api/odata';
    import NotificationItem from '@/components/common/notification-item.vue';

    @Component({})
    export default class RegisterRequestToReviewers extends NotificationItem {
        @Prop({ type: Object, required: true })
        readonly notification!: Notification;

        register: Register | null = null;

        async mounted() {
            this.register = await Odata.Register.setParams({}).getById(this.notification.EntityId.toString());
            this.link = this.register ? `/asbuilt/registers/${this.register.Id}` : null;
        }
    }
