
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import store from '@/store';
    import DocumentCategorySelectItem from '@/components/documents-journals/documents/document-category-select-item.vue';

    @Component({
        components: {
            DocumentCategorySelectItem,
        },
    })
    export default class DocumentCategorySelect extends Vue {
        @Prop(String) value?: string;

        category: any = [];

        @Watch('documentCategoryLength', { immediate: true })
        onChange() {
            if (!this.documentCategory.length) return;
            this.category = this.flattenItems(this.documentCategory);
        }

        get documentCategory() {
            const categories = store.state.document.documentCategories
                .filter(x => !x.IsHidden)
                .map((x: any) => ({ ...x, ShowSubItems: false }));
            if (!categories) return [];
            const parentMap: any = {};
            const res: any[] = [];
            categories.forEach(curr => {
                if (parentMap[curr.RootId]) {
                    parentMap[curr.RootId].Children = [ ...(parentMap[curr.RootId].Children || []), curr ];
                    parentMap[curr.RootId].Children = [ ...new Set(parentMap[curr.RootId].Children) ];
                } else {
                    res.push(curr);
                }
                parentMap[curr.Id] = curr;
            });
            return res;
        }

        get documentCategoryLength() {
            return this.documentCategory.length;
        }

        flattenItems(items: any): any[] {
            const flattenedItems = [];

            for (const item of items) {
                flattenedItems.push(item);
                if (item.Children && item.ShowSubItems) {
                    flattenedItems.push(...this.flattenItems(item.Children.map((x: any) => ({ ...x, SubItem: true }))));
                }
            }

            return flattenedItems;
        }

        toggleSubItems(item: any) {
            item.ShowSubItems = !item.ShowSubItems;
            this.category = this.flattenItems(this.documentCategory);
        }
    }
