import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { FileDesc } from '@/api/model/interfaces';
import { InfoFileDesc, ReadSignature } from '@/types';

export const fileDesc = {
    getHash: (id: string, algorithm?: string): Promise<string> =>
        dynamicDocInstance.get<string>(`/FileDesc/GetHash?id=${id}&algorithm=${algorithm}`).then(x => x.data),

    getSaveFileSignature: (id: string, signature?: string): Promise<FileDesc> =>
        dynamicDocInstance.post<FileDesc>(`/FileDesc/SaveFileSignature/${id}`, { signature: signature }).then(x => x.data),

    getInfoFileDesc: (id: string): Promise<InfoFileDesc> =>
        dynamicDocInstance.get<InfoFileDesc>(`/FileDesc/GetInfoFileDesc/${id}`).then(x => x.data),

    readSignature: (id: string): Promise<ReadSignature[]> =>
        dynamicDocInstance.get<ReadSignature[]>(`/FileDesc/ReadSignature/${id}`).then(x => x.data),

    readSignatureByDocumentId: (id: string): Promise<ReadSignature[]> =>
        dynamicDocInstance.get<ReadSignature[]>(`/FileDesc/ReadSignatureByDocumentId/${id}`).then(x => x.data),

    downloadDataSig: (id: string): Promise<string> =>
        dynamicDocInstance.get<string>(`/FileDesc/DownloadDataSig/${id}`).then(x => x.data),

    download: (id: string): Promise<Blob | MediaSource> =>
        dynamicDocInstance.get<Blob | MediaSource>(`/FileDesc/Download/${id}`, { responseType: 'blob' }).then(x => x.data),
};
