import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { CompletenessRequirement } from '@/types/dynamicDoc';
import { IBaseApi } from '@/types/cis/IBaseApi';

/** Требования комплектности */
export const completenessRequirement: IBaseApi<CompletenessRequirement, CompletenessRequirement> = {
    getAll: (): Promise<CompletenessRequirement[]> =>
        dynamicDocInstance.get<CompletenessRequirement[]>('CompletenessRequirement').then(x => x.data),

    getById: (id: string): Promise<CompletenessRequirement> =>
        dynamicDocInstance.get<CompletenessRequirement>(`CompletenessRequirement/${id}`).then(x => x.data),

    create: (data: CompletenessRequirement): Promise<CompletenessRequirement> =>
        dynamicDocInstance.post<CompletenessRequirement>('CompletenessRequirement', data).then(x => x.data),

    update: (id: string, data: CompletenessRequirement): Promise<CompletenessRequirement> =>
        dynamicDocInstance.put<CompletenessRequirement>(`CompletenessRequirement/${id}`, data).then(x => x.data),

    remove: (id: string): Promise<void> =>
        dynamicDocInstance.delete(`CompletenessRequirement/${id}`).then(x => x.data),
};
