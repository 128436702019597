
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DataOptions, DataTableHeader } from 'vuetify';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { RegisterStatusesInfo, RegisterStatusesInfoFilter } from '@/types/cis/widget';
    import * as apiCiS from '@/api/cis';
    import { localizeDescription, localizeName, localizeText } from '@/util/field-translation';
    import { DocumentCategory, RegisterStatus, TitleObject } from '@/api/model/interfaces';
    import { AutocompleteItem, ContragentResponse } from '@/types';
    import { registerStatuses } from '../asbuilt-docs/registers/helpers';
    import { RegisterStatus as RegisterStatusConst } from '@/api/constants';
    import { PropType } from 'vue';
    import VueI18n from 'vue-i18n';

    @Component({
        components: {
            ButtonTooltip,
        },
        methods: { localizeText },
    })
    export default class ChartRegisterSubcontractorInfo extends Vue {
        @Prop({ type: String as PropType<VueI18n.TranslateResult>, required: true })
        readonly title!: string;

        loading = false;
        totalItems = 0;
        items: RegisterStatusesInfo[] = [];
        totalRow: { [key: string]: number } = {};
        filter: RegisterStatusesInfoFilter = {}

        optionsTable = {
            page: 1,
            itemsPerPage: 25,
        } as DataOptions;

        async created() {
            await this.loadItems();
        }

        @Watch('filter', { immediate: true, deep: true })
        async onSubcontractorIdSelect() {
            await this.loadItems();
        }

        async loadItems() {
            this.loading = true;
            try {
                const { Items, TotalItems, TotalRow } = await apiCiS.widget.getChartRegisterSubcontractorInfo({
                    ...this.filter,
                    PageNumber: this.optionsTable.page,
                    PageSize: this.optionsTable.itemsPerPage,
                });
                this.items = Items;
                this.totalItems = TotalItems;
                this.totalRow = TotalRow;
            } catch (ex) {
                this.$root.$notify.show('error', ex);
            } finally {
                this.loading = false;
            }
        }

        get itemsFormat() {
            return this.items?.map((x: RegisterStatusesInfo) => {
                const contragent = this.getContragent(x.SubcontractorId);
                return {
                    ...x,
                    Code: x.SubcontractorId ? contragent?.code : this.$t('common.not-filled'),
                };
            });
        }

        get headers(): DataTableHeader[] {
            return [
                { text: this.$t('common.code') as string, value: 'Code' },
                ...this.tableRegisterStatuses,
                { text: this.$t('common.total') as string, value: 'Total', align: 'center' },
            ];
        }

        get registerStatus(): AutocompleteItem[] {
            return this.$store.state.reftables.RegisterStatus.map((x: RegisterStatus) => ({
                text: localizeDescription(x),
                value: x.Id,
            }));
        }

        get tableRegisterStatuses() {
            return this.registerStatus.filter(x => x.value !== RegisterStatusConst.Canceled)
                .map(x => {
                    const index = Object.values(RegisterStatusConst).indexOf(x.value);
                    const headerValue = Object.keys(RegisterStatusConst)[index];
                    return {
                        text: registerStatuses[x.value].title,
                        value: headerValue,
                        align: 'center',
                        statusId: x.value,
                    } as DataTableHeader;
                });
        }

        get contragents(): any[] {
            return this.$store.state.reftables.Contragents.map((x: ContragentResponse) => ({
                text: localizeName(x),
                value: x.id,
                code: x.code,
            }));
        }

        get titleObjects(): AutocompleteItem[] {
            return this.$store.state.reftables.TitleObjects.map((x: TitleObject) => ({
                text: `${x.PlanCode}. ${localizeDescription(x)}`,
                value: x.Id,
            }));
        }

        get totalRegistersCount(): number {
            return Object.values(this.totalRow).reduce((sum, current) => sum + current, 0);
        }

        get documentCategories(): DocumentCategory[] {
            return this.$store.state.reftables.DocumentCategories
                .map((x: DocumentCategory) => ({
                    text: x.Code,
                    value: x.Id,
                }));
        }

        getContragent(id: number) {
            return this.contragents.find(x => x.value === id);
        }

        goToRegister(statusId: string, subcontractorId: any) {
            this.$router.push({
                name: 'registers',
                params: {
                    statusId: statusId,
                    subContractorId: subcontractorId,
                },
            });
        }
    }
