
    import { Component, Vue } from 'vue-property-decorator';
    import { UserResponse } from '@/types';
    import { AUTH_LOGOUT } from '@/store/auth';
    import AppBarChangePasswordPopup from '@/components/home/app-bar-change-password-popup.vue';

    @Component({
        components: {
            AppBarChangePasswordPopup,
        },
    })
    export default class AppBarAccount extends Vue {
        isVisibleChangePasswordPopup = false;

        get user(): UserResponse {
            return this.$store.state.auth.user;
        }

        get lastLoginDate(): string {
            return this.$store.state.auth.lastLoginDate;
        }

        get lifeDaysPassword(): number {
            return this.$store.state.auth.lifeDaysPassword;
        }

        get badgeVisible(): boolean {
            return Boolean(this.lifeDaysPassword) && this.lifeDaysPassword <= 7;
        }

        get lifeTimeText() {
            if (this.lifeDaysPassword <= 0) return this.$t('common.recomend-change-password');
            return `${this.$t('password.life-time')} ${this.$tc('common.n-day', this.lifeDaysPassword)}`;
        }

        get items() {
            return [
                {
                    text: this.$t('main-menu.changepassword'),
                    icon: 'mdi-lock-reset',
                    event: this.changePassword,
                    subText: this.lifeTimeText,
                    textColor: this.lifeSpanColors(),
                },
                {
                    text: this.$t('main-menu.exit'),
                    icon: 'mdi-logout-variant',
                    event: this.logout,
                    subText: null,
                    textColor: '',
                },
            ];
        }

        lifeSpanColors() {
            if (!this.lifeDaysPassword || this.lifeDaysPassword > 10) return '';
            if (this.lifeDaysPassword <= 10 && this.lifeDaysPassword > 7) return 'warning--text text--lighten-2';
            if (this.lifeDaysPassword <= 7) return 'error--text text--lighten-2';
        }

        changePassword() {
            this.isVisibleChangePasswordPopup = true;
        }

        async logout() {
            await this.$store.dispatch(AUTH_LOGOUT);
            location.pathname = '/login';
        }
    }
