import { BaseCalendar, CalendarFilterRequest } from '@/types';
import { authInstance } from './auth-instance';

/** Производственный каледарь */
export const calendar= {
    getAll: (): Promise<BaseCalendar[]> =>
        authInstance.get<BaseCalendar[]>('Calendar').then(x => x.data),

    getByYear: (filter: CalendarFilterRequest): Promise<BaseCalendar[]> =>
        authInstance.get<BaseCalendar[]>(`Calendar/GetByYear?year=${filter.year}&weekType=${filter.weekType}`).then(x => x.data),

    getById: (id: number): Promise<BaseCalendar> =>
        authInstance.get<BaseCalendar>(`Calendar/${id}`).then(x => x.data),

    create: (data: BaseCalendar): Promise<BaseCalendar> =>
        authInstance.post<BaseCalendar>('Calendar', data).then(x => x.data),

    update: (id: number, data: BaseCalendar): Promise<BaseCalendar> =>
        authInstance.put<BaseCalendar>(`Calendar/${id}`, data).then(x => x.data),

    remove: (id: number): Promise<void> =>
        authInstance.delete(`Calendar/${id}`).then(x => x.data),

    parse: (filter: CalendarFilterRequest): Promise<BaseCalendar[]> =>
        authInstance.get<BaseCalendar[]>(`Calendar/Parse?year=${filter.year}&weekType=${filter.weekType}`).then(x => x.data),
};
