
    import { Component, Vue } from 'vue-property-decorator';
    import AppBarLocale from '@/components/home/app-bar-locale.vue';
    import AppBarAccount from '@/components/home/app-bar-account.vue';
    import AppBarNotification from '@/components/home/app-bar-notification.vue';
    import { AppBarMenu } from '@/store/topmenu/types';
    import AppBarVersion from '@/components/home/app-bar-version.vue';

    @Component({
        components: {
            AppBarLocale,
            AppBarAccount,
            AppBarNotification,
            AppBarVersion,
        },
    })
    export default class AppBar extends Vue {
        get glossaryLink() {
            return this.$store.state.settings?.common?.GlossaryLink || null;
        }

        get appBarMenu(): AppBarMenu[] {
            return this.$store.state.topMenu.appBarMenu;
        }
    }
