import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import {
    DynamicJournalSchemaApi,
    DynamicJournalSchema,
    DynamicJournalSchemaNew,
    DynamicResponse,
    Options,
    getByFilter,
    DynamicJournalSchemaPartitions,
    DocumentSchemaShort,
    SchemaSearchRequest,
} from '@/types/dynamicDoc';

/** Схемы журналов */
export const journalSchema: DynamicJournalSchemaApi<DynamicJournalSchema, DynamicJournalSchemaNew> = {
    getAll: (): Promise<DynamicJournalSchema[]> =>
        dynamicDocInstance.get<DynamicJournalSchema[]>('JournalSchema').then(x => x.data),

    getByFilter: (options: Options) =>
        dynamicDocInstance.post<getByFilter<DynamicJournalSchema>>('/JournalSchema/getByFilter', options)
            .then(x => x.data),

    getById: (id: string): Promise<DynamicJournalSchema> =>
        dynamicDocInstance.get<DynamicJournalSchema>(`JournalSchema/${id}`).then(x => x.data),

    create: (data: DynamicJournalSchemaNew): Promise<DynamicJournalSchema> => {
        const bodyFormData = new FormData();
        bodyFormData.append('Title', data.Title);
        bodyFormData.append('Category', data.Category);
        bodyFormData.append('TemplateFile', data.TemplateFile);
        if (data.CopyFromId) bodyFormData.append('CopyFromId', data.CopyFromId);
        return dynamicDocInstance.post<DynamicJournalSchema>('JournalSchema', bodyFormData).then(x => x.data);
    },

    update: (id: string, data: DynamicJournalSchema): Promise<DynamicJournalSchema> =>
        dynamicDocInstance.put<DynamicJournalSchema>(`JournalSchema/${id}`, data).then(x => x.data),

    getFullEditErrors: (id: string): Promise<string[]> => {
        return dynamicDocInstance.get<string[]>(`/JournalSchema/GetFullEditErrors/${id}`).then(x => x.data);
    },

    remove: (id: string): Promise<void> =>
        dynamicDocInstance.delete(`JournalSchema/${id}`).then(x => x.data),

    getPage: (page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DynamicJournalSchema>>(`/JournalSchema/page?page=${page}&pageSize=${pageSize}`)
            .then(x => x.data),

    getCategoryPage: (id: string, page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DynamicJournalSchema>>(
            `/JournalSchema/ByCategoryPage?categoryId=${id}&page=${page}&pageSize=${pageSize}`)
            .then(x => x.data),

    getByCategory: (categoryId: string, showOnlyActive: boolean) =>
        dynamicDocInstance.get<Promise<DocumentSchemaShort[]>>(`/JournalSchema/bycategory?categoryId=${categoryId}&showOnlyActive=${showOnlyActive}`)
            .then(x => x.data),

    searchByTitleAndCategory: (options: SchemaSearchRequest) =>
        dynamicDocInstance.get<DynamicJournalSchema[]>(`/JournalSchema/SearchByTitleAndCategory?search=${options.search}&categoryId=${options.categoryId}`)
            .then(x => x.data),

    searchByTitle: (options: SchemaSearchRequest): Promise<DocumentSchemaShort[]> =>
        dynamicDocInstance.get<DocumentSchemaShort[]>(`/JournalSchema/SearchByTitle?search=${options.search}`)
            .then(x => x.data),

    getRelatedDocumentType: (documentSchemaId: string, formula?: string) =>
        dynamicDocInstance.post<DynamicJournalSchema[]>(`/JournalSchema/RelatedDocumentType?id=${documentSchemaId}`, { formula })
            .then(x => x.data),

    getPartition: (id: string) =>
        dynamicDocInstance.get<DynamicJournalSchemaPartitions>(`/JournalPartition/Get/${id}`)
            .then(x => x.data),

    getInfoById: (id: string): Promise<DynamicJournalSchema> =>
        dynamicDocInstance.get<DynamicJournalSchema>(`JournalSchema/GetInfo/${id}`)
            .then(x => x.data),

    changeCategory: (id: string, categoryId: string): Promise<boolean> => {
        return dynamicDocInstance.post<boolean>(`/JournalSchema/ChangeCategory?id=${id}&categoryId=${categoryId}`).then(x => x.data);
    },

    download: (id: string): Promise<Blob> =>
        dynamicDocInstance.get<Blob>(`/JournalSchema/Download/${id}`, { responseType: 'blob' }).then(x => x.data),

    downloadTemplateFileSchema: (id: string): Promise<Blob> =>
        dynamicDocInstance.get<Blob>(`/JournalSchema/downloadTemplateFileSchema/${id}`, { responseType: 'blob' }).then(x => x.data),
};
