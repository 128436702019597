import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { DynamicDocumentState } from './types';
import {
    CHANGE_DOCUMENT_LIST,
    GET_DOCUMENT,
    GET_DOCUMENT_CATEGORIES,
    GET_DOCUMENT_LINKS,
    GET_DOCUMENTSCHEMA,
    GET_DOCUMENTSCHEMAS,
    GET_REFTABLESCHEMA,
    GET_REFTABLESCHEMAS,
    GET_RELATED_DOCUMENTS,
    SET_DOCUMENT,
} from './actions';
import { RootState } from '@/store/types';
import * as api from '@/api/dynamic';
import { DocumentLink, DynamicDocument, DynamicDocumentCategory, DynamicDocumentSchema, DynamicDocumentWithLinkName, IdWithTitle, ReftableSchema } from '@/types/dynamicDoc';
import { AutocompleteItem } from '@/types';

const state: DynamicDocumentState = {
    documentSchemas: [],
    reftableSchemas: [],
    documentCategories: [],
    documentSchema: null,
    reftableSchema: null,
    documentLinks: [],
    relatedDocuments: [],
    documentsList: [],
    document: null,
};

const getters: GetterTree<DynamicDocumentState, RootState> = {
    linkedSchemaIds: (state: DynamicDocumentState): string[] => {
        if (state.documentLinks.find(x => x.DocumentSchemaId === state.documentSchema?.Id)) {
            return state.documentLinks.map(x => x.LinkDocumentSchemaId);
        }
        return state.documentLinks.map(x => x.DocumentSchemaId);
    },
    documentsWithLinkName: (state: DynamicDocumentState): DynamicDocumentWithLinkName[] => {
        if (!state.documentLinks.length || !state.relatedDocuments.length) return [];
        return state.documentLinks.reduce((arr: any, link) => {
            state.relatedDocuments.flatMap(x => arr.push({
                ...x,
                Schema: state.documentSchemas.find(s => s.Id === x.SchemaId),
                LinkName: link.Name,
            }));
            return arr;
        }, []);
    },
};

const actions: ActionTree<DynamicDocumentState, RootState> = {
    [GET_DOCUMENTSCHEMAS]: async({ commit }) => {
        commit(GET_DOCUMENTSCHEMAS, await api.documentSchema.getAll());
    },
    [GET_REFTABLESCHEMAS]: async({ commit }) => {
        commit(GET_REFTABLESCHEMAS, await api.reftableSchema.getAll());
    },
    [GET_DOCUMENTSCHEMA]: async({ commit }, id: string) => {
        commit(GET_DOCUMENTSCHEMA, await api.documentSchema.getById(id));
    },
    [GET_REFTABLESCHEMA]: async({ commit }, id: string) => {
        commit(GET_REFTABLESCHEMA, await api.reftableSchema.getById(id));
    },
    [GET_DOCUMENT_CATEGORIES]: async({ commit }) => {
        commit(GET_DOCUMENT_CATEGORIES, await api.documentCategory.getAll());
    },
    [GET_DOCUMENT_LINKS]: async({ commit }, schemaId) => {
        commit(GET_DOCUMENT_LINKS, await api.documentLink.getByDocumentSchema(schemaId));
    },
    [GET_RELATED_DOCUMENTS]: async({ commit }, schemaIds: string[]) => {
        commit(GET_RELATED_DOCUMENTS, await api.document.getByDocumentSchemaIdRange(schemaIds));
    },
    [GET_DOCUMENT]: async({ commit }, id: string) => {
        commit(SET_DOCUMENT, await api.document.getById(id));
    },
};

const mutations: MutationTree<DynamicDocumentState> = {
    [GET_DOCUMENTSCHEMAS]: (state, payload: IdWithTitle[]) => (state.documentSchemas = payload),
    [GET_REFTABLESCHEMAS]: (state, payload: IdWithTitle[]) => (state.reftableSchemas = payload),
    [GET_DOCUMENTSCHEMA]: (state, payload: DynamicDocumentSchema) => (state.documentSchema = payload),
    [GET_REFTABLESCHEMA]: (state, payload: ReftableSchema) => (state.reftableSchema = payload),
    [GET_DOCUMENT_CATEGORIES]: (state, payload: DynamicDocumentCategory[]) => (state.documentCategories = payload),
    [GET_DOCUMENT_LINKS]: (state, payload: DocumentLink[]) => (state.documentLinks = payload),
    [GET_RELATED_DOCUMENTS]: (state, payload: DynamicDocument[]) => (state.relatedDocuments = payload),
    [CHANGE_DOCUMENT_LIST]: (state, payload: AutocompleteItem[]) => (state.documentsList = payload),
    [SET_DOCUMENT]: (state, payload: DynamicDocument) => (state.document = payload),
};

const module: Module<DynamicDocumentState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default module;
