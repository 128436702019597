export enum RoleConstants {
    Auth = '1',
    Permit = '2',
    Cis = '3',
    TMC = '4',
    Document = '5',
    AllUsers = '6',
    Approvers = '7',
    Checkers = '8',
    Customers = '9',
    WorkPerformers = '10',
    GeneralContractors = '11',
    DrawingDesigners = '12',
    DrawingCustomers = '13',
    KS2Contractors = '14',
    KS2Customers = '15',
    Supervisor = '16',
}
