
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import TableItemPopup from '@/components/dynamic/common/editors/table-item-popup.vue';
    import { DynamicEditorOptions, DynamicReftableItem, FieldType, IField } from '@/types/dynamicDoc';
    import * as api from '@/api/dynamic';
    import { DataTableHeader } from 'vuetify';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { exportCSV } from '@/components/dynamic/common/editors/table-items-helper';
    import ImportCsvDialog from '@/components/dynamic/common/editors/import-csv-dialog.vue';

    @Component({
        components: {
            ButtonTooltip,
            TableItemPopup,
            ImportCsvDialog,
        },
        methods: {
            exportCSV,
        },
    })
    export default class DocumentTableItems extends Vue {
        @Prop({ type: Array, default: () => [] })
        value!: any[];

        @Prop({ type: String, required: true })
        private readonly label!: string;

        @Prop({ type: Object, required: true })
        private readonly field!: IField;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Object })
        readonly options?: DynamicEditorOptions;

        tableValues: Record<string, string | number>[] = []
        tableItemPopupVisible = false;
        importCsvDialogVisible = false;
        editValue: any = null;
        indexRow: number | null = null;
        reftables: Record<string, DynamicReftableItem[]> = {};

        @Watch('value', { immediate: true })
        async onChangeValue() {
            await this.getReftable();
            this.setTableValues();
        }

        @Watch('tableItemPopupVisible')
        onTableItemPopupVisible() {
            if (!this.tableItemPopupVisible) {
                this.editValue = null;
            }
        }

        get tableHeaders(): DataTableHeader[] {
            if (!this.field.TableFields) return [];
            const header = this.field.TableFields.map(x => ({
                text: x.Title,
                value: x.Title,
            })) as DataTableHeader[];
            header.push({ text: '', value: 'actions', width: '6em', sortable: false });
            return header;
        }

        get headerText(): string[] {
            return this.tableHeaders
                .filter(x => x.text)
                .map(x => x.text);
        }

        get headerTextTrim() {
            return this.headerText.map(x =>
                x.replace(/\s+/g, ' ')
                    .replace(/\n+/g, '\n')
                    .trim());
        }

        checkDate(item: Record<string, string | number>) {
            for (const key in item) {
                if (typeof item[key] === 'number') {
                    item[key] = !isNaN(new Date(item[key]) as any)
                        && item[key] > 946674000000
                        ? new Date(item[key]).toLocaleDateString()
                        : item[key];
                }
            }
            return item;
        }

        openPopup() {
            this.tableItemPopupVisible = true;
        }

        async getReftable() {
            for (const field of this.field.TableFields) {
                if (field && field.Type === FieldType.Reftable && field.ReftableSchemaId) {
                    this.reftables[field.ReftableSchemaId as string] = await api.reftableItem
                        .getRelatedSchema(field.ReftableSchemaId, field.Formula as string);
                }
            }
        }

        setTableValues() {
            this.tableValues = [];
            this.value?.map(x => ({ ...x })).forEach(item => {
                Object.keys(item).forEach(key => {
                    const tabField = this.field.TableFields.find(i => i.Title === key);
                    if (tabField && tabField.Type === FieldType.Reftable && tabField.ReftableSchemaId) {
                        const reftable = this.reftables[tabField.ReftableSchemaId as string];
                        const value = reftable?.find(x => x.Id === item[key]);
                        item[key] = value?.Data?.$Display;
                    }
                });
                this.tableValues.push(this.checkDate(item));
            });
        }

        create(item: Record<string, string | number>) {
            this.value.push(item);
            this.$emit('input', this.value);
        }

        edit(index: number) {
            this.editValue = this.value[index];
            this.indexRow = index;
            this.openPopup();
        }

        valueEdited(item: any) {
            if (this.indexRow !== null) {
                this.value.splice(this.indexRow, 1, item);
                this.$emit('input', this.value);
            }
            this.indexRow = null;
        }

        remove(index: number) {
            this.value.splice(index, 1);
            this.$emit('input', this.value);
        }

        importCsv(items: any[]) {
            this.value.push(...items);
            this.$emit('input', this.value);
            this.importCsvDialogVisible = false;
        }
    }
