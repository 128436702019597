import * as Odata from '@/api/odata';
import { Ks2AppSettingsId } from '@/components/ks2/constants';

export async function getKS2Settings() : Promise<KS2AppSettings> {
    return await Odata.KS2AppSettings.getById(Ks2AppSettingsId) as any;
}

export interface KS2AppSettings {
    SignatureEnabled: boolean;
    CheckEnabled: boolean;
}

export enum KS2Tabs {
    Info,
    Documents,
    Issues
}

export interface IssuePopupKS2Options {
    reviewId?: string | null;
    documentId?: string | null;
    documentItemId?: string | null;
    issueId?: string | null;
}
