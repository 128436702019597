import { authInstance } from './auth-instance';
import { RoleRightsRequest } from '@/types';

export const roleRight = {
    create: (data: RoleRightsRequest): Promise<boolean> =>
        authInstance.post<boolean>('RoleRight/addRightsInRole', data).then(x => x.data),

    remove: (data: RoleRightsRequest): Promise<void> =>
        authInstance.post('RoleRight/deletedRightsInRole', data).then(x => x.data),
};
