
    import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
    import VuePdfApp from 'vue-pdf-app';
    import { DynamicDocument, documentPageFormats } from '@/types/dynamicDoc';
    import * as api from '@/api/dynamic';
    import { Tabs } from '@/components/documents-journals/documents/document.vue';
    import { formatSize } from '@/components/files/file-size-format';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import DocumentViewer from '@/components/documents-journals/documents/documentViewer.vue';
    import { InfoFileDesc } from '@/types';
    @Component({
        components: {
            VuePdfApp,
            ButtonTooltip,
            DocumentViewer,
        },
        methods: {
            formatSize,
        },
        data: () => ({
            documentPageFormats,
        }),
    })
    export default class DocumentTabViewer extends Vue {
        @PropSync('item', { type: Object, required: true })
        document!: DynamicDocument;

        @Prop({ type: Number })
        tabIndex!: Tabs;

        @Prop({ type: Object, default: false })
        infoFileDesc?: InfoFileDesc;

        doc: string | null = null;

        @Watch('tabIndex', { immediate: true })
        async initTabIndex() {
            if (this.tabIndex === Tabs.Viewer) {
                await this.getDocumentFile();
            } else {
                this.doc = null;
            }
        }

        get fileType() {
            if (!this.infoFileDesc?.File?.UploadName) return '';
            const fileName = this.infoFileDesc.File.UploadName;
            return fileName.substring(fileName.lastIndexOf('.') + 1);
        }

        async getDocumentFile() {
            const res = await api.document.download(this.document.Id);
            this.doc = URL.createObjectURL(res);
        }
    }
