
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import TooltipWrapper from '@/components/dynamic/common/tooltip-wrapper.vue';
    import { required } from '@/components/common/validation-rules';

    @Component({
        components: { TooltipWrapper },
    })
    export default class TextInput extends Vue {
        @Prop({ required: true })
        private readonly label!: string;

        @Prop([ String, Array ])
        private readonly value!: string | string[];

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly disabled!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly validate!: boolean;

        rules = { required };
    }
