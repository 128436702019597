import { render, staticRenderFns } from "./loading-page.vue?vue&type=template&id=4f6f7a3d&scoped=true&lang=pug&"
import script from "./loading-page.vue?vue&type=script&lang=ts&"
export * from "./loading-page.vue?vue&type=script&lang=ts&"
import style0 from "./loading-page.vue?vue&type=style&index=0&id=4f6f7a3d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f6f7a3d",
  null
  
)

export default component.exports