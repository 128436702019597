export enum RegisterStatus {
    Draft = 'ec3dfdb9-2c7a-9a45-9ced-fde8e9fe7617', // Черновик
    Check = 'e65bd063-4b28-9174-db6d-83319a90ad76', // Проверка
    HasIssues = '81f3ef08-bfa7-4176-e0c1-54ef6d687b6b', // Выданы замечания
    ResolvingIssues = '28fe952a-5094-3f4e-96cf-f9cddfae5e74', // Устранение замечаний
    SecondaryCheck = 'bcfd9a12-a2d8-f81c-c7d5-d43f190ed507', // Повторная проверка
    Approval = '2e96ff56-a2c3-7e5f-d06f-28eb06f8106f', // Утверждение
    NotApproved = 'e10cd869-3878-29b0-1b30-a4a2855c6986', // Отказано в утверждении
    WaitingConstruction = '55bc74c0-937a-1691-9c19-4d40d6028c96', // Ожидание завершения СМР
    Canceled = 'c0327b4c-b2eb-32dc-ce07-80f23940350a', // Аннулирован
    Signing = '76a87163-4ceb-4f4d-bef4-4a4dd51f6df5', // Подписание
    Signed = '95e4f0ea-9378-dc03-cf36-eb9efa314512', // Подписан
}

export enum RegisterReviewStage {
    check = '1812202d-a56e-4521-a354-cdb0848fd051',
    approval = '508b1b85-4472-4c6a-bbd0-48b3868adba0',
    expert = '6da89175-4fab-4d22-b50d-db7dc532d8c9'
}

export enum DocumentStatus {
    Draft = '5e1a9818-f8a5-481c-20f0-c16d362df87a',
    Accepted = '12c12fe0-2085-5d30-87a5-5d98ba3c6ed8',
}

export enum RegisterReviewStatus {
    New = 'cd23196a-3282-46dd-8adb-276d3a140317', // Запрошено
    InProgress = '0b79a496-7542-4d64-8dcd-3fb8710fadb6', // Начато
    Canceled = '7ce580fd-6caa-4925-97a5-9a62c359a62f', // Отменено
    Done = 'f77f0dc4-b367-41a5-8e6d-efd5cc3834ac', // Завершено
}

export enum RegisterIssueStatus {
    Draft = 'ed5d9bc1-4da1-44c1-bbec-fef7d48af3ef', // Черновик
    Issued = '676bc148-decc-49d9-8287-935c161c20dc', // Выпущено
    Fixed = '1b6b0dd9-5ca8-45bc-b634-929ffa740f4a', // Исправлено
    Approved = '589e1875-3f9d-4614-bf12-2fbde70212ec', // Утверждено
    Canceled = 'a7bb1862-0f87-4892-9533-848802ec7ac9', // Отменено
}

export enum ContragentRole {
    Subcontractor = 1,
    Contractor = 2,
    Supervisor = 3,
    Customer = 4,

    DrawingPackageCustomer = 5,
    Designer = 6,

    PackageKS2Contractor = 7,
    PackageKS2Customer = 8,
}

export enum DocumentType {
    NA = '724b20fd-df8d-4b4c-8afc-d54fe796f254',
}

export enum DocTransmittalStatus {
    Draft = 'd4bf0633-caf9-4c6b-99ea-746f433f8932',
    Active = '5229a723-bc25-42ee-8fc3-67855a1678da',
    Submitted = '671b34c3-7dd4-45d3-a428-7e5fe85eccad',
}

export enum FinalBook {
    AsBuiltDrawings = 'abd37aaf-9c0a-41fa-ad0c-f300705dd5c8',
    AsBuilt = 'cdbff134-f727-44f9-a51f-2740e5813d04',
    Permission = '968f0f64-faf7-40d9-b221-416bedffcbb9',
}

export enum PackageKS2Status {
    Draft = 'deaf712f-b057-40a4-87d9-3dce6080a776',
    Check = '412ef95c-0000-40cf-b8f7-88edd15caeab',
    Approval = 'adde07a1-2772-4afc-b92a-b0cca9f6fee1',
    HasIssues = '1229e200-2ee0-4ca7-820a-cd2f825018e9',
    ResolvingIssues = 'e1229e20-ee20-4f51-9e77-3bc2362c6f91',
    Signing = '21a919d0-f547-4101-ab9a-7594d6af56aa',
    Archived = 'adaca850-54e5-4866-9ca9-b2ab6593964a',
}

export enum PackageKS2DocumentStatus {
    Draft = 'df82083e-89d5-4cbb-8dc8-76f7a3506f4e',
    Accepted = 'accee341-2473-446c-a1ad-551ab1409b14',
}

export enum PackageKS2ReviewStatus {
    New = '9eba7559-e7c5-4ea0-a27b-1e0adf2dc0a1',
    InProgress = '1961ff2f-b873-4208-bb1a-ccc44acab3f1',
    Canceled = 'ca9ce9ed-691b-4d95-bced-04dce523ced9',
    Done = 'd09e3846-a9e4-4a29-94dc-de5f2b5cbd87',
}

export enum PackageKS2IssueStatus {
    Draft = 'd3ca975d-8369-4ba5-8a61-082ae8276924',
    Issued = '122ed3bd-a04a-47ed-a13d-15748da32ce7',
    Fixed = 'f1f202a4-e6fe-49c2-9cb5-a78a2f3d9f37',
    Approved = 'a613394b-470e-40ca-9696-80c9a5a35294',
    Canceled = 'c4d0f062-0d2e-4821-b623-217911ea223d',
}

export enum DrawingPackageStatus {
    Draft = '3bda7eac-9e2d-4ee7-91e7-e22346e984e5',
    Check = 'f5bb0912-0a9d-433c-bdc6-237ad9a4e518',
    HasIssues = '6c34b8ab-bf5b-4248-98b3-9d2eb4938d94',
    ResolvingIssues = '2ea3276f-ee6b-4d21-8207-28dfb5c89c36',
    Approval = 'e644db1f-8373-4461-a67e-0b26c037389c',
    Signing = 'f24d9c7c-2a56-457b-ab13-e737d24d9507',
    Archived = '583919fd-a7ca-49d8-8302-1124809c4700',
}

export enum DrawingPackageReviewStatus {
    New = 'eadcf0b0-efb5-4b7d-8bea-e20d500b7f7c',
    InProgress = '18fde837-8977-4a52-b8e8-8d0278f34682',
    Canceled = 'cb4a19d2-9ae3-45df-b129-cbf7b183f9b3',
    Done = 'd62109d6-ddc5-487b-baa7-6dd50f62062c',
}

export enum DrawingPackageIssueStatus {
    Draft = 'd5eeb235-41de-4726-9dfe-b20a570b7b24',
    Issued = '198b9322-687d-4559-8d3d-60418b2762cc',
    Fixed = 'f66fbe18-56ae-47b2-a408-d1b4cf5c0892',
    Approved = 'a9ce451c-85d1-4bd3-925b-db8e72b633ae',
    Canceled = 'c733958f-0d91-480c-95db-e99a565c8814',
}

export enum PackageKS2ReviewStage {
    check = '80b77e15-3837-42ea-923a-867a6160d6d2',
    approval = 'b1b07acf-393e-46f1-a5c0-8430b82dfc9d',
}

export enum RegisterDocumentsHandlers {
    registerAsbuiltDoc = '6a0b31d5-226b-4e83-b884-6fbcdef5e856',
}

export const AppSettingsId = '710df917-d9b1-414b-863b-f810a8021615';
export const AgreementSettingsId = '5a075540-03d7-4196-8022-a473b577551a';
export const GuidEmpty = '00000000-0000-0000-0000-000000000000';
