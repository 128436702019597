import axios from 'axios';
import { interceptors } from '@/api/helpers';

const baseUrl = process.env.VUE_APP_API_AUTH_SERVICE;

export const authInstance = axios.create({
    baseURL: `${baseUrl}api/`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') },
});

// Перехватчик запросов axios
interceptors(authInstance);
