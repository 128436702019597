import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import {
    DynamicDocument,
    getByFilter,
    PhysicalVolumeItem,
    PhysicalVolumeItemNew,
    PhysicalVolumeOptions,
    UpdateActualValue,
    VolumeTypeAndDrawingPackageRequest,
} from '@/types/dynamicDoc';

/** Физический объем элемент */
export const physicalVolumeItem = {
    getByFilter: (options: PhysicalVolumeOptions) =>
        dynamicDocInstance.post<getByFilter<PhysicalVolumeItem>>('/PhysicalVolumeItem/getByFilter', options)
            .then(x => x.data),

    getAllByTypeAndDrawingPackage: (options: VolumeTypeAndDrawingPackageRequest) =>
        dynamicDocInstance.post<PhysicalVolumeItem[]>('/PhysicalVolumeItem/GetAllByTypeAndDrawingPackage', options)
            .then(x => x.data),

    getById: (id: string): Promise<PhysicalVolumeItem> =>
        dynamicDocInstance.get<PhysicalVolumeItem>(`PhysicalVolumeItem/${id}`).then(x => x.data),

    create: (data: PhysicalVolumeItemNew): Promise<PhysicalVolumeItem> =>
        dynamicDocInstance.post<PhysicalVolumeItem>('PhysicalVolumeItem', data).then(x => x.data),

    getByDrawingPackageRange: (ids: string[]): Promise<PhysicalVolumeItem[]> =>
        dynamicDocInstance.post<PhysicalVolumeItem[]>('PhysicalVolumeItem/GetByDrawingPackageRange', ids).then(x => x.data),

    getContractsByDrawingPackage: (drawingPackageId: string): Promise<string[]> =>
        dynamicDocInstance.get<string[]>(`PhysicalVolumeItem/GetContractsByDrawingPackage/${drawingPackageId}`).then(x => x.data),

    update: (id: string, data: PhysicalVolumeItem): Promise<PhysicalVolumeItem> =>
        dynamicDocInstance.patch<PhysicalVolumeItem>(`PhysicalVolumeItem/${id}`, data).then(x => x.data),

    updateActualValue: (id: string, data: UpdateActualValue): Promise<UpdateActualValue> =>
        dynamicDocInstance.put<UpdateActualValue>(`PhysicalVolumeItem/UpdateActualValue/${id}`, data).then(x => x.data),

    getDocumentsByPhysicalVolumeItemId: (id: string): Promise<DynamicDocument[]> =>
        dynamicDocInstance.get<DynamicDocument[]>(`/PhysicalVolumeItem/GetDocumentsByPhysicalVolumeItemId/${id}`).then(x => x.data),
};
