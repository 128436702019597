
    import { Component, Vue } from 'vue-property-decorator';
    import AboutDialog from '@/components/home/about-dialog.vue';

    @Component({
        components: { AboutDialog },
    })
    export default class AboutNavigationItem extends Vue {
        isVisible = false;

        open() {
            this.isVisible = true;
        }
    }
