
    import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
    import { AUTH_REQUEST } from '@/store/auth';
    import { LoginForm, VForm } from '@/types';
    import { required } from '../common/validation-rules';

    @Component({})
    export default class LoginPage extends Vue {
        @Ref() form!: VForm;

        formData: LoginForm = {
            login: this.$store.getters.getLastUserName,
            password: '',
        };

        rules = { required };
        valid = true;

        get authStatus(): string {
            return this.$store.getters.authStatus;
        }

        get authError(): string | null {
            const err = this.$store.getters.authError;

            if (err) {
                if (err === 'unauthorized') {
                    return this.$t('login-page.wrong-password') as string;
                }

                if (err.endsWith('403')) {
                    return this.$t('login-page.access-denied') as string;
                }

                if (err.endsWith('400')) {
                    return this.$t('login-page.invalid-login-or-password') as string;
                }
            }

            return err;
        }

        @Watch('authStatus', { immediate: true })
        async isSuccessSignIn(value: string) {
            if (value === 'success') {
                await this.$router.push({ name: 'home' });
            }
        }

        async login() {
            if (!this.form.validate()) return;
            await this.$store.dispatch(AUTH_REQUEST, this.formData);
        }
    }
