
    import { Component, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { AutocompleteItem, ContragentResponse, VForm } from '@/types';
    import * as Odata from '@/api/odata';
    import { WidgetAppSettingsId } from '../asbuilt-docs/constants';
    import { AppSettings } from '@/api/model/interfaces';
    import { SettingData } from '@/store/settings/types';
    import { localizeName } from '@/util/field-translation';

    export interface WidgetAppSettings {
        InfoBySubContractorFullAccessContragentIds: number[],
    }

    @Component({
        components: {
            ButtonTooltip,
        },
    })
    export default class WidgetAppSettingsDialog extends Vue {
        @Ref() form!: VForm;

        @PropSync('visible', { required: true })
        isVisible!: boolean;

        loading = false;
        valid = true;
        settings: Partial<WidgetAppSettings> = {
            InfoBySubContractorFullAccessContragentIds: [],
        }

        @Watch('isVisible')
        async onVisible() {
            if (!this.isVisible) return;
            await this.loadSettings();
        }

        get settingData(): SettingData {
            return {
                Data: JSON.stringify(this.settings),
            };
        }

        get contragents(): AutocompleteItem[] {
            return this.$store.state.reftables.Contragents.map((x: ContragentResponse) => ({
                text: localizeName(x),
                value: x.id,
            }));
        }

        async loadSettings() {
            try {
                this.loading = true;
                this.settings = await Odata.WidgetAppSettings.getById(WidgetAppSettingsId) as Partial<WidgetAppSettings>;
            } catch (e) {
                this.$root.$notify.show('error', e);
            } finally {
                this.loading = false;
            }
        }

        async save() {
            if (!this.form.validate()) return;
            try {
                this.loading = true;
                await Odata.WidgetAppSettings.update(WidgetAppSettingsId, this.settingData as AppSettings);
                this.$root.$notify.show('success');
                this.close();
            } catch (e) {
                this.$root.$notify.show('error', e);
            } finally {
                this.loading = false;
            }
        }

        close() {
            this.form.reset();
            this.form.resetValidation();
            this.isVisible = false;
        }
    }
