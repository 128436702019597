
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            label: String,
            value: {
                type: Array,
                default: () => [],
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
    });

