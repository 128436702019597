
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DynamicEditorOptions, IField, RowTable, TypeFieldEditor } from '@/types/dynamicDoc';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import * as api from '@/api/dynamic';

    @Component({
        components: {
            DynamicFieldEditor: () => import('@/components/dynamic/common/dynamic-field-editor.vue'),
            ButtonTooltip,
        },
    })
    export default class TableItemPopup extends Vue {
        @Prop({ type: Boolean, required: true })
        value!: boolean;

        @Prop({ type: Array, default: () => [] })
        fields!: IField[];

        @Prop({ type: Object, default: () => null })
        tableItem!: Record<string, string|number>;

        @Prop({ type: Object, default: () => null })
        requestParams!: RowTable;

        @Prop({ type: Object })
        readonly options?: DynamicEditorOptions;

        formData: Record<string, string|number> = {};
        valid = true;
        loading = false;

        @Watch('value', { immediate: true })
        onChangeValue() {
            if (!this.value) return;
            this.fields.forEach(x => {
                const val = this.isEdit && this.tableItem[x.Title] ? this.tableItem[x.Title] : '';
                Vue.set(this.formData, x.Title, val);
            });
        }

        get isValidForm() {
            return Object.entries(this.formData).every(x => x[1]);
        }

        get isEdit() {
            return !!this.tableItem;
        }

        get title() {
            return this.isEdit ? this.$t('common.edit') : this.$t('common.add');
        }

        close() {
            this.$emit('input', false);
        }

        async save() {
            if (this.options?.type !== TypeFieldEditor.Journal) {
                const event = this.isEdit ? 'edit' : 'create';
                this.$emit(event, { ...this.formData });
                this.close();
                return;
            }
            this.isEdit
                ? await this.update()
                : await this.create();
        }

        async create() {
            this.loading = true;
            try {
                await api.journal.addRowInTable({
                    ...this.requestParams,
                    Data: [ this.formData ],
                });
                this.$emit('updated');
                this.$root.$notify.show('success');
                this.close();
            } catch (e) {
                this.$root.$notify.show('error');
            } finally {
                this.loading = false;
            }
        }

        async update() {
            this.loading = true;
            try {
                await api.journal.updateRowInTable({
                    ...this.requestParams,
                    Data: { ...this.formData, _id: this.tableItem._id },
                });
                this.$emit('updated');
                this.$root.$notify.show('success');
                this.close();
            } catch (e) {
                this.$root.$notify.show('error');
            } finally {
                this.loading = false;
            }
        }
    }
