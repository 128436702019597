
    import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import TooltipWrapper from '@/components/dynamic/common/tooltip-wrapper.vue';

    @Component({
        components: { TooltipWrapper, ButtonTooltip },
    })
    export default class DocumentTitle extends Vue {
        @PropSync('value', { type: String, required: true }) title!: string;
        @Prop({ type: String }) docNumber!: string;
        @Prop({ type: Boolean }) isEditDocument!: boolean;
        isEdit = false;
        oldValue = '';

        edit() {
            this.oldValue = this.title;
            this.isEdit = true;
        }

        save() {
            this.isEdit = false;
            this.oldValue = '';
        }

        cancel() {
            this.title = this.oldValue;
            this.isEdit = false;
        }
    }
