import { authInstance } from './auth-instance';
import {
    AgreementUserRequest,
    DayType,
    Employee,
    FilterItemsResponse,
    Locale,
    UserItemResponse,
    UserItemResponseFilter,
    UserOptions,
    UserPutPasswordRequest,
    UserRequest,
    UserResponse,
} from '@/types';
import {
    GenerateUserCalendarFilter,
    UserCalendarType,
} from '@/components/admin/roles-model/types';

export const user = {
    create: (data: UserRequest): Promise<UserResponse> =>
        authInstance.post<UserResponse>('users', data).then(x => x.data),

    getAll: (): Promise<UserResponse[]> =>
        authInstance.get<UserResponse[]>('users/all').then(x => x.data),

    getItems: (filter?: UserItemResponseFilter): Promise<UserItemResponse[]> =>
        authInstance.get<UserItemResponse[]>('users/items', { params: filter }).then(x => x.data),

    getByFilter: (options: UserOptions): Promise<FilterItemsResponse<UserResponse>> =>
        authInstance.post<FilterItemsResponse<UserResponse>>('users/byFilter', options).then(x => x.data),

    getById: (userId: number): Promise<UserResponse> =>
        authInstance.get<UserResponse>(`users/${userId}`).then(x => x.data),

    updatePassword: (userId: number, data: UserPutPasswordRequest) =>
        authInstance.put(`users/update-password/${userId}`, data).then(x => x.data),

    update: (userId: number, data: UserRequest) =>
        authInstance.put(`users/${userId}`, data).then(x => x.data),

    setLocale: (userId: number, locale: Locale) =>
        authInstance.put(`users/SetLocale/${userId}?locale=${locale}`).then(x => x.data),

    setAgreementUser: (data: AgreementUserRequest) =>
        authInstance.post('users/SetAgreementUser', data).then(x => x.data),

    remove: (userId: number): Promise<void> =>
        authInstance.delete(`users/${userId}`).then(x => x.data),

    blocked: (userId: number): Promise<void> =>
        authInstance.delete(`users/blocked/${userId}`).then(x => x.data),

    getUserInfo: (): Promise<UserResponse> =>
        authInstance.get<UserResponse>('users/user-info').then(x => x.data),

    getEmployee: (guidId: string): Promise<Employee> =>
        authInstance.get<Employee>(`users/employee/${guidId}`).then(x => x.data),

    getAllEmployees: (): Promise<Employee[]> =>
        authInstance.get<Employee[]>('users/employee').then(x => x.data),

    getCalendar: (calendarId: number): Promise<UserCalendarType> =>
        authInstance.get<UserCalendarType>(`Users/GetCalendar/${calendarId}`).then(x => x.data),

    getUserCalendarByYear: (options: GenerateUserCalendarFilter): Promise<UserCalendarType[]> =>
        authInstance.post<UserCalendarType[]>('Users/GetUserCalendarByYear', options).then(x => x.data),

    generateUserCalendar: (options: GenerateUserCalendarFilter): Promise<UserCalendarType[]> =>
        authInstance.post<UserCalendarType[]>('Users/GenerateUserCalendar', options).then(x => x.data),

    addCalendar: (data: UserCalendarType): Promise<UserCalendarType> =>
        authInstance.post<UserCalendarType>('Users/AddCalendar', data).then(x => x.data),

    updateCalendar: (dayId: number, dayType: DayType): Promise<UserCalendarType> =>
        authInstance.put<UserCalendarType>(`Users/UpdateCalendar/${dayId}`, { dayType }).then(x => x.data),

    updateRangeCalendar: (data: UserCalendarType): Promise<UserCalendarType> =>
        authInstance.put<UserCalendarType>('Users/UpdateRangeCalendar', data).then(x => x.data),

    deleteCalendar: (calendarId: number): Promise<void> =>
        authInstance.delete(`Users/DeleteCalendar/${calendarId}`).then(x => x.data),

    getEmployeesByContragent: (contragentId: number): Promise<Employee[]> =>
        authInstance.get(`Users/GetEmployeesByContragent/${contragentId}`).then(x => x.data),
};
