export const GET_DOCUMENTSCHEMAS = 'GET_DOCUMENTSCHEMAS';
export const GET_REFTABLESCHEMAS = 'GET_REFTABLESCHEMAS';
export const GET_DOCUMENTSCHEMA = 'GET_DOCUMENTSCHEMA';
export const GET_REFTABLESCHEMA = 'GET_REFTABLESCHEMA';
export const GET_DOCUMENT_CATEGORIES = 'GET_DOCUMENT_CATEGORIES';
export const GET_DOCUMENT_LINKS = 'GET_DOCUMENT_LINKS';
export const GET_RELATED_DOCUMENTS = 'GET_RELATED_DOCUMENTS';
export const CHANGE_DOCUMENT_LIST = 'CHANGE_DOCUMENT_LIST';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const GET_ALL_REGISTER_STATUS = 'GET_ALL_REGISTER_STATUS';
