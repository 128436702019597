import { MutationTree } from 'vuex';
import { AsBuiltAppSettings, CommonAppSettings, SET_ASBUILT_APP_SETTINGS, SET_COMMON_APP_SETTINGS, Settings } from '@/store/settings/types';

export const mutations: MutationTree<Settings> = {
    [SET_COMMON_APP_SETTINGS]: (state, commonAppSettings: CommonAppSettings) => {
        state.common = commonAppSettings;
    },
    [SET_ASBUILT_APP_SETTINGS]: (state, settings: AsBuiltAppSettings) => {
        state.asBuilt = settings;
    },
};
