import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { ru, en } from 'vuetify/src/locale';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#072974',
                secondary: '#7389C3',
                success: '#4caf50',
                successLight: '#dcfce7',
                warning: '#fb8c00',
                warningLight: '#fed7aa',
                error: '#ff5252',
                errorLight: '#fee2e2',
                info: '#2196f3',
                infoLight: '#bfdbfe',
                dark: '#2a3b4d',
                muted: '#6c757d',
                hoverTable: '#eeeeee',
                lightGray: '#cccccc',
            },
        },
        options: { customProperties: true },
    },
    lang: {
        locales: { ru, en },
        current: 'ru',
    },
});
