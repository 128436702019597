import { RegisterStatus as RegisterStatusEnum } from '@/api/constants';

export const colorStatus: Record<RegisterStatusEnum, string> = {
    [RegisterStatusEnum.Draft]: '#1d90ff',
    [RegisterStatusEnum.Check]: '#6495ec',
    [RegisterStatusEnum.HasIssues]: '#ffaf3e',
    [RegisterStatusEnum.ResolvingIssues]: '#ffed59',
    [RegisterStatusEnum.SecondaryCheck]: '#21b2aa',
    [RegisterStatusEnum.Approval]: '#03bfff',
    [RegisterStatusEnum.NotApproved]: '#ffacac',
    [RegisterStatusEnum.WaitingConstruction]: '#fed801',
    [RegisterStatusEnum.Canceled]: '#cd5c5b',
    [RegisterStatusEnum.Signing]: '#3bb372',
    [RegisterStatusEnum.Signed]: '#006400',
};

export interface TabsConfiguration {
    IsAsBuiltDocumentationTabVisible: boolean;
    IsAsBuiltDrawingsTabVisible: boolean;
    IsWorkingDrawingsTabVisible: boolean;
    IsKS2TabVisible: boolean;
}

export enum TypeTab {
    DocumentationTab,
    DrawingsTab,
    WorkingDrawingsTab,
    KS2Tab,
}
