
    import { Component, Vue } from 'vue-property-decorator';
    import MyRegistersReport from '@/components/asbuilt-docs/reports/my-registers-report.vue';
    import RegistersWithCheckReport from '@/components/asbuilt-docs/reports/registers-with-check-report.vue';
    import * as Odata from '@/api/odata';
    import { FinalBook, RegisterIssueStatus, RegisterStatus } from '@/api/model/interfaces';
    import IssuesReport from '@/components/asbuilt-docs/reports/issues-report.vue';
    import RegistersWithApprovalReport from '@/components/asbuilt-docs/reports/registers-with-approval-report.vue';
    import RegistersForGenApproverReport from '@/components/asbuilt-docs/reports/registers-for-gen-approver-report.vue';
    import { AsBuiltReportActionName } from '@/components/asbuilt-docs/constants';

    interface ReportOption {
        actionName: AsBuiltReportActionName;
        title: string;
    }

    @Component({})
    export default class AsBuiltReportsIndex extends Vue {
        registerStatuses: RegisterStatus[] = [];
        registerIssueStatuses: RegisterIssueStatus[] = [];
        finalBooks: FinalBook[] = [];
        report = 0;
        reports: ReportOption[] = [
            { actionName: AsBuiltReportActionName.Registers, title: this.$t('dashboard.my-registers') as string },
            { actionName: AsBuiltReportActionName.RegistersWithCheck, title: this.$t('dashboard.my-registers-by-check-status') as string },
            { actionName: AsBuiltReportActionName.RegistersWithApproval, title: this.$t('dashboard.my-registers-by-approval-status') as string },
            { actionName: AsBuiltReportActionName.RegistersForGenApprover, title: this.$t('dashboard.registers-declined-waiting-signing') as string },
            { actionName: AsBuiltReportActionName.Issues, title: this.$t('dashboard.my-issues') as string },
        ];

        async mounted() {
            await this.getRegisterStatus();
            await this.getRegisterIssueStatus();
            await this.getFinalBooks();
        }

        get actionName(): AsBuiltReportActionName {
            return this.reports[this.report].actionName;
        }

        get reportComponent() {
            if (!this.actionName) return null;

            switch (this.actionName) {
                case AsBuiltReportActionName.Registers:
                    return MyRegistersReport;

                case AsBuiltReportActionName.RegistersWithCheck:
                    return RegistersWithCheckReport;

                case AsBuiltReportActionName.RegistersWithApproval:
                    return RegistersWithApprovalReport;

                case AsBuiltReportActionName.RegistersForGenApprover:
                    return RegistersForGenApproverReport;

                case AsBuiltReportActionName.Issues:
                    return IssuesReport;

                default:
                    throw new Error('Could not find a component for the specified StatisticsType.');
            }
        }

        async getRegisterStatus() {
            this.registerStatuses = await Odata.RegisterStatus.getAll();
        }

        async getRegisterIssueStatus() {
            this.registerIssueStatuses = await Odata.RegisterIssueStatus.getAll();
        }

        async getFinalBooks() {
            this.finalBooks = await Odata.FinalBook.getAll();
        }
    }
