
    import { Component, Vue } from 'vue-property-decorator';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import VueI18n from 'vue-i18n';

    export enum ConfirmType {
        confirm = 'confirm',
        delete = 'delete',
        info = 'info',
    }

    @Component({
        components: { ButtonTooltip },
    })
    export default class Confirm extends Vue {
        isVisible = false
        type: ConfirmType = ConfirmType.confirm;
        message: VueI18n.TranslateResult = '';
        name = '';

        get title() {
            switch (this.type) {
                case ConfirmType.confirm:
                    return this.$t('confirm.confirm-action');
                case ConfirmType.delete:
                    return this.$t('permissions.Delete');
                case ConfirmType.info:
                    return this.$t('common.caution');
            }
        }

        get submitText() {
            switch (this.type) {
                case ConfirmType.confirm:
                    return this.$t('common.confirm');
                case ConfirmType.delete:
                    return this.$t('common.delete');
                case ConfirmType.info:
                    return 'Ok';
            }
        }

        get color() {
            switch (this.type) {
                case ConfirmType.confirm:
                    return 'warning';
                case ConfirmType.delete:
                    return 'error';
                case ConfirmType.info:
                    return 'primary';
            }
        }

        close() {
            this.message = '';
            this.name = '';
            this.isVisible = false;
        }

        show(name: string, type: ConfirmType, message: VueI18n.TranslateResult) {
            this.name = name;
            this.type = type;
            this.message = message;
            this.isVisible = true;
        }

        submit() {
            this.$emit('success', this.name);
            this.close();
        }
    }
