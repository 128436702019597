
    import { Component, Vue } from 'vue-property-decorator';
    import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
    import apiBaseUrl from '@/api/base-url';

    @Component
    export default class AppBarVersion extends Vue {
        connection: HubConnection | null = null;
        version: string | null = null;
        updatedVersion = false;

        get tooltip() {
            return this.updatedVersion ? this.$t('common.refresh') : this.$t('common.actual');
        }

        async created() {
            this.version = localStorage.getItem('version');
            this.connection = new HubConnectionBuilder()
                .withUrl(`${apiBaseUrl}hubs/version`, {
                    accessTokenFactory: () => this.$store.getters.authToken,
                })
                .withAutomaticReconnect()
                .build();

            this.connection.on('UpdatedVersion', async(version) => {
                const currentVersion = localStorage.getItem('version');
                this.version = version;
                if (currentVersion == null) {
                    localStorage.setItem('version', version);
                    return;
                }
                this.updatedVersion = currentVersion !== version;
                if (this.updatedVersion) localStorage.setItem('version', version);
            });
            await this.connection.start();
            await this.connection.invoke('GetVersion');
        }

        refresh() {
            location.reload();
        }
    }
