
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
    import VueI18n from 'vue-i18n';
    import { InputValidationRules } from 'vuetify';
    import { VForm } from '@/types';
    import { PropType } from 'vue';

    @Component({})
    export default class DragFileUpload extends Vue {
        @Ref() fileBtn!: HTMLInputElement;

        @Ref() dropzone: HTMLDivElement | undefined;

        @Prop({ default: () => null })
        label!: VueI18n.TranslateResult | null;

        @Prop({ type: Array, default: () => [] })
        files!: File[];

        @Prop({ type: String })
        accept!: string;

        @Prop({ type: Boolean, default: () => false })
        multiple!: boolean;

        @Prop({ type: Boolean, default: () => false })
        immediately!: boolean;

        @Prop({ type: Array, default: () => [] })
        rules!: InputValidationRules;

        @Prop({ type: Object as PropType<VForm> })
        form?: VForm;

        @Prop({ type: Number, default: null })
        loaderPercent!: number | null;

        @Prop({ type: Boolean, default: false })
        loading!: boolean;

        hoverCounter = 0;
        hoveringContent: DataTransferItemList | null = null;
        matchAnything = /.*/;
        shouldDim = false;
        errorBucket: string[] = [];

        @Watch('form.$props.value')
        updateForm() {
            this.validate();
        }

        @Watch('multiple', { immediate: true })
        updateMultiple(val: boolean) {
            if (!val) {
                this.files.splice(0, this.files.length - 1);
            }
        }

        @Watch('hoveringContent', { immediate: true })
        updateHoveringContent(val: DataTransferItemList | null) {
            this.shouldDim = false;
            if (!this.dropzone || !val) return;

            // Если у нас есть проверка типов и мы используем только MIME-типы
            if (this.accept && this.accept.length && this.validTypes.extensions.length === 0) {
                // Для каждого файла, наведенного на поле...
                for (let i = 0; i < val.length; i++) {
                    if (
                        // Проверьте тип по всем нашим типам mime
                        this.validTypes.mimetypes.reduce((prev, regex) => prev || !!val[i].type.match(regex))
                    ) {
                        this.shouldDim = true;
                        break;
                    }
                }
                // Если нет, мы не можем окончательно проверить тип, так что...
            } else {
                // Убедитесь, что у нас есть файл там
                for (let i = 0; i < val?.length; i++) {
                    if (val[i].kind === 'file') {
                        this.shouldDim = true;
                        break;
                    }
                }
            }
        }

        @Watch('hoverCounter', { immediate: true })
        updateHoverCounter(val: number) {
            if (val === 0) {
                this.hoveringContent = null;
            }
        }

        get isDropzoneClass() {
            return {
                dropzone: !!this.hoveringContent,
                dropHover: this.shouldDim,
            };
        }

        get fileBtnAccess() {
            return this.validatedAccept && [
                ...this.validatedAccept.extensions,
                ...this.validatedAccept.mimetypes,
            ].join(',');
        }

        get validTypes() {
            if (this.validatedAccept) {
                return {
                    extensions: this.validatedAccept.extensions
                        .map(ext => ext.replace(/(\W)/g, '\\$1')) // Экранирование всех потенциальных токенов регулярных выражений
                        .map(rgxstr => new RegExp(`${rgxstr}$`, 'i')), // Преобразуйте в регулярное выражение для поиска расширения
                    mimetypes: this.validatedAccept.mimetypes
                        .map(mt => mt.replace(/([-+/])/g, '\\$1')) // Экранирование специальных символов
                        .map(mt => mt.replace(/\*/g, '(?:[A-Za-z0-9\\-\\+]*)*')) // Включить подстановочные знаки
                        .map(rgxstr => new RegExp(`^${rgxstr}$`)), // Преобразовать в регулярное выражение
                };
            } else {
                // Если нам не дали никаких фильтров...
                return {
                    extensions: [ this.matchAnything ],
                    mimetypes: [ this.matchAnything ],
                };
            }
        }

        get validatedAccept() {
            if (!this.accept) return null;
            const EXTENSION_REGEX = /^\.(?!.*\/)/;
            const MIME_TYPE_REGEX = /^(?:(?:[A-Za-z0-9\-+]*)|\*)\/(?:(?:[A-Za-z0-9\-+.]*)|\*)$/;
            return {
                extensions: this.accept
                    .split(',')
                    .filter(type => type.match(EXTENSION_REGEX)),
                mimetypes: this.accept
                    .split(',')
                    .filter(type => type.match(MIME_TYPE_REGEX)),
            };
        }

        validate() {
            this.errorBucket = [];
            this.rules.forEach(rule => {
                const valid = typeof rule === 'function' ? rule(this.files) : rule;

                if (valid === false || typeof valid === 'string') {
                    this.errorBucket.push(valid || '');
                }
            });
        }

        upload() {
            const files = this.fileBtn.files ?? [];
            for (let i = 0; i < files.length; i++) {
                if (!this.multiple) {
                    this.files.splice(0, this.files.length);
                }
                const shouldPush = this.validTypes.extensions.reduce((prev, regex) => prev || !!files[i].name.match(regex), false)
                    || this.validTypes.mimetypes.reduce((prev :boolean, regex) => prev || !!files[i].type.match(regex), false);
                if (shouldPush) {
                    this.files.push(files[i]);
                    this.$emit('input', this.files);
                    this.validate();
                }
            }
            this.fileBtn.value = '';
        }

        dragenter(e: DragEvent) {
            if (!e.dataTransfer) return;
            this.hoveringContent = e.dataTransfer.items;
            this.hoverCounter++;
        }

        /** Подсчитывает события выхода */
        dragleave() {
            this.hoverCounter--;
            this.validate();
        }

        /** Проверяет и отслеживает удаленное содержимое */
        drop(e: DragEvent) {
            if (!e.dataTransfer) return;
            this.hoverCounter = 0; // Контент нельзя перетаскивать, поэтому сбросьте счетчик
            const rejected = []; // Отслеживает отклоненные элементы для отчета в конце

            for (const file of e.dataTransfer.items) {
                if (file.kind !== 'file') return;

                // Каталоги не поддерживаются. Пропустить все найденные
                const entry = file.webkitGetAsEntry();
                if (entry?.isDirectory) {
                    rejected.push(entry.name);
                    continue;
                }

                const objFile = file.getAsFile();
                if (!objFile) continue;

                // Проверить массивы Regex из свойства accept
                const shouldPush = this.validTypes.extensions
                    .reduce((prev: boolean, regex) => prev || !!objFile.name.match(regex), false)
                    || this.validTypes.mimetypes
                        .reduce((prev: boolean, regex) => prev || !!objFile.type.match(regex), false);
                if (shouldPush) {
                    if (this.multiple) {
                        // Удалить дубликаты
                        this.files
                            .filter(currFile => currFile.name === objFile.name)
                            .forEach(fileToRemove => this.files.splice(this.files.indexOf(fileToRemove), 1));
                    } else {
                        // Удалить все
                        this.files.splice(0, this.files.length);
                    }
                    this.files.push(objFile);
                    this.$emit('input', this.files);
                    this.validate();
                } else {
                    rejected.push(objFile); // Следите за отклоненными файлами
                    continue;
                }
            }
            // Выдавать отклоненные файлы
            if (rejected.length) {
                this.$emit('rejected-files', rejected);
                this.validate();
            }
        }

        /** Удаляет вложение по запросу пользователя */
        remove(file: File) {
            const arr = this.files;
            this.$emit('remove', file);
            arr.splice(arr.indexOf(file), 1);
            this.validate();
        }
    }
