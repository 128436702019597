
    import { Component, Vue } from 'vue-property-decorator';
    import { GET_COMMON_APP_SETTINGS } from '@/store/settings/types';
    import { AppBarMenu } from '@/store/topmenu/types';
    import { UPDATE_MENU } from '@/store/topmenu/module';
    import { SET_DRAWING_MODULE } from '@/store/drawings/actions';
    import { DrawingRootRouteName } from '@/components/drawing-docs/constants';

    @Component({})
    export default class DrawingDocsIndex extends Vue {
        get menu(): AppBarMenu[] {
            return [
                {
                    id: 1,
                    title: 'common.packages',
                    to: { name: 'drawing-packages' },
                    right: 'Cis.ListDrawingPackage',
                },
                {
                    id: 2,
                    title: 'common.reports',
                    to: {
                        name: 'drawing-reports',
                        params: { reportName: 'DrawingPackages' },
                    },
                    right: 'Cis.ReportsDrawingPackage',
                },
            ];
        }

        async created() {
            this.$store.commit(UPDATE_MENU, this.menu);
            this.$store.commit('drawings/' + SET_DRAWING_MODULE, DrawingRootRouteName.WorkingDrawings);
            await this.$store.dispatch(GET_COMMON_APP_SETTINGS);
        }
    }
