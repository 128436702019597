export enum RightNameConstants {
    ExecutiveDocumentationModule = 'Cis.Module.ExecutiveDocumentationModule',
    WorkingDrawingsModule = 'Cis.Module.WorkingDrawingsModule',
    Ks2Module = 'Cis.Module.Ks2Module',
    PermissiveDocumentationModule = 'Cis.Module.PermissiveDocumentationModule',
    ReferencesModule = 'Cis.Module.ReferencesModule',
    TmcModule = 'Cis.Module.TmcModule',
    DocumentsAndJournalsModule = 'Cis.Module.DocumentsAndJournalsModule',
    SecurityModule = 'Cis.Module.SecurityModule',
    GeneralDocumentationModule = 'Cis.Module.GeneralDocumentationModule',
    AdministrationModule = 'Cis.Module.AdministrationModule',
    ReportsModule = 'Cis.ReportsAsBuilt',
}
