
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import * as api from '@/api/dynamic';
    import { ActualPhysicalVolume } from '@/components/asbuilt-docs/volume/utils';
    import ActualVolumeDialog from '@/components/asbuilt-docs/volume/actual/actual-volume-dialog.vue';
    import { Contract, DrawingPackage, MeasurementUnit, WorkType } from '@/api/model/interfaces';
    import { localizeDescription } from '@/util/field-translation';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { DataOptions } from 'vuetify';
    import { ActualPhysicalVolumeOptions, VolumeCategory } from '@/types/dynamicDoc';
    import { AutocompleteItem, DataTableHeaderCustom, MenuFilterType } from '@/types';
    import DataTableFilter from '@/components/common/data-table-filter.vue';
    import { useStorage } from '@vueuse/core';
    import * as VueType from 'vue';
    import PhysicalVolumeDocumentsDialog from '@/components/asbuilt-docs/volume/actual/physical-volume-documents-dialog.vue';
    import * as Odata from '@/api/odata';
    import { DrawingPackageStatus } from '@/api/constants';

    @Component({
        components: {
            DataTableFilter,
            ActualVolumeDialog,
            PhysicalVolumeDocumentsDialog,
            ButtonTooltip,
        },
        data: () => ({ MenuFilterType }),
    })
    export default class ActualVolume extends Vue {
        isVisibleDialog = false;
        isVisibleDocumentsDialog = false;
        dynamicItems: ActualPhysicalVolume[] = [];
        drawingPackages: DrawingPackage[] = [];
        physicalVolumeId: string | null = null;

        get toolbarFilter() {
            return useStorage(`${this.$route.name}-actual-toolbar-optionsTable`, {
                IsCompleted: false,
                IsWithExcess: false,
                IsActive: false,
            }, sessionStorage) as VueType.Ref<ActualPhysicalVolumeOptions>;
        }

        options: Partial<ActualPhysicalVolumeOptions> = {
            Search: {
                DrawingPackageCode: null,
            },
        };

        loading = false;
        totalDocuments = 0;

        readonly volumeTypes: AutocompleteItem[] = [
            { value: VolumeCategory.Project, text: this.$t('volume.project') as string },
            { value: VolumeCategory.Defect, text: this.$t('volume.defect') as string },
        ];

        @Watch('$route', { immediate: true })
        async init() {
            if (this.$route.params.subItem === 'actual') {
                this.optionsTable.value.page = 1;
                await this.getDynamicActualPhysicalVolume();
            }
        }

        @Watch('optionsTable', { deep: true })
        async onOptionsTable() {
            if (this.loading || !this.optionsTable || this.$route.params.subItem !== 'actual') return;
            await this.getDynamicActualPhysicalVolume();
        }

        @Watch('toolbarFilter', { deep: true })
        async onToolbarFilter() {
            if (this.loading || !this.toolbarFilter.value || this.$route.params.subItem !== 'actual') return;
            await this.getDynamicActualPhysicalVolume();
        }

        async created() {
            await this.getDrawingPackages();
        }

        get optionsTable() {
            return useStorage(`${this.$route.name}-actual-optionsTable`, {
                page: 1,
                itemsPerPage: 25,
                sortBy: [ 'CreatedAt' ],
                sortDesc: [ true ],
            }, sessionStorage) as VueType.Ref<DataOptions>;
        }

        get itemsFormat() {
            return this.dynamicItems.map((x: ActualPhysicalVolume) => ({
                ...x,
                DrawingPackageCode: this.drawingPackages.find(d => d.Id === x.DrawingPackageId)?.MainCode || '',
                DrawingPackageRevision: this.drawingPackages.find(d => d.Id === x.DrawingPackageId)?.RevisionTitle || '',
                Type: this.volumeTypes.find(v => v.value === x.Type)?.text,
                Contract: this.contracts.find(v => v.value === x.ContractId)?.text,
                WorkType: this.workTypes.find(v => v.value === x.WorkTypeId)?.text,
                MeasurementUnit: this.measurementUnit.find(v => v.value === x.MeasurementUnitId)?.text,
                KfoMeasurementUnit: this.measurementUnit.find(v => v.value === x.KfoMeasurementUnitId)?.text,
                TopKfoMeasurementUnit: this.measurementUnit.find(v => v.value === x.TopKfoMeasurementUnitId)?.text,
            }));
        }

        get headers(): DataTableHeaderCustom[] {
            return [
                {
                    text: this.$t('common.drawing-package') as string,
                    value: 'DrawingPackageId',
                    width: 220,
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.drawingPackages.map(x => ({ text: x.MainCode, value: x.Id })),
                },
                {
                    text: this.$t('common.revision') as string,
                    value: 'DrawingPackageRevision',
                    width: 60,
                    sortable: false,
                },
                {
                    text: this.$t('common.contract') as string,
                    value: 'ContractId',
                    width: 110,
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.contracts,
                    sortable: false,
                },
                {
                    text: this.$t('common.work-type') as string,
                    value: 'WorkTypeId',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.workTypes,
                    sortable: false,
                },
                {
                    text: this.$t('volume.project') as string,
                    value: 'ProjectValue',
                    cellClass: 'white-space-no-wrap',
                    sortable: false,
                },
                {
                    text: this.$t('volume.document') as string,
                    value: 'DocumentValue',
                    cellClass: 'white-space-no-wrap',
                    sortable: false,
                },
                {
                    text: this.$t('volume.document-kfo') as string,
                    value: 'DocumentKfoValue',
                    cellClass: 'white-space-no-wrap',
                    sortable: false,
                },
                {
                    text: this.$t('common.volume-top-kfo') as string,
                    value: 'DocumentTopKfoValue',
                    cellClass: 'white-space-no-wrap',
                    sortable: false,
                },
                {
                    text: this.$t('volume.actual') as string,
                    value: 'ActualValue',
                    cellClass: 'white-space-no-wrap',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'Actions',
                    width: 70,
                },
            ];
        }

        get contracts(): AutocompleteItem[] {
            return this.$store.state.reftables.Contracts.map((x: Contract) => ({
                text: x.Code,
                value: x.Id,
            }));
        }

        get measurementUnit(): AutocompleteItem[] {
            return this.$store.state.reftables.MeasurementUnit.map((x: MeasurementUnit) => ({
                text: x.Title,
                value: x.Id,
            }));
        }

        get workTypes(): AutocompleteItem[] {
            return this.$store.state.reftables.WorkTypes.map((x: WorkType) => ({
                text: x.Code?.length ? `${x.Code}. ${localizeDescription(x)}` : localizeDescription(x),
                value: x.Id,
            }));
        }

        get titleDialog() {
            if (!this.physicalVolumeId) return '';
            const volume = this.dynamicItems.find(x => x.Id === this.physicalVolumeId);
            const mainCode = this.drawingPackages.find(x => x.Id === volume?.DrawingPackageId)?.MainCode;
            const workType = this.workTypes.find(x => x.value.toString() === volume?.WorkTypeId);
            return `${mainCode || ''} (${workType?.text})`;
        }

        async update() {
            this.deleteEmptyValue(this.options);
            if (this.options.Search.DrawingPackageCode === undefined) this.options.Search.DrawingPackageCode = null;
            this.optionsTable.value.page = 1;
            await this.getDynamicActualPhysicalVolume();
        }

        changeSortBy(sortBy: string) {
            this.optionsTable.value.sortBy = [ sortBy ] || [ 'CreatedAt' ];
        }

        changeSortDesc(desc: boolean) {
            this.optionsTable.value.sortDesc = [ desc ] || [ true ];
        }

        edit(item: ActualPhysicalVolume) {
            this.physicalVolumeId = item.Id;
            this.isVisibleDialog = true;
        }

        openDocumentsDialog(item: ActualPhysicalVolume) {
            this.physicalVolumeId = item.Id;
            this.isVisibleDocumentsDialog = true;
        }

        deleteEmptyValue(item: any) {
            for (const key in item) {
                if (!item[key]?.toString()) delete item[key];
            }
        }

        async getDrawingPackages() {
            this.drawingPackages = await Odata.DrawingPackage.setParams({
                select: [ 'Id', 'MainCode', 'RevisionTitle' ],
                filter: [
                    `StatusId eq ${DrawingPackageStatus.Archived}`,
                    'IsActual eq true' ],
            }).getAll();
        }

        async getDynamicActualPhysicalVolume() {
            try {
                this.loading = true;
                const res = await api.physicalVolume.getActual({
                    ...this.options,
                    ...this.toolbarFilter.value,
                    PageSize: this.optionsTable.value.itemsPerPage,
                    PageNumber: this.optionsTable.value.page,
                    OrderBy: this.optionsTable.value.sortBy[0],
                    IsDesc: this.optionsTable.value.sortDesc[0],
                });
                this.dynamicItems = res.Items;
                this.totalDocuments = res.TotalItems;
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        }
    }
