import Vue from 'vue';

export function getFieldValue(entity: Record<string, any> | null | undefined, fieldName: string, throwIfUndefined = false) {
    if (!entity) return null;

    const fieldsHierarchy = fieldName.split('.');
    const lastField = fieldsHierarchy.pop();

    if (!lastField) { throw new Error(`Wrong calculate expression format. '${fieldName}' is not a valid field name.`); }

    let obj = entity;
    for (const field of fieldsHierarchy) {
        if (!obj.hasOwnProperty(field)) {
            if (throwIfUndefined) { throw new Error(`Wrong expression. Property '${field}' doesn't exist on type ${typeof obj}.`); }

            return null;
        }

        obj = obj[field];
    }

    return obj[lastField];
}

export function setFieldValue(entity: Record<string, any> | null | undefined, fieldName: string, val: any, createNested = true) {
    if (!entity) return null;

    const fieldsHierarchy = fieldName.split('.');
    const lastFieldName = fieldsHierarchy.pop();

    if (!lastFieldName) { throw new Error('Wrong calculate expression format.'); }

    let obj = entity;
    for (const field of fieldsHierarchy) {
        if (!obj.hasOwnProperty(field)) {
            if (!createNested) {
                throw new Error(`Wrong expression. Property '${field}' doesn't exist on type ${typeof obj}.`);
            }

            Vue.set(obj, field, {});
        }

        obj = obj[field];
    }

    Vue.set(obj, lastFieldName, val);
}

export function objectWithoutKey<T>(object: T, key: keyof T) {
    const { [key]: deletedKey, ...otherKeys } = object;
    return otherKeys;
}
