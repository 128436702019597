
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { required } from '@/components/common/validation-rules';
    import TooltipWrapper from '@/components/dynamic/common/tooltip-wrapper.vue';

    @Component({
        components: { TooltipWrapper },
    })
    export default class NumberInput extends Vue {
        @Prop({ type: String, required: true })
        private readonly label!: string;

        @Prop([ Number, Array, String ])
        private readonly value!: number | string | number[];

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly validate!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly clearable!: boolean;

        rules = { required };

        formatValue($event: string | null) {
            this.$emit('input', $event ? Number($event) : null);
        }
    }
