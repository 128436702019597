import { cisInstance } from './cis-instance';
import { RegisterGroupByReviewerUserIdResponse, RegisterGroupByStatusResponse, RegisterStatusesInfoFilter, RegisterWidgetResponse } from '@/types/cis/widget';

/** Виджеты */
export const widget = {
    getByStatus: (userId: string | null = null): Promise<RegisterGroupByStatusResponse[]> =>
        cisInstance.get<RegisterGroupByStatusResponse[]>(userId ? `Widget/GetByStatus/${userId}` : 'Widget/GetByStatus')
            .then(x => x.data),
    getByUserExpert: (): Promise<RegisterWidgetResponse[]> =>
        cisInstance.get<RegisterWidgetResponse[]>('Widget/GetByUserExpert').then(x => x.data),
    getNotProcessedIssue: (): Promise<RegisterWidgetResponse[]> =>
        cisInstance.get<RegisterWidgetResponse[]>('Widget/GetNotProcessedIssue').then(x => x.data),
    getTimersByRegistry: (): Promise<RegisterGroupByReviewerUserIdResponse[]> =>
        cisInstance.get<RegisterGroupByReviewerUserIdResponse[]>('Widget/GetTimersByRegistry').then(x => x.data),
    getUnprocessedErrorsInDocuments: (): Promise<RegisterGroupByReviewerUserIdResponse[]> =>
        cisInstance.get<RegisterGroupByReviewerUserIdResponse[]>('Widget/GetUnprocessedErrorsInDocuments').then(x => x.data),
    getChartRegisterSubcontractorInfo: (filter: RegisterStatusesInfoFilter): Promise<any> =>
        cisInstance.post<any>('Widget/GetRegisterStatusesInfoBySubContractor', filter)
            .then(x => x.data),
};
