import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import * as api from '@/api/report';
import { ReportState } from '@/store/report/types';
import { GET_CONDITION_ITEMS } from '@/store/report/actions';
import { ConditionItem } from '@/types/report';

const state: ReportState = {
    conditions: [],
};

const actions: ActionTree<ReportState, RootState> = {
    [GET_CONDITION_ITEMS]: async({ commit }) => {
        commit(GET_CONDITION_ITEMS, await api.condition.getAll());
    },
};

const mutations: MutationTree<ReportState> = {
    [GET_CONDITION_ITEMS]: (state, payload: ConditionItem[]) => {
        state.conditions = payload.sort((a, b) => a.Name.localeCompare(b.Name));
    },
};

const getters: GetterTree<ReportState, RootState> = {};

const module: Module<ReportState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};

export default module;
