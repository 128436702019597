import axios from 'axios';
import { interceptors } from '@/api/helpers';

export const apiReportServiceUrl = process.env.VUE_APP_API_REPORT_SERVICE;

export const reportInstance = axios.create({
    baseURL: `${apiReportServiceUrl}api/`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') },
});

// Перехватчик запросов axios
interceptors(reportInstance);
