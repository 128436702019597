import { RegisterIssue } from '@/api/model/interfaces';
import { RegisterIssueRequest } from '@/types';
import { cisOdataInstance } from '@/api/cis/cis-instance';

/** Замечания реестра */
export const registerIssue = {
    create: (data: RegisterIssueRequest): Promise<RegisterIssue> => {
        const formData = new FormData();
        if (data.Title) formData.append('Title', data.Title);
        if (data.Description) formData.append('Description', data.Description);
        if (data.ReviewId) formData.append('ReviewId', data.ReviewId);
        if (data.DocumentId) formData.append('DocumentId', data.DocumentId);
        if (data.DynamicDocumentItemId) formData.append('DynamicDocumentItemId', data.DynamicDocumentItemId);
        data.Files.forEach(file => formData.append('Files', file));

        return cisOdataInstance.post<RegisterIssue>('RegisterIssue/', formData).then(x => x.data);
    },
};
