import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { DocumentTemplate, DocumentTemplateAndType, DynamicResponse, getByFilter, Options } from '@/types/dynamicDoc';

/** Шаблон документа */
export const documentTemplate = {
    getAll: () =>
        dynamicDocInstance.get<DocumentTemplate[]>('/DocumentTemplate/getAll').then(x => x.data),

    getByFilter: (options: Options) =>
        dynamicDocInstance.post<getByFilter<DocumentTemplateAndType>>('/DocumentTemplate/GetMany/getByFilter', options)
            .then(x => x.data),

    getPage: (page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DocumentTemplateAndType>>(`/DocumentTemplate/page?page=${page}&pageSize=${pageSize}`).then(x => x.data),

    getCategoryPage: (id: string, page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DocumentTemplateAndType>>(
            `/DocumentTemplate/ByCategoryPage?categoryId=${id}&page=${page}&pageSize=${pageSize}`)
            .then(x => x.data),

    getById: (key: string) =>
        dynamicDocInstance.get(`/DocumentTemplate/get?id=${key}`).then(x => x.data),

    getByCategory: (categoryId: string) =>
        dynamicDocInstance.get<DynamicResponse<DocumentTemplateAndType>>(`/DocumentTemplate/ByCategory?categoryId=${categoryId}`)
            .then(x => x.data),

    create: (template: DocumentTemplate) =>
        dynamicDocInstance.post('/DocumentTemplate/post', template).then(x => x.data),

    update: (key: string, template: DocumentTemplate) =>
        dynamicDocInstance.patch(`/DocumentTemplate/patch?id=${key}`, template).then(x => x.data),

    remove: (key: string) =>
        dynamicDocInstance.delete(`/DocumentTemplate/delete?id=${key}`).then(x => x.data),
};
