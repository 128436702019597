
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import '@vue-office/excel/lib/index.css';
    import '@vue-office/docx/lib/index.css';
    import 'viewerjs/dist/viewer.css';
    import VuePdfApp from 'vue-pdf-app';
    import VueOfficeExcel from '@vue-office/excel';
    import VueOfficeDocx from '@vue-office/docx';
    import VueViewer from 'v-viewer';
    import ViewerJs from 'viewerjs';
    Vue.use(VueViewer);

    @Component({
        components: {
            VuePdfApp,
            VueOfficeExcel,
            VueOfficeDocx,
        },
    })
    export default class DocumentViewer extends Vue {
        @Prop({ type: String, required: true })
        fileType!: string;

        @Prop({ type: String, required: true }) src!: string;

        configPdf = {
            secondaryToolbar: true,
            toolbar: {
                toolbarViewerLeft: {
                    findbar: false,
                    previous: true,
                    next: true,
                    pageNumber: true,
                },
                toolbarViewerMiddle: {
                    zoomOut: true,
                    zoomIn: true,
                    scaleSelectContainer: true,
                },
                toolbarViewerRight: {
                    presentationMode: true,
                    openFile: false,
                    print: true,
                    download: true,
                    viewBookmark: false,
                },
            },
        }

        toolbar: ViewerJs.Options = {
            button: true,
            navbar: true,
            title: true,
            toolbar: {
                zoomIn: true,
                zoomOut: true,
            },
            tooltip: true,
            movable: true,
            zoomable: true,
            rotatable: true,
            scalable: true,
            transition: true,
            fullscreen: true,
            keyboard: true,
        };

        get images() {
            if (!this.isImage) return [];
            return [ this.src ];
        }

        get isWord() {
            return this.fileType === 'doc' || this.fileType === 'docx';
        }

        get isExcel() {
            return this.fileType === 'xls' || this.fileType === 'xlsx';
        }

        get isImage() {
            switch (this.fileType) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                    return true;
                default:
                    return false;
            }
        }

        get isPdf() {
            return this.fileType === 'pdf';
        }
    }
