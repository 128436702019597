import { cisInstance } from './cis-instance';
import { RegisterReview } from '@/api/model/interfaces';
import { RegisterForReviewOptions } from '@/types';
import { getByFilter } from '@/types/dynamicDoc';

/** Проверяющие реестра */
export const registerReview = {
    getRegisterReviewToCheck: (options: RegisterForReviewOptions): Promise<getByFilter<RegisterReview>> =>
        cisInstance.post<getByFilter<RegisterReview>>('RegisterReview/GetRegisterReviewToCheck', options)
            .then(x => x.data),
};
