import { IBaseApi } from '@/types/cis/IBaseApi';
import * as Enums from '@/api/model/enums';

export enum ReviewerTemplateType {
    AsBuilt,
    DrawingPackages,
    KS2
}

export enum ReviewStageValue {
    RegisterCheck,
    RegisterApproval,
    RegisterExpert,
    DrawingPackageCheck,
    DrawingPackageApproval,
    KS2Check,
    KS2Approval,
}

export const reviewStageName = [
    'common.check',
    'common.approval',
    'common.expertise',
    'common.check',
    'common.approval',
    'common.check',
    'common.approval',
];

export const ReviewStageList = [
    'RegisterCheck',
    'RegisterApproval',
    'RegisterExpert',
    'DrawingPackageCheck',
    'DrawingPackageApproval',
    'KS2Check',
    'KS2Approval',
];

export function reviewStageByType(type: ReviewerTemplateType): ReviewStageValue[] {
    switch (type) {
    case ReviewerTemplateType.AsBuilt:
        return [
            ReviewStageValue.RegisterCheck,
            ReviewStageValue.RegisterApproval,
            ReviewStageValue.RegisterExpert,
        ];
    case ReviewerTemplateType.DrawingPackages:
        return [
            ReviewStageValue.DrawingPackageCheck,
            ReviewStageValue.DrawingPackageApproval,
        ];
    case ReviewerTemplateType.KS2:
        return [
            ReviewStageValue.KS2Check,
            ReviewStageValue.KS2Approval,
        ];
    }
}

export interface ReviewerTemplateItem {
    Id?: string;
    ReviewerTemplateId?: string;
    ContragentId: number;
    AppUserId: string;
}

export interface ReviewerTemplate {
    Id?: string;
    Type: ReviewerTemplateType;
    Title: string | null;
    Stage: ReviewStageValue | null;
    ReviewerTemplateItems: ReviewerTemplateItem[];
}

export interface ReviewerTemplateSidebar {
    Type: ReviewerTemplateType;
    Title: string;
}

export interface ReviewerTemplateFilter {
    Type: ReviewerTemplateType | null;
    Stage: ReviewStageValue | null;
}

export interface ReviewerNew {
    RespUserId: string | null;
    ReviewedEntityId: string | null;
    Stage: Enums.ReviewStage;
}

export type GetByTypeFunction<TEntity> = (type: ReviewerTemplateType) => Promise<TEntity[]>;
export type GetByFilterFunction<TEntity> = (filter: ReviewerTemplateFilter) => Promise<TEntity[]>;

export interface ReviewerTemplateApi<TEntity, TModel> extends IBaseApi<TEntity, TModel> {
    getByType: GetByTypeFunction<TEntity>;
    getByFilter: GetByFilterFunction<TEntity>;
}
