
    import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
    import { RouteConfig } from 'vue-router';
    import store from '@/store';
    import { AUTH_REFRESH_TOKEN } from '@/store/auth';
    import AboutNavigationItem from '@/components/home/about-navigation-Item.vue';

    @Component({
        components: { AboutNavigationItem },
    })
    export default class NavigationMenu extends Vue {
        @PropSync('miniMenu', { type: Boolean, default: () => true })
        mini!: boolean;

        routes: RouteConfig[] = [];
        refreshTokenTime = this.expiresTime;
        refreshTokenTimer: ReturnType<typeof setTimeout> | null = null

        @Watch('refreshTokenTime', { immediate: true, deep: true })
        async refreshToken(val: number) {
            if (val === 0) {
                await store.dispatch(AUTH_REFRESH_TOKEN);
                this.refreshTimer();
            }
        }

        get expiresTime(): number {
            const expire = new Date(this.$store.state.auth.expires).getTime();
            const date = new Date().getTime();
            return Math.floor((expire - date) / 1000);
        }

        initNavbar() {
            const navbarRoutes = this.$router.options.routes!
                .filter(route => route.meta && route.meta.nav)
                .sort((l, r) => l.meta?.order - r.meta?.order);
            this.routes = navbarRoutes.filter(r => !r.meta?.requiresAuth
                || this.$gates.hasAnyPermission(
                    typeof r.meta?.requirePermission === 'string'
                        ? r.meta?.requirePermission
                        : r.meta?.requirePermission.join('|'),
                ));
        }

        startTimer() {
            this.refreshTokenTimer = setInterval(() => {
                this.refreshTokenTime--;
            }, 1000);
        }

        refreshTimer() {
            clearTimeout(this.refreshTokenTimer!);
            this.refreshTokenTimer = null;
            this.refreshTokenTime = this.expiresTime;
            this.startTimer();
        }

        mounted() {
            this.startTimer();
            this.initNavbar();
        }
    }
