
    import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import VueI18n from 'vue-i18n';
    import i18n from '@/plugins/i18n';
    import './dialog-draggable';

    @Component({
        components: {
            ButtonTooltip,
        },
        template: '',
    })
    export default class CustomDialog extends Vue {
        @PropSync('modal', { type: Boolean, default: () => false })
        isVisible!: boolean;

        @Prop({ type: String, default: '600px' })
        dialogWidth!: string;

        @Prop({ type: String as () => VueI18n.TranslateResult, default: i18n.t('common.title-not-set') })
        title!: VueI18n.TranslateResult;

        @Prop({ type: Boolean, default: () => false })
        isFull!: boolean;

        @Prop({ type: Boolean, default: () => false })
        loading!: boolean;

        @Prop({ type: Boolean, default: () => false })
        isCustomSlot!: boolean;

        @Prop({ type: Boolean, default: () => false })
        showBtnIsFull!: boolean;

        fullScreen = false;

        @Watch('isFull', { immediate: true })
        onChangeIsFull() {
            this.fullScreen = this.isFull;
        }

        close() {
            this.isVisible = false;
            this.$emit('close');
        }
    }
