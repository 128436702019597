import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ProjectDocumentationState } from './types';
import { GET_DRAWING_PACKAGE, SET_DRAWING_MODULE } from './actions';
import { DrawingRootRouteName, MODULE_TO_STAGE_ID } from '@/components/drawing-docs/constants';
import { RootState } from '@/store/types';
import * as Odata from '@/api/odata';
import { DrawingPackage } from '@/api/model/interfaces';

const state: ProjectDocumentationState = {
    module: null,
    drawingPackage: [],
};

const getters: GetterTree<ProjectDocumentationState, RootState> = {
    documentationStageId: state => state.module ? MODULE_TO_STAGE_ID[state.module] : null,
};

const actions: ActionTree<ProjectDocumentationState, RootState> = {
    [GET_DRAWING_PACKAGE]: async({ commit }) => {
        commit(GET_DRAWING_PACKAGE, await Odata.DrawingPackage.getAll());
    },
};

const mutations: MutationTree<ProjectDocumentationState> = {
    [SET_DRAWING_MODULE](state, module: DrawingRootRouteName | null) {
        state.module = module;
    },
    [GET_DRAWING_PACKAGE]: (state, payload: DrawingPackage[]) => (state.drawingPackage = payload),
};

const module: Module<ProjectDocumentationState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default module;
