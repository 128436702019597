interface DialogData {
    el?: HTMLElement;
    mouseStartX: number;
    mouseStartY: number;
    elStartX: number;
    elStartY: number;
    oldTransition: string;
}

(function() {
    const dialogData = {} as DialogData;

    function handleMouseDown(e: MouseEvent): void {
        const closestDialog = (e.target as HTMLElement).closest<HTMLElement>('.v-dialog.v-dialog--active');
        if (
            e.button === 0
            && closestDialog != null
            && (e.target as HTMLElement).classList.contains('v-card__title')
        ) {
            dialogData.el = closestDialog;
            dialogData.mouseStartX = e.clientX;
            dialogData.mouseStartY = e.clientY;
            dialogData.elStartX = dialogData.el.getBoundingClientRect().left;
            dialogData.elStartY = dialogData.el.getBoundingClientRect().top;
            dialogData.el.style.position = 'fixed';
            dialogData.el.style.margin = '0';
            dialogData.oldTransition = dialogData.el.style.transition;
            dialogData.el.style.transition = 'none';
        }
    }

    function handleMouseMove(e: MouseEvent): void {
        if (dialogData.el === undefined) return;
        const elRect = dialogData.el.getBoundingClientRect();
        const newLeft = Math.min(
            Math.max(dialogData.elStartX + e.clientX - dialogData.mouseStartX, 0),
            window.innerWidth - elRect.width,
        );
        const newTop = Math.min(
            Math.max(dialogData.elStartY + e.clientY - dialogData.mouseStartY, 0),
            window.innerHeight - elRect.height,
        );
        dialogData.el.style.left = `${newLeft}px`;
        dialogData.el.style.top = `${newTop}px`;
    }

    function handleMouseUp(): void {
        if (dialogData.el === undefined) return;
        dialogData.el.style.transition = dialogData.oldTransition;
        delete dialogData.el;
    }

    function preventOutOfBounds(): void {
        const dialog = document.querySelector('.v-dialog.v-dialog--active') as HTMLElement;
        if (dialog === null) return;
        const newLeft = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width);
        const newTop = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height);
        dialog.style.left = `${newLeft}px`;
        dialog.style.top = `${newTop}px`;
    }

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    setInterval(preventOutOfBounds, 100);
})();
