
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { DynamicDocument, SignatoryUser } from '@/types/dynamicDoc';
    import { AutocompleteItem, UserResponse } from '@/types';
    import { uuidv4 } from '@/util/uuidv4';
    import { RootId } from '@/api/security';
    import { userFullNameObj } from '@/components/admin/roles-model/users/helpers';

    @Component({
        components: { ButtonTooltip },
    })
    export default class SignatoryUsers extends Vue {
        @Prop({ type: Array, default: () => [] })
        signatoryUsers!: SignatoryUser[];

        @Prop({ type: Object })
        document!: DynamicDocument;

        @Prop({ type: Boolean })
        isEdit!: boolean;

        get value() {
            if (!this.signatoryUsers) return [];
            return this.signatoryUsers.map(x => x.UserId);
        }

        get employees(): AutocompleteItem[] {
            if (!this.$store.state.reftables.Employees) return [];
            return this.$store.state.reftables.Employees
                .filter((x: UserResponse) => x.guidId !== RootId)
                .map((x: UserResponse) => ({ text: userFullNameObj(x), value: x.id }));
        }

        onChange(items: number[]) {
            this.$emit('success', items.map(UserId => ({
                Id: uuidv4(),
                DocumentId: this.document.Id,
                UserId,
            })));
        }
    }
