import './registerServiceWorker';
import Vue from 'vue';
import VueRx from 'vue-rx';
import vuetify from '@/plugins/vuetify';
import '@/styles/main.scss';
import 'uno.css';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { onAppInit } from '@/auth/helper';
import DefaultLayout from '@/layouts/default.vue';
import BlankLayout from '@/layouts/blank.vue';
import store from './store';
import router from './router';
import i18n from '@/plugins/i18n';
import App from './App.vue';
import VueApexCharts from 'vue-apexcharts';
import '@/plugins/vue-gates.ts';
import VTiptap from '@peepi/vuetify-tiptap';
import VueCodemirror from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/sql/sql.js';

Vue.component('default-layout', DefaultLayout);
Vue.component('blank-layout', BlankLayout);
Vue.component('ApexChart', VueApexCharts);

Vue.use(VueApexCharts);
Vue.use(VueRx, { Observable, Subscription, Subject });
Vue.use(VTiptap);
Vue.use(VueCodemirror);

onAppInit().then(() => {
    Vue.config.productionTip = false;

    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: h => h(App),
    }).$mount('#app');
});
