import { apiDocumentServiceUrl, dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import {
    AnalysisResult,
    DocumentShort,
    DynamicDocument,
    DynamicDocumentFilterRequest,
    DynamicDocumentListItem,
    DynamicResponse,
    getByFilter,
    Options,
    SearchResult,
} from '@/types/dynamicDoc';
import { AxiosRequestConfig } from 'axios';

/** Динамический документ */
export const document = {
    getPage: (page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DynamicDocumentListItem>>(`/DynamicDocument/page?page=${page}&pageSize=${pageSize}`).then(x => x.data),

    getPageForRegister: (options: Options) =>
        dynamicDocInstance.post<getByFilter<DynamicDocumentListItem>>('/DynamicDocument/PageForRegister', options)
            .then(x => x.data),

    getByFilter: (options: Options) =>
        dynamicDocInstance.post<getByFilter<DynamicDocumentListItem>>('/DynamicDocument/getByFilter', options)
            .then(x => x.data),

    getCategoryPage: (id: string, page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DynamicDocumentListItem>>(
            `/DynamicDocument/ByCategoryPage?categoryId=${id}&page=${page}&pageSize=${pageSize}`)
            .then(x => x.data),

    getById: (key: string) =>
        dynamicDocInstance.get<DynamicDocument>(`/DynamicDocument/${key}`).then(x => x.data),

    getByIdRange: (ids: string[]) =>
        dynamicDocInstance.post<DynamicDocument[]>('/DynamicDocument/GetByIdRange', ids).then(x => x.data),

    getByDocumentSchema: (documentSchemaId: string): Promise<DynamicDocument[]> =>
        dynamicDocInstance.get<DynamicDocument[]>(`/DynamicDocument/GetByDocumentSchema?documentSchemaId=${documentSchemaId}`).then(x => x.data),

    getByDocumentSchemaIdRange: (documentSchemaIds: string[]): Promise<DynamicDocument[]> =>
        dynamicDocInstance.post<DynamicDocument[]>('/DynamicDocument/GetByDocumentSchemaIdRange', documentSchemaIds).then(x => x.data),

    download: (key: string): Promise<Blob> =>
        dynamicDocInstance.get(`${apiDocumentServiceUrl}api/DynamicDocument/Download?id=${key}`, { responseType: 'blob' }).then(x => x.data),

    exportDocumentsToZip: (ids: string[]): Promise<Blob> =>
        dynamicDocInstance.post(`${apiDocumentServiceUrl}api/DynamicDocument/ExportDocumentsToZip/`, ids, { responseType: 'blob' }).then(x => x.data),

    update: (key: string, document: DynamicDocument) =>
        dynamicDocInstance.put(`/DynamicDocument/${key}`, document).then(x => x.data),

    updatePhysicalVolumeOnly: (key: string, document: DynamicDocument) =>
        dynamicDocInstance.put(`/DynamicDocument/UpdatePhysicalVolumeOnly/${key}`, document).then(x => x.data),

    create: (document: DynamicDocument) =>
        dynamicDocInstance.post('/DynamicDocument', document).then(x => x.data),

    remove: (id: string) =>
        dynamicDocInstance.delete(`/DynamicDocument/${id}`).then(x => x.data),

    createFile: (id: string) =>
        dynamicDocInstance.post(`/DynamicDocument/CreateFile?id=${id}`).then(x => x.data),

    checkDynamicDocumentsInRegister: (ids: string[]) =>
        dynamicDocInstance.post<AnalysisResult[]>('/DynamicDocument/CheckDynamicDocumentsInRegister', ids).then(x => x.data),

    upload: (id: string, file: File, signature?: File | null, config?: AxiosRequestConfig): Promise<DynamicDocument> => {
        const bodyFormData = new FormData();
        bodyFormData.append('File', file);
        if (signature) bodyFormData.append('Signature', signature);
        return dynamicDocInstance.post<DynamicDocument>(`DynamicDocument/Upload?id=${id}`, bodyFormData, config).then(x => x.data);
    },

    uploadSignatures: (id: string, signature: File): Promise<DynamicDocument> => {
        const bodyFormData = new FormData();
        bodyFormData.append('Signature', signature);
        return dynamicDocInstance.post<DynamicDocument>(`/DynamicDocument/UploadSignatures?id=${id}`, bodyFormData).then(x => x.data);
    },

    getDocumentGroupByFilter: (filter: DynamicDocumentFilterRequest): Promise<getByFilter<DocumentShort>> =>
        dynamicDocInstance.post<getByFilter<DocumentShort>>('/DynamicDocument/GetDocumentGroupByFilter', filter).then(x => x.data),

    helperSearchDocument: (search: string): Promise<SearchResult[]> =>
        dynamicDocInstance.get<SearchResult[]>(`/DynamicDocument/HelperSearchDocument/${search}`).then(x => x.data),

    searchDocuments: (search: string): Promise<DynamicDocumentListItem[]> =>
        dynamicDocInstance.get<DynamicDocumentListItem[]>(`/DynamicDocument/SearchDocuments/${search}`).then(x => x.data),

    downloadAllToZip: (id: string) =>
        dynamicDocInstance.get(`/DynamicDocument/DownloadAllToZip?id=${id}`, { responseType: 'blob' }).then(x => x.data),

    downloadToQrcodeAndStamp: (id: string) =>
        dynamicDocInstance.get(`/DynamicDocument/DownloadToQrcodeAndStamp?id=${id}`, { responseType: 'blob' }).then(x => x.data),

    documentsByHelperSearch: (data: SearchResult): Promise<DynamicDocumentListItem[]> =>
        dynamicDocInstance.post<DynamicDocumentListItem[]>('/DynamicDocument/DocumentsByHelperSearch', data).then(x => x.data),

    getDocumentShortsByIds: (data: string[]): Promise<DocumentShort[]> =>
        dynamicDocInstance.post<DocumentShort[]>('/DynamicDocument/GetDocumentShortsByIds', data).then(x => x.data),

    getExternalDocuments: (id: string): Promise<DynamicDocument[]> =>
        dynamicDocInstance.get<DynamicDocument[]>(`/DynamicDocument/GetExternalDocuments/${id}`).then(x => x.data),

    getDocumentsWithSignatoryUsersByRegister: (registerId: string): Promise<DynamicDocument[]> =>
        dynamicDocInstance.get<DynamicDocument[]>(`/DynamicDocument/GetDocumentsWithSignatoryUsersByRegister/${registerId}`).then(x => x.data),

    getErrorDocumentIdsByRangeIds: (ids: string[]): Promise<string[]> =>
        dynamicDocInstance.post<string[]>('/DynamicDocument/GetErrorDocumentIdsByRangeIds', ids).then(x => x.data),

    getFileName: (id: string): Promise<string> =>
        dynamicDocInstance.get<string>(`/DynamicDocument/GetFileName/${id}`).then(x => x.data),

    updateRelatedDocument: (key: string, fieldTemplateCode: string, relatedDocumentId?: string) => {
        const model = {
            TemplateCode: fieldTemplateCode,
            RelatedDocumentId: relatedDocumentId,
        };
        return dynamicDocInstance.post(`/DynamicDocument/UpdateRelatedDocument/${key}`, model).then(x => x.data);
    },
};
