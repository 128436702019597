import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { DynamicDocCategoryApi, DynamicJournalCategory, DynamicResponse, getByFilter, JournalCategoryFilter } from '@/types/dynamicDoc';

/** Категории динамических журналов */
export const journalCategory: DynamicDocCategoryApi<DynamicJournalCategory, DynamicJournalCategory> = {
    getAll: (): Promise<DynamicJournalCategory[]> =>
        dynamicDocInstance.get<DynamicJournalCategory[]>('JournalCategory').then(x => x.data),

    getByFilter: (options: JournalCategoryFilter) =>
        dynamicDocInstance.post<getByFilter<DynamicJournalCategory>>('/JournalCategory/getByFilter', options)
            .then(x => x.data),

    getPage: (page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<DynamicJournalCategory>>(`JournalCategory/Page?page=${page}&pageSize=${pageSize}`).then(x => x.data),

    getById: (id: string): Promise<DynamicJournalCategory> =>
        dynamicDocInstance.get<DynamicJournalCategory>(`JournalCategory/${id}`).then(x => x.data),

    create: (data: DynamicJournalCategory): Promise<DynamicJournalCategory> =>
        dynamicDocInstance.post<DynamicJournalCategory>('JournalCategory', data).then(x => x.data),

    update: (id: string, data: DynamicJournalCategory): Promise<DynamicJournalCategory> =>
        dynamicDocInstance.put<DynamicJournalCategory>(`JournalCategory/${id}`, data).then(x => x.data),

    remove: (id: string): Promise<void> =>
        dynamicDocInstance.delete(`JournalCategory/${id}`).then(x => x.data),

    search: (search: string) =>
        dynamicDocInstance.get<DynamicJournalCategory[]>(`/JournalCategory/Search?search=${search}`)
            .then(x => x.data),
};
