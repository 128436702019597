
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { RegisterStatus } from '@/api/model/interfaces';
    import { RegisterStatus as RegisterStatusEnum } from '@/api/constants';
    import { RegisterGroupByStatusResponse } from '@/types/cis/widget';
    import * as Odata from '@/api/odata';
    import { colorStatus } from '@/api/dashboard/types';
    import { ApexOptions } from 'apexcharts';
    import { Employee } from '@/types';
    import * as api from '@/api/auth';
    import { registerStatuses } from '../asbuilt-docs/registers/helpers';

    @Component({
        components: {},
    })
    export default class ChartRegisterStatus extends Vue {
        @Prop({ type: Array, required: true })
        items!: RegisterGroupByStatusResponse[];

        registerStatus: RegisterStatus[] = [];
        chartOptions: ApexOptions = {
            chart: {
                type: 'bar',
                height: 170,
                stacked: true,
                stackType: '100%',
                toolbar: {
                    show: false,
                },
                events: {
                    click: (e: any, chart?: any, options?: any) => {
                        this.goToRegister(options.seriesIndex);
                    },
                },
            },
            dataLabels: {
                formatter: (val, opts) => {
                    const length = opts.config.series[opts.seriesIndex]?.data[0];
                    return `${length} (${Math.round(parseFloat(val as string))}%)`;
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 1,
                colors: [ '#fff' ],
            },
            xaxis: {
                categories: [ 'ИД' ],
            },
            tooltip: {
                y: {
                    formatter: (val: number) => {
                        return this.$tc('chart.n-registers', val);
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'left',
                offsetX: 40,
            },
        };

        statusId = '';
        employees: Employee[] = [];

        get widgetData() {
            return this.items.map(item => ({
                id: item.StatusId,
                name: registerStatuses[item.StatusId].title,
                data: [ item.Registers.length ],
                color: colorStatus[item.StatusId as RegisterStatusEnum],
            }));
        }

        get user() {
            return this.$store.state.auth.user;
        }

        get visibleUserSelector() {
            return this.user.isAdmin || this.user.isChief;
        }

        goToRegister(index: number) {
            this.statusId = this.widgetData[index]?.id;
            if (!this.statusId) return;
            this.$router.push({
                name: 'registers',
                params: {
                    statusId: this.statusId,
                },
            });
        }

        async mounted() {
            this.registerStatus = await Odata.RegisterStatus.getAll();
            await this.getEmployees();
        }

        async getEmployees() {
            if (this.user.isAdmin) {
                this.employees = this.$store.state.Employees;
            } else if (this.user.isChief) {
                this.employees = await api.user.getEmployeesByContragent(this.user.contragentId);
            }
        }
    }
