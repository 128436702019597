
    import { Component, Prop } from 'vue-property-decorator';
    import { Notification, PackageKS2 } from '@/api/model/interfaces';
    import * as Odata from '@/api/odata';
    import NotificationItem from '@/components/common/notification-item.vue';

    @Component
    export default class Ks2HasIssues extends NotificationItem {
        @Prop({ required: true })
        notification!: Notification;

        register: PackageKS2 | null = null;

        async mounted() {
            this.register = await Odata.PackageKS2.setParams({}).getById(this.notification.EntityId.toString());
            this.link = this.register ? `/ks2/packages/${this.register.Id}` : null;
        }
    }
