
    import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { DataOptions } from 'vuetify';
    import { localizeDescription } from '@/util/field-translation';
    import { DocumentRegisterStatus, DynamicDocument, DynamicDocumentListItem, Options } from '@/types/dynamicDoc';
    import { Register, RegisterStatus } from '@/api/model/interfaces';
    import { RightNameConstants } from '@/types/right-name-constants';
    import * as api from '@/api/dynamic';
    import * as Odata from '@/api/odata';
    import More from '@/components/permit/more.vue';
    import MenuFilter from '@/components/reports/menu-filter.vue';
    import CustomDialog from '@/components/dynamic/common/custom-dialog.vue';
    import { AutocompleteItem, DataTableHeaderCustom, MenuFilterType, UserResponse } from '@/types';
    import { GET_ALL_REGISTER_STATUS } from '@/store/document/actions';
    import { userFullNameObj } from '@/components/admin/roles-model/users/helpers';

    @Component({
        components: {
            ButtonTooltip,
            More,
            MenuFilter,
            CustomDialog,
            Document: () => import('@/components/documents-journals/documents/document.vue'),
        },
    })
    export default class DocumentListDialog extends Vue {
        @PropSync('visible', { type: Boolean, required: true })
        visibleDialog!: boolean;

        documents: DynamicDocumentListItem[] = [];
        registers: Register[] = [];
        selected: DynamicDocumentListItem[] = [];
        timer: any = null;
        loadPage = false;
        loadTable = false;
        isVisibleDocumentDialog = false;
        documentId: string | null = null;
        totalDocuments = 0;
        options = {
            Search: {},
            SearchDocumentData: {},
            DrawingPackageId: null,
            WorkTypeId: null,
            SearchResult: null,
            SearchMainStr: null,
            DateRange: {
                start: null,
                end: null,
            },
        } as Options;

        optionsTable = {
            page: 1,
            itemsPerPage: 20,
        } as DataOptions;

        @Watch('visibleDialog')
        async onVisible() {
            if (!this.visibleDialog) return;
            this.loadPage = true;
            await this.$store.dispatch(`document/${GET_ALL_REGISTER_STATUS}`);
            await this.getDocuments();
            this.loadPage = false;
        }

        @Watch('optionsTable')
        async changedOptionsTable() {
            if (!this.optionsTable) return;
            await this.getDocuments();
        }

        get tableHeaders(): DataTableHeaderCustom[] {
            return [
                {
                    text: this.$t('common.document-number') as string,
                    value: 'Number',
                    filterVisible: true,
                    filterType: MenuFilterType.String,
                    filterIsSearch: true,
                },
                {
                    text: this.$t('common.document-outline') as string,
                    value: 'Type',
                    filterVisible: true,
                    filterType: MenuFilterType.String,
                    filterIsSearch: true,
                },
                {
                    text: this.$t('common.register') as string,
                    value: 'RegisterStatus',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.registerStatus,
                    filterIsSearch: true,
                },
                {
                    text: this.$t('common.author') as string,
                    value: 'UserId',
                    filterVisible: true,
                    filterType: MenuFilterType.SelectEnumString,
                    filterItems: this.users,
                },
                {
                    text: this.$t('common.document-date') as string,
                    value: 'DateRange',
                    filterVisible: true,
                    filterType: MenuFilterType.Date,
                },
            ];
        }

        get registerStatus(): AutocompleteItem[] {
            const status = this.$store.state.reftables.RegisterStatus.map((x: RegisterStatus) => ({
                text: localizeDescription(x),
                value: x.Id.toString(),
            }));
            status.unshift({
                text: this.$t('common.document-not-register') as string,
                value: DocumentRegisterStatus.NotRegister,
            });
            return status;
        }

        get disabledBtn() {
            return !this.selected.length;
        }

        get selectedIds() {
            return this.selected.map(x => x.Id);
        }

        get document(): DynamicDocument | null {
            return this.$store.state.document.document;
        }

        get items() {
            return this.documents
                .filter(x => x.Id !== this.document?.Id)
                .map(x => {
                    const item = this.registers.find(y => y.Id.toString() === x.RegisterId);
                    const status = this.registerStatus.find(y => y.value === item?.StatusId.toString());
                    const user = this.users.find(user => user.value === x.UserId);

                    return {
                        ...x,
                        Number: `${x.Type} №${x.Number}`,
                        Register: item ? `${this.$t('common.register')} ${item!.Code}` : '',
                        RegisterStatus: status ? status.text : '',
                        Date: new Date(x.Date).toLocaleDateString(),
                        StartDate: x.StartDate ? new Date(x.StartDate).toLocaleDateString() : null,
                        EndDate: x.EndDate ? new Date(x.EndDate).toLocaleDateString() : null,
                        UserId: user ? user.text : '',
                    };
                });
        }

        get users(): AutocompleteItem[] {
            if (!this.$store.state.reftables?.Users) return [];
            return this.$store.state.reftables.Users
                .map((x: UserResponse) => ({
                    value: x.id,
                    text: userFullNameObj(x),
                }));
        }

        deleteEmptyValue(item: any) {
            for (const key in item) {
                if (!item[key]?.toString()) delete item[key];
            }
        }

        menuFilterHandler(item: { value: any, header: DataTableHeaderCustom }) {
            if (item.header?.filterIsSearch) {
                this.options.Search[item.header?.value as keyof Options] = item.value;
                this.deleteEmptyValue(this.options.Search);
                this.searchTimeRequest();
            } else {
                this.options[item.header?.value as keyof Options] = item.value;
                this.searchTimeRequest();
            }
        }

        searchTimeRequest() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async() => {
                await this.getDocuments();
            }, 2000);
        }

        async getDocuments() {
            if (!this.$gates.hasPermission(RightNameConstants.DocumentsAndJournalsModule)) return;
            this.loadTable = true;
            const res = await api.document
                .getByFilter({
                    PageNumber: this.optionsTable.page,
                    PageSize: this.optionsTable.itemsPerPage,
                    ...this.options,
                } as Options);
            this.documents = res.Items;
            this.totalDocuments = res.TotalItems;
            await this.getRegisters();
            this.loadTable = false;
        }

        async getRegisters() {
            const ids = this.documents.map(x => x.RegisterId).filter(x => !!x);
            if (!ids.length) return;
            this.registers = await Odata.Register
                .runAction('GetRegistersByIdRange', null, { ids })
                .then(x => x.value);
        }

        openCreateDocument() {
            this.documentId = null;
            this.isVisibleDocumentDialog = true;
        }

        addDocument() {
            if (!this.selected.length) return;
            this.close();
            this.$emit('selected', this.selectedIds);
        }

        close() {
            this.visibleDialog = false;
        }
    }
