import { Module, MutationTree } from 'vuex';
import { AppBarMenuState, AppBarMenu } from './types';
import { RootState } from '@/store/types';

export const UPDATE_MENU = 'UPDATE_MENU';
export const CLEAR_MENU = 'CLEAR_MENU';

const state: AppBarMenuState = {
    appBarMenu: [],
};

const mutations: MutationTree<AppBarMenuState> = {
    [UPDATE_MENU]: (state, data: AppBarMenu[]) => {
        state.appBarMenu = data.map(x => ({
            ...x,
            isVisible: x.isVisible ?? true,
        }));
    },
    [CLEAR_MENU]: (state) => {
        state.appBarMenu = [];
    },
};

const topMenu: Module<AppBarMenuState, RootState> = {
    state,
    mutations,
};

export default topMenu;
