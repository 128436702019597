import { cisInstance } from './cis-instance';

/** Api формулы сущности */
export const entityFormula = {
    getRegisterFormulaProperties: (): Promise<{ [key: string]: string[] }> =>
        cisInstance.get<{ [key: string]: string[] }>('/EntityFormula/GetRegisterFormulaProperties').then(x => x.data),

    registerFormulaIsValid: (formula: string): Promise<string | null> =>
        cisInstance.get<string | null>(`/EntityFormula/RegisterFormulaIsValid?formula=${formula}`).then(x => x.data),

    getRegisterDocumentArchiveNameProperties: (): Promise<{ [key: string]: string[] }> =>
        cisInstance.get<{ [key: string]: string[] }>('/EntityFormula/GetRegisterDocumentArchiveNameProperties').then(x => x.data),

    registerDocumentArchiveNameFormulaIsValid: (formula: string): Promise<string | null> =>
        cisInstance.get<string | null>(`/EntityFormula/RegisterDocumentArchiveNameFormulaIsValid?formula=${formula}`).then(x => x.data),
};
