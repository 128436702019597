
    import Vue, { PropType } from 'vue';
    import ReftableItemSelector from './editors/reftable-item-selector.vue';
    import MultiplePrivitiveInput from './editors/multiple-privitive-input.vue';
    import DocumentTypeSelector from './editors/document-type-selector.vue';
    import { DynamicDocumentSchema, DynamicEditorOptions, DynamicJournalPartition, DynamicJournalSchemaPartitions, FieldType, IField, TypeControl, TypeFieldEditor } from '@/types/dynamicDoc';
    import PhysicalVolumeItems from '@/components/dynamic/common/editors/physical-volume-items.vue';
    import DatePickerInput from '@/components/admin/roles-model/users/date-picker-input.vue';
    import TextInput from '@/components/dynamic/common/editors/text-input.vue';
    import NumberInput from '@/components/dynamic/common/editors/number-input.vue';
    import BooleanInput from '@/components/dynamic/common/editors/boolean-input.vue';
    import MultiReftableItemSelector from '@/components/dynamic/common/editors/multi-reftable-item-selector.vue';
    import PermitDocumentSelector from '@/components/dynamic/common/editors/permit-document-selector.vue';
    import JournalTableItems from '@/components/dynamic/common/editors/journal-table-items.vue';
    import DocumentTableItems from '@/components/dynamic/common/editors/document-table-items.vue';
    import SearchTextInput from './editors/search-text-input.vue';
    import SearchContragentInput from './editors/search-contragent-input.vue';

    const resolveEditorForSingleField = (field: IField, type: TypeFieldEditor) => {
        switch (field.Type) {
            case FieldType.String:
                if (!field.IsControl) return TextInput;
                switch (field.TypeControl) {
                    case TypeControl.Nostroy:
                    case TypeControl.Naks:
                    case TypeControl.SroNopriz:
                    case TypeControl.WeldingMaterial:
                    case TypeControl.MemberSroNopriz:
                    case TypeControl.WeldingEquipment:
                    case TypeControl.CertifiedWeldingOrganization:
                    case TypeControl.SpecialistNopriz: return SearchTextInput;
                    case TypeControl.CisContragent: return SearchContragentInput;
                    default: return TextInput;
                }

            case FieldType.Boolean:
                return BooleanInput;
            case FieldType.Integer:
                return NumberInput;
            case FieldType.Document:
                return DocumentTypeSelector;

            case FieldType.Date:
                return DatePickerInput;
            case FieldType.Reftable:
                return ReftableItemSelector;
            case FieldType.Table:
                return type === 'Journal'
                    ? JournalTableItems
                    : DocumentTableItems;
            case FieldType.PhysicalVolume:
                return PhysicalVolumeItems;
            case FieldType.MultiReftable:
                return MultiReftableItemSelector;
            case FieldType.PermitDocument:
                return PermitDocumentSelector;
        }
    };

    const resolveEditorForMultipleField = (field: IField, type: TypeFieldEditor) => {
        switch (field.Type) {
            case FieldType.String:
            case FieldType.Integer:
            case FieldType.Date:
            case FieldType.Boolean:
                return MultiplePrivitiveInput;
            case FieldType.Document:
                return DocumentTypeSelector;

            case FieldType.Reftable:
                return ReftableItemSelector;
            case FieldType.Table:
                return type === TypeFieldEditor.Journal
                    ? JournalTableItems
                    : DocumentTableItems;
            case FieldType.PhysicalVolume:
                return PhysicalVolumeItems;
            case FieldType.MultiReftable:
                return MultiReftableItemSelector;
            case FieldType.PermitDocument:
                return PermitDocumentSelector;
        }
    };

    export default Vue.extend({
        name: 'DynamicFieldEditor',
        functional: true,
        props: {
            field: { type: Object as PropType<IField>, required: true },
            schemaPart: { type: Object as PropType<DynamicJournalSchemaPartitions>, default: null },
            part: { type: Object as PropType<DynamicJournalPartition>, default: null },
            value: { required: false },
            readonly: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
            validate: { type: Boolean, default: true },
            required: { type: Boolean, default: true },
            drawingPackageIds: { type: Array, default: () => [] },
            documentDate: { type: Number, default: null },
            documentSchema: { type: Object as PropType<DynamicDocumentSchema>, default: null },
            options: {
                type: Object as PropType<DynamicEditorOptions>,
                default: () => ({
                    isCreated: false,
                    type: TypeFieldEditor.Document,
                    data: {},
                } as DynamicEditorOptions),
            },
        },
        render(h, ctx) {
            const {
                field,
                value,
                readonly,
                disabled,
                validate,
                drawingPackageIds,
                required,
                documentDate,
                documentSchema,
                options,
                part,
                schemaPart,
            } = ctx.props;

            return h(
                !field.IsMultiple
                    ? resolveEditorForSingleField(field, options.type)
                    : resolveEditorForMultipleField(field, options.type),
                {
                    props: {
                        field: field,
                        label: field.Title,
                        value,
                        schemaId: field.ReftableSchemaId,
                        isMultiple: field.IsMultiple,
                        formula: field.FormulaSmall,
                        formulaFull: field.Formula,
                        readonly,
                        disabled,
                        validate,
                        ...ctx.data.attrs,
                        drawingPackageIds,
                        required,
                        documentDate,
                        documentSchema,
                        part,
                        schemaPart,
                        options,
                    },
                    on: ctx.listeners,
                },
            );
        },
    });
