import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { SubObjectNew, SubObject } from '@/types/dynamicDoc';

const controllerName = 'SubObject';
export const subObject = {
    getById: (id: string): Promise<SubObject> =>
        dynamicDocInstance.get<SubObject>(`${controllerName}/${id}`).then(x => x.data),

    getByDrawingPackage: (id: string): Promise<SubObject[]> =>
        dynamicDocInstance.get<SubObject[]>(`${controllerName}/GetByDrawingPackage/${id}`).then(x => x.data),

    getByDrawingPackageRange: (ids: string[]): Promise<SubObject[]> =>
        dynamicDocInstance.post<SubObject[]>(`${controllerName}/GetByDrawingPackageRange`, ids).then(x => x.data),

    create: (data: SubObjectNew): Promise<SubObject> =>
        dynamicDocInstance.post<SubObject>(controllerName, data).then(x => x.data),

    update: (id: string, data: SubObject): Promise<SubObject> =>
        dynamicDocInstance.put<SubObject>(`${controllerName}/${id}`, data).then(x => x.data),

    remove: (id: string): Promise<void> =>
        dynamicDocInstance.delete(`${controllerName}/${id}`).then(x => x.data),

    upload: (drawingPackageId: string, file: File): Promise<boolean> => {
        const bodyFormData = new FormData();
        bodyFormData.append('File', file);
        return dynamicDocInstance
            .post<boolean>(`${controllerName}/Upload?drawingPackageId=${drawingPackageId}`, bodyFormData)
            .then(x => x.data);
    },
};
