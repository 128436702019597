
    import { TranslateResult } from 'vue-i18n';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    export interface MenuItem {
        title: string | TranslateResult,
        icon: string,
        subTitle?: string | null | TranslateResult,
        event: () => void | Promise<void>,
        permission?: string,
        visible: boolean,
        disabled: boolean,
    }

    @Component({})
    export default class VerticalMenu extends Vue {
        @Prop({ type: String, required: false })
        readonly value!: string;

        @Prop({ type: Array, required: false })
        readonly items!: MenuItem[];

        get hasVisibleItems(): boolean {
            return !!this.items.filter((v: MenuItem) => v.visible).length;
        }
    }
