import { cisInstance } from './cis-instance';
import { WorkType, WorkTypeApi, WorkTypeOptions } from '@/types';
import { getByFilter } from '@/types/dynamicDoc';

/** Наименование работ */
export const workType: WorkTypeApi<WorkType, WorkType> = {
    getAll: (): Promise<WorkType[]> =>
        cisInstance.get<WorkType[]>('WorkTypeApi').then(x => x.data),

    getById: (id: string): Promise<WorkType> =>
        cisInstance.get<WorkType>(`WorkTypeApi/${id}`).then(x => x.data),

    create: (data: WorkType): Promise<WorkType> =>
        cisInstance.post<WorkType>('WorkTypeApi', data).then(x => x.data),

    getByFilter: (data: WorkTypeOptions): Promise<getByFilter<WorkType>> =>
        cisInstance.post<getByFilter<WorkType>>('WorkTypeApi/GetInPagination', data).then(x => x.data),

    update: (id: string, data: WorkType): Promise<WorkType> =>
        cisInstance.put<WorkType>(`WorkTypeApi/${id}`, data).then(x => x.data),

    remove: (id: string): Promise<void> =>
        cisInstance.delete(`WorkTypeApi/${id}`).then(x => x.data),
};
