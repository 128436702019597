export enum Volume {
    Project = 'Project',
    Actual = 'Actual',
    Document = 'Document',
    Defect = 'Defect',
    Progress = 'Progress',
}

interface ConfigDataStore {
    paginate: boolean;
    ru: boolean;
    en: boolean;
    searchExpr: string[];
    select: string[];
}

export interface VolumeSetting {
    type: Volume;
    actionName: string;
    title: string;
}

export interface VolumeTypeDesc {
    type: number;
    title: string
}

export interface ChartItem {
    ActualVolume: number;
    ArchivedFromProjectPercent: number;
    ArchivedVolume: number;
    CheckFromProjectPercent: number;
    CheckVolume: number;
    DiffActualAndDocumentVolume: number;
    WorkNotCompletedDocumentationVolume: number;
    ProjectWithoutActualVolume: number;
    DocumentFromProjectPercent: number;
    DocumentVolume: number;
    DraftFromProjectPercent: number;
    DraftVolume: number;
    ForApprovalFromProjectPercent: number;
    ForApprovalVolume: number;
    NotApprovedFromProjectPercent: number;
    WaitingCompletionVolume: number;
    WaitingCompletionVolumePercent: number;
    ProjectVolume: number;
    ResolvingIssuesFromProjectPercent: number;
    ResolvingIssuesVolume: number;
    SecondaryCheckFromProjectPercent: number;
    SecondaryCheckVolume: number;
    SigningFromProjectPercent: number;
    SigningVolume: number;
    WorkTypeName: string;
}

export interface ChartDataSource {
    title: string;
    data: ChartItem[];
}

export interface ActualPhysicalVolume {
    Id: string;
    DrawingPackageId: string;
    DrawingPackageCode: number;
    DrawingPackageRevision: string;
    DrawingPackageMainCode: string;
    Type: number;
    ContractId: string;
    WorkTypeId: string;
    MeasurementUnitId: string;
    KfoMeasurementUnitId: string;
    TopKfoMeasurementUnitId: string;
    ProjectValue: number;
    ActualValue: number;
    DocumentValue: number;
    DocumentKfoValue: number;
    DocumentTopKfoValue: number;
}

export interface PhysicalVolumeHistoryOptions {
    PageNumber?: number;
    PageSize?: number;
    OrderBy?: string;
    IsDesc?: boolean;
    Search?: any;
    Id?: string;
    OldValue?: number;
    NewValue?: number;
    StartDate?: string;
    EndDate?: string;
    CreateAt?: string;
    PhysicalVolumeId?: string;
    AppUserId?: string;
}
