
    import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator';
    import CustomDialog from '@/components/dynamic/common/custom-dialog.vue';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import MenuFilter from '@/components/reports/menu-filter.vue';
    import TableItemPopup from '@/components/dynamic/common/editors/table-item-popup.vue';
    import ImportCsvDialog from '@/components/dynamic/common/editors/import-csv-dialog.vue';
    import {
        DynamicEditorOptions,
        DynamicJournalPartitions,
        DynamicJournalSchemaPartitions,
        DynamicReftableItem,
        FieldType,
        IField,
        RowTable,
        Timer,
    } from '@/types/dynamicDoc';
    import { DataOptions, DataTableHeader } from 'vuetify';
    import { DataTableHeaderCustom, MenuFilterType } from '@/types';
    import { exportCSV } from '@/components/dynamic/common/editors/table-items-helper';
    import * as api from '@/api/dynamic';
    import Confirm, { ConfirmType } from '@/components/common/confirm.vue';
    import VerticalMenu, { MenuItem } from '@/components/common/v-menu.vue';
    import JournalTableRenumberDialog from './journal-table-renumber-dialog.vue';

    @Component({
        components: {
            CustomDialog,
            ButtonTooltip,
            MenuFilter,
            TableItemPopup,
            ImportCsvDialog,
            Confirm,
            VerticalMenu,
            JournalTableRenumberDialog,
        },
        methods: {
            exportCSV,
        },
    })
    export default class JournalTableItemsDialog extends Vue {
        @Ref() readonly confirm?: Confirm;
        @PropSync('modal', { type: Boolean, default: () => false })
        isVisible!: boolean;

        @Prop({ type: String, required: true }) title!: string;
        @Prop({ type: Object, required: true }) field!: IField;
        @Prop({ type: Object, required: true }) part!: DynamicJournalPartitions;
        @Prop({ type: Object, required: true }) schemaPart!: DynamicJournalSchemaPartitions;
        @Prop({ type: Object, required: true }) reftables!: Record<string, DynamicReftableItem[]>;
        @Prop({ type: Boolean, default: false }) readonly!: boolean;
        @Prop({ type: Object }) options?: DynamicEditorOptions;

        tableItemPopupVisible = false;
        tableRenumberDialogVisible = false;
        importCsvDialogVisible = false;
        loading = false;
        totalItems = 0;
        timer: Timer = null;
        table: Record<string, string|number>[] = [];
        tableItem: Record<string, string|number> | null = null;
        search: Record<string, string> = {};
        optionsTable = {
            page: 1,
            itemsPerPage: 25,
        } as DataOptions;

        get tableHeaders(): DataTableHeaderCustom[] {
            if (!this.field.TableFields) return [];
            const header = this.field.TableFields.map(x => ({
                text: x.Title,
                value: x.Title,
                filterVisible: true,
                filterType: this.filterType(x),
                filterItems: this.filterItems(x),
                filterIsSearch: true,
            })) as DataTableHeader[];
            header.push({ text: '', value: 'actions', width: '6em', sortable: false });
            return header;
        }

        filterType(field: IField) {
            switch (field.Type) {
                case FieldType.String:
                    return MenuFilterType.String;
                case FieldType.Reftable:
                    return MenuFilterType.SelectEnumString;
                case FieldType.Date:
                    return MenuFilterType.DateSingle;
                case FieldType.Integer:
                    return MenuFilterType.Number;
            }
        }

        filterItems(field: IField) {
            if (field.Type !== FieldType.Reftable) return [];
            return this.reftables[field.ReftableSchemaId as string]?.map(x => ({
                text: x.Data?.$Display,
                value: x.Id,
            }));
        }

        get headerText(): string[] {
            return this.tableHeaders
                .filter(x => x.text)
                .map(x => x.text);
        }

        get headerTextTrim() {
            return this.headerText.map(x =>
                x.replace(/\s+/g, ' ')
                    .replace(/\n+/g, '\n')
                    .trim());
        }

        get items() {
            const arr: Record<string, string | number>[] = [];
            this.table.map(x => ({ ...x }))
                .forEach(item => {
                    Object.keys(item).forEach(key => {
                        const tabField = this.field.TableFields.find(i => i.Title === key);
                        if (tabField && tabField.Type === FieldType.Reftable && tabField.ReftableSchemaId) {
                            const reftable = this.reftables[tabField.ReftableSchemaId as string];
                            const value = reftable?.find(x => x.Id === item[key]);
                            item[key] = value?.Data?.$Display;
                        }
                    });
                    arr.push(this.checkDate(item));
                });
            return arr;
        }

        get requestParams(): RowTable {
            return {
                JournalPartitionId: this.schemaPart.Id,
                DynamicJournalId: this.part.DynamicJournalId,
                DynamicJournalPartitionId: this.part.Id,
                TableTemplateCode: this.field.TemplateCode as string,
            };
        }

        get topMenu(): MenuItem[] {
            return [
                {
                    title: this.$t('common.template'),
                    icon: 'mdi-file-export-outline',
                    event: () => exportCSV(this.headerTextTrim, this.title),
                    visible: true,
                    disabled: false,
                },
                {
                    title: this.$t('common.import-csv'),
                    icon: 'mdi-file-import-outline',
                    event: () => { this.importCsvDialogVisible = true; },
                    visible: true,
                    disabled: false,
                },
                {
                    title: this.$t('common.renumber-column'),
                    icon: 'mdi-numeric',
                    event: () => { this.tableRenumberDialogVisible = true; },
                    visible: true,
                    disabled: !this.totalItems,
                },
                {
                    title: this.$t('common.clear-data'),
                    icon: 'mdi-delete-outline',
                    event: this.showConfirmClearTable,
                    visible: true,
                    disabled: !this.totalItems,
                },
            ];
        }

        showConfirmClearTable() {
            this.confirm?.show('ClearTable', ConfirmType.delete, this.$t('confirm.confirm-clear-table'));
        }

        showConfirmDeleteRowInTable(index: number) {
            this.tableItem = this.table[index];
            this.confirm?.show('DeleteRowInTable', ConfirmType.delete, this.$t('confirm.confirm-delete'));
        }

        async onConfirm(name: string) {
            if (name === 'DeleteRowInTable') await this.remove();
            if (name === 'ClearTable') await this.clearTable();
        }

        checkDate(item: Record<string, string | number>) {
            for (const key in item) {
                if (typeof item[key] === 'number') {
                    item[key] = !isNaN(new Date(item[key]).getTime())
                        && item[key] > 946674000000
                        ? new Date(item[key]).toLocaleDateString()
                        : item[key];
                }
            }
            return item;
        }

        openPopup() {
            this.tableItem = null;
            this.tableItemPopupVisible = true;
        }

        deleteEmptyValue(item: Record<string, string>) {
            for (const key in item) {
                if (!item[key]?.toString()) delete item[key];
            }
        }

        menuFilterHandler(item: { value: string, header: DataTableHeaderCustom }) {
            if (item.header?.filterIsSearch) {
                this.search[item.header?.value] = item.value;
                this.deleteEmptyValue(this.search);
                this.searchTimeRequest();
            }
        }

        searchTimeRequest() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async() => {
                await this.getDataTable();
            }, 2000);
        }

        get orderByField() {
            return this.field.TableFields.find(i => i.IsSortBy);
        }

        get orderBy() {
            return this.orderByField?.Title ?? '';
        }

        async getDataTable() {
            if (!this.part.Id || !this.field.TemplateCode || this.loading) return;
            this.loading = true;
            try {
                const { Items, TotalItems } = await api.journal.getTablePagination({
                    Search: this.search,
                    DynamicJournalPartitionId: this.part.Id,
                    TableTemplateCode: this.field.TemplateCode,
                    PageNumber: this.optionsTable.page,
                    PageSize: this.optionsTable.itemsPerPage,
                    IsDesc: this.optionsTable.sortDesc[0],
                    OrderBy: this.optionsTable.sortBy[0],
                });
                this.table = Items;
                this.totalItems = TotalItems;
            } finally {
                this.loading = false;
            }
        }

        async update() {
            await this.getDataTable();
            this.$emit('updated');
        }

        edit(index: number) {
            this.tableItem = this.table[index];
            this.tableItemPopupVisible = true;
        }

        async remove() {
            if (!this.tableItem) return;
            try {
                await api.journal.deleteRowInTable({
                    ...this.requestParams,
                    Data: this.tableItem,
                });
                await this.update();
                this.$root.$notify.show('success');
            } catch (e) {
                this.$root.$notify.show('error');
            }
        }

        async clearTable() {
            if (!this.table) return;
            try {
                await api.journal.clearTable(this.requestParams);
                await this.update();
                this.$root.$notify.show('success');
            } catch (e) {
                this.$root.$notify.show('error');
            }
        }
    }
