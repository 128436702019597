
    import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
    import { DataTableHeader } from 'vuetify';

    @Component({
        components: {},
    })
    export default class DataTableSetting extends Vue {
        @PropSync('columns', { type: Array, default: () => [] })
        showColumns!: string[];

        @Prop({ type: Array, default: () => [] })
        tableHeaders!: DataTableHeader[];
    }
