
    import { AutocompleteItem, ContragentResponse } from '@/types';
    import { Component } from 'vue-property-decorator';
    import SearchTextInput from './search-text-input.vue';
    import { localizeName } from '@/util/field-translation';

    @Component
    export default class SearchContragentInput extends SearchTextInput {

        get items(): AutocompleteItem[] {
            return this.$store.state.reftables.Contragents.map((x: ContragentResponse) => ({
                text: localizeName(x),
                value: x.id,
            }));
        }
    }

