export const Ks2Groups = {
    ContractorsKS2Id: '16',
    CustomersKS2PhysicalId: '17',
    CustomersKS2AsBuiltId: '18',
};

export const Ks2AppSettingsId = 'cc23b32a-7070-49b2-bfa6-92243a2f084e';

export enum KS2ActionName {
    PackagesKS2 = 'PackagesKS2',
    PackageKS2Issues = 'PackageKS2Issues',
    PackageKS2Checks = 'PackageKS2Checks',
    PackageKS2Approvals = 'PackageKS2Approvals',
    PackagesKS2WithCheck = 'PackagesKS2WithCheck',
    PackagesKS2WithApproval = 'PackagesKS2WithApproval',
}
