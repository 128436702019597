
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { IField, RowsItemServiceGenerateNew, TypeControl } from '@/types/dynamicDoc';
    import * as api from '@/api/dynamic';

    interface IconData {
        visible: boolean;
        tooltip?: string;
        color?: string;
        value?: string;
    }

    @Component({})
    export default class SearchTextInput extends Vue {
        @Prop({ type: String, required: true })
        private readonly label!: string;

        @Prop([ String, Array, Number ])
        private readonly value!: string;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly disabled!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly validate!: boolean;

        @Prop({ type: Object })
        private readonly field!: IField;

        loading = false;
        notFound = false;
        isFound = false;
        timer: any = null;

        get rules() {
            return {
                required: (v: any) => !!v || this.$t('errors.required'),
                validNumber: (v: any) => this.valueRegExp.test(v) || this.$t('common.identification-number-incorrect'),
            };
        }

        get valid() {
            return this.value && this.valueRegExp.test(this.value) && !this.loading;
        }

        get typeControl() {
            return this.field ? this.field.TypeControl : null;
        }

        get placeholder() {
            if (this.typeControl === null) return '';
            switch (this.typeControl) {
                case TypeControl.Nostroy: return 'С-78-000001';
                case TypeControl.Naks: return 'БР-1АЦ-III-00069';
                case TypeControl.SroNopriz: return 'СРО-И-001-28042009';
                case TypeControl.WeldingMaterial: return 'АЦСМ-8-00272';
                case TypeControl.MemberSroNopriz: return 'СРО-П-199-23052018/7806494622';
                case TypeControl.WeldingEquipment: return '[Регистрационный номер]/[Серийный номер]';
                case TypeControl.CertifiedWeldingOrganization: return 'АЦСТ-144-00071';
                case TypeControl.SpecialistNopriz: return 'П-000011';
                default: return '';
            }
        }

        get valueRegExp() {
            if (this.typeControl === null) return new RegExp('');
            switch (this.typeControl) {
                case TypeControl.Nostroy: return /^С-\w{2}-\d{6}$/;
                case TypeControl.Naks: return /^\S{2,3}-\S{3,4}-\S{1,3}-\d{5,}$/;
                case TypeControl.SroNopriz: return /^\p{Lu}{3}-\p{Lu}-\d{3}-\d{8}$/u;
                case TypeControl.WeldingMaterial: return /^\p{Lu}{4}-\d{1,2}-\d{5,}$/u;
                case TypeControl.MemberSroNopriz: return /^\p{Lu}{3}-\p{Lu}-\d{3}-\d{8}\/\d{10}$|\d{12}$/u;
                case TypeControl.WeldingEquipment: return /((\S){4}-(\d){1,3}-(\d{5,})(\/\S{3,})?)/;
                case TypeControl.CertifiedWeldingOrganization: return /^\p{Lu}{4}-\d{1,3}-\d{5,}$/u;
                case TypeControl.SpecialistNopriz: return /^\p{Lu}{1,2}-\d{6}$/u;
                default: return /\S+/u;
            }
        }

        get statusIcon(): IconData {
            if (this.isFound) return {
                visible: true,
                value: 'mdi-check',
                color: 'success',
                tooltip: this.$t('notify.success') as string,
            }; else if (this.notFound) return {
                visible: true,
                value: 'mdi-alert-circle-outline',
                color: 'error',
                tooltip: this.$t('common.not-found') as string,
            };
            return { visible: false };
        }

        get rowsItemServiceGenerateNew(): RowsItemServiceGenerateNew {
            return {
                ServiceKey: this.value,
                SchemaId: this.field.SchemaId as string,
                TypeControl: this.typeControl as TypeControl,
            };
        }

        @Watch('value', { immediate: true })
        async changeValue() {
            if (!this.valid || this.loading) return;
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async() => {
                await this.getData();
            }, 1500);
        }

        async getData() {
            try {
                this.loading = true;
                const res = await api.reftableItem.fillRowGenerateService(this.rowsItemServiceGenerateNew);
                this.$emit('getDataFromApi', res);
                !!res ? this.isFound = !!res : this.notFound = !res;
            } catch (e) {
                this.notFound = true;
            } finally {
                this.loading = false;
            }
        }

        change(event: any) {
            this.$emit('input', event);
            this.clear();
        }

        clear() {
            this.loading = false;
            this.isFound = false;
            this.notFound = false;
            this.$emit('clearFields', this.field);
        }
    }
