
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { OperationStatus } from '@/api/entity/operations';
    import { localizeIfExists } from '@/util/field-translation';
    import VueI18n from 'vue-i18n';
    import { Timer } from '@/types/dynamicDoc';

    @Component({
        methods: {
            localizeIfExists,
        },
    })
    export default class OperationButton extends Vue {
        @Prop({ required: true })
        readonly operation!: OperationStatus;

        @Prop({ type: String, default: 'primary' })
        readonly variant!: string;

        @Prop({ type: String, default: '' })
        readonly size!: string;

        @Prop({ type: Boolean, default: true })
        readonly enabled!: boolean;

        @Prop({ type: Boolean, default: false })
        readonly icon!: boolean;

        @Prop({ type: Boolean, default: false })
        readonly text!: boolean;

        @Prop({ type: Boolean, default: false })
        readonly block!: boolean;

        @Prop({ default: () => null })
        readonly tooltip!: VueI18n.TranslateResult | null;

        @Prop({ type: Boolean, default: false })
        readonly loading!: boolean;

        timer: Timer = null;

        onClick() {
            if (this.operation.Available && this.operation.Enabled && !this.loading) {
                this.$emit('click', this.operation);
            }
        }

        get options() {
            return {
                sizeSm: this.size === 'sm',
                sizeXs: this.size === 'xs',
                tooltip: this.operation && (!this.operation.Enabled || this.operation.Warnings?.length) || !!this.tooltip,
                btn: this.operation && this.operation.Available,
            };
        }

        get disabled() {
            return !this.operation?.Enabled || !this.enabled || this.loading;
        }

        get problems() {
            return this.operation?.Problems || [];
        }

        get warnings() {
            return this.operation?.Warnings || [];
        }
    }

