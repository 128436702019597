export enum ContragentRoleConstants {
    Subcontractor = 1,
    Contractor = 2,
    Supervisor = 3,
    Customer = 4,
    DrawingPackageCustomer = 5,
    Designer = 6,
    PackageKS2Contractor = 7,
    PackageKS2Customer = 8,
}
