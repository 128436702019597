/* Generated file. Do not edit. */

const events = {
    'PackageKS2IssueIssued': 'd1f219c4-d17e-4a3d-bf8c-42ff79e1a94d',
    'PackageKS2NewCheck': 'df0ed4f8-d963-442f-aae8-fee00f60875b',
    'PackageKS2NewApprove': '47145a3c-24b1-4d90-9f3e-52f9545c4e64',
    'PackageKS2RefuseApprove': 'eb4001dc-aa51-4adf-93cf-cf23ae6dc5ff',
    'PackageKS2HasIssues': 'c9114f70-235c-4e21-9f87-d56d47c0aab5',
    'PackageKS2Approval': '33b79e63-b21e-4506-b3a2-07d72fdeca9a',
    'DrawingPackageIssueIssued': 'f21a8687-a6f2-4ac0-8d7d-bfcb66711907',
    'DrawingPackageNewCheck': '5e63ce72-e0ff-4702-9939-9c8119b142f2',
    'DrawingPackageNewApprove': '0b183d79-69eb-4e68-bfbb-fe3c1dbf47d4',
    'DrawingPackageRefuseApprove': 'c691c5ce-9491-4be7-b05b-407d9173774f',
    'DrawingPackageHasIssues': '2bb092be-04f0-4c3d-8482-ce05a587dfee',
    'RegisterTaskLittleResolveIssuesTime': '62fe2036-cd08-4449-a1ec-e3549e50a5a0',
    'RegisterTaskChangeEndDateRequest': '413cebdc-6cf3-4a9f-b427-545354a9be19',
    'RegisterTaskApproveChangeEndDate': '845803da-a580-4342-9b74-cbdc2decaf47',
    'RegisterTaskCancelChangeEndDate': '0ed4f960-83c2-427e-9a44-273e51b4a099',
    'RegisterIssueIssued': '3603afd6-a0e8-4fd5-ad8e-29bd9f7618bc',
    'RegisterHasIssues': '3f318203-ccbc-4a4a-a810-27612758bf53',
    'RegisterReviewNewCheck': 'f88b6109-85d9-4762-bbf0-f7aed23add5e',
    'RegisterReviewNewApprove': 'f72f0fd9-5b9b-416b-8bca-054412f32ad0',
    'RegisterReviewFailedCheck': '78f27428-db77-487a-bef3-02fa6b3eb99b',
    'RegisterReviewSuccessCheck': 'a960a680-a807-47a9-a341-a981db983c6b',
    'RegisterReviewSuccessApprove': '29285abd-70ef-4a29-bfa2-474b028b7df5',
    'RegisterReviewRefuseApprove': '04b43d2a-9b09-47e7-bfbe-b6d559c3949e',
    'RegisterSendRequestToReviewers': '46dba474-0073-4e5c-8789-d5cf8cd4234a',
    'SendNotifyToExpert': '87a56164-4a62-4c57-a698-a44fec4b9366',
    'CorrectReviewEndDate': '617bf2ba-67ce-4376-aafb-7e7605039dca',
    'ApproveCorrectDate': '5644a49a-177c-4c0c-aa11-87505d5c70ae',
    'RefuseCorrectDate': 'ceb827a4-234d-4296-af58-332802c0b7cd',
    'RegisterReviewLittleCheckTime': '5f98d193-41ef-483b-bbe5-f4ef32c3be11',
    'RegisterReviewLittleApproveTime': '242a369a-8f0a-446f-a99f-4c98b2e0e062',
    'RegisterReviewOthersDoneCheck': 'f19b8538-68fb-4191-b2a3-794a0a14a700',
};

export default events;
