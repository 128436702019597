
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import { DocumentTemplate, DynamicDocument } from '@/types/dynamicDoc';
    import * as api from '@/api/dynamic';
    import { VForm } from '@/types';
    import { required } from '@/components/common/validation-rules';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';

    @Component({
        components: { ButtonTooltip },
        data: () => ({ required }),
    })
    export default class DocumentsJournalsDocumentTemplatePopup extends Vue {
        @Ref() form!: VForm;
        @Prop({ type: Object }) document!: DynamicDocument;

        valid = true;
        isVisible = false;
        loading = false;
        template: Partial<DocumentTemplate> = {
            Title: '',
            IsPersonal: false,
        };

        get templateResult() {
            return {
                ...this.template,
                SchemaId: this.document.SchemaId,
                Data: this.document.Data,
                DrawingPackageIds: this.document.DrawingPackageIds,
            } as DocumentTemplate;
        }

        close() {
            this.form.reset();
            this.isVisible = false;
        }

        open() {
            this.isVisible = true;
        }

        async save() {
            if (!this.form.validate()) return;
            this.loading = true;
            try {
                await api.documentTemplate.create(this.templateResult);
                this.$emit('updated');
                this.$root.$notify.show('success');
            } catch (e) {
                this.$root.$notify.show('error', this.$t('common.error-please-later') as string);
            } finally {
                this.loading = false;
                this.close();
            }
        }
    }
