
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { required } from '@/components/common/validation-rules';
    import DatePicker from 'v-calendar/lib/components/date-picker.umd';
    import VueI18n from 'vue-i18n';

    @Component({
        components: { DatePicker },
    })
    export default class DatePickerInput extends Vue {
        @Prop({ default: () => null })
        private readonly value!: number | string;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly dense!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly disabled!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly validate!: boolean;

        @Prop()
        private readonly label!: string | VueI18n.TranslateResult;

        @Prop()
        private readonly min!: number | Date;

        @Prop({ type: Number })
        private readonly max!: number;

        @Prop({ type: Boolean, default: true })
        private readonly required!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly clearable!: boolean;

        @Prop({})
        private readonly placeholder!: string | VueI18n.TranslateResult;

        @Prop({ type: Boolean, default: false })
        private readonly time?: boolean;

        menu = false;
        date: Date | null = null;
        dateRegExp = /^(\d{2})\.(\d{2})\.(\d{4})$/
        modelConfig = {
            timeAdjust: '12:00:00',
        };

        @Watch('value', { immediate: true })
        onChangeValue() {
            if (!this.value) {
                this.date = null;
                return;
            }
            this.date = new Date(this.value);
            const normalizeDate = this.date.setHours(12, 0, 0, 0);
            if (normalizeDate !== this.date.getDate()) {
                this.$emit('input', normalizeDate);
            }
        }

        get isRequired() {
            return !this.required || required;
        }

        isDateFormat(v?: string) {
            return !v
                || (this.dateRegExp.test(v) && !isNaN(Date.parse(v.replace(this.dateRegExp, '$2/$1/$3'))))
                || this.$t('error.invalid-date-format');
        }

        get formatDate() {
            if (!this.date) return null;
            return this.date.toLocaleDateString();
        }

        onSelectDate() {
            if (!this.date) return;
            this.menu = false;
            this.$emit('input', this.date.getTime());
        }

        onInputDate(value?: string) {
            if (!value || this.isDateFormat(value) !== true) return;

            const timestamp = Date.parse(value.replace(this.dateRegExp, '$2/$1/$3'));
            this.$emit('input', timestamp);
        }
    }
