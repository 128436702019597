export const DrawingPackagesAppSettingsId = 'dd6b1367-d4f2-4523-877b-00771caf69e1';

export enum DrawingPackagesGroups {
    DrawingPackageDesignersId = '88cfd38c-c82a-4ffb-9d6d-c5d90e793975',
    DrawingPackageCustomersId = '6fd66067-660d-44f3-9eaf-9881f1842264',
}

export enum ProjectDocumentationStage {
    ProjectDocumentationId = '6747b554-4477-4581-80da-3d8eb7c010a1',
    WorkingDrawingsId = '90c3e628-f001-4ed6-b951-46f04e6f1a82',
}

export enum DrawingModule {
    ProjectDocumentation = 0,
    WorkingDrawings = 1,
}

export enum DrawingRootRouteName {
    ProjectDocumentation = 'project-documentation',
    WorkingDrawings = 'working-drawings',
}

export const MODULE_TO_STAGE_ID = Object.freeze({
    [DrawingRootRouteName.ProjectDocumentation]: ProjectDocumentationStage.ProjectDocumentationId,
    [DrawingRootRouteName.WorkingDrawings]: ProjectDocumentationStage.WorkingDrawingsId,
});

export enum DrawingPackageTab {
    Main,
    Documents,
    SubObjects,
    Issues,
}

export enum DrawingPackageActionName {
    DrawingPackages = 'DrawingPackages',
    DrawingPackagesWithApproval = 'DrawingPackagesWithApproval',
    DrawingPackagesWithChecks = 'DrawingPackagesWithChecks',
    DrawingPackageApprovals = 'DrawingPackageApprovals',
    DrawingPackageChecks = 'DrawingPackageChecks',
    DrawingPackageIssues = 'DrawingPackageIssues',
}
