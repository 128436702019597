import { Module } from 'vuex';
import { Settings } from '@/store/settings/types';
import { state } from './state';
import { mutations } from './mutations';
import { actions } from './actions';
import { RootState } from '@/store/types';

const settings: Module<Settings, RootState> = {
    state,
    mutations,
    actions,
};

export default settings;
