import { AutocompleteItem, FilterOptions } from '../common';
import i18n from '@/plugins/i18n';

export enum RegisterTaskType {
    ResolveIssues,
}

export enum RegisterTaskStatus {
    New,
    InProgress,
    Completed,
    ExpiredCompleted,
}

export interface RegisterTaskResponse {
    Id: string;
    AppUserId: string | null;
    CountDaysOff: number | null;
    LastStatusDate: Date | null;
    CorrectEndDate: Date | null;
    RegisterId: string | null;
    RegisterCode: number | null;
    RegisterStatusId: string | null;
    StartDate: Date;
    EndDate: Date | null;
    Status: RegisterTaskStatus;
    Type: RegisterTaskType;
    CanChangeEndDate: boolean;
    CanRequestChangeEndDate: boolean;
}

export interface RegisterTaskFilterOptions extends FilterOptions {
    RegisterStatusId?: string;
    AppUserId?: string;
    StartDate?: number;
    EndDate?: number;
    Code?: number;
    ShowOnlyActive?: boolean;
    Type?: RegisterTaskType;
}

export const registerTaskTypeItems: AutocompleteItem[] = [
    { value: RegisterTaskType.ResolveIssues, text: i18n.t('report.resolving-issues') as string },
];

export const registerTaskStatusItems: AutocompleteItem[] = [
    { value: RegisterTaskStatus.New, text: i18n.t('register-task.status-new') as string },
    { value: RegisterTaskStatus.InProgress, text: i18n.t('register-task.status-in-progress') as string },
    { value: RegisterTaskStatus.Completed, text: i18n.t('register-task.status-completed') as string },
    { value: RegisterTaskStatus.ExpiredCompleted, text: i18n.t('register-task.status-expired-completed') as string },
];
