import { cisInstance } from './cis-instance';

export const file = {
    download: (fileDescId: string, preview = false) =>
        cisInstance.get(`File/Download?fileDescId=${fileDescId}&preview=${preview}`,
            { responseType: 'blob' })
            .then(x => x.data),

    downloadTemplate: () =>
        cisInstance.get('File/DownloadTemplate', { responseType: 'blob' })
            .then(x => x.data),
};
