import { EventDescriptor } from '@/components/notification/store/event-descriptor';
import events from '@/api/model/events';
import IssueIssued from './issue-issued.vue';
import DrawingPackageReviewNew from './drawing-package-review-new.vue';
import DrawingPackageHasIssues from './drawing-package-has-issues.vue';

export default function getDrawingPackagesEventDescriptors() : EventDescriptor[] {
    return [
        {
            id: events.DrawingPackageIssueIssued,
            i18keyName: 'events.DrawingPackageIssueIssued',
            descriptionComponent: IssueIssued,
        },
        {
            id: events.DrawingPackageNewApprove,
            i18keyName: 'events.DrawingPackageNewApprove',
            descriptionComponent: DrawingPackageReviewNew,
        },
        {
            id: events.DrawingPackageNewCheck,
            i18keyName: 'events.DrawingPackageNewCheck',
            descriptionComponent: DrawingPackageReviewNew,
        },
        {
            id: events.DrawingPackageHasIssues,
            i18keyName: 'events.DrawingPackageHasIssues',
            descriptionComponent: DrawingPackageHasIssues,
        },
    ];
}
