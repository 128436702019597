
    import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
    import { DynamicDocument, SignatoryUser } from '@/types/dynamicDoc';
    import DragFileUpload from '@/components/files/drag-file-upload.vue';
    import * as api from '@/api/dynamic';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { formatSize } from '@/components/files/file-size-format';
    import CertificateSelector from '@/components/documents-journals/documents/signatures/certificate-selector.vue';
    import { SignatureCreateOptions } from '@/crypto/types';
    import CertificateVersion from '@/components/documents-journals/documents/signatures/certificate-version.vue';
    import CertificateSignatureList from '@/components/documents-journals/documents/signatures/certificate-signature-list.vue';
    import { InfoFileDesc, ReadSignature, Signature, UserResponse } from '@/types';
    import { Register } from '@/api/model/interfaces';
    import { RegisterStatus } from '@/api/constants';
    import { Tabs } from '@/components/documents-journals/documents/document.vue';
    import { getUserFullName } from '@/components/admin/roles-model/users/helpers';
    import { createDetachedSignature } from 'crypto-pro';
    import { getHash } from '@/crypto/utils';

    @Component({
        components: {
            DragFileUpload,
            ButtonTooltip,
            CertificateSelector,
            CertificateVersion,
            CertificateSignatureList,
        },
        methods: {
            formatSize,
        },
        data: () => ({
            Tabs,
        }),
    })
    export default class DocumentTabFiles extends Vue {
        @PropSync('item', { type: Object, required: true })
        document!: DynamicDocument;

        @Prop({ type: Object })
        infoFileDesc?: InfoFileDesc | null;

        @Prop({ type: String })
        registerStatusId!: string;

        @Prop({ type: Number })
        tabIndex!: Tabs;

        @Prop({ type: Object, default: () => null })
        register!: Register | null;

        signatureCreateOptions: SignatureCreateOptions | null = null;
        signature: Signature = { hash: '', sign: '' };
        readSignature: ReadSignature[] = [];
        signingLoading = false;
        isCertificateSelected = false;

        @Watch('tabIndex', { immediate: true })
        async initTabIndex() {
            if (this.tabIndex !== Tabs.File) return;
            this.readSignature = [];
            this.signatureCreateOptions = null;
            this.getInfoFileDesc();
        }

        @Watch('currentSignature', { immediate: true, deep: true })
        async changeCurrentSignature() {
            if (!this.currentSignature) return;
            this.signature.sign = await api.fileDesc.downloadDataSig(this.currentSignature.Id as string);
            this.readSignature = await api.fileDesc.readSignatureByDocumentId(this.currentSignature.SignatureForId as string);
        }

        get uploadDocumentLabel() {
            return this.fileDescId
                ? this.$t('common.replace-document')
                : this.$t('common.upload-document');
        }

        get uploadSignatureLabel() {
            return this.infoFileDesc?.CurrentSignatureFile
                ? this.$t('file-signatures.replace-signature')
                : this.$t('file-signatures.add-signature');
        }

        get isSigningRegisterStatus() {
            return this.registerStatusId === RegisterStatus.Signing;
        }

        get signingBtnDisable() {
            return !(this.fileDescId
                && this.isCertificateSelected);
        }

        get isVisibleUploadFiles() {
            return !this.register
                || (this.register.StatusId.toString() === RegisterStatus.Draft
                    || this.register.StatusId.toString() === RegisterStatus.ResolvingIssues);
        }

        get currentSignature() {
            if (!this.infoFileDesc?.CurrentSignatureFile) return null;
            return this.infoFileDesc.CurrentSignatureFile;
        }

        get fileDescId() {
            if (!this.document.FileDescId) return null;
            return this.document.FileDescId;
        }

        get users(): UserResponse[] {
            return this.$store.state.reftables.Users;
        }

        get signatoryUsers(): SignatoryUser[] {
            if (!this.document.SignatoryUsers?.length) return [];
            return this.document.SignatoryUsers.map(x => {
                const status = Array.from(new Set(this.readSignature.flatMap(s => s.SignatureUserIds)))
                    .find(id => id === x.UserId);
                return {
                    ...x,
                    UserName: getUserFullName(x.UserId),
                    Status: !!status,
                };
            });
        }

        get fileName() {
            return this.document.ArchiveName
                ? this.document.ArchiveName + this.fileType
                : this.infoFileDesc?.File.UploadName;
        }

        get signFileName() {
            return this.document.ArchiveName
                ? this.document.ArchiveName + this.fileType
                : this.infoFileDesc?.CurrentSignatureFile.UploadName;
        }

        get fileType() {
            if (!this.infoFileDesc?.File?.UploadName) return '';
            const fileName = this.infoFileDesc.File.UploadName;
            return fileName.substring(fileName.lastIndexOf('.'));
        }

        onCertificateSelected(value: boolean) {
            this.isCertificateSelected = value;
        }

        async download() {
            try {
                const res = await api.document.download(this.document.Id);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(res);
                link.download = this.fileName || this.document.Number;
                link.click();
            } catch (e) {
                this.$root.$notify.show('error', e.response.data.Message);
            }
        }

        async downloadSign() {
            try {
                if (!this.currentSignature) return;
                const res = await api.fileDesc.download(this.currentSignature.Id as string);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(res);
                link.download = this.signFileName || this.document.Number;
                link.click();
            } catch (e) {
                this.$root.$notify.show('error', e.response.data.Message);
            }
        }

        async getDocument() {
            this.document = await api.document.getById(this.document.Id);
        }

        async attachFileToDocument(files: File[]) {
            try {
                await api.document.upload(this.document.Id, files[0]);
                this.$root.$notify.show('success', this.$t('common.attached-to-document') as string);
                await this.getDocument();
                this.getInfoFileDesc();
            } catch (e) {
                this.$root.$notify.show('error', e.response.data.Message);
            }
        }

        async attachFileToSignature(files: File[]) {
            try {
                await api.document.uploadSignatures(this.document.Id, files[0]);
                this.$root.$notify.show('success');
                await this.getDocument();
                this.getInfoFileDesc();
            } catch (e) {
                this.$root.$notify.show('error', e.response.data.Message);
            }
        }

        async generateFileDesc() {
            try {
                this.document.FileDescId = await api.document.createFile(this.document.Id);
                this.$root.$notify.show('success', this.$t('common.file-generated-success') as string);
                this.getInfoFileDesc();
                this.$emit('updated');
            } catch (e) {
                this.$root.$notify.show('error', e.response.data.Message);
            }
        }

        async onSignClick() {
            if (!this.signatureCreateOptions || !this.fileDescId) return;
            this.signingLoading = true;
            try {
                this.signature.hash = await getHash(this.fileDescId);
                this.signature.sign = await createDetachedSignature(this.signatureCreateOptions.thumbprint, this.signature.hash);
                await this.saveFileSignature();
            } catch (e) {
                this.$root.$notify.show('error', e);
            } finally {
                this.signingLoading = false;
                this.getInfoFileDesc();
            }
        }

        getInfoFileDesc() {
            this.$emit('refresh');
        }

        async saveFileSignature() {
            if (!this.signature.sign) return;
            try {
                await api.fileDesc.getSaveFileSignature(this.fileDescId!, this.signature.sign);
                this.$root.$notify.show('success', this.$t('file-signatures.signature-ready') as string);
            } catch (e) {
                this.$root.$notify.show('error', e.response.data.Message);
            }
        }
    }
