
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { DataTableHeader } from 'vuetify';
    import { RegisterGroupByReviewerUserIdResponse } from '@/types/cis/widget';
    import { UserResponse } from '@/types';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { getUserFullName } from '@/components/admin/roles-model/users/helpers';
    import { PropType } from 'vue';
    import VueI18n from 'vue-i18n';

    interface DeadlineItem {
        name: string;
        text: string;
        value: string;
        deadline: string;
        date: string;
    }

    @Component({
        components: { ButtonTooltip },
    })
    export default class ChartRegisterTimeout extends Vue {
        @Prop({ type: String as PropType<VueI18n.TranslateResult>, required: true })
        readonly title!: string;

        @Prop({ type: Array, required: true })
        readonly items!: RegisterGroupByReviewerUserIdResponse[];

        readonly user = this.$store.getters.getUser;

        get isChief(): boolean {
            return this.user.isChief;
        }

        get isAdmin(): boolean {
            return this.$store.getters.isAdmin;
        }

        get users(): UserResponse[] {
            return this.$store.state.reftables.Users;
        }

        get headers(): DataTableHeader[] {
            return [
                { text: this.$t('common.title') as string, value: 'text' },
                { text: this.$t('common.deadline') as string, value: 'deadline' },
                { text: this.$t('common.left') as string, value: 'date' },
                { text: '', value: 'actions', align: 'end' },
            ];
        }

        get itemsFormat() {
            return this.items
                .reduce((arr: DeadlineItem[], item) => {
                    item.Registers.forEach(reg => {
                        const deadLine = reg.ReviewEndDate ? new Date(reg.ReviewEndDate) : null;
                        arr.push({
                            name: getUserFullName(item.ReviewerUserId),
                            text: this.$tc('common.n-register', Number(reg.Code)),
                            value: reg.Id,
                            deadline: deadLine !== null ? deadLine.toLocaleDateString() : '',
                            date: deadLine !== null ? this.formatDate(deadLine) : '',
                        });
                    });
                    return arr;
                }, []);
        }

        formatDate(endDate: Date) {
            const t = endDate.getTime() - new Date().getTime();
            const days = Math.floor(t / (1000 * 60 * 60 * 24));
            const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            if (!days) return `${this.$tc('common.n-hour', hours)}`;
            return `${this.$tc('common.n-day', days)} ${this.$tc('common.n-hour', hours)}`;
        }
    }
