import { AxiosInstance } from 'axios';

export class BaseApi<TEntity, TModel> {
    protected readonly instance: AxiosInstance;
    protected readonly entityName: string;

    constructor(entityName: string, instance: AxiosInstance) {
        this.instance = instance;
        this.entityName = entityName;
    }

    getAll(): Promise<TEntity[]> {
        return this.instance.get<TEntity[]>(this.entityName).then(x => x.data);
    }

    getById(id: string): Promise<TEntity> {
        return this.instance.get<TEntity>(`${this.entityName}/${id}`).then(x => x.data);
    }

    create(data: TModel): Promise<TEntity> {
        return this.instance.post<TEntity>(this.entityName, data).then(x => x.data);
    }

    update(id: string, data: TEntity | TModel): Promise<TEntity> {
        return this.instance.put<TEntity>(`${this.entityName}/${id}`, data).then(x => x.data);
    }

    remove(id: string): Promise<void> {
        return this.instance.delete(`${this.entityName}/${id}`).then(x => x.data);
    }
}
