import { BaseApi } from '@/api/BaseApi';
import { Letter } from '@/types/report';

/** Письма по подписке */
export default class LetterApi extends BaseApi<Letter, Letter> {
    byUser(userId: string): Promise<Letter[]> {
        return this.instance.get<Letter[]>(`${this.entityName}/GetByUser/${userId}`).then(x => x.data);
    }

    download(letterId: string) {
        return this.instance.get(`${this.entityName}/Download/${letterId}`, { responseType: 'blob' })
            .then(x => x.data);
    }
}
