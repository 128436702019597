
    import { Component, Vue } from 'vue-property-decorator';
    import { UserResponse } from '@/types';
    import AppBarNotificationItem from '@/components/home/app-bar-notification-item.vue';
    import * as Odata from '@/api/odata';
    import { NotificationStatus } from '@/api/model/enums';
    import { HubConnection } from '@microsoft/signalr';
    import getNotificationHub from '@/components/notification/notificationHub';
    import { Notification } from '@/api/model/interfaces';

    @Component({
        components: {
            AppBarNotificationItem,
        },
    })
    export default class AppBarNotification extends Vue {
        notification: Notification[] = [];
        notifyHubConnection: HubConnection | null = null;
        count: number | null = null;
        timer!: ReturnType<typeof setTimeout> ;

        get user(): UserResponse {
            return this.$store.state.auth.user;
        }

        async getNotification() {
            this.notification = await Odata.Notification.setParams({
                orderBy: 'EventDate desc',
                filter: [ `Status eq '${NotificationStatus.New}'` ],
            }).getAll();
        }

        async loadMessages() {
            const result = await Odata.Notification.setParams({
                filter: [ `Status eq '${NotificationStatus.New}'` ],
                top: 0,
            }).count();
            await this.setCount(result);
        }

        async setCount(newCount: number) {
            if (this.count !== newCount) {
                this.count = newCount;
                await this.getNotification();
            }
        }

        async setAllNotificationsAsRead() {
            const newNotifications = this.notification.filter(n => n.Status === NotificationStatus.New);
            for (const item of newNotifications) {
                await Odata.Notification.runAction('MarkAsRead', item.Id, item);
            }
            await this.loadMessages();
        }

        async created() {
            this.notifyHubConnection = getNotificationHub(this.$store.getters.authToken);
            this.notifyHubConnection.on('Notify', async() => {
                setTimeout(async() => {
                    await this.loadMessages();
                }, 4000);
            });
            await this.notifyHubConnection.start();
        }

        async mounted() {
            await this.loadMessages();
        }
    }
