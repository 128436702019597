import { DataOptions, DataTableHeader } from 'vuetify';

export interface AutocompleteItem {
    text: string;
    value: any;
}

export interface IDateRange {
    start: Date | null;
    end: Date | null;
}

export interface ITimeStampRange {
    start: number | null;
    end: number | null;
}
export enum MenuFilterType {
    String,
    Number,
    SelectEnumString,
    SelectEnumNumber,
    Date,
    DateSingle,
    Boolean,
}

export interface DataTableHeaderCustom extends DataTableHeader {
    filterVisible?: boolean;
    filterIsSearch?: boolean;
    filterType?: MenuFilterType;
    filterItems?: AutocompleteItem[];
    multiple?: boolean;
    disabled?: boolean;
}

export interface FilterOptions {
    PageNumber?: number;
    PageSize?: number;
    OrderBy?: string;
    IsDesc?: boolean;
    Search?: any,
    Id?: string;
}

export interface OptionsDataTable extends DataOptions {
    showColumns?: string[];
}

export type OptionsFilterOdata<T> = Record<keyof T, any>
