export enum RegisterLogType {
    Register,
}

export interface RegisterLogResponse {
    Id: string;
    RegisterLogType: RegisterLogType;
    OldValues: string;
    NewValues: string;
    Description: string;
    CreatedAt: string;
    UserId: string;
}
