import { AxiosInstance, AxiosResponse } from 'axios';
import entitySets from '@/api/model/dx-odata-entities';
import { getOperations } from '@/api/entity/operations';

export interface LoadOptions {
    expand?: string[];
    filter?: string[] | null;
    select?: string[];
    skip?: number;
    orderBy?: string | null;
    top?: number;
}

function setUrlParams(options: LoadOptions | null): string {
    if (!options) return '';
    const params = new URLSearchParams();

    Object.entries(options).forEach(([ key, value ]) => {
        if (Array.isArray(value) && key !== 'filter') {
            params.append(`${key}`, value.join(','));
        } else if (Array.isArray(value) && key === 'filter') {
            const data = value.map(v => `(${v})`).join(' and ');
            params.append(`${key}`, data);
        } else if (typeof value === 'number') {
            params.append(key, value.toString());
        } else if (typeof value === 'string') {
            params.append(key, value);
        }
    });
    return params.toString();
}

function getUrl(key?: string | null, name?: string, queryParams?: Record<string, string>) {
    let url = '';
    if (key) url = url + `(${(key)})`;
    if (name) url = url + '/' + name;

    if (queryParams) {
        const params = new URLSearchParams(queryParams);
        url += '?' + params.toString();
    }
    return url;
}

export class OdataManager<TEntity> {
    protected readonly instance: AxiosInstance;
    protected readonly entityName: keyof typeof entitySets;
    private params: LoadOptions | null = null;

    constructor(entityName: keyof typeof entitySets, instance: AxiosInstance) {
        this.instance = instance;
        this.entityName = entityName;
    }

    setParams(params: LoadOptions) {
        this.params = params;
        return this;
    }

    getUrl(name?: string, key?: string | null, queryParams?: Record<string, string>): string {
        return `${process.env.VUE_APP_API_BASE_URL}odata/${this.entityName}${getUrl(key, name, queryParams)}`;
    }

    count(): Promise<number> {
        const params = setUrlParams(this.params);
        return this.instance.get<TEntity[]>(`${this.entityName}/$count?${params}`)
            .then((x: AxiosResponse) => x.data);
    }

    runAction(name: string, id?: string | null, data = {}, queryParams?: Record<string, any>): Promise<any> {
        const url = getUrl(id, name, queryParams);
        return this.instance.post<TEntity>(this.entityName + url, data).then(x => x.data);
    }

    runFunction(name: string, id?: string | null, queryParams?: Record<string, any>): Promise<any> {
        const url = getUrl(id, name, queryParams);
        return this.instance.get<TEntity>(this.entityName + url).then((x: AxiosResponse) => x.data.value);
    }

    getAll(): Promise<TEntity[]> {
        const params = setUrlParams(this.params);
        return this.instance.get<TEntity[]>(`${this.entityName}?${params}`)
            .then((x: AxiosResponse) => x.data.value);
    }

    getById(id: string, operationNames?: string[]): Promise<TEntity> {
        const params = setUrlParams(this.params);
        return this.instance.get<TEntity>(`${this.entityName}/${id}?${params}`)
            .then(async(x: AxiosResponse) => {
                if (operationNames) x.data.ops = await getOperations(operationNames, id);
                return x.data;
            });
    }

    create(data: TEntity): Promise<TEntity> {
        return this.instance.post<TEntity>(this.entityName, data).then(x => x.data);
    }

    update(id: string, data: TEntity): Promise<TEntity> {
        return this.instance.patch<TEntity>(`${this.entityName}/${id}`, data).then(x => x.data);
    }

    put(id: string, data: TEntity): Promise<TEntity> {
        return this.instance.put<TEntity>(`${this.entityName}/${id}`, data).then(x => x.data);
    }

    remove(id: string): Promise<void> {
        return this.instance.delete(`${this.entityName}/${id}`).then(x => x.data);
    }
}
