
    import { Component, Prop } from 'vue-property-decorator';
    import { Notification, Register } from '@/api/model/interfaces';
    import * as Odata from '@/api/odata';
    import NotificationItem from '@/components/common/notification-item.vue';

    @Component({})
    export default class CorrectRevieEndDateNotification extends NotificationItem {
        @Prop({ required: true })
        notification!: Notification;

        register: Register | null = null;

        async mounted() {
            const res = await Odata.RegisterReview.setParams({
                expand: [ 'Iteration($expand=Register)' ],
            }).getById(this.notification.EntityId.toString());
            this.register = res.Iteration?.Register || null;
            this.link = this.register ? `/asbuilt/registers/${this.register.Id}` : null;
        }
    }
