import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { DocumentAudit, DocumentAuditOptions, getByFilter } from '@/types/dynamicDoc';

/** История изменения динамического документа */
export const audits = {
    get: () =>
        dynamicDocInstance.get<DocumentAudit[]>('/Audits').then(x => x.data),

    getByFilter: (options: DocumentAuditOptions): Promise<getByFilter<DocumentAudit>> =>
        dynamicDocInstance.post<getByFilter<DocumentAudit>>('/Audits/getByFilter', options)
            .then(x => x.data),

    getByDynamicDocument: (data: string[]): Promise<DocumentAudit[]> =>
        dynamicDocInstance.post<DocumentAudit[]>('/Audits/GetByDynamicDocument', data)
            .then(x => x.data),
};
