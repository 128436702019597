
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import VueI18n from 'vue-i18n';
    interface ToRoute {
        name: string;
        params?: any;
    }
    @Component({})
    export default class ButtonTooltip extends Vue {
        @Prop({ default: () => null })
        readonly icon!: string | null;

        @Prop({ type: String, default: () => 'primary' })
        readonly color!: string;

        @Prop({ type: String, default: () => '_parent' })
        readonly target!: string;

        @Prop({ type: String, default: () => '' })
        readonly permission!: string;

        @Prop({ type: Object || String })
        readonly to?: ToRoute | string;

        @Prop({ type: Boolean, default: () => false })
        readonly disabled!: boolean;

        @Prop({ type: Boolean, default: () => false })
        readonly link!: boolean;

        @Prop({ type: Boolean, default: () => false })
        readonly lg!: boolean;

        @Prop({ type: Boolean, default: () => false })
        readonly sm!: boolean;

        @Prop({ type: Boolean, default: () => false })
        readonly xs!: boolean;

        @Prop({ type: Boolean, default: () => false })
        readonly rounded!: boolean;

        @Prop({ type: Boolean, default: () => false })
        readonly btnText!: boolean;

        @Prop({ type: Boolean, default: () => false })
        readonly loading!: boolean;

        @Prop({ type: Boolean, default: () => false })
        readonly tile!: boolean;

        @Prop({ default: () => null })
        readonly tooltip!: VueI18n.TranslateResult | null;

        @Prop({ default: () => null })
        readonly title!: VueI18n.TranslateResult | null;
    }
