// Типы для Сервиса авторизации

import Vue from 'vue';

export enum Locale {
    Ru,
    En,
}

export enum DialogState {
    Create,
    Update
}

export interface OptionsTable {
    pageNumber?: number;
    pageSize?: number;
    isDesc?: boolean;
    orderBy?: string;
}

export interface OptionsContragent extends OptionsTable {
    id?: number | null;
    userId?: number | null;
    contragentRoleId?: number | null;
}

export interface NodeServiceSystemCodeResponse {
    id: number;
    systemCode: string;
}

export interface NodeServiceResponse {
    id: number;
    name: string;
    systemCodes: NodeServiceSystemCodeResponse[];
}

export interface NodeServicePostRequest {
    name: string;
    password: number;
    systemCodes: string[];
}

export interface PositionResponse {
    id: number;
    nameRu: string;
    nameEn: string;
}

export interface PositionRequest {
    nameRu: string;
    nameEn: string;
}

export interface ContragentRequest {
    nameRu: string;
    nameEn: string;
    code: string | null;
}

export interface ResponsibleContragentRequest {
    contragentId: number | null;
    userIds: number[];
}

export interface ContragentRoleRequest {
    nameRu: string;
    nameEn: string;
}

export interface ContragentResponse {
    id: number;
    nameRu: string;
    nameEn: string;
    code: string | null;
    contragentRoleId: number | null;
    subcontractorForId: number | null;
    parentId?: number | null;
    parent: ContragentResponse;
    responsibleContragents: ResponsibleUserResponse[];
    guidId: string;
}

export interface ContragentWithChildren {
    id: number;
    guidId: string;
    nameRu: string;
    nameEn: string;
    children: ContragentWithChildren[];
}

export interface ContragentRoleResponse {
    id: number;
    nameRu: string;
    nameEn: string;
    contragents: ContragentResponse[];
}

export interface ResponsibleUserResponse {
    id: number;
    GuidId: string;
    firstName: string;
    lastName: string;
    middleName: string;
}

export interface Headers {
    id?: number;
    width?: string;
    align?: string;
    text: string;
    value: string | number;
}

export interface UserPasswordRequest {
    oldPassword: string;
    newPassword: string;
}

export interface LoginForm {
    login: string;
    password: string;
}

export interface RightResponse {
    id: number;
    nameRu: string;
    nameEn: string;
    systemCode: string;
    moduleId: number;
}

export interface RoleModuleRequest {
    roleId: number;
    moduleId: number;
}

export interface RightRequest {
    nameRu: string;
    nameEn: string;
    systemCode: string;
    moduleId: number;
}

export interface RoleResponse {
    id: number;
    nameRu: string;
    nameEn: string;
    rights: RightResponse[];
}

export interface RoleRequest {
    nameRu: string;
    nameEn: string;
}

export interface Role {
    id: number;
    nameRu: string;
    nameEn: string;
    isEnable?: boolean;
    header?: Headers[];
    rights?: RightResponse[];
}

export interface RoleRightsRequest {
    roleId: number;
    rightIds: number[];
}

export interface RoleGroupResponse {
    id: number;
    nameRu: string;
    nameEn: string;
    roles: RoleResponse[];
}
export enum RoleGroupColumn {
    roleGroup,
    role
}
export interface RoleGroupList {
    groupId: number;
    rolesId: number[];
}

export interface PermissionEvent {
    create?: string;
    edit?: string;
    delete?: string;
}

export interface RoleGroupRequest {
    nameRu: string;
    nameEn: string;
}

export interface UserAccessGroupResponse {
    id: number;
    userId: number;
    contragent: ContragentResponse;
    roleGroup: RoleGroupResponse;
}

export interface UserAccessGroupPostRequest {
    contragentId: number;
    roleGroupId: number;
}

export interface UserAccessRoleResponse {
    id: number;
    userId: number;
    contragent: ContragentResponse;
    role: RoleResponse;
}

export interface LoginSuccessResponse {
    lifetime: number;
    refreshToken: string;
    token: string;
    user: UserResponse;
    lastLoginDate: number | null;
    lifeDaysPassword: number;
}

export interface UserAccessRolePostRequest {
    contragentId: number;
    roleId: number;
}

export interface UserPutPasswordRequest {
    newPassword: string;
    oldPassword?: string;
}

export interface UserRequest {
    firstName: string;
    lastName: string;
    middleName: string;
    email: string;
    positionId: number | null;
    contragentId: number | null;
    isChief: boolean;
    locale: Locale;
    accessRoleGroups: UserAccessGroupPostRequest[];
    accessRoles: UserAccessRolePostRequest[];
    login?: string;
    password?: string;
    isBlocked?: boolean;
    isConsentPersonalData?: boolean;
    calendarType?: number;
    isAdmin?: boolean;
}

export interface UserResponse extends UserItemResponse {
    id: number;
    guidId: string;
    login: string;
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    fullName: string;
    position: PositionResponse
    accessGroups: UserAccessGroupResponse[];
    accessRoles: UserAccessRoleResponse[];
    roles: string[],
    rights: number[],
    systemCodeRights: string[],
    isChief: boolean;
    isAdmin: boolean;
    contragentId: number;
    contragent: ContragentResponse;
    isConsentPersonalData: boolean;
    isBlocked: boolean;
    isDeleted: boolean;
    deletedAt: Date;
    locale: Locale;
    calendarType: number;
}

export interface UserItemResponse {
    id: number;
    guidId: string;
    fullName: string;
    contragentId: number;
    position: PositionResponse;
    isDeleted: boolean;
}

export interface UserItemResponseFilter {
    contragentId?: number,
    roleId?: string,
}

export interface AgreementUserRequest {
    isConsentPersonalData: boolean;
}
export interface Employee {
    id: number;
    guidId: string;
    firstName: string;
    lastName: string;
    middleName: string;
    fullName: string;
    isChief: boolean;
    contragentId: number;
    positionId: number;
    position: PositionResponse;
    contragent: ContragentResponse;
}

export interface FilterItemsResponse<TModel> {
    pageSize: number;
    totalItems: number;
    orderBy: string | null;
    items: Array<TModel>;
    totalPages: number;
}

export enum NodeServices {
    Cis,
    AuthService,
    PermitService,
}

export type VForm = Vue & {
    validate: () => boolean;
    resetValidation: () => boolean;
    reset: () => void;
};

export interface OptionsModule extends OptionsTable{
    orderBy?: string;
    id?: number;
    parentId?: number;
}

export interface Module {
    id?: number;
    parentId: number | null;
    nameRu: string;
    nameEn: string;
    roles?: RoleResponse[]
}

export interface OptionsRight extends OptionsTable{
    orderBy?: string;
    id?: number;
    contragentId?: number;
    userId?: number;
    roleId?: number;
    moduleId?: number;
}

export enum DayType {
    Working,
    Shortened,
    Weekend,
    Holiday
}

export enum WeekType {
    Five,
    Six,
}

export interface CalendarFilterRequest {
    year: number;
    weekType: WeekType;
}

export enum CalendarType {
    /* Пятидневная рабочая неделя */
    Five = 0,
    /* Вахтовый рабочий режим */
    Tour = 1,
    /* Шестидневная рабочая неделя */
    Six = 2,
}

export interface BaseCalendar {
    id?: number | null;
    dayType: DayType;
    date: string;
}

export interface CalendarParams {
    year: number | null;
}

export interface UserOptions extends OptionsTable {
    contragentId?: number;
    positionId?: number;
    locale?: number;
    isChief?: boolean;
    calendarType?: number;
}
