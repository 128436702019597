
    import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator';
    import { required } from '@/components/common/validation-rules';
    import { VForm } from '@/types';
    import * as api from '@/api/dynamic';
    import { UpdateActualValue } from '@/types/dynamicDoc';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import DatePickerInput from '@/components/admin/roles-model/users/date-picker-input.vue';

    @Component({
        components: { DatePickerInput, ButtonTooltip },
    })
    export default class ActualVolumeDialog extends Vue {
        @Ref() form!: VForm;
        @PropSync('modal', { type: Boolean, default: () => false })
        isVisible!: boolean;

        @Prop({ type: String })
        physicalVolumeId?: string | null;

        @Prop({ type: String, required: true })
        title!: string;

        valid = true;
        loading = false;
        value: number | null = null;
        startDate: number | null = null;
        endDate: number | null = null;
        rules = { required };

        get actualValue() {
            if (!this.startDate || !this.endDate) return null;
            const startDate = new Date(this.startDate);
            const endDate = new Date(this.endDate);
            return {
                ActualValue: this.value,
                StartDate: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`,
                EndDate: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`,
            };
        }

        close() {
            this.value = null;
            this.startDate = null;
            this.endDate = null;
            this.form.reset();
            this.isVisible = false;
        }

        async save() {
            if (!this.physicalVolumeId || !this.form.validate()) return;
            this.loading = true;
            try {
                await api.physicalVolumeItem
                    .updateActualValue(this.physicalVolumeId, this.actualValue as UpdateActualValue);
                this.$emit('success');
                this.$root.$notify.show('success');
                this.close();
            } catch (error) {
                this.$root.$notify.show('error', this.$t('common.error-please-later') as string);
            } finally {
                this.loading = false;
            }
        }
    }
