
    import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
    import {
        AltitudeMark,
        CoordinateAxi,
        DateType,
        DocumentField,
        DocumentTemplate,
        DynamicDocument,
        DynamicDocumentCategory,
        DynamicDocumentItemResponse,
        DynamicDocumentSchema,
        FieldType,
        IField,
        RelatedFieldItem,
        SignatoryUser,
        TypeControl,
        Work,
        documentPageFormats,
        DynamicEditorOptions,
    } from '@/types/dynamicDoc';
    import * as api from '@/api/dynamic';
    import * as Odata from '@/api/odata';
    import { objectWithoutKey } from '@/util/object-util';
    import DynamicFieldEditor from '@/components/dynamic/common/dynamic-field-editor.vue';
    import DocumentsJournalsDocumentTemplatePopup from './documents-journals-document-template-popup.vue';
    import { InfoFileDesc, UserResponse, VForm, WorkType } from '@/types';
    import { required, requiredEnum, requiredMany, requiredPositiveNumber } from '@/components/common/validation-rules';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { Register } from '@/api/model/interfaces';
    import { localizeDescription, localizeName, localizeText } from '@/util/field-translation';
    import DocumentTabFiles from '@/components/documents-journals/documents/document-tab-files.vue';
    import DocumentHistoryDialog from '@/components/documents-journals/documents/document-history-dialog.vue';
    import DatePickerInput from '@/components/admin/roles-model/users/date-picker-input.vue';
    import ConfirmRemoveDialog from '@/components/admin/roles-model/confirm-remove-dialog.vue';
    import { DrawingPackageStatus, RegisterStatus } from '@/api/constants';
    import {
        CHANGE_DOCUMENT_LIST,
        GET_DOCUMENT,
        GET_DOCUMENT_CATEGORIES,
        GET_DOCUMENT_LINKS,
        GET_DOCUMENTSCHEMA,
        GET_RELATED_DOCUMENTS,
        SET_DOCUMENT,
    } from '@/store/document/actions';
    import DocumentRelated from '@/components/documents-journals/documents/document-related.vue';
    import DocumentLinks from '@/components/documents-journals/documents/document-links.vue';
    import DocumentList from '@/components/documents-journals/documents/document-list.vue';
    import IssueAddDialog from '@/components/asbuilt-docs/issues/issue-add-dialog.vue';
    import * as cisApi from '@/api/cis';
    import { getOperationsForKeys, OperationsByKey } from '@/api/entity/operations';
    import DocumentTabViewer from '@/components/documents-journals/documents/document-tab-viewer.vue';
    import { IssueAddFormOptions } from '@/components/asbuilt-docs/issues/interfaces';
    import SignatoryUsers from '@/components/documents-journals/documents/signatory-users.vue';
    import DragFileUpload from '@/components/files/drag-file-upload.vue';
    import DocumentCategorySelect from '@/components/documents-journals/documents/document-cagegory-select.vue';
    import DocumentTitle from '@/components/documents-journals/documents/document-title.vue';
    import { userFullNameObj } from '@/components/admin/roles-model/users/helpers';
    import ServerAutocomplete from '@/components/common/server-autocomplete.vue';

    export enum Tabs {
        Main,
        File,
        Viewer,
        Related,
        Links,
        ExternalLinks,
    }

    @Component({
        components: {
            DragFileUpload,
            ButtonTooltip,
            DynamicFieldEditor,
            DocumentsJournalsDocumentTemplatePopup,
            DocumentHistoryDialog,
            DocumentTabFiles,
            DatePickerInput,
            ConfirmRemoveDialog,
            DocumentRelated,
            DocumentLinks,
            DocumentList,
            DocumentTabViewer,
            IssueAddDialog,
            SignatoryUsers,
            DocumentCategorySelect,
            DocumentTitle,
            ServerAutocomplete,
        },
        data: () => ({
            Tabs,
            documentPageFormats,
        }),
        methods: {
            localizeDescription,
            localizeName,
            localizeText,
        },
    })
    export default class Document extends Vue {
        @Ref() readonly form!: VForm;
        @Ref() readonly templatePopup!: DocumentsJournalsDocumentTemplatePopup;
        @Ref() readonly issueAddDialog!: IssueAddDialog;
        @PropSync('open', { type: Boolean, default: () => false })
        isOpen!: boolean;

        @PropSync('id', { type: String as () => string | null, default: null })
        documentId!: string | null;

        @Prop({ type: Object }) template?: DocumentTemplate;
        @Prop({ type: String }) drawingPackageId?: string;

        valid = true;
        loading = false;
        isVisibleHistoryPopup = false;
        isEdit = false;
        confirmDialogVisible = false;
        categoryId: string | null = null;
        cisIterationDocuments: DynamicDocumentItemResponse[] = [];
        cisDynamicDocuments: DynamicDocumentItemResponse[] = [];
        documentSchemasCategory: DynamicDocumentSchema[] = [];
        register: Register | null = null;
        reviewOps: OperationsByKey | null = null;
        works: Work[] = [];
        altitudeMarks: AltitudeMark[] = [];
        coordinateAxis: CoordinateAxi[] = [];
        currentVersionId: string | null = null;
        documentUser: UserResponse | null = null;
        infoFileDesc: InfoFileDesc | null = null;
        documentSchema: DynamicDocumentSchema | null = null;
        files: File[] = [];
        loaderPercent: number | null = null;
        errors: any[] = [];
        rules = { required, requiredMany, requiredEnum, requiredPositiveNumber };
        tabItem = Tabs.Main;

        document: Partial<DynamicDocument> = {
            Data: {},
            Number: '',
            SchemaId: null,
            WorkTypes: [],
            AltitudeMarks: [],
            CoordinateAxis: [],
            RelatedFieldItems: [],
            Date: null,
        };

        externalDocuments: DynamicDocument[] = [];

        @Watch('isOpen', { immediate: true, deep: true })
        async init() {
            if (!this.isOpen) return;
            this.loading = true;
            this.documentId ? await this.openEdit() : await this.openCreate();
            if (this.drawingPackageId) this.document.DrawingPackageIds = [ this.drawingPackageId ];
            this.loading = false;
        }

        @Watch('categoryId', { deep: true })
        async changeCategoryId() {
            if (!this.isOpen && !this.categoryId) return;
            await this.getDocumentSchemaCategory();
        }

        @Watch('document.SchemaId', { deep: true })
        async documentSchemaGetById(valueNew: string, valueOld: string) {
            if (!this.document.SchemaId || valueOld === valueNew) return;
            this.documentSchema = await api.documentSchema.getById(this.document.SchemaId);
            await this.$store.dispatch(`document/${GET_DOCUMENT_LINKS}`, this.document.SchemaId);

            if (this.linkedSchemaIds) {
                await this.$store.dispatch(`document/${GET_RELATED_DOCUMENTS}`, this.linkedSchemaIds);
            }
            if (!this.document.Date) {
                this.document.Date = new Date().getTime();
            }
            if (!this.documentId) {
                this.setDefaultData();
            }
        }

        @Watch('document.RegisterId', { immediate: true })
        async onRegisterId() {
            await this.getRegister();
            await this.getCisIterationDocuments();
            await this.getCisDynamicDocuments();
            this.currentVersionId = this.documentId;
        }

        @Watch('document.Date', { immediate: true })
        onChangeDate() {
            if (!this.document.Date) return;
            const fieldName = this.getDateFromSchemaByDateType(DateType.Document);
            if (fieldName.length) this.$set(this.document.Data, fieldName, this.document.Date);
        }

        @Watch('document.Data', { deep: true })
        onChangeDataDate() {
            if (!this.document.Data || !this.documentSchema || !this.documentSchema.FieldSet) return;
            this.$set(this.document, 'StartDate', this.document.Data[this.getDateFromSchemaByDateType(DateType.StartWork)] || null);
            this.$set(this.document, 'EndDate', this.document.Data[this.getDateFromSchemaByDateType(DateType.EndWork)] || null);
        }

        @Watch('document.StartDate', { immediate: true })
        onChangeStartDate() {
            if (!this.document.StartDate) return;
            this.$set(this.document.Data, this.getDateFromSchemaByDateType(DateType.StartWork), this.document.StartDate);
        }

        @Watch('document.EndDate', { immediate: true })
        onChangeEndDate() {
            if (!this.document.EndDate) return;
            this.$set(this.document.Data, this.getDateFromSchemaByDateType(DateType.EndWork), this.document.EndDate);
        }

        @Watch('docNumber', { immediate: true })
        onChangeNumber() {
            if (!this.docNumber) return null;
            if (!this.isView) {
                this.document.Number = this.docNumber;
            }
        }

        @Watch('document.DrawingPackageIds', { immediate: true })
        async onDrawingPackage() {
            if (!this.document.DrawingPackageIds?.length) {
                this.works = [];
                this.altitudeMarks = [];
                this.coordinateAxis = [];
                this.document.WorkTypes = [];
                this.document.AltitudeMarks = [];
                this.document.CoordinateAxis = [];
            }
        }

        @Watch('document.WorkTypes', { immediate: true })
        async onWorkTypeIds() {
            if (!this.document.WorkTypes?.length) {
                this.altitudeMarks = [];
                this.coordinateAxis = [];
                this.document.AltitudeMarks = [];
                this.document.CoordinateAxis = [];
                return;
            }

            const workTypeIds = this.document.WorkTypes.map(x => x.Id as string);
            this.altitudeMarks = await api.altitudeMark.getAltitudeMarkByWorkTypeIdRange(workTypeIds);
            this.coordinateAxis = await api.coordinateAxi.getCoordinateAxiByWorkTypeIdRange(workTypeIds);
        }

        @Watch('documentSchema')
        async onDocumentSchema() {
            await this.onDocumentsList();
            if (!this.documentSchema?.RelatedFields || this.isView) return;
            this.document.Data = {};
            this.document.RelatedFieldItems = this.documentSchema.RelatedFields.map(x => ({
                RelatedFieldId: x.Id,
                ReftableSchemaId: x.ReftableItemId,
                DynamicReftableItemId: null,
                Fields: this.relatedFieldsGrouped[x.Id as string]?.map((f: DocumentField) => f?.TemplateCode),
                FieldsStr: this.relatedFieldsGrouped[x.Id as string]?.map((f: DocumentField) => f?.TemplateCode).join(','),
            })) as RelatedFieldItem[];
            if (this.template) {
                this.document.Data = this.template.Data;
                this.document.Number = this.template.Title;
                this.document.DrawingPackageIds = this.template.DrawingPackageIds;
                this.categoryId = this.documentSchema?.CategoryId as string;
            }
        }

        @Watch('template', { immediate: true })
        onChangeTemplate() {
            if (!this.template) return;
            this.document.SchemaId = this.template.SchemaId;
        }

        get optionsFieldEditor() {
            return {
                data: this.document.Data,
                relatedFieldItems: this.document.RelatedFieldItems,
                document: this.document,
                updatePhysicalVolumeOnly: this.updatePhysicalVolumeOnly,
            } as DynamicEditorOptions;
        }

        get isVersionVisible() {
            return this.versions.length > 1 && this.document.IsAddedInRegister;
        }

        get isDeleteDisabled() {
            return !!(this.document.IsAddedInRegister || this.visibleExternalDocumentsTab);
        }

        get isDeleteTooltip() {
            if (this.visibleExternalDocumentsTab) return this.$t('dynamic.document-delete-external-link');
            if (this.document.IsAddedInRegister) return this.$t('common.add-register');
            return '';
        }

        get relatedFieldsGrouped() {
            if (!this.documentSchema?.FieldSet) return {};
            return this.documentSchema?.FieldSet
                .filter((x: IField) => x.RelatedFieldId)
                .reduce((rv: any, x: any) => {
                    (rv[x.RelatedFieldId] = rv[x.RelatedFieldId] || []).push(x);
                    return rv;
                }, {});
        }

        get categories(): DynamicDocumentCategory[] {
            return this.$store.state.document.documentCategories;
        }

        get documentRootId() {
            return this.document.RootId;
        }

        getDateFromSchemaByDateType(type: DateType) {
            if (!this.documentSchema || !this.documentSchema.FieldSet) return '';
            const field = this.documentSchema?.FieldSet.find(field => field.DateType === type);
            return field ? field.TemplateCode : '';
        }

        get technicalDocumentationTooltip() {
            return this.documentSchema?.TechnicalDocumentationLink
                ? this.$t('common.normative-technical-documentation')
                : this.$t('common.customer-form');
        }

        get documentSchemas(): DynamicDocumentSchema[] {
            return this.$store.state.document.documentSchemas;
        }

        get visibleRelatedDocumentTab() {
            const fields = this.documentSchema?.FieldSet?.filter(x => x.Type === FieldType.Document) || [];
            return !!fields.flatMap(filed => this.document.Data[filed.TemplateCode]).filter(x => !!x)?.length;
        }

        get visibleDocumentLinksTab() {
            return this.isView && this.$store.getters['document/linkedSchemaIds']?.length;
        }

        get visibleExternalDocumentsTab() {
            return this.isView && this.externalDocuments.length;
        }

        get visibleRegister() {
            return Boolean(this.isView && this.document.IsAddedInRegister && this.register);
        }

        get registerId() {
            return this.register?.Id.toString() || null;
        }

        get registerStatusId() {
            return this.register?.StatusId.toString() || null;
        }

        get isDraftRegisterStatus() {
            return this.registerStatusId === RegisterStatus.Draft;
        }

        get isResolvingIssuesRegisterStatus() {
            return this.registerStatusId === RegisterStatus.ResolvingIssues;
        }

        get canAddIssues() {
            return !!this.reviewOps && Object.values(this.reviewOps)
                .some(rop => rop.RegisterIssueCreate.Enabled && rop.RegisterIssueCreate.Available);
        }

        get registerItemInfo() {
            if (!this.document.IsAddedInRegister) return 'Документ не добавлен в реестр';
            return `Реестр ${this.register?.Code} (${localizeDescription(this.register?.Status)})`;
        }

        get btnEditVisible() {
            return !this.document.IsAddedInRegister
                || this.isDraftRegisterStatus
                || this.isResolvingIssuesRegisterStatus
                && this.isLatestVersionSelected;
        }

        get isLatestVersionSelected() {
            const index = this.versions.findIndex(x => x.value === this.document.Id);
            return index > -1 && index === this.versions.length - 1;
        }

        get versions() {
            if (!this.cisDynamicDocuments.length) return [];
            return this.cisDynamicDocuments.map(x => {
                const createAt = new Date(x.CreatedAt).toLocaleString();
                return {
                    text: `${this.$t('common.version')} ${x.VersionNumber} ${this.$t('common.from')} ${createAt}`,
                    value: x.DynamicDocumentId.toString(),
                };
            });
        }

        get isView() {
            return !!this.documentId;
        }

        get isFileUploadVisible() {
            return !this.documentId
                && this.documentSchema
                && !this.documentSchema.TemplateFilePath;
        }

        get cisIterationDocument() {
            if (!this.cisIterationDocuments.length) return null;
            return this.cisIterationDocuments.find(x => x.DynamicDocumentId.toString() === this.documentId);
        }

        get cisDynamicDocument() {
            if (!this.cisDynamicDocuments.length) return null;
            return this.cisDynamicDocuments.find(x => x.DynamicDocumentId.toString() === this.documentId);
        }

        get title() {
            return !this.isView && !this.isDraftRegisterStatus
                ? this.$t('common.create')
                : this.isView && this.isEdit
                    ? this.$t('common.edit')
                    : this.$t('permissions.RegisterView');
        }

        get authorDocument() {
            if (!this.documentUser) return null;
            return `${userFullNameObj(this.documentUser)} (${localizeName(this.documentUser.position)})`;
        }

        get schemaTitle() {
            if (!this.documentSchema) return null;
            return this.documentSchema.Title;
        }

        get docNumber() {
            if (!this.documentSchema?.Number) return '';
            return this.documentSchema?.Number.replace(/\{([^}]+)\}/g, (_, key) => {
                const field = this.documentSchema?.FieldSet?.find(x => x.TemplateCode === key);
                if (!field || !this.document?.Data[key]) return '';
                return field.Type === FieldType.Date
                    ? new Date(this.document?.Data[key]).toLocaleDateString()
                    : String(this.document?.Data[key]);
            });
        }

        get workFormatted() {
            return this.works.map(x => {
                const workType = this.workTypes.find(type => type.Id === x.WorkTypeId);
                return {
                    ...x,
                    text: `${workType?.Code}.${localizeDescription(workType)}`,
                    value: x.Id as string,
                };
            });
        }

        get coordinateAxisFormatted() {
            return this.coordinateAxis.map(axis => {
                const work = this.workFormatted.find(work => work.Id === axis.WorkTypeId)!;
                return {
                    ...axis,
                    text: `${work?.text}/(K:${axis.Name})`,
                };
            });
        }

        get altitudeMarksFormat() {
            return this.altitudeMarks.map(mark => {
                const work = this.workFormatted.find(work => work.Id === mark.WorkTypeId)!;
                return {
                    ...mark,
                    text: `${work?.text}/(B:${mark.Name})`,
                };
            });
        }

        get workTypes(): WorkType[] {
            return this.$store.state.reftables.WorkTypes;
        }

        get isDeleteDocument() {
            return (this.document.UserId === this.currentUser.id || this.isAdmin) && this.isView;
        }

        get isAdmin() {
            return this.$store.getters.isAdmin;
        }

        get currentUser() {
            return this.$store.state.auth.user;
        }

        get linkedSchemaIds() {
            return this.$store.getters['document/linkedSchemaIds'];
        }

        get isPdf(): boolean {
            if (!this.infoFileDesc) return false;
            return this.infoFileDesc.File.ContentType === 'application/pdf';
        }

        get documentSign() {
            if (!this.infoFileDesc?.CurrentSignatureFile) return null;
            return this.infoFileDesc.CurrentSignatureFile;
        }

        get infoFileDescFileName() {
            if (!this.infoFileDesc) return null;
            // eslint-disable-next-line prefer-regex-literals
            const regex = new RegExp(/\.[^/.]+$/);
            return this.infoFileDesc.File.UploadName.replace(regex, '');
        }

        get fileName() {
            return this.document.ArchiveName
                ? this.document.ArchiveName
                : this.infoFileDescFileName;
        }

        get documentDateVisible() {
            return !this.documentSchema?.FieldSet?.find((d: DocumentField) =>
                d.DateType === DateType.Document);
        }

        get drawingPackageFilter() {
            return [
                `StatusId eq ${DrawingPackageStatus.Archived}`,
                'IsActual eq true',
            ];
        }

        async mounted() {
            await this.$store.dispatch(`document/${GET_DOCUMENT_CATEGORIES}`);
        }

        async refresh() {
            await this.getDocument(this.documentId!);
            await this.getExternalDocuments();
            await this.getInfoFileDesc();
        }

        async getRegister() {
            if (!this.document.RegisterId) {
                this.register = null;
                return;
            }
            this.register = await Odata.Register
                .setParams({
                    expand: [ 'Status', 'CurrentIteration($expand=ReviewSet)' ],
                    select: [
                        'Id',
                        'CurrentIterationId',
                        'CurrentIteration',
                        'StatusId',
                        'Status',
                        'Code',
                    ],
                }).getById(this.document.RegisterId);
            if (this.register?.CurrentIteration?.ReviewSet) {
                this.reviewOps = await getOperationsForKeys(
                    [
                        'RegisterReviewStart',
                        'RegisterReviewFinish',
                        'RegisterIssueCreate',
                        'RegisterReviewChangeEndDate',
                    ],
                    this.register.CurrentIteration.ReviewSet.map(r => r.Id.toString()));
            }
        }

        async getCisIterationDocuments() {
            if (!this.register?.CurrentIterationId) return;
            const currentIterationId = this.register.CurrentIterationId.toString();
            this.cisIterationDocuments = await cisApi.document.getDynamicDocuments(currentIterationId);
        }

        async getCisDynamicDocuments() {
            if (!this.cisIterationDocument?.RootId) return;
            this.cisDynamicDocuments = await Odata.DynamicDocumentItem.setParams({
                filter: [ `RootId eq ${this.cisIterationDocument.RootId.toString()}` ],
                orderBy: 'VersionNumber',
            }).getAll() as any;
        }

        async getInfoFileDesc() {
            if (!this.document.FileDescId) return;
            this.infoFileDesc = await api.fileDesc.getInfoFileDesc(this.document.FileDescId);
        }

        async getDocumentUser() {
            if (!this.document.UserId || !this.$store.state.reftables.Users) return;
            this.documentUser = this.$store.state.reftables.Users.find((x: UserResponse) => x.id === this.document.UserId);
        }

        async deleteDocument() {
            try {
                await api.document.remove(this.documentId!);
                this.$emit('updated');
                this.close();
            } catch (e) {
                this.$root.$notify.show('error', this.$t('common.error-please-later') as string);
            } finally {
                this.confirmDialogVisible = false;
            }
        }

        async openLinkedDocument(id: string) {
            this.close();
            this.documentId = id;
            await this.openEdit();
        }

        openHistoryPopup(id: string) {
            this.documentId = id;
            this.isVisibleHistoryPopup = true;
        }

        async getDocumentSchemaCategory() {
            if (!this.categoryId) return;
            this.documentSchemasCategory = await api.documentSchema.getByCategory(this.categoryId, true);
        }

        async selectedRow(id: string) {
            this.currentVersionId = id;
            await this.getDocument(id);
            this.documentId = this.document.Id as string;
        }

        async download() {
            try {
                const res = await api.document.download(this.documentId!);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(res);
                link.download = this.infoFileDescFileName
                    ? this.fileName as string
                    : this.document.Number as string;
                link.click();
            } catch (e) {
                this.$root.$notify.show('error', e.response.data.Message);
            }
        }

        async downloadAll() {
            try {
                const res = await api.document.downloadAllToZip(this.documentId!);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(res);
                link.download = this.infoFileDescFileName
                    ? `${this.fileName}(${this.$t('file-signatures.document-signature')})`
                    : `${this.document.Number}(${this.$t('file-signatures.document-signature')})`;
                link.click();
            } catch (e) {
                this.$root.$notify.show('error', e.response.data.Message);
            }
        }

        async downloadStamp() {
            try {
                const res = await api.document.downloadToQrcodeAndStamp(this.documentId!);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(res);
                link.download = this.infoFileDescFileName
                    ? `${this.fileName}(${this.$t('file-signatures.document-stamp')})`
                    : `${this.document.Number}(${this.$t('file-signatures.document-stamp')})`;
                link.click();
            } catch (e) {
                this.$root.$notify.show('error', e.response.data.Message);
            }
        }

        openTemplatePopup() {
            this.templatePopup.open();
        }

        addIssue() {
            if (!this.cisIterationDocument) return;
            this.issueAddDialog.show({
                dynamicDocumentItemId: this.cisIterationDocument.Id.toString(),
                documentId: this.cisIterationDocument.DynamicDocumentId,
            } as IssueAddFormOptions);
        }

        async openCreate() {
            this.isEdit = true;
            this.isOpen = true;
        }

        async openEdit() {
            await this.refresh();
            await this.getDocumentUser();
            this.isOpen = true;
        }

        close() {
            this.$store.commit(`document/${GET_DOCUMENTSCHEMA}`, null);
            this.$store.commit(`document/${CHANGE_DOCUMENT_LIST}`, []);
            this.$store.commit(`document/${SET_DOCUMENT}`, null);
            this.documentId = null;
            this.tabItem = Tabs.Main;
            this.cisDynamicDocuments = [];
            this.cisIterationDocuments = [];
            this.documentSchema = null;
            this.categoryId = null;
            this.document = {
                Data: {},
                Number: '',
                SchemaId: null,
                WorkTypes: [],
                AltitudeMarks: [],
                CoordinateAxis: [],
                Date: null,
            };
            this.infoFileDesc = null;
            this.files = [];
            this.loaderPercent = null;
            this.isEdit = false;
            this.isOpen = false;
            this.confirmDialogVisible = false;
            if (this.form) this.form.resetValidation();
        }

        async save() {
            if (!this.form.validate()) return;
            if (!this.isView && !this.isResolvingIssuesRegisterStatus) {
                await this.createdDocument();
            } else if (this.isView && this.isResolvingIssuesRegisterStatus) {
                await this.newVersionDocument();
            } else {
                await this.updateDocument();
            }
        }

        async updatePhysicalVolumeOnly() {
            if (!this.documentId) return;
            if (this.isResolvingIssuesRegisterStatus) {
                await this.newVersionDocument();
            } else {
                this.loading = true;
                try {
                    const document = objectWithoutKey(this.document!, 'Id') as DynamicDocument;
                    await api.document.updatePhysicalVolumeOnly(this.documentId, document);
                    this.$root.$notify.show('success');
                    this.$emit('updated');
                } catch (e) {
                    this.$root.$notify.show('error', this.$t('common.error-please-later') as string);
                } finally {
                    this.loading = false;
                    await this.refresh();
                }
            }
        }

        async updateDocument() {
            this.loading = true;
            try {
                await api.document.update(this.documentId!, objectWithoutKey(this.document!, 'Id') as DynamicDocument);
                this.$root.$notify.show('success');
                this.$emit('updated');
                this.isEdit = false;
            } catch (e) {
                this.$root.$notify.show('error', this.$t('common.error-please-later') as string);
                this.errors = [ ...this.errors, ...e.response.data.flat() ];
            } finally {
                this.loading = false;
                await this.refresh();
            }
        }

        async createCopyDocument(id: string) {
            this.isOpen = false;
            this.isEdit = false;
            this.categoryId = this.documentSchema?.CategoryId || null;
            this.documentId = null;
            await this.openCreate();
            await this.getDocument(id);
            this.register = null;
            this.document.Number = '';
        }

        async createdDocument() {
            this.loading = true;
            try {
                const document = await api.document.create(objectWithoutKey(this.document!, 'Id') as DynamicDocument);
                if (this.files.length) {
                    await api.document.upload(document.Id, this.files[0], null, {
                        onUploadProgress: (progressEvent) => {
                            this.loaderPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        },
                    });
                }
                this.$emit('created', document.Id);
                this.$root.$notify.show('success');
                this.$emit('updated');
                this.close();
            } catch (e) {
                this.$root.$notify.show('error', this.$t('common.error-please-later') as string);
                this.errors = [ ...this.errors, ...e.response.data.flat() ];
                this.loaderPercent = null;
            } finally {
                this.loading = false;
            }
        }

        async newVersionDocument() {
            try {
                this.loading = true;
                await Odata.Register.runAction('AddNewVersionDynamicDocument', this.registerId, {
                    DynamicDocument: this.document,
                    PreviousDocumentId: this.cisDynamicDocument?.Id,
                });
                this.$root.$notify.show('success');
                this.$emit('updated');
            } catch (e) {
                this.$root.$notify.show('error', this.$t('common.error-please-later') as string);
                this.errors = [ ...this.errors, ...e.response.data.flat() ];
            } finally {
                await this.refresh();
                await this.getCisDynamicDocuments();
                await this.getCisIterationDocuments();
                await this.selectedRow(this.versions[this.versions.length - 1].value);
                this.isEdit = false;
                this.loading = false;
            }
        }

        async getDocument(id: string) {
            await this.$store.dispatch(`document/${GET_DOCUMENT}`, id);
            this.document = JSON.parse(JSON.stringify(this.$store.state.document.document));
        }

        async getExternalDocuments() {
            if (!this.documentId) return;
            this.externalDocuments = await api.document.getExternalDocuments(this.documentId);
        }

        openNTD() {
            if (!this.documentSchema || !this.documentSchema.TechnicalDocumentationLink) return;
            window.open(this.documentSchema.TechnicalDocumentationLink, '_blank');
        }

        onChangeDocumentDate() {
            const fields = this.documentSchema?.FieldSet?.filter(x => x.TypeControl === TypeControl.Nostroy);
            if (!fields) return;

            for (const field of fields) {
                const item = this.document.Data[field.TemplateCode];
                if (!item) continue;
                if (field.TypeControl === TypeControl.Nostroy) continue;
                this.document.Data[field.TemplateCode] = null;
            }
        }

        async onDocumentsList() {
            if (!this.document.Data || !this.documentSchema || !this.documentSchema.FieldSet?.length) return;

            const documentsFields = this.documentSchema.FieldSet.filter(x => x.Type === FieldType.Document);
            if (!documentsFields) return;

            const documentIds = documentsFields
                .filter(field => !!this.document.Data[field.TemplateCode])
                .flatMap(field => this.document.Data[field.TemplateCode].flatMap((x: string) => x));
            if (!documentIds.length) return;

            const documents = await api.document.getByIdRange(documentIds);
            const documentsList = documents.map(doc => ({
                text: `${doc.Schema?.Title} №${doc.Number} от ${new Date(doc.Date as number).toLocaleDateString()}`,
                value: doc.Id,
                isBlock: doc.IsBlock,
            }));
            this.$store.commit(`document/${CHANGE_DOCUMENT_LIST}`, documentsList);
        }

        addPhysicalVolume(id: string) {
            const work = this.works.find(work => work.WorkTypeId === id);
            if (!work) return;
            const isDoubleWork = this.document.WorkTypes?.some(x => x.Id === work.Id);
            if (isDoubleWork) {
                this.$root.$notify.show('warning', 'Такой вид работ уже содержится в документе', 5000);
            } else {
                this.document.WorkTypes!.push(work as any);
            }
        }

        goToDocument() {
            const route = this.$router.resolve({
                name: 'document-page',
                params: { documentId: this.documentId as string },
            });
            window.open(route.href, '_blank');
        }

        onChangeSignatoryUsers(value: SignatoryUser[]) {
            this.document.SignatoryUsers = value;
        }

        setDefaultData() {
            if (!this.documentSchema || !this.document) return;

            const fields = this.documentSchema?.FieldSet?.filter(x => x.DefaultValue);
            if (!fields) return;

            for (const field of fields) {
                this.document.Data[field.TemplateCode] = field.DefaultValue;
            }
            if (this.documentSchema.DefaultPageCount) {
                this.document.PageCount = this.documentSchema.DefaultPageCount;
            }
            if (this.documentSchema.DefaultPageFormat) {
                this.document.PageFormat = this.documentSchema.DefaultPageFormat;
            }
        }
    }
