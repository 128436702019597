import { cisOdataInstance } from '@/api/cis/cis-instance';
import WorkTypeOdata from '@/api/odata/reftable/work-type-odata';
import RegisterOdata from '@/api/odata/register/register-odata';
import RegisterReviewOdata from '@/api/odata/register/register-review-odata';
import RegisterItemOdata from '@/api/odata/register/register-item-odata';
import RegisterIssueOdata from '@/api/odata/register/register-issue-odata';
import CertifiersImportOdata from '@/api/odata/admin/certifiers-import-odata';
import RegisterItemDiffOdata from '@/api/odata/register/register-item-diff-odata';
import RegisterReviewerOdata from '@/api/odata/register/register-reviewer-odata';
import AsBuiltAppSettingsOdata from '@/api/odata/admin/asbuilt-appsettings-odata';
import WidgetAppSettingsOdata from '@/api/odata/admin/widget-appsettings-odata';
import DrawingPackageOdata from '@/api/odata/drawing/drawing-odata';
import DrawingPackageIssueOdata from '@/api/odata/drawing/drawing-issue-odata';
import DrawingPackageMarkaOdata from '@/api/odata/drawing/drawing-marka-odata';
import DrawingPackageTitleObjectOdata from '@/api/odata/drawing/drawing-title-object-odata';
import DrawingPackageAttachmentOdata from '@/api/odata/drawing/drawing-attachment-odata';
import DrawingPackageIssueAttachmentOdata from '@/api/odata/drawing/drawing-issue-attachment-odata';
import DrawingPackageIssueCommentOdata from '@/api/odata/drawing/drawing-issue-comment-odata';
import DrawingPackageIssueCommentAttachmentOdata from '@/api/odata/drawing/drawing-issue-comment-attachment-odata';
import DrawingPackageIssueStatusOdata from '@/api/odata/drawing/drawing-issue-status-odata';
import DrawingPackageIssueStatusHistoryOdata from '@/api/odata/drawing/drawing-issue-status-history-odata';
import DrawingPackageItemDiffOdata from '@/api/odata/drawing/drawing-item-diff-odata';
import DrawingPackageIterationOdata from '@/api/odata/drawing/drawing-iteration-odata';
import DrawingPackageReviewOdata from '@/api/odata/drawing/drawing-review-odata';
import DrawingPackageReviewerOdata from '@/api/odata/drawing/drawing-reviewer-odata';
import DrawingPackageReviewStatusOdata from '@/api/odata/drawing/drawing-review-status-odata';
import DrawingPackageReviewStatusHistoryOdata from '@/api/odata/drawing/drawing-review-status-history-odata';
import DrawingPackagesAppSettingsOdata from '@/api/odata/admin/drawing-appsettings-odata';
import DrawingPackagesFileDescOdata from '@/api/odata/drawing/drawing-filedesc-odata';
import DrawingPackageStatusOdata from '@/api/odata/drawing/drawing-status-odata';
import DrawingReportsOdata from '@/api/odata/drawing/drawing-reports-odata';
import AppSettingsOdata from '@/api/odata/admin/appsettings-odata';
import CommonAppSettingsOdata from '@/api/odata/admin/common-appsettings-odata';
import KS2AppSettingsOdata from '@/api/odata/admin/ks2-appsettings-odata';
import RegisterLineOdata from '@/api/odata/register/register-line-odata';
import RegisterExpertCommentaryOdata from '@/api/odata/register/register-expert-commentary-odata';
import RegisterIssueAttachmentOdata from '@/api/odata/register/register-issue-attachment-odata';
import RegisterIssueCommentOdata from '@/api/odata/register/register-issue-comment-odata';
import RegisterIssueCommentAttachmentOdata from '@/api/odata/register/register-issue-comment-attachment-odata';
import RegisterIssueStatusOdata from '@/api/odata/register/register-issue-status-odata';
import RegisterIssueStatusHistoryOdata from '@/api/odata/register/register-issue-status-history-odata';
import RegisterIterationOdata from '@/api/odata/register/register-iteration-odata';
import RegisterLogOdata from '@/api/odata/register/register-log-odata';
import RegisterReviewStatusOdata from '@/api/odata/register/register-review-status-odata';
import RegisterStatusOdata from '@/api/odata/register/register-status-odata';
import RegisterStatusHistoryOdata from '@/api/odata/register/register-status-history-odata';
import PackageKS2Odata from '@/api/odata/ks2/ks2-odata';
import PackageKS2DrawingPackageOdata from '@/api/odata/ks2/ks2-drawing-odata';
import PackageKS2RegisterOdata from '@/api/odata/ks2/ks2-register-odata';
import PackageKS2DocumentOdata from '@/api/odata/ks2/ks2-document-odata';
import PackageKS2DocumentAttachmentOdata from '@/api/odata/ks2/ks2-document-attachment-odata';
import PackageKS2DocumentStatusOdata from '@/api/odata/ks2/ks2-document-status-odata';
import PackageKS2DocumentTypeOdata from '@/api/odata/ks2/ks2-document-type-odata';
import PackageKS2IssueOdata from '@/api/odata/ks2/ks2-issue-odata';
import PackageKS2IssueAttachmentOdata from '@/api/odata/ks2/ks2-issue-attachment-odata';
import PackageKS2IssueCommentOdata from '@/api/odata/ks2/ks2-issue-comment-odata';
import PackageKS2IssueCommentAttachmentOdata from '@/api/odata/ks2/ks2-issue-comment-attachment-odata';
import PackageKS2IssueStatusOdata from '@/api/odata/ks2/ks2-issue-status-odata';
import PackageKS2IssueStatusHistoryOdata from '@/api/odata/ks2/ks2-issue-status-history-odata';
import PackageKS2ItemOdata from '@/api/odata/ks2/ks2-item-odata';
import PackageKS2ItemDiffOdata from '@/api/odata/ks2/ks2-item-diff-odata';
import PackageKS2IterationOdata from '@/api/odata/ks2/ks2-iteration-odata';
import PackageKS2ReviewOdata from '@/api/odata/ks2/ks2-review-odata';
import PackageKS2ReviewerOdata from '@/api/odata/ks2/ks2-reviewer-odata';
import PackageKS2ReviewStatusOdata from '@/api/odata/ks2/ks2-review-status-odata';
import PackageKS2ReviewStatusHistoryOdata from '@/api/odata/ks2/ks2-review-status-history-odata';
import PackageKS2StatusOdata from '@/api/odata/ks2/ks2-status-odata';
import PackageKS2StatusHistoryOdata from '@/api/odata/ks2/ks2-status-history-odata';
import ProjectSectionOdata from '@/api/odata/reftable/project-section-odata';
import MarkaOdata from '@/api/odata/reftable/marka-odata';
import TitleObjectOdata from '@/api/odata/reftable/title-object-odata';
import LineOdata from '@/api/odata/reftable/line-odata';
import FinalBookOdata from '@/api/odata/reftable/final-book-odata';
import DynamicDocumentItemOdata from '@/api/odata/other/dynamic-document-item-odata';
import NotificationOdata from '@/api/odata/other/notification-odata';
import AuditOdata from '@/api/odata/other/audit-odata';
import AsBuiltReportsOdata from '@/api/odata/other/asbuilt-reports-odata';
import KS2ReportsOdata from '@/api/odata/other/ks2-reports-odata';
import KS2FileDescOdata from '@/api/odata/ks2/ks2-file-desc-odata';
import ContractOdata from '@/api/odata/reftable/contract-odata';
import ParagraphOdata from '@/api/odata/reftable/paragraph-odata';
import MeasurementUnitOdata from '@/api/odata/reftable/measurement-unit-odata';
import ReftableOdata from '@/api/odata/reftable/reftable-odata';
import DocumentTypeOdata from '@/api/odata/other/document-type-odata';
import ProjectDocumentationStageOdata from '@/api/odata/reftable/project-documentation-stage-odata';
import AgreementSettingsOdata from '@/api/odata/admin/agreement-settings-odata';
import RegisterJournalOdata from '@/api/odata/register/register-journal-odata';
import RegisterProjectCodeOdata from '@/api/odata/reftable/register-project-code-odata';
import DocumentCategoryOdata from '@/api/odata/reftable/document-category-odata';
import ConstructionAreaOdata from '@/api/odata/reftable/construction-area-odata';
import FacilityOdata from '@/api/odata/reftable/facility-odata';
import AdditionalAgreementOdata from '@/api/odata/reftable/additional-agreement-odata';
import KfoOdata from '@/api/odata/reftable/kfo-odata';
import ExternalRegisterOdata from '@/api/odata/reftable/external-register-odata';
import VolumeTypeOdata from '@/api/odata/reftable/volume-type-odata';
import TopKfoOdata from '@/api/odata/reftable/top-kfo-odata';
import PnrTitleObjectOdata from '@/api/odata/reftable/pnr-title-object-odata';
import PnrDocumentTypeOdata from '@/api/odata/reftable/pnr-document-type-odata';

// Reftable
export const WorkType = new WorkTypeOdata('WorkType', cisOdataInstance);
export const ProjectSection = new ProjectSectionOdata('ProjectSection', cisOdataInstance);
export const Marka = new MarkaOdata('Marka', cisOdataInstance);
export const TitleObject = new TitleObjectOdata('TitleObject', cisOdataInstance);
export const Line = new LineOdata('Line', cisOdataInstance);
export const FinalBook = new FinalBookOdata('FinalBook', cisOdataInstance);
export const Contract = new ContractOdata('Contract', cisOdataInstance);
export const Paragraph = new ParagraphOdata('Paragraph', cisOdataInstance);
export const MeasurementUnit = new MeasurementUnitOdata('MeasurementUnit', cisOdataInstance);
export const Reftable = new ReftableOdata('Reftable', cisOdataInstance);
export const ProjectDocumentationStage = new ProjectDocumentationStageOdata('ProjectDocumentationStage', cisOdataInstance);
export const RegisterProjectCode = new RegisterProjectCodeOdata('RegisterProjectCode', cisOdataInstance);
export const DocumentCategory = new DocumentCategoryOdata('DocumentCategory', cisOdataInstance);
export const ConstructionArea = new ConstructionAreaOdata('ConstructionArea', cisOdataInstance);
export const Facility = new FacilityOdata('Facility', cisOdataInstance);
export const AdditionalAgreement = new AdditionalAgreementOdata('AdditionalAgreement', cisOdataInstance);
export const Kfo = new KfoOdata('Kfo', cisOdataInstance);
export const ExternalRegister = new ExternalRegisterOdata('ExternalRegister', cisOdataInstance);
export const VolumeType = new VolumeTypeOdata('VolumeType', cisOdataInstance);
export const TopKfo = new TopKfoOdata('TopKfo', cisOdataInstance);
export const PnrTitleObject = new PnrTitleObjectOdata('PnrTitleObject', cisOdataInstance);
export const PnrDocumentType = new PnrDocumentTypeOdata('PnrDocumentType', cisOdataInstance);

// Register
export const Register = new RegisterOdata('Register', cisOdataInstance);
export const RegisterLine = new RegisterLineOdata('Register_Line', cisOdataInstance);
export const RegisterExpertCommentary = new RegisterExpertCommentaryOdata('RegisterExpertCommentary', cisOdataInstance);
export const RegisterIssue = new RegisterIssueOdata('RegisterIssue', cisOdataInstance);
export const RegisterIssueAttachment = new RegisterIssueAttachmentOdata('RegisterIssueAttachment', cisOdataInstance);
export const RegisterIssueComment = new RegisterIssueCommentOdata('RegisterIssueComment', cisOdataInstance);
export const RegisterIssueCommentAttachment = new RegisterIssueCommentAttachmentOdata('RegisterIssueCommentAttachment', cisOdataInstance);
export const RegisterIssueStatus = new RegisterIssueStatusOdata('RegisterIssueStatus', cisOdataInstance);
export const RegisterIssueStatusHistory = new RegisterIssueStatusHistoryOdata('RegisterIssueStatusHistory', cisOdataInstance);
export const RegisterItem = new RegisterItemOdata('RegisterItem', cisOdataInstance);
export const RegisterItemDiff = new RegisterItemDiffOdata('RegisterItemDiff', cisOdataInstance);
export const RegisterIteration = new RegisterIterationOdata('RegisterIteration', cisOdataInstance);
export const RegisterLog = new RegisterLogOdata('RegisterLog', cisOdataInstance);
export const RegisterReview = new RegisterReviewOdata('RegisterReview', cisOdataInstance);
export const RegisterReviewer = new RegisterReviewerOdata('RegisterReviewer', cisOdataInstance);
export const RegisterReviewStatus = new RegisterReviewStatusOdata('RegisterReviewStatus', cisOdataInstance);
export const RegisterStatus = new RegisterStatusOdata('RegisterStatus', cisOdataInstance);
export const RegisterStatusHistory = new RegisterStatusHistoryOdata('RegisterStatusHistory', cisOdataInstance);
export const RegisterJournal = new RegisterJournalOdata('RegisterJournal', cisOdataInstance);

// DrawingPackage
export const DrawingPackage = new DrawingPackageOdata('DrawingPackage', cisOdataInstance);
export const DrawingPackageAttachment = new DrawingPackageAttachmentOdata('DrawingPackageAttachment', cisOdataInstance);
export const DrawingPackageMarka = new DrawingPackageMarkaOdata('DrawingPackage_Marka', cisOdataInstance);
export const DrawingPackageTitleObject = new DrawingPackageTitleObjectOdata('DrawingPackage_TitleObject', cisOdataInstance);
export const DrawingPackageIssue = new DrawingPackageIssueOdata('DrawingPackageIssue', cisOdataInstance);
export const DrawingPackageIssueAttachment = new DrawingPackageIssueAttachmentOdata('DrawingPackageIssueAttachment', cisOdataInstance);
export const DrawingPackageIssueComment = new DrawingPackageIssueCommentOdata('DrawingPackageIssueComment', cisOdataInstance);
export const DrawingPackageIssueCommentAttachment = new DrawingPackageIssueCommentAttachmentOdata('DrawingPackageIssueCommentAttachment', cisOdataInstance);
export const DrawingPackageIssueStatus = new DrawingPackageIssueStatusOdata('DrawingPackageIssueStatus', cisOdataInstance);
export const DrawingPackageIssueStatusHistory = new DrawingPackageIssueStatusHistoryOdata('DrawingPackageIssueStatusHistory', cisOdataInstance);
export const DrawingPackageItemDiff = new DrawingPackageItemDiffOdata('DrawingPackageItemDiff', cisOdataInstance);
export const DrawingPackageIteration = new DrawingPackageIterationOdata('DrawingPackageIteration', cisOdataInstance);
export const DrawingPackageReview = new DrawingPackageReviewOdata('DrawingPackageReview', cisOdataInstance);
export const DrawingPackageReviewer = new DrawingPackageReviewerOdata('DrawingPackageReviewer', cisOdataInstance);
export const DrawingPackageReviewStatus = new DrawingPackageReviewStatusOdata('DrawingPackageReviewStatus', cisOdataInstance);
export const DrawingPackageReviewStatusHistory = new DrawingPackageReviewStatusHistoryOdata('DrawingPackageReviewStatusHistory', cisOdataInstance);
export const DrawingPackagesFileDesc = new DrawingPackagesFileDescOdata('DrawingPackagesFileDesc', cisOdataInstance);
export const DrawingPackageStatus = new DrawingPackageStatusOdata('DrawingPackageStatus', cisOdataInstance);
export const DrawingReports = new DrawingReportsOdata('DrawingReports', cisOdataInstance);

// KS2
export const PackageKS2 = new PackageKS2Odata('PackageKS2', cisOdataInstance);
export const PackageKS2DrawingPackage = new PackageKS2DrawingPackageOdata('PackageKS2_DrawingPackage', cisOdataInstance);
export const PackageKS2Register = new PackageKS2RegisterOdata('PackageKS2_Register', cisOdataInstance);
export const PackageKS2Document = new PackageKS2DocumentOdata('PackageKS2Document', cisOdataInstance);
export const PackageKS2DocumentAttachment = new PackageKS2DocumentAttachmentOdata('PackageKS2DocumentAttachment', cisOdataInstance);
export const PackageKS2DocumentStatus = new PackageKS2DocumentStatusOdata('PackageKS2DocumentStatus', cisOdataInstance);
export const PackageKS2DocumentType = new PackageKS2DocumentTypeOdata('PackageKS2DocumentType', cisOdataInstance);
export const PackageKS2Issue = new PackageKS2IssueOdata('PackageKS2Issue', cisOdataInstance);
export const PackageKS2IssueAttachment = new PackageKS2IssueAttachmentOdata('PackageKS2IssueAttachment', cisOdataInstance);
export const PackageKS2IssueComment = new PackageKS2IssueCommentOdata('PackageKS2IssueComment', cisOdataInstance);
export const PackageKS2IssueCommentAttachment = new PackageKS2IssueCommentAttachmentOdata('PackageKS2IssueCommentAttachment', cisOdataInstance);
export const PackageKS2IssueStatus = new PackageKS2IssueStatusOdata('PackageKS2IssueStatus', cisOdataInstance);
export const PackageKS2IssueStatusHistory = new PackageKS2IssueStatusHistoryOdata('PackageKS2IssueStatusHistory', cisOdataInstance);
export const PackageKS2Item = new PackageKS2ItemOdata('PackageKS2Item', cisOdataInstance);
export const PackageKS2ItemDiff = new PackageKS2ItemDiffOdata('PackageKS2ItemDiff', cisOdataInstance);
export const PackageKS2Iteration = new PackageKS2IterationOdata('PackageKS2Iteration', cisOdataInstance);
export const PackageKS2Review = new PackageKS2ReviewOdata('PackageKS2Review', cisOdataInstance);
export const PackageKS2Reviewer = new PackageKS2ReviewerOdata('PackageKS2Reviewer', cisOdataInstance);
export const PackageKS2ReviewStatus = new PackageKS2ReviewStatusOdata('PackageKS2ReviewStatus', cisOdataInstance);
export const PackageKS2ReviewStatusHistory = new PackageKS2ReviewStatusHistoryOdata('PackageKS2ReviewStatusHistory', cisOdataInstance);
export const PackageKS2Status = new PackageKS2StatusOdata('PackageKS2Status', cisOdataInstance);
export const PackageKS2StatusHistory = new PackageKS2StatusHistoryOdata('PackageKS2StatusHistory', cisOdataInstance);
export const KS2FileDesc = new KS2FileDescOdata('KS2FileDesc', cisOdataInstance);

// Administrations
export const AsBuiltAppSettings = new AsBuiltAppSettingsOdata('AsBuiltAppSettings', cisOdataInstance);
export const DrawingPackagesAppSettings = new DrawingPackagesAppSettingsOdata('DrawingPackagesAppSettings', cisOdataInstance);
export const AppSettings = new AppSettingsOdata('AppSettings', cisOdataInstance);
export const CommonAppSettings = new CommonAppSettingsOdata('CommonAppSettings', cisOdataInstance);
export const KS2AppSettings = new KS2AppSettingsOdata('KS2AppSettings', cisOdataInstance);
export const AgreementSettings = new AgreementSettingsOdata('AgreementSettings', cisOdataInstance);
export const WidgetAppSettings = new WidgetAppSettingsOdata('WidgetAppSettings', cisOdataInstance);

// Other
export const CertifiersImport = new CertifiersImportOdata('CertifiersImport', cisOdataInstance);
export const DynamicDocumentItem = new DynamicDocumentItemOdata('DynamicDocumentItem', cisOdataInstance);
export const Notification = new NotificationOdata('Notification', cisOdataInstance);
export const Audit = new AuditOdata('Audit', cisOdataInstance);
export const AsBuiltReports = new AsBuiltReportsOdata('AsBuiltReports', cisOdataInstance);
export const KS2Reports = new KS2ReportsOdata('KS2Reports', cisOdataInstance);
export const DocumentType = new DocumentTypeOdata('DocumentType', cisOdataInstance);
