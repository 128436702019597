import HomePage from '@/components/home/index.vue';
import LoginPage from '@/components/account/login-page.vue';
import AsbuiltIndex from '@/components/asbuilt-docs/index.vue';
import DrawingDocsIndex from '@/components/drawing-docs/index.vue';
import PhysicalVolumeIndex from '@/components/asbuilt-docs/volume/index.vue';
import RegisterInterface from '@/components/asbuilt-docs/registers/index.vue';
import AsBuiltReportsIndex from '@/components/asbuilt-docs/reports/index.vue';
import { RouteConfig } from 'vue-router';
import { RightNameConstants } from '@/types/right-name-constants';

const routes: RouteConfig[] = [
    {
        name: 'home',
        path: '/',
        component: HomePage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'login',
        path: '/login',
        component: LoginPage,
        meta: {
            layout: 'blank',
        },
    },
    {
        name: 'asbuilt',
        component: AsbuiltIndex,
        redirect: { name: 'registers' },
        path: '/asbuilt',
        meta: {
            requiresAuth: true,
            requirePermission: [ RightNameConstants.ExecutiveDocumentationModule ],
            nav: true,
            title: 'common.asbuilt',
            icon: 'mdi-file-chart',
            order: 5,
        },
        children: [
            {
                name: 'registers',
                path: 'registers',
                component: RegisterInterface,
                props: route => ({
                    statusId: route.params.statusId,
                    subContractorId: route.params.subContractorId,
                }),
            },
            {
                name: 'register-page',
                path: 'registers/:registerId/:iterationId?',
                component: () => import('@/components/asbuilt-docs/registers/register-page.vue'),
                props: route => ({
                    registerId: route.params.registerId,
                }),
            },
            {
                name: 'volume',
                path: 'volume/:subItem',
                component: PhysicalVolumeIndex,
                props: true,
            },
            {
                name: 'asbuilt-reports',
                path: 'reports/:reportName',
                component: AsBuiltReportsIndex,
                props: true,
            },
            {
                name: 'terms-of-work',
                path: 'terms-of-work',
                redirect: { name: 'terms-of-work.registries-for-review' },
                component: () => import('@/components/asbuilt-docs/registries-for-review/index.vue'),
                children: [
                    {
                        name: 'terms-of-work.registries-for-review',
                        path: 'registries-for-review',
                        component: () => import('@/components/asbuilt-docs/registries-for-review/register-for-review.vue'),
                    },
                    {
                        name: 'terms-of-work.register-task-list',
                        path: 'register-task',
                        component: () => import('@/components/asbuilt-docs/registries-for-review/register-task-list.vue'),
                    },
                ],
            },
        ],
    },
    {
        name: 'working-drawings',
        path: '/working-drawings',
        component: DrawingDocsIndex,
        redirect: { name: 'drawing-packages' },
        meta: {
            requiresAuth: true,
            requirePermission: [ RightNameConstants.WorkingDrawingsModule ],
            icon: 'mdi-file-cad',
            nav: true,
            title: 'common.working-drawings',
            order: 7,
        },
        children: [
            {
                name: 'drawing-packages',
                path: 'packages',
                component: () => import('@/components/drawing-docs/drawing-packages/index.vue'),
            },
            {
                name: 'drawing-package-page',
                path: 'packages/:drawingPackageId/:iterationId?',
                component: () => import('@/components/drawing-docs/drawing-packages/drawing-package-page.vue'),
                props: route => ({
                    drawingPackageId: route.params.drawingPackageId,
                }),
            },
            {
                name: 'drawing-reports',
                path: 'reports/:reportName',
                component: () => import('@/components/drawing-docs/reports/index.vue'),
                props: true,
            },
        ],
    },
    {
        name: 'ks2',
        path: '/ks2',
        component: () => import('@/components/ks2/index.vue'),
        redirect: { name: 'ks2-packages' },
        meta: {
            requiresAuth: true,
            requirePermission: [ RightNameConstants.Ks2Module ],
            nav: true,
            title: 'common.ks2',
            icon: 'mdi-file',
            order: 10,
        },
        children: [
            {
                name: 'ks2-packages',
                path: 'packages',
                component: () => import('@/components/ks2/packages/index.vue'),
            },
            {
                name: 'ks2-package-page',
                path: 'packages/:packageId',
                component: () => import('@/components/ks2/packages/ks2-package-page.vue'),
                props: true,
            },
            {
                name: 'ks2-documents',
                path: 'documents',
                component: () => import('@/components/ks2/documents/index.vue'),
            },
            {
                name: 'ks2-document-page',
                path: 'documents/:documentId',
                component: () => import('@/components/ks2/documents/ks2-document-page.vue'),
                props: true,
            },
            {
                name: 'ks2-reports',
                path: 'reports/:reportName',
                component: () => import('@/components/ks2/reports/index.vue'),
                props: true,
            },
        ],
    },
    {
        name: 'reftables',
        path: '/reftables/:modelName?',
        component: () => import('@/components/reftables/index.vue'),
        props: true,
        meta: {
            requiresAuth: true,
            title: 'common.dictionaries',
            icon: 'mdi-bookshelf',
            order: 20,
            nav: true,
            requirePermission: [ RightNameConstants.ReferencesModule ],
        },
    },
    {
        name: 'permit',
        path: '/permit',
        component: () => import('@/components/permit/index.vue'),
        redirect: { name: 'permit-dictionaries' },
        props: true,
        meta: {
            requiresAuth: true,
            title: 'dashboard.permission-documentation',
            icon: 'mdi-file-compare',
            order: 15,
            nav: true,
            requirePermission: [ RightNameConstants.PermissiveDocumentationModule ],
        },
        children: [
            {
                name: 'permit-requirement',
                path: 'requirement',
                component: () => import('@/components/permit/permit-requirement/index.vue'),
            },
            {
                name: 'permit-dictionaries',
                path: 'dictionaries',
                component: () => import('@/components/permit/permit-dictionaries/index.vue'),
                children: [
                    {
                        name: 'permit-dictionaries-items',
                        path: ':schemeId',
                        component: () => import('@/components/permit/permit-dictionaries/permit-dictionaries-item.vue'),
                        props: true,
                    },
                ],
            },
            {
                name: 'permit-document-scheme',
                path: 'permit-document-scheme',
                component: () => import('@/components/permit/permit-document-scheme/index.vue'),
            },
            {
                name: 'permit-document',
                path: 'document',
                component: () => import('@/components/permit/permit-document/index.vue'),
            },
            {
                name: 'permit-examination',
                path: 'examination',
                component: () => import('@/components/permit/permit-examination/index.vue'),
            },
        ],
    },
    {
        name: 'tmc',
        path: '/tmc',
        component: () => import('@/components/tmc/index.vue'),
        redirect: { name: 'tmc-dictionaries' },
        props: true,
        meta: {
            requiresAuth: true,
            title: 'tmc.tmc',
            icon: 'mdi-cash-multiple',
            order: 25,
            nav: true,
            requirePermission: [ RightNameConstants.TmcModule ],
        },
        children: [
            {
                name: 'tmc-dictionaries',
                path: 'directory',
                component: () => import('@/components/tmc/dictionaries/index.vue'),
            },
            {
                name: 'tmc-requirement',
                path: 'requirement',
                component: () => import('@/components/tmc/requirement/index.vue'),
            },
            {
                name: 'tmc-incoming-materials',
                path: 'incoming-materials',
                component: () => import('@/components/tmc/incoming-materials/index.vue'),
            },
            {
                name: 'tmc-incoming-control-acts',
                path: 'incoming-control-acts',
                component: () => import('@/components/tmc/incoming-control-acts/index.vue'),
            },
        ],
    },
    {
        name: 'admin',
        path: '/admin',
        component: () => import('@/components/admin/index.vue'),
        redirect: { name: 'roles-model.users' },
        props: true,
        meta: {
            title: 'common.admin',
            icon: 'mdi-cog',
            order: 99,
            nav: true,
            requiresAuth: true,
            requirePermission: [ RightNameConstants.AdministrationModule ],
        },
        children: [
            {
                name: 'settings',
                path: 'settings',
                meta: {
                    requiresAuth: true,
                    requirePermission: [ RightNameConstants.AdministrationModule ],
                },
                component: () => import('@/components/admin/settings/index.vue'),
                redirect: { name: 'admin-common-settings' },
                children: [
                    {
                        name: 'admin-common-settings',
                        path: 'admin-common-settings',
                        component: () => import('@/components/admin/settings/admin-common-settings.vue'),
                    },
                    {
                        name: 'admin-asbuilt-settings',
                        path: 'admin-asbuilt-settings',
                        component: () => import('@/components/admin/settings/admin-asbuilt-settings/index.vue'),
                    },
                    {
                        name: 'admin-drawing-packages-settings',
                        path: 'admin-drawing-packages-settings',
                        component: () => import('@/components/admin/settings/admin-drawing-packages-settings.vue'),
                    },
                    {
                        name: 'admin-ks2-settings',
                        path: 'admin-ks2-settings',
                        component: () => import('@/components/admin/settings/admin-ks2-settings.vue'),
                    },
                    {
                        name: 'admin-agreement-settings',
                        path: 'admin-agreement-settings',
                        component: () => import('@/components/admin/settings/admin-agreement-settings.vue'),
                    },
                ],
            },
            {
                name: 'revirewer-templates',
                path: 'revirewer-templates',
                meta: {
                    requiresAuth: true,
                    requirePermission: [ RightNameConstants.AdministrationModule ],
                },
                component: () => import('@/components/admin/revirewer-templates/index.vue'),
            },
            {
                name: 'roles-model',
                path: 'roles-model',
                meta: {
                    requiresAuth: true,
                    requirePermission: [ RightNameConstants.AdministrationModule ],
                },
                component: () => import('@/components/admin/roles-model/index.vue'),
                redirect: { name: 'roles-model.users' },
                children: [
                    {
                        name: 'roles-model.users',
                        path: 'users',
                        component: () => import('@/components/admin/roles-model/users/index.vue'),
                    },
                    {
                        name: 'roles-model.positions',
                        path: 'positions',
                        component: () => import('@/components/admin/roles-model/positions/index.vue'),
                    },
                    {
                        name: 'roles-model.contragents',
                        path: 'contragents',
                        component: () => import('@/components/admin/roles-model/contragents/index.vue'),
                    },
                    {
                        name: 'roles-model.responsible-for-reviewrs',
                        path: 'responsible-for-reviewrs',
                        component: () => import('@/components/admin/roles-model/responsible-for-reviewrs/index.vue'),
                    },
                    {
                        name: 'roles-model.roles',
                        path: 'roles',
                        component: () => import('@/components/admin/roles-model/roles/index.vue'),
                    },
                    {
                        name: 'roles-model.role-groups',
                        path: 'role-groups',
                        component: () => import('@/components/admin/roles-model/role-groups/index.vue'),
                    },
                ],
            },
            {
                name: 'production-calendar',
                path: 'production-calendar',
                meta: {
                    requiresAuth: true,
                    requirePermission: [ RightNameConstants.AdministrationModule ],
                },
                component: () => import('@/components/admin/production-calendar/index.vue'),
            },
        ],
    },
    {
        name: 'documents-journals',
        path: '/documents-journals',
        component: () => import('@/components/documents-journals/index.vue'),
        redirect: { name: 'documents-journals-documents' },
        props: true,
        meta: {
            requiresAuth: true,
            title: 'common.documents-journals',
            icon: 'mdi-file-multiple',
            order: 26,
            nav: true,
            requirePermission: [ RightNameConstants.DocumentsAndJournalsModule ],
        },
        children: [
            {
                name: 'documents-journals-references',
                path: 'references',
                component: () => import('@/components/documents-journals/references/index.vue'),
                children: [
                    {
                        name: 'documents-journals-references-item',
                        path: ':schemaId',
                        component: () => import('@/components/documents-journals/references/references-item.vue'),
                        props: true,
                    },
                ],
            },
            {
                name: 'documents-journals-document-templates',
                path: 'document-templates',
                component: () => import('@/components/documents-journals/document-templates/index.vue'),
            },
            {
                name: 'documents-journals-document-schemes',
                path: 'document-schemes',
                component: () => import('@/components/documents-journals/document-schemes/index.vue'),
            },
            {
                name: 'documents-journals-documents',
                path: 'documents',
                component: () => import('@/components/documents-journals/documents/index.vue'),
            },
            {
                name: 'document-page',
                path: 'documents/:documentId',
                component: () => import('@/components/documents-journals/documents/document/index.vue'),
                props: route => ({ documentId: route.params.documentId }),
            },
            {
                name: 'documents-journals-journal-scheme',
                path: 'journal-scheme',
                component: () => import('@/components/documents-journals/journal-scheme/index.vue'),
            },
            {
                name: 'journals',
                path: 'journals',
                component: () => import('@/components/documents-journals/journals/index.vue'),
            },
            {
                name: 'journal-page',
                path: 'journals/:journalId',
                component: () => import('@/components/documents-journals/journals/journal-page.vue'),
                props: route => ({ journalId: route.params.journalId }),
            },
            {
                name: 'documents-journals-document-links',
                path: 'document-links',
                component: () => import('@/components/documents-journals/document-links/index.vue'),
            },
            {
                name: 'documents-journals-completeness-requirements',
                path: 'completeness-requirements',
                component: () => import('@/components/documents-journals/completeness-requirements/index.vue'),
            },
        ],
    },
    {
        name: 'notifications',
        path: '/notifications',
        component: () => import('@/components/notification/notifications.vue'),
    },
    {
        name: 'reports',
        path: '/reports',
        component: () => import('@/components/reports/index.vue'),
        redirect: { name: 'reports-beta' },
        props: true,
        meta: {
            requiresAuth: true,
            title: 'common.reports',
            icon: 'mdi-chart-arc',
            order: 30,
            nav: true,
            requirePermission: [ RightNameConstants.ReportsModule ],
        },
        children: [
            {
                name: 'reports-beta',
                path: 'reports-beta',
                component: () => import('@/components/reports/reports-beta.vue'),
            },
            {
                name: 'reports-generator',
                path: 'reports-generator',
                component: () => import('@/components/reports/reports-generator.vue'),
            },
            {
                name: 'report',
                path: 'report/:reportId',
                component: () => import('@/components/reports/report-item.vue'),
                props: route => ({ reportId: route.params.reportId }),
            },
            {
                name: 'asbuilt-executive-discipline-report',
                path: 'system/asbuilt-executive-discipline',
                component: () => import('@/components/reports/system/asbuilt-executive-discipline-report/index.vue'),
            },
            {
                name: 'general-information-report',
                path: 'system/general-information',
                component: () => import('@/components/reports/system/general-information/index.vue'),
            },
            {
                name: 'report-condition',
                path: 'report-condition',
                component: () => import('@/components/reports/conditions/index.vue'),
            },
        ],
    },
    {
        name: 'security',
        path: '/security',
        component: () => import('@/components/security/index.vue'),
        redirect: { name: 'active-users' },
        props: true,
        meta: {
            requiresAuth: true,
            title: 'common.security',
            icon: 'mdi-shield-account',
            order: 30,
            nav: true,
            requirePermission: [ RightNameConstants.SecurityModule ],
        },
        children: [
            {
                name: 'active-users',
                path: 'active-users',
                component: () => import('@/components/security/active-users/index.vue'),
            },
            {
                name: 'edited-documents',
                path: 'edited-documents',
                component: () => import('@/components/security/edited-documents.vue'),
            },
            {
                name: 'reftable-item-changes',
                path: 'reftable-item-changes',
                component: () => import('@/components/security/reftable-item-changes.vue'),
            },
            {
                name: 'actions-users',
                path: 'actions-users',
                component: () => import('@/components/security/actions-users.vue'),
            },
        ],
    },
    {
        name: 'GeneralDocumentation',
        path: '/general-documentation',
        component: () => import('@/components/general-documentation/index.vue'),
        redirect: { name: 'ChronologyDocumentation' },
        props: true,
        meta: {
            requiresAuth: true,
            title: 'common.general-documentation',
            icon: 'mdi-book-open-page-variant-outline',
            order: 31,
            nav: true,
            requirePermission: [ RightNameConstants.GeneralDocumentationModule ],
        },
        children: [
            {
                name: 'ChronologyDocumentation',
                path: 'chronology-documentation',
                component: () => import('@/components/general-documentation/chronology-documentation.vue'),
            },
            {
                name: 'RegisterDocumentation',
                path: 'register-documentation',
                component: () => import('@/components/general-documentation/register-documentation.vue'),
            },
        ],
    },
];

export default routes;
