import { ActionTree } from 'vuex';
import { AsBuiltAppSettings, CommonAppSettings, GET_ASBUILT_APP_SETTINGS, GET_COMMON_APP_SETTINGS, SET_ASBUILT_APP_SETTINGS, SET_COMMON_APP_SETTINGS, Settings } from '@/store/settings/types';
import * as Odata from '@/api/odata';
import { AppSettingsId } from '@/api/constants';
import { RootState } from '@/store/types';
import { AsBuiltAppSettingsId } from '@/components/asbuilt-docs/constants';

export const actions: ActionTree<Settings, RootState> = {
    [GET_COMMON_APP_SETTINGS]: async({ commit }) => {
        const commonSetting: any = await Odata.CommonAppSettings.getById(AppSettingsId);
        commit(SET_COMMON_APP_SETTINGS, commonSetting as CommonAppSettings);
    },
    [GET_ASBUILT_APP_SETTINGS]: async({ commit }) => {
        const asBuiltAppSettings: any = await Odata.AsBuiltAppSettings.getById(AsBuiltAppSettingsId);
        commit(SET_ASBUILT_APP_SETTINGS, asBuiltAppSettings as AsBuiltAppSettings);
    },
};
