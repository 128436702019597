
    import { Component, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { AutocompleteItem, VForm } from '@/types';
    import { nullOrNumber2length, required } from '@/components/common/validation-rules';
    import { Contract, DocumentCategory, PnrDocumentType, PnrTitleObject, Register, RegisterProjectCode } from '@/api/model/interfaces';
    import * as dynamicApi from '@/api/dynamic';
    import { DrawingPackageStatus } from '@/api/constants';
    import { localizeDescription } from '@/util/field-translation';
    import ServerAutocomplete from '@/components/common/server-autocomplete.vue';

    @Component({
        components: {
            ButtonTooltip,
            ServerAutocomplete,
        },
    })
    export default class RegisterDialog extends Vue {
        @Ref() form!: VForm;

        @PropSync('visible', { required: true })
        isVisible!: boolean;

        valid = true;
        comment: string | null = null;
        documentCategoryId: string | null = null;
        drawingPackageId: string | null = null;
        contractId: string | null = null;
        registerName: string | null = null;
        systemType: string | null = null;
        subSystemCode: string | null = null;
        pnrDocumentTypeId: string | null = null;
        pnrTitleObjectId: string | null = null;
        contractLoading = false;
        contracts: AutocompleteItem[] = [];
        rules = { required, nullOrNumber2length };

        @Watch('drawingPackageId')
        async onDrawingPackageId() {
            await this.getContractsFromDrawingPackage();
        }

        @Watch('documentCategory')
        async onDocumentCategory() {
            this.registerName = null;
        }

        get documentCategories(): DocumentCategory[] {
            return this.$store.state.reftables.DocumentCategories
                .map((x: DocumentCategory) => ({
                    text: `${x.Code}. ${x.Title}`,
                    value: x.Id,
                }));
        }

        get pnrDocumentTypes(): PnrDocumentType[] {
            return this.$store.state.reftables.PnrDocumentTypes
                .map((x: PnrDocumentType) => ({
                    text: `${x.Title}. ${localizeDescription(x)}`,
                    value: x.Id,
                }));
        }

        get pnrTitleObjects(): PnrTitleObject[] {
            return this.$store.state.reftables.PnrTitleObjects
                .map((x: PnrTitleObject) => ({
                    text: x.Title,
                    value: x.Id,
                }));
        }

        get documentCategory(): DocumentCategory {
            return this.$store.state.reftables.DocumentCategories
                .find((x: DocumentCategory) => x.Id === this.documentCategoryId);
        }

        get registerNameVisible() {
            return this.documentCategoryId && !this.documentCategory?.RegisterNameTemplate;
        }

        get projectCode() {
            if (!this.documentCategory) return '';

            const projectCode = this.$store.state.reftables.RegisterProjectCodes
                .find((x: RegisterProjectCode) => x.Id === this.documentCategory.RegisterProjectCodeId);
            if (!projectCode) return '';

            return `${projectCode.Code}. ${projectCode.Title}`;
        }

        get customerDefaultId() {
            return this.$store.state.settings.asBuilt?.CustomerDefaultId || null;
        }

        get contractorDefaultId() {
            return this.$store.state.settings.asBuilt?.ContractorDefaultId || null;
        }

        get register(): Partial<Register> {
            return {
                DrawingPackageId: this.drawingPackageId,
                ContractId: this.contractId,
                CustomerId: this.customerDefaultId,
                ContractorId: this.contractorDefaultId,
                DocumentCategoryId: this.documentCategoryId,
                Comment: this.comment,
                Name: this.registerName,
                SystemType: this.systemType,
                SubSystemCode: this.subSystemCode,
                PnrDocumentTypeId: this.pnrDocumentTypeId,
                PnrTitleObjectId: this.pnrTitleObjectId,
            };
        }

        get drawingPackageFilter() {
            return [
                `StatusId eq ${DrawingPackageStatus.Archived}`,
                'IsActual eq true',
            ];
        }

        async getContractsFromDrawingPackage() {
            if (!this.drawingPackageId) {
                this.contracts = [];
                return;
            }

            this.contractLoading = true;
            const contractIds = await dynamicApi.physicalVolumeItem
                .getContractsByDrawingPackage(this.drawingPackageId);
            this.contractLoading = false;
            if (!contractIds.length) {
                this.contracts = [];
                return;
            }

            this.contracts = this.$store.state.reftables.Contracts
                .filter((x: Contract) => contractIds.includes(x.Id))
                .map((x: Contract) => ({
                    value: x.Id,
                    text: `${x.Code}. ${localizeDescription(x)}`,
                }));
        }

        close() {
            this.form.reset();
            this.form.resetValidation();
            this.isVisible = false;
        }

        add() {
            if (!this.form.validate()) return;
            this.$emit('create', { ...this.register });
            this.close();
        }

        isVisibleField(field: string) {
            return !!this.documentCategory.VisibleRegisterFields?.find(x => x === field);
        }

        isRequiredField(field: string) {
            return !!this.documentCategory.RequiredRegisterFields?.find(x => x === field);
        }
    }
