export const GET_USERS = 'GET_USERS';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_CONTRAGENTS = 'GET_CONTRAGENTS';
export const GET_CONTRAGENTROLES = 'GET_CONTRAGENTROLES';
export const GET_POSITIONS = 'GET_POSITIONS';
export const GET_WORKTYPES = 'GET_WORKTYPES';
export const GET_REVIEWER_TEMPLATES = 'GET_REVIEWER_TEMPLATES';
export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_MEASUREMENT_UNITS = 'GET_MEASUREMENT_UNITS';
export const GET_MARKA = 'GET_MARKA';
export const GET_TITLE_OBJECT = 'GET_TITLE_OBJECT';
export const GET_REGISTER_STATUS = 'GET_REGISTER_STATUS';
export const GET_DRAWING_PACKAGE_STATUS = 'GET_DRAWING_PACKAGE_STATUS';
export const GET_KS2_DOCUMENT_STATUS = 'GET_KS2_DOCUMENT_STATUS';
export const GET_KS2_DOCUMENT_TYPE = 'GET_KS2_DOCUMENT_TYPE';
export const GET_REGISTER_PROJECT_CODE = 'GET_REGISTER_PROJECT_CODE';
export const GET_DOCUMENT_CATEGORY = 'GET_DOCUMENT_CATEGORY';
export const GET_CONSTRUCTION_AREA = 'GET_CONSTRUCTION_AREA';
export const GET_KFO = 'GET_KFO';
export const GET_TOP_KFO = 'GET_TOP_KFO';
export const GET_PNR_DOCUMENT_TYPE = 'GET_PNR_DOCUMENT_TYPE';
export const GET_PNR_TITLE_OBJECT = 'GET_PNR_TITLE_OBJECT';
