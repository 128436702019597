
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DocumentPhysicalVolumeNew, DynamicEditorOptions, IField, MaterialElement, PhysicalVolumeItem, SubObject } from '@/types/dynamicDoc';
    import { DataTableHeader } from 'vuetify';
    import PhysicalVolumeDialog from '@/components/dynamic/physical-volume-dialog.vue';
    import * as api from '@/api/dynamic';
    import * as Odata from '@/api/odata';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { MeasurementUnit } from '@/api/model/interfaces';
    import { UserResponse, WorkType } from '@/types';
    import { localizeDescription } from '@/util/field-translation';
    import { PropType } from 'vue';

    @Component({
        components: {
            PhysicalVolumeDialog,
            ButtonTooltip,
        },
    })
    export default class PhysicalVolumeItems extends Vue {
        @Prop({ type: Array, default: () => [] })
        value!: DocumentPhysicalVolumeNew[];

        @Prop({ type: String, required: true })
        private readonly label!: string;

        @Prop({ type: Object, required: true })
        private readonly field!: IField;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Array, default: () => [] })
        private readonly drawingPackageIds!: string[];

        @Prop({ type: Object as PropType<DynamicEditorOptions> })
        options!: DynamicEditorOptions;

        currentItem: DocumentPhysicalVolumeNew | null = null;
        physicalVolumeItems: PhysicalVolumeItem[] = [];
        subObjects: SubObject[] = [];
        materialElements: MaterialElement[] = [];
        visiblePhysicalVolumeDialog = false;
        header: DataTableHeader[] = [
            { text: this.$t('common.work-type') as string, value: 'WorkType' },
            { text: this.$t('common.sub-object') as string, value: 'SubObjectName' },
            { text: this.$t('common.element') as string, value: 'MaterialElementName' },
            { text: this.$t('common.measurement-units-short') as string, value: 'MeasurementUnit', width: 90 },
            { text: this.$t('common.element-count') as string, value: 'CountValue', width: 100, align: 'center' },
            { text: this.$t('common.cell-capacity') as string, value: 'ItemValue', width: 100, align: 'center' },
            { text: this.$t('common.volume-rd-teq') as string, value: 'Value', width: 100, align: 'center' },
            { text: '', value: 'actions', width: 90 },
        ];

        @Watch('drawingPackageIds', { immediate: true })
        async onDrawingPackageIdsChange() {
            if (!this.drawingPackageIds?.length) return;
            await this.getPhysicalVolumeItem();
            await this.getSubObjects();
            await this.getMaterialElements();
        }

        get items() {
            return this.value.map(item => {
                const physicalVolume = this.physicalVolumeItems.find(x => x.PhysicalVolumeId === item.PhysicalVolumeId)?.PhysicalVolume;
                const workType = this.workTypes.find(x => x.Id === physicalVolume?.WorkTypeId);
                const measurementUnit = this.measurementUnits.find(x => x.Id.toString() === physicalVolume?.MeasurementUnitId);
                return {
                    ...item,
                    SubObjectId: item.SubObjectId || null,
                    MaterialElementId: item.MaterialElementId || null,
                    WorkType: `${workType?.Code ? workType?.Code + '.' : ''}${localizeDescription(workType)}`,
                    MeasurementUnit: measurementUnit ? measurementUnit.Title : '',
                    SubObjectName: this.subObjects.find(x => x.Id === item.SubObjectId)?.Name || '',
                    MaterialElementName: this.materialElements.find(x => x.Id === item.MaterialElementId)?.Name || '',
                };
            });
        }

        get workTypes(): WorkType[] {
            return this.$store.state.reftables.WorkTypes;
        }

        get measurementUnits(): MeasurementUnit[] {
            return this.$store.state.reftables.MeasurementUnit;
        }

        get user(): UserResponse {
            return this.$store.state.auth.user;
        }

        get visibleBtn() {
            return this.user.isAdmin || this.user.id === this.options.document?.UserId;
        }

        async getPhysicalVolumeItem() {
            const lastDrawingPackagesVersion = await this.getLastRevisionDrawingPackages();
            this.physicalVolumeItems = await api.physicalVolumeItem.getByDrawingPackageRange(lastDrawingPackagesVersion);
        }

        async getLastRevisionDrawingPackages(): Promise<string[]> {
            return await Odata.DrawingPackage
                .runAction('GetLastRevisionByRange', null, { Ids: this.drawingPackageIds })
                .then(x => x.value);
        }

        async getMaterialElements() {
            if (!this.drawingPackageIds?.length) return;
            this.materialElements = await api.materialElement.getByDrawingPackageRange(this.drawingPackageIds);
        }

        async getSubObjects() {
            if (!this.drawingPackageIds?.length) return;
            this.subObjects = await api.subObject.getByDrawingPackageRange(this.drawingPackageIds);
        }

        open() {
            this.currentItem = null;
            this.visiblePhysicalVolumeDialog = true;
        }

        edit(index: number) {
            this.currentItem = this.value[index];
            this.currentItem.Id = index;
            this.visiblePhysicalVolumeDialog = true;
        }

        async updatedPhysicalVolume(item: DocumentPhysicalVolumeNew) {
            if (this.currentItem && this.currentItem.Id !== undefined) {
                delete item.Id;
                this.value[this.currentItem.Id] = item;
            } else {
                this.value.push(item);
            }
            this.$emit('input', this.value);
            this.visiblePhysicalVolumeDialog = false;
            await this.update();
        }

        async remove(index: number) {
            if (index === -1) return;
            this.value.splice(index, 1);
            await this.update();
        }

        async update() {
            if (this.options.updatePhysicalVolumeOnly) {
                await this.options.updatePhysicalVolumeOnly();
            }
        }
    }
