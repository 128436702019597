import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { DocumentLink, DocumentLinkApi } from '@/types/dynamicDoc';

/** Связи документа */
export const documentLink: DocumentLinkApi<DocumentLink, DocumentLink> = {
    getAll: (): Promise<DocumentLink[]> =>
        dynamicDocInstance.get<DocumentLink[]>('DocumentLink').then(x => x.data),

    getById: (id: string): Promise<DocumentLink> =>
        dynamicDocInstance.get<DocumentLink>(`DocumentLink/${id}`).then(x => x.data),

    create: (data: DocumentLink): Promise<DocumentLink> =>
        dynamicDocInstance.post<DocumentLink>('DocumentLink', data).then(x => x.data),

    update: (id: string, data: DocumentLink): Promise<DocumentLink> =>
        dynamicDocInstance.put<DocumentLink>(`DocumentLink/${id}`, data).then(x => x.data),

    remove: (id: string): Promise<void> =>
        dynamicDocInstance.delete(`DocumentLink/${id}`).then(x => x.data),

    getByDocumentSchema: (documentSchemaId: string): Promise<DocumentLink[]> =>
        dynamicDocInstance.get<DocumentLink[]>(`DocumentLink/ByDocumentSchema?documentSchemaId=${documentSchemaId}`).then(x => x.data),
};
