import store from '@/store';
import { setAuthHeaders } from '@/auth/headers';
import i18n, { forcedLocale } from '@/plugins/i18n';
import axios from 'axios';
import { AUTH_USER_REQUEST } from '@/store/auth';

export async function onAppInit() {
    const token = store.getters.authToken;

    if (token) {
        setAuthHeaders(token);
        await store.dispatch(AUTH_USER_REQUEST);

        setAppLocale(forcedLocale || store.getters.getLocale);
    }

    const isDebug = process.env.NODE_ENV !== 'production';

    if (isDebug) {
        enableDebugUtils();
    }
}

function enableDebugUtils() {
    Object.assign(window, {
        axios,
        $store: store,
    });
}

export function setAppLocale(locale: string | null): void {
    i18n.locale = locale || i18n.fallbackLocale as string;
}
