
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DynamicDocument } from '@/types/dynamicDoc';
    import { AutocompleteItem, WorkType } from '@/types';
    import { localizeDescription } from '@/util/field-translation';
    import { drawingPackageTitle } from '@/components/drawing-docs/helpers';
    import { DrawingPackage, Register, RegisterStatus } from '@/api/model/interfaces';
    import { DataTableHeader } from 'vuetify';
    import * as Odata from '@/api/odata';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';

    @Component({
        components: {
            ButtonTooltip,
        },
    })
    export default class DocumentList extends Vue {
        @Prop({ type: Array, default: () => [] })
        documents!: DynamicDocument[];

        drawingPackages: DrawingPackage[] = [];
        registers: Register[] = [];

        @Watch('documents', { immediate: true })
        async onRelatedDocumentIds() {
            if (!this.documents.length) return;
            await this.getRegisters();
            await this.getDrawingPackages();
        }

        get headers(): DataTableHeader[] {
            return [
                { text: this.$t('common.document-number') as string, value: 'Number', groupable: false },
                { text: this.$t('common.document-outline') as string, value: 'Type', groupable: false },
                { text: this.$t('common.work-type') as string, value: 'WorkTypes', groupable: false },
                { text: this.$t('common.drawing-packages') as string, value: 'DrawingPackages', groupable: false },
                { text: this.$t('common.register') as string, value: 'Register', groupable: false },
                { text: this.$t('common.circuit-type') as string, value: 'IsGenerated', groupable: false },
                { text: '', value: 'actions', sortable: false, groupable: false, align: 'end' },
            ];
        }

        get documentsFormated() {
            return this.documents.map(x => {
                const item = this.registers.find(y => y.Id.toString() === x.RegisterId);
                const status = this.registerStatus.find(y => y.Id.toString() === item?.StatusId.toString());
                const workTypes: AutocompleteItem[] = [];
                if (x.WorkTypeIds?.length) {
                    x.WorkTypeIds.forEach(id => {
                        const workType = this.workTypes.find(w => w.value === id);
                        if (workType) workTypes.push(workType);
                    });
                }

                const drawingPackages: AutocompleteItem[] = [];
                if (x.DrawingPackageIds?.length) {
                    x.DrawingPackageIds.forEach(id => {
                        const drawingPackage = this.drawingPackagesFormated.find(w => w.value === id);
                        if (drawingPackage) drawingPackages.push(drawingPackage);
                    });
                }

                return {
                    ...x,
                    Register: item ? `${this.$t('common.register')} ${item!.Code}` : '',
                    RegisterStatus: status ? ` (${localizeDescription(status)})` : '',
                    WorkTypes: workTypes,
                    DrawingPackages: drawingPackages,
                };
            });
        }

        get registerStatus(): RegisterStatus[] {
            return this.$store.state.reftables.RegisterStatus;
        }

        get workTypes(): AutocompleteItem[] {
            if (!this.$store.state.reftables?.WorkTypes) return [];
            return this.$store.state.reftables.WorkTypes
                .map((x: WorkType) => ({
                    value: x.Id,
                    text: x.Code.length ? `${x.Code}. ${localizeDescription(x)}` : localizeDescription(x),
                }));
        }

        get drawingPackagesFormated(): AutocompleteItem[] {
            return this.drawingPackages.map(x => ({
                value: x.Id.toString(),
                text: drawingPackageTitle(x),
            }));
        }

        get drawingPackageIds() {
            const ids = this.documents.flatMap(x => x.DrawingPackageIds)
                .filter(x => !!x) as string[];
            return [ ...new Set(ids) ];
        }

        async getRegisters() {
            const ids = this.documents.map(x => x.RegisterId).filter(x => !!x);
            if (!ids.length) return;
            this.registers = await Odata.Register
                .runAction('GetRegistersByIdRange', null, { ids })
                .then(x => x.value);
        }

        async getDrawingPackages() {
            this.drawingPackages = await Odata.DrawingPackage.runAction('GetByIdRange',
                                                                        null,
                                                                        {
                                                                            Ids: this.drawingPackageIds,
                                                                        },
                                                                        {
                                                                            select: [ 'Id', 'Code', 'AdditionalCode', 'MainCode' ],
                                                                        })
                .then(x => x.value);
        }

        schemaName(id: string) {
            const schema = this.$store.state.document.documentSchemas.find((x: any) => x.Id === id);
            return schema ? schema.Title : '';
        }

        openDocument(doc: DynamicDocument) {
            this.$emit('openDocument', doc.Id);
        }
    }
