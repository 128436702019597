import { ExecuteReport, ExecuteReportResponse, Report, ReportResponse, ReportGetByFilter, ReportModule } from '@/types/report/types';
import { BaseApi } from '@/api/BaseApi';
import { getByFilter } from '@/types/dynamicDoc';

/** Отчеты */
export default class ReportApi extends BaseApi<Report, Report> {
    executeQuery(data: ExecuteReport): Promise<ExecuteReportResponse> {
        return this.instance.post<ExecuteReportResponse>(`${this.entityName}/executeQuery`, data).then(x => x.data);
    }

    byCategory(category: ReportModule, favorite: boolean): Promise<ReportResponse[]> {
        return this.instance.get<ReportResponse[]>(`${this.entityName}/GetReportsByCategory/${category}?favorite=${favorite}`).then(x => x.data);
    }

    setFavorite(id: string): Promise<string> {
        return this.instance.post(`${this.entityName}/setFavorite/${id}`).then(x => x.data);
    }

    removeFavorite(id: string): Promise<string> {
        return this.instance.post(`${this.entityName}/removeFavorite/${id}`).then(x => x.data);
    }

    download(data: ExecuteReport) {
        return this.instance.post(`${this.entityName}/Download`, data, { responseType: 'blob' }).then(x => x.data);
    }

    getByFilter(data: ReportGetByFilter): Promise<getByFilter<Report>> {
        return this.instance.post<getByFilter<Report>>(`${this.entityName}/getByFilter`, data).then(x => x.data);
    }
}
