
    import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
    import { auditTypes, DocumentHistoryTabs } from '@/types/dynamicDoc';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import DocumentHistoryActions from '@/components/documents-journals/documents/document-history-actions.vue';
    import DocumentHistoryEdited from '@/components/documents-journals/documents/document-history-edited.vue';
    import CustomDialog from '@/components/dynamic/common/custom-dialog.vue';

    @Component({
        components: {
            ButtonTooltip,
            DocumentHistoryActions,
            DocumentHistoryEdited,
            CustomDialog,
        },
        data: () => ({
            DocumentHistoryTabs,
            auditTypes,
        }),
    })
    export default class DocumentHistoryDialog extends Vue {
        @Prop({ type: String, required: true })
        readonly documentId!: string;

        @PropSync('visible', { type: Boolean, required: true })
        isVisible!: boolean;

        tabIndex: DocumentHistoryTabs | null = null;

        close() {
            this.tabIndex = DocumentHistoryTabs.edited;
            this.isVisible = false;
        }
    }
