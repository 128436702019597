import { render, staticRenderFns } from "./documentViewer.vue?vue&type=template&id=3a391ae2&scoped=true&lang=pug&"
import script from "./documentViewer.vue?vue&type=script&lang=ts&"
export * from "./documentViewer.vue?vue&type=script&lang=ts&"
import style0 from "./documentViewer.vue?vue&type=style&index=0&id=3a391ae2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a391ae2",
  null
  
)

export default component.exports