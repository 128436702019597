
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { TranslateResult } from 'vue-i18n';
    import { Ks2Groups } from '@/components/ks2/constants';
    import { Certificate, getUserCertificates } from 'crypto-pro';
    import { AutocompleteItem } from '@/types';
    import { SignatureCreateOptions } from '@/crypto/types';

    @Component({})
    export default class CertificateSelector extends Vue {
        @Prop({ type: Object, default: () => null })
        value!: SignatureCreateOptions | null;

        @Prop({ type: Boolean, default: () => false })
        disabled!: boolean;

        certificates: Certificate[] = [];
        certificate = {} as AutocompleteItem;
        signatureUsage = '1.2.643.6.3.1.4';
        certsLoading = false;
        signatureComment = '';

        @Watch('signatureOptions', { immediate: true })
        changeSignatureOptions(val: SignatureCreateOptions) {
            this.$emit('input', val);
        }

        @Watch('certificate.value', { immediate: true })
        onSelectedCertSerialNumber() {
            this.$emit('certificationSelected', !!this.certificate.value);
        }

        get certificatesItems(): AutocompleteItem[] {
            return this.certificates.map(x => ({
                value: x.thumbprint,
                text: `${x.name} (${this.$t('common.valid-until')}: ${new Date(x.validTo).toLocaleString()})`,
            }));
        }

        get signatureUsageChoices(): (string | TranslateResult)[][] {
            return [
                [ '1.2.643.6.3.1.0', this.$t('cert-selector.creation') ],
                [ '1.2.643.6.3.1.1', this.$t('cert-selector.fixed') ],
                [ '1.2.643.6.3.1.2', this.$t('cert-selector.informed') ],
                [ '1.2.643.6.3.1.3', this.$t('cert-selector.agreed') ],
                [ '1.2.643.6.3.1.4', this.$t('cert-selector.signed') ],
                [ '1.2.643.6.3.1.5', this.$t('common.approved') ],
            ];
        }

        get signatureOptions(): SignatureCreateOptions | null {
            if (!this.certificate.value) return null;
            return {
                thumbprint: this.certificate.value,
                signatureUsage: this.signatureUsage,
                signatureComment: this.signatureComment,
            };
        }

        get availableComments(): string[] {
            const availableComments = [];

            if (this.$store.getters.hasRoles(Ks2Groups.CustomersKS2AsBuiltId)) {
                availableComments.push(
                    'Исполнительная документация подписана в полном объёме',
                    'Исполнительная документация не оформлена',
                    'Исполнительная документация на проверке',
                    'Исполнительная документация на устранении замечаний',
                    'Исполнительная документация не требуется',
                );
            }

            if (this.$store.getters.hasRoles(Ks2Groups.CustomersKS2PhysicalId)) {
                availableComments.push(
                    'Физ.Объёмы и качество работ подтверждаю',
                    'Физ.Объёмы и качество работ не подтверждаю',
                );
            }

            return availableComments;
        }

        async mounted() {
            this.certsLoading = true;
            this.certificates = await getUserCertificates();
            this.certsLoading = false;
        }
    }
