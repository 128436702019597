
    import { Component, Vue } from 'vue-property-decorator';
    import { GET_COMMON_APP_SETTINGS } from '@/store/settings/types';
    import * as apiCiS from '@/api/cis';
    import { RegisterGroupByReviewerUserIdResponse, RegisterGroupByStatusResponse, RegisterWidgetResponse, WidgetType } from '@/types/cis/widget';
    import ChartRegisterStatus from '@/components/dashboard/chart-register-status.vue';
    import ChartRegisterInfo from '@/components/dashboard/chart-register-info.vue';
    import ChartRegisterTimeout from '@/components/dashboard/chart-register-timeout.vue';
    import ChartDocumentsErrors from '@/components/dashboard/chart-documents-errors.vue';
    import ChartRegisterSubcontractorInfo from './chart-register-subcontractor-info.vue';
    import { DynamicDocumentErrors } from '@/types/dynamicDoc';
    import { UPDATE_MENU } from '@/store/topmenu/module';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import WidgetAppSettingsDialog from './widget-app-settings-dialog.vue';

    @Component({
        components: {
            ChartRegisterStatus,
            ChartRegisterInfo,
            ChartRegisterTimeout,
            ChartDocumentsErrors,
            ChartRegisterSubcontractorInfo,
            ButtonTooltip,
            WidgetAppSettingsDialog,
        },
        data: () => ({ WidgetType }),
    })
    export default class Dashboard extends Vue {
        widgetRegisterStatus: RegisterGroupByStatusResponse[] = [];
        widgetExperts: RegisterWidgetResponse[] = [];
        widgetIssues: RegisterWidgetResponse[] = [];
        widgetTimes: RegisterGroupByReviewerUserIdResponse[] = [];
        widgetErrors: RegisterGroupByReviewerUserIdResponse[] = [];
        widgetDocumentsErrors: DynamicDocumentErrors[] = [];
        widgetSettingsVisible = false;

        mounted() {
            this.$store.commit(UPDATE_MENU, []);
        }

        get gradient() {
            return {
                expert: 'background: linear-gradient(to right, #1d90ff, #6dd5ed);',
                issue: 'background: linear-gradient(to right, #ffaf3e, #fce38a);',
                error: 'background: linear-gradient(to right, #cd5c5b, #f7bb97);',
            };
        }

        get user() {
            return this.$store.state.auth.user;
        }

        async created() {
            await this.$store.dispatch(GET_COMMON_APP_SETTINGS);
            this.widgetRegisterStatus = await apiCiS.widget.getByStatus();
            this.widgetExperts = await apiCiS.widget.getByUserExpert();
            this.widgetIssues = await apiCiS.widget.getNotProcessedIssue();
            this.widgetTimes = await apiCiS.widget.getTimersByRegistry();
            this.widgetErrors = await apiCiS.widget.getUnprocessedErrorsInDocuments();
            // await this.getDocumentsErrors();
        }

        // async getDocumentsErrors() {
        // Todo Andrey: пока не показывать ошибки в документах
        // this.widgetDocumentsErrors = await apiDocs.error.getErrorInDynamicDocument(true);
        // }

        async onFilter(userId: string | null) {
            this.widgetRegisterStatus = await apiCiS.widget.getByStatus(userId);
        }

        openSettings() {
            this.widgetSettingsVisible = true;
        }
    }

