import { RegisterState } from '@/store/register/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import * as documentApi from '@/api/dynamic';
import * as cisApi from '@/api/cis';
import { GET_DOCUMENT_SHORTS, GET_DYNAMIC_DOCUMENT_ITEMS, GET_ERROR_DOCUMENT_IDS, SET_REGISTER } from '@/store/register/actions';
import { DocumentShort, DynamicDocumentItemResponse } from '@/types/dynamicDoc';
import { Register } from '@/api/model/interfaces';

const state: RegisterState = {
    register: null,
    documentShorts: [],
    dynamicDocumentItems: [],
    errorDocumentIds: [],
};

const actions: ActionTree<RegisterState, RootState> = {
    [GET_DOCUMENT_SHORTS]: async({ commit }, data: string[]) => {
        commit(GET_DOCUMENT_SHORTS, await documentApi.document.getDocumentShortsByIds(data));
    },
    [GET_DYNAMIC_DOCUMENT_ITEMS]: async({ commit }, data: string) => {
        commit(GET_DYNAMIC_DOCUMENT_ITEMS, await cisApi.document.getDynamicDocuments(data));
    },
    [GET_ERROR_DOCUMENT_IDS]: async({ commit }, data: string[]) => {
        commit(GET_ERROR_DOCUMENT_IDS, await documentApi.document.getErrorDocumentIdsByRangeIds(data));
    },
};

const mutations: MutationTree<RegisterState> = {
    [GET_DOCUMENT_SHORTS]: (state, payload: DocumentShort[]) => (state.documentShorts = payload),
    [GET_DYNAMIC_DOCUMENT_ITEMS]: (state, payload: DynamicDocumentItemResponse[]) => (state.dynamicDocumentItems = payload),
    [SET_REGISTER]: (state, payload: Register) => (state.register = payload),
    [GET_ERROR_DOCUMENT_IDS]: (state, payload: string[]) => (state.errorDocumentIds = payload),
};

const getters: GetterTree<RegisterState, RootState> = {};

const module: Module<RegisterState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};

export default module;
