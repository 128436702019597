import Vue from 'vue';
import { AUTH_ERROR, AUTH_LOCALE, AUTH_LOGOUT, AUTH_REFRESH_TOKEN, AUTH_REQUEST, AUTH_SUCCESS, AUTH_USER_REQUEST } from './actions';
import { setAuthHeaders } from '@/auth/headers';
import { ActionTree, Commit, GetterTree, Module, MutationTree } from 'vuex';
import { AuthState } from './types';
import * as api from '@/api/auth';
import { Locale, LoginForm, LoginSuccessResponse, RoleConstants, UserResponse } from '@/types';
import { RootState } from '@/store/types';
import { setAppLocale } from '@/auth/helper';
import { forcedLocale } from '@/plugins/i18n';

const locales = [ 'ru', 'en' ];

const state: AuthState = {
    token: localStorage.getItem('user-token') || '',
    refreshToken: localStorage.getItem('refresh-token') || '',
    status: '',
    expires: localStorage.getItem('user-token-expires') || null,
    lastLoginDate: localStorage.getItem('last-login-date') || null,
    error: null,
    lastUserName: localStorage.getItem('last-username') || '',
    user: null,
    lifeDaysPassword: Number(localStorage.getItem('life-days-password')) || null,
};

const getters: GetterTree<AuthState, RootState> = {
    authToken: state => {
        return (state.expires !== null && new Date(state.expires) > new Date()) ? state.token : null;
    },
    isAuthenticated: state => !!state.token,
    isProfileLoaded: state => !!state.user,
    authStatus: state => state.status,
    authError: state => state.error,
    refreshToken: state => state.refreshToken,
    getLastUserName: state => state.lastUserName,
    hasRoles: state => (role: string) => !!state.user?.roles.includes(role),
    hasAnyRole: (state, getters) => (roles: string[]) => roles.some(x => getters.hasRoles(x)),
    isAdmin: state => state.user && state.user.isAdmin,
    isPermitAdmin: (_state, getters) => getters.hasRoles(RoleConstants.Permit),
    isAuthAdmin: (_state, getters) => getters.hasRoles(RoleConstants.Auth),
    getLocale: state => state.user ? locales[state.user.locale] : null,
    getUser: state => state.user,
    hasAgreementUser: state => !!state.user?.isConsentPersonalData,
};

function onAuthSuccess(commit: Commit, data: LoginSuccessResponse) {
    const expires = new Date((new Date()).getTime() + 1000 * (data.lifetime - 10)).toISOString();

    localStorage.setItem('user-token', data.token);
    localStorage.setItem('user-token-expires', expires);
    localStorage.setItem('refresh-token', data.refreshToken);
    localStorage.setItem('life-days-password', String(data.lifeDaysPassword));
    if (data.lastLoginDate) {
        localStorage.setItem('last-login-date', new Date(data.lastLoginDate).toLocaleString());
    }

    setAuthHeaders(data.token);
    commit(AUTH_SUCCESS, {
        token: data.token,
        expires: expires,
        lastLoginDate: data.lastLoginDate ? new Date(data.lastLoginDate).toLocaleString() : null,
        refreshToken: data.refreshToken,
        lifeDaysPassword: data.lifeDaysPassword,
    });
}

const actions: ActionTree<AuthState, RootState> = {
    async AUTH_REQUEST({ commit, dispatch }, loginForm: LoginForm) {
        commit(AUTH_REQUEST);

        try {
            const data = await api.auth.signIn(loginForm);
            onAuthSuccess(commit, data);
            await dispatch(AUTH_USER_REQUEST);
        } catch (err) {
            commit(AUTH_ERROR, err);
            localStorage.removeItem('user-token');

            throw new Error(err);
        }
    },
    async AUTH_USER_REQUEST({ commit, dispatch }) {
        try {
            const data = await api.user.getUserInfo();
            localStorage.setItem('last-username', data.login);
            setAppLocale(forcedLocale || locales[data.locale]);
            commit(AUTH_USER_REQUEST, data);
        } catch (err) {
            commit(AUTH_ERROR);
            await dispatch(AUTH_LOGOUT);

            throw new Error(err);
        }
    },
    async AUTH_REFRESH_TOKEN({ commit, getters }) {
        try {
            const data = await api.auth.refreshToken(getters.refreshToken);
            onAuthSuccess(commit, data);
        } catch (err) {
            commit(AUTH_ERROR, err);
            localStorage.removeItem('user-token');

            throw new Error(err);
        }
    },
    async AUTH_LOGOUT({ commit }) {
        await api.auth.logout(state.refreshToken);
        localStorage.removeItem('user-token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('user-token-expires');
        localStorage.removeItem('last-login-date');
        commit(AUTH_LOGOUT);
        return null;
    },
    async AUTH_GET_TOKEN({ dispatch, getters }) {
        const token = getters.authToken;
        if (token !== null) return token;
        const refreshToken = getters.refreshToken;
        if (refreshToken !== null) {
            await dispatch(AUTH_REFRESH_TOKEN);
            return getters.authToken;
        }
        return null;
    },
    async AUTH_LOCALE({ commit, state, dispatch }, locale: Locale) {
        const localeData = locales.findIndex(x => x === locale.toString());
        await api.user.setLocale(state.user!.id, localeData);
        commit(AUTH_LOCALE, locale);
        dispatch(AUTH_USER_REQUEST);
    },
};

const mutations: MutationTree<AuthState> = {
    [AUTH_REQUEST]: (state) => {
        state.status = 'loading';
        state.token = '';
        state.error = null;
    },
    [AUTH_USER_REQUEST]: (state, data: UserResponse) => {
        state.status = 'success';
        state.error = null;
        state.lastUserName = data.lastName;
        Vue.set(state, 'user', data);
        if (state.user) {
            if (state.user.isAdmin) {
                localStorage.setItem('roles', JSON.stringify([ 'isAdmin' ]));
                Vue.gates.setRoles([ 'isAdmin' ]);
            } else {
                localStorage.setItem('roles', JSON.stringify(state.user.roles));
                localStorage.setItem('permissions', JSON.stringify(state.user.systemCodeRights));
                Vue.gates.setRoles(state.user.roles);
                Vue.gates.setPermissions(state.user.systemCodeRights);
            }
        }
    },
    [AUTH_SUCCESS]: (state: AuthState, data) => {
        state.token = data.token;
        state.expires = data.expires;
        state.lastLoginDate = data.lastLoginDate;
        state.refreshToken = data.refreshToken;
        state.lifeDaysPassword = data.lifeDaysPassword;
    },
    [AUTH_ERROR]: (state, err) => {
        state.status = 'error';
        if (err.response?.status === 401) {
            state.error = 'unauthorized';
        } else if (err.response?.status === 400) {
            state.error = err.response.data.message;
        } else {
            state.error = 'Error when processing request: ' + err.response?.status.toString();
        }
    },
    [AUTH_LOGOUT]: (state) => {
        state.token = '';
        state.status = '';
        state.refreshToken = '';
        state.expires = null;
        state.lastLoginDate = null;
        state.error = null;
        state.user = null;
    },
    [AUTH_LOCALE]: (state, locale: Locale) => {
        if (state.user) {
            state.user.locale = locale;
        } else {
            throw new Error(`Unexpected ${AUTH_LOCALE} commit. Before committing ${AUTH_LOCALE} you must be logged in.`);
        }
    },
};

const auth: Module<AuthState, RootState> = {
    state,
    getters,
    actions,
    mutations,
};

export default auth;
