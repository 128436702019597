
    import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
    import { DocumentPhysicalVolumeNew, MaterialElement, PhysicalVolumeItem, SubObject } from '@/types/dynamicDoc';
    import { AutocompleteItem, VForm, WorkType } from '@/types';
    import { localizeDescription } from '@/util/field-translation';
    import { MeasurementUnit } from '@/api/model/interfaces';
    import { required, requiredPositiveNumber } from '@/components/common/validation-rules';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';

    @Component({
        data: () => ({
            required,
            requiredPositiveNumber,
        }),
        components: { ButtonTooltip },
    })
    export default class PhysicalVolumeDialog extends Vue {
        @Ref() form!: VForm;
        @PropSync('visible', { type: Boolean, required: true })
        visibleDialog!: boolean;

        @Prop({ type: Object })
        value!: DocumentPhysicalVolumeNew;

        @Prop({ type: Array, default: () => [] })
        readonly selectItems!: DocumentPhysicalVolumeNew[];

        @Prop({ type: Array, default: () => [] })
        physicalVolumeItems!: PhysicalVolumeItem[];

        @Prop({ type: Array, default: () => [] })
        private readonly materialElements!: MaterialElement[];

        @Prop({ type: Array, default: () => [] })
        private readonly subObjects!: SubObject[];

        valid = true;
        loading = false;
        workTypeId: string | null = null;
        measurementUnit: string | null = null;
        item: DocumentPhysicalVolumeNew = {
            Value: null,
            PhysicalVolumeId: null,
            SubObjectId: null,
            MaterialElementId: null,
            ItemValue: null,
            CountValue: null,
        };

        @Watch('visibleDialog', { immediate: true })
        async onVisibleDialog() {
            if (!this.visibleDialog) return;
            if (this.form) this.form.resetValidation();
            if (this.value) {
                this.item = this.value;
                const physicalVolume = this.physicalVolumeItems.find(x => x.PhysicalVolumeId === this.item.PhysicalVolumeId);
                if (physicalVolume) {
                    this.workTypeId = physicalVolume.PhysicalVolume!.WorkTypeId;
                }
            }
        }

        @Watch('workTypeId', { immediate: true })
        onWorkTypeChange() {
            if (!this.workTypeId) return;
            const result = this.physicalVolumeItems
                .find(x => x.PhysicalVolume?.WorkTypeId?.toString() === this.workTypeId);
            if (!result) return;
            this.item.PhysicalVolumeId = result.PhysicalVolumeId;
            this.measurementUnit = this.measurementUnits
                .find(measure => measure.Id.toString() === result.PhysicalVolume?.MeasurementUnitId)!.Title;
        }

        get measurementUnits(): MeasurementUnit[] {
            return this.$store.state.reftables.MeasurementUnit;
        }

        get workTypesFiltered(): AutocompleteItem[] {
            if (!this.$store.state.reftables?.WorkTypes) return [];
            return this.$store.state.reftables?.WorkTypes
                .filter((x: WorkType) => this.physicalVolumeItems.find(i => i.PhysicalVolume?.WorkTypeId === x.Id?.toString()))
                .map((x: WorkType) => ({
                    value: x.Id,
                    text: x.Code.length ? `${x.Code}. ${localizeDescription(x)}` : localizeDescription(x),
                }));
        }

        get subObjectsFiltered() {
            const subObjectIds = this.physicalVolumeItems
                .filter(x => x.PhysicalVolume?.WorkTypeId === this.workTypeId && !!x.SubObjectId)
                .map(x => x.SubObjectId);
            if (!subObjectIds.length) return [];

            return this.subObjects
                .filter(x => [ ...new Set(subObjectIds) ].includes(x.Id));
        }

        get materialElementsFiltered() {
            const materialElementIds = this.physicalVolumeItems
                .filter(x => x.PhysicalVolume?.WorkTypeId === this.workTypeId && !!x.MaterialElementId)
                .map(x => x.MaterialElementId);
            if (!materialElementIds.length) return [];

            return this.materialElements
                .filter(x => [ ...new Set(materialElementIds) ].includes(x.Id)
                    && x.SubObjectId === this.item.SubObjectId);
        }

        reset() {
            this.workTypeId = null;
            this.measurementUnit = null;
            this.item = {
                Value: null,
                PhysicalVolumeId: null,
                SubObjectId: null,
                MaterialElementId: null,
                ItemValue: null,
                CountValue: null,
            };
        }

        save() {
            if (!this.form.validate()) return;
            this.loading = true;
            this.$emit('update', {
                Value: this.item.Value,
                PhysicalVolumeId: this.item.PhysicalVolumeId,
                SubObjectId: this.item.SubObjectId,
                MaterialElementId: this.item.MaterialElementId,
                ItemValue: this.item.ItemValue,
                CountValue: this.item.CountValue,
            } as DocumentPhysicalVolumeNew);
            this.$emit('addWorkType', this.workTypeId);
            this.loading = false;
            this.reset();
        }

        close() {
            this.visibleDialog = false;
            this.reset();
        }

        onSubObject() {
            this.item.MaterialElementId = null;
        }

        onWorkType() {
            this.item.SubObjectId = null;
        }

        onValue() {
            this.item.Value = this.item.ItemValue && this.item.CountValue
                ? this.item.ItemValue * this.item.CountValue
                : null;
        }
    }
