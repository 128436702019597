import { authInstance } from './auth-instance';
import { EntityApi, FilterItemsResponse, OptionsTable, RoleGroupRequest, RoleGroupResponse } from '@/types';

export const roleGroup: EntityApi<RoleGroupResponse, RoleGroupRequest> = {
    getAll: (): Promise<RoleGroupResponse[]> =>
        authInstance.get<RoleGroupResponse[]>('rolegroups/all').then(x => x.data),

    getByFilter: (options: OptionsTable): Promise<FilterItemsResponse<RoleGroupResponse>> =>
        authInstance.post<FilterItemsResponse<RoleGroupResponse>>('rolegroups/byFilter', options).then(x => x.data),

    getById: (id: number): Promise<RoleGroupResponse> =>
        authInstance.get<RoleGroupResponse>(`rolegroups/${id}`).then(x => x.data),

    create: (data: RoleGroupRequest): Promise<RoleGroupResponse> =>
        authInstance.post<RoleGroupResponse>('rolegroups', data).then(x => x.data),

    update: (id: number, data: RoleGroupRequest): Promise<RoleGroupResponse> =>
        authInstance.put<RoleGroupResponse>(`rolegroups/${id}`, data).then(x => x.data),

    remove: (id: number): Promise<void> =>
        authInstance.delete(`rolegroups/${id}`).then(x => x.data),
};
