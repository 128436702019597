import { RegisterTaskFilterOptions, RegisterTaskResponse } from '@/types/cis/register-task';
import { cisInstance } from './cis-instance';
import { getByFilter } from '@/types/dynamicDoc';
import { RegisterTask } from '../model/interfaces';

/** Задачи по реестру */
export const registerTaskApi = {
    getRegisterActiveTaskResolveIssues: (id: string): Promise<RegisterTaskResponse> =>
        cisInstance.get<RegisterTaskResponse>(`RegisterTask/GetRegisterActiveTaskResolveIssues/${id}`).then(x => x.data),

    getTask: (id: string): Promise<RegisterTaskResponse> =>
        cisInstance.get<RegisterTaskResponse>(`RegisterTask/${id}`).then(x => x.data),

    getByFilter: (options: RegisterTaskFilterOptions): Promise<getByFilter<RegisterTaskResponse>> =>
        cisInstance.post<getByFilter<RegisterTaskResponse>>('RegisterTask/GetByFilter', options)
            .then(x => x.data),

    changeEndDateRequest: (id: string, date: Date): Promise<boolean> =>
        cisInstance.post<boolean>(`RegisterTask/ChangeEndDateRequest/${id}`, { date: date }).then(x => x.data),

    approveChangeEndDate: (id: string, date: Date): Promise<boolean> =>
        cisInstance.post<boolean>(`RegisterTask/ApproveChangeEndDate/${id}`, { date: date }).then(x => x.data),

    cancelChangeEndDate: (id: string): Promise<boolean> =>
        cisInstance.post<boolean>(`RegisterTask/CancelChangeEndDate/${id}`).then(x => x.data),
};
