import { authInstance } from './auth-instance';
import { EntityApi, FilterItemsResponse, OptionsTable, ContragentRoleRequest, ContragentRoleResponse } from '@/types';

export const contragentRole: EntityApi<ContragentRoleResponse, ContragentRoleRequest> = {
    getAll: (): Promise<ContragentRoleResponse[]> =>
        authInstance.get<ContragentRoleResponse[]>('ContragentRole/all').then(x => x.data),

    getByFilter: (options: OptionsTable): Promise<FilterItemsResponse<ContragentRoleResponse>> =>
        authInstance.post<FilterItemsResponse<ContragentRoleResponse>>('ContragentRole/byFilter', options).then(x => x.data),

    getById: (id: number): Promise<ContragentRoleResponse> =>
        authInstance.get<ContragentRoleResponse>(`ContragentRole/${id}`).then(x => x.data),

    create: (data: ContragentRoleRequest): Promise<ContragentRoleResponse> =>
        authInstance.post<ContragentRoleResponse>('ContragentRole', data).then(x => x.data),

    update: (id: number, data: ContragentRoleRequest): Promise<ContragentRoleResponse> =>
        authInstance.put<ContragentRoleResponse>(`ContragentRole/${id}`, data).then(x => x.data),

    remove: (id: number): Promise<void> =>
        authInstance.delete(`ContragentRole/${id}`).then(x => x.data),
};
