import store from '@/store';
import i18n, { forcedLocale } from '@/plugins/i18n';
import { Locale } from '@/util/locale';

export enum TypeCase {
    CamelCase, // camelCase
    KebabCase, // kebab-case
    PascalCase, // PascalCase
    SnakeCase, // snake_case
}

export function getLangCode(): Locale {
    return (forcedLocale || store.getters.getLocale || i18n.fallbackLocale);
}

/** Calculates field name for current locale. */
export function localizeFieldName(fieldName: string, langCode?: Locale): string {
    return `${fieldName}_${langCode || getLangCode()}`;
}

/** Calculates text for current locale. example: nameRu | nameEn */
export function localizeText(fieldName: string, langCode?: Locale): string {
    return `${fieldName}${langCode || getLangCode().trim()
        .replace(/^\w/, (c) => c.toUpperCase())}`;
}

export function localizeName(item: any) {
    return getLangCode() === 'ru' ? item.nameRu : item.nameEn;
}

export function localize(entity: Record<string, any> | null | undefined, fieldName: string, type?: TypeCase): string | null {
    if (!entity) return null;
    const lang = getLangCode();

    if (type === TypeCase.KebabCase) return lang === 'ru' ? entity[fieldName + '-ru'] : entity[fieldName + '-en'];
    if (type === TypeCase.SnakeCase) return lang === 'ru' ? entity[fieldName + '_ru'] : entity[fieldName + '_en'];

    return lang === 'ru' ? entity[fieldName + 'Ru'] : entity[fieldName + 'En'];
}

export function localizeDescriptionFieldName(langCode?: Locale): string {
    return localizeFieldName('Description', langCode);
}

/**
 * Calculates field value for current locale.
 *
 * @param entity Data object in which to calculate the field value.
 * @param fieldName Field name from which the value will be calculated (supports nested props), e.g.: `Employee.Contragent.Description`
 *
 * @example localizeField(someAppUser, 'Employee.Contragent.Description');
 * @returns someAppUser.Employee.Contragent.Description_ru
 * @returns someAppUser.Employee.Contragent.Description_en
 */
export function localizeField(entity: Record<string, any> | null | undefined, fieldName: string): string | null {
    if (!entity) return null;

    const fieldsHierarchy = fieldName.split('.');
    const lastField = fieldsHierarchy.pop();

    if (!lastField) { throw new Error('Wrong calculate expression format.'); }

    let obj = entity;
    for (const field of fieldsHierarchy) {
        if (!obj.hasOwnProperty(field)) { throw new Error(`Wrong expression. Property '${field}' doesn't exist on type ${typeof obj}.`); }

        obj = obj[field];
    }

    const lookupFields = [
        localizeFieldName(lastField),
        localizeFieldName(lastField, Locale.En),
        localizeFieldName(lastField, Locale.Ru),
    ];

    const field = lookupFields.find(f => obj.hasOwnProperty(f));
    return field && obj[field] || '';
}

export function localizeDescription(entity: Record<string, any> | null | undefined) {
    return localizeField(entity, 'Description');
}

export function localizeIfExists(key: string): string {
    return i18n.te(key)
        ? i18n.t(key) as string
        : key;
}

export function transformTypeCase(key: string, typeCase: TypeCase): string {
    switch (typeCase) {
    case TypeCase.PascalCase: return key.replace(/[A-Z]/g, (letter, index) => index === 0 ? letter.toLowerCase() : '-' + letter.toLowerCase());
    default: return key;
    }
}
