import * as Odata from '@/api/odata';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ReftablesState } from './types';
import {
    GET_CONTRACT,
    GET_CONTRAGENTROLES,
    GET_CONTRAGENTS,
    GET_DRAWING_PACKAGE_STATUS,
    GET_EMPLOYEES,
    GET_KS2_DOCUMENT_STATUS,
    GET_KS2_DOCUMENT_TYPE,
    GET_MARKA,
    GET_MEASUREMENT_UNITS,
    GET_POSITIONS,
    GET_REGISTER_STATUS,
    GET_REVIEWER_TEMPLATES,
    GET_TITLE_OBJECT,
    GET_USERS,
    GET_WORKTYPES,
    GET_REGISTER_PROJECT_CODE,
    GET_DOCUMENT_CATEGORY,
    GET_CONSTRUCTION_AREA,
    GET_KFO,
    GET_TOP_KFO,
    GET_PNR_DOCUMENT_TYPE,
    GET_PNR_TITLE_OBJECT,
} from './actions';
import { RootState } from '@/store/types';
import * as authApi from '@/api/auth';
import * as cisApi from '@/api/cis';
import {
    ContragentResponse,
    ContragentRoleResponse,
    Employee,
    PositionResponse,
    ReviewerTemplate,
    UserItemResponse,
    WorkType,
} from '@/types';
import {
    Contract,
    MeasurementUnit,
    Marka,
    TitleObject,
    RegisterStatus,
    DrawingPackageStatus,
    PackageKS2DocumentStatus,
    PackageKS2DocumentType,
    RegisterProjectCode,
    DocumentCategory,
    ConstructionArea,
    TopKfo,
    Kfo,
    PnrDocumentType,
    PnrTitleObject,
} from '@/api/model/interfaces';

const state: ReftablesState = {
    Users: [],
    Employees: [],
    Contragents: [],
    ContragentRoles: [],
    Positions: [],
    WorkTypes: [],
    ReviewerTemplates: [],
    Contracts: [],
    MeasurementUnit: [],
    Marka: [],
    TitleObjects: [],
    RegisterStatus: [],
    DrawingPackageStatus: [],
    KS2DocumentStatus: [],
    KS2DocumentType: [],
    RegisterProjectCodes: [],
    DocumentCategories: [],
    ConstructionAreas: [],
    Kfos: [],
    TopKfos: [],
    PnrDocumentTypes: [],
    PnrTitleObjects: [],
};

const actions: ActionTree<ReftablesState, RootState> = {
    [GET_USERS]: async({ commit }) => {
        commit(GET_USERS, await authApi.user.getItems());
    },
    [GET_EMPLOYEES]: async({ commit }) => {
        commit(GET_EMPLOYEES, await authApi.user.getAllEmployees());
    },
    [GET_CONTRAGENTS]: async({ commit }) => {
        commit(GET_CONTRAGENTS, await authApi.contragent.getAll());
    },
    [GET_CONTRAGENTROLES]: async({ commit }) => {
        commit(GET_CONTRAGENTROLES, await authApi.contragentRole.getAll());
    },
    [GET_POSITIONS]: async({ commit }) => {
        commit(GET_POSITIONS, await authApi.position.getAll());
    },
    [GET_WORKTYPES]: async({ commit }) => {
        commit(GET_WORKTYPES, await cisApi.workType.getAll());
    },
    [GET_REVIEWER_TEMPLATES]: async({ commit }) => {
        commit(GET_REVIEWER_TEMPLATES, await cisApi.reviewerTemplate.getAll());
    },
    [GET_CONTRACT]: async({ commit }) => {
        commit(GET_CONTRACT, await Odata.Contract.setParams({
            expand: [ 'AdditionalAgreement' ],
        }).getAll());
    },
    [GET_MEASUREMENT_UNITS]: async({ commit }) => {
        commit(GET_MEASUREMENT_UNITS, await cisApi.measurementUnit.getAll());
    },
    [GET_MARKA]: async({ commit }) => {
        commit(GET_MARKA, await Odata.Marka.setParams({
            select: [ 'Id', 'Description_en', 'Description_ru', 'Title' ],
        }).getAll());
    },
    [GET_TITLE_OBJECT]: async({ commit }) => {
        commit(GET_TITLE_OBJECT, await Odata.TitleObject.setParams({
            select: [ 'Id', 'Description_en', 'Description_ru', 'PlanCode' ],
        }).getAll());
    },
    [GET_REGISTER_STATUS]: async({ commit }) => {
        commit(GET_REGISTER_STATUS, await Odata.RegisterStatus.setParams({
            select: [ 'Id', 'Description_en', 'Description_ru' ],
        }).getAll());
    },
    [GET_DRAWING_PACKAGE_STATUS]: async({ commit }) => {
        commit(GET_DRAWING_PACKAGE_STATUS, await Odata.DrawingPackageStatus.setParams({
            select: [ 'Id', 'Description_en', 'Description_ru' ],
        }).getAll());
    },
    [GET_KS2_DOCUMENT_STATUS]: async({ commit }) => {
        commit(GET_KS2_DOCUMENT_STATUS, await Odata.PackageKS2DocumentStatus.setParams({
            select: [ 'Id', 'Description_en', 'Description_ru' ],
        }).getAll());
    },
    [GET_KS2_DOCUMENT_TYPE]: async({ commit }) => {
        commit(GET_KS2_DOCUMENT_TYPE, await Odata.PackageKS2DocumentType.setParams({
            select: [ 'Id', 'Description_en', 'Description_ru' ],
        }).getAll());
    },
    [GET_REGISTER_PROJECT_CODE]: async({ commit }) => {
        commit(GET_REGISTER_PROJECT_CODE, await Odata.RegisterProjectCode.setParams({
            select: [ 'Id', 'Code', 'Title' ],
        }).getAll());
    },
    [GET_DOCUMENT_CATEGORY]: async({ commit }) => {
        commit(GET_DOCUMENT_CATEGORY, await Odata.DocumentCategory.getAll());
    },
    [GET_CONSTRUCTION_AREA]: async({ commit }) => {
        commit(GET_CONSTRUCTION_AREA, await Odata.ConstructionArea.getAll());
    },
    [GET_KFO]: async({ commit }) => {
        commit(GET_KFO, await Odata.Kfo.setParams({
            select: [ 'Id', 'Title' ],
        }).getAll());
    },
    [GET_TOP_KFO]: async({ commit }) => {
        commit(GET_TOP_KFO, await Odata.TopKfo.setParams({
            select: [ 'Id', 'Title' ],
        }).getAll());
    },
    [GET_PNR_DOCUMENT_TYPE]: async({ commit }) => {
        commit(GET_PNR_DOCUMENT_TYPE, await Odata.PnrDocumentType.setParams({
            select: [ 'Id', 'Title', 'Description_ru', 'Description_en' ],
        }).getAll());
    },
    [GET_PNR_TITLE_OBJECT]: async({ commit }) => {
        commit(GET_PNR_TITLE_OBJECT, await Odata.PnrTitleObject.setParams({
            select: [ 'Id', 'Title' ],
        }).getAll());
    },
};

const mutations: MutationTree<ReftablesState> = {
    [GET_USERS]: (state, payload: UserItemResponse[]) => (state.Users = payload),
    [GET_EMPLOYEES]: (state, payload: Employee[]) => (state.Employees = payload),
    [GET_CONTRAGENTS]: (state, payload: ContragentResponse[]) => (state.Contragents = payload),
    [GET_CONTRAGENTROLES]: (state, payload: ContragentRoleResponse[]) => (state.ContragentRoles = payload),
    [GET_POSITIONS]: (state, payload: PositionResponse[]) => (state.Positions = payload),
    [GET_WORKTYPES]: (state, payload: WorkType[]) => (state.WorkTypes = payload),
    [GET_REVIEWER_TEMPLATES]: (state, payload: ReviewerTemplate[]) => (state.ReviewerTemplates = payload),
    [GET_CONTRACT]: (state, payload: Contract[]) => (state.Contracts = payload),
    [GET_MEASUREMENT_UNITS]: (state, payload: MeasurementUnit[]) => (state.MeasurementUnit = payload),
    [GET_MARKA]: (state, payload: Marka[]) => (state.Marka = payload),
    [GET_TITLE_OBJECT]: (state, payload: TitleObject[]) => (state.TitleObjects = payload),
    [GET_REGISTER_STATUS]: (state, payload: RegisterStatus[]) => (state.RegisterStatus = payload),
    [GET_DRAWING_PACKAGE_STATUS]: (state, payload: DrawingPackageStatus[]) => (state.DrawingPackageStatus = payload),
    [GET_KS2_DOCUMENT_STATUS]: (state, payload: PackageKS2DocumentStatus[]) => (state.KS2DocumentStatus = payload),
    [GET_KS2_DOCUMENT_TYPE]: (state, payload: PackageKS2DocumentType[]) => (state.KS2DocumentType = payload),
    [GET_REGISTER_PROJECT_CODE]: (state, payload: RegisterProjectCode[]) => (state.RegisterProjectCodes = payload),
    [GET_DOCUMENT_CATEGORY]: (state, payload: DocumentCategory[]) => (state.DocumentCategories = payload),
    [GET_CONSTRUCTION_AREA]: (state, payload: ConstructionArea[]) => (state.ConstructionAreas = payload),
    [GET_KFO]: (state, payload: Kfo[]) => (state.Kfos = payload),
    [GET_TOP_KFO]: (state, payload: TopKfo[]) => (state.TopKfos = payload),
    [GET_PNR_DOCUMENT_TYPE]: (state, payload: PnrDocumentType[]) => (state.PnrDocumentTypes = payload),
    [GET_PNR_TITLE_OBJECT]: (state, payload: PnrTitleObject[]) => (state.PnrTitleObjects = payload),
};

const getters: GetterTree<ReftablesState, RootState> = {
    Users: state => state.Users,
};

const module: Module<ReftablesState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};

export default module;
