import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { ReftableItemAudit, getByFilter, ReftableItemAuditOptions } from '@/types/dynamicDoc';

/** История изменения динамического справочника */
export const reftableItemAudits = {
    get: (): Promise<ReftableItemAudit[]> =>
        dynamicDocInstance.get<ReftableItemAudit[]>('/ReftableItemAudit').then(x => x.data),

    getByFilter: (options: ReftableItemAuditOptions): Promise<getByFilter<ReftableItemAudit>> =>
        dynamicDocInstance.post<getByFilter<ReftableItemAudit>>('/ReftableItemAudit/getByFilter', options)
            .then(x => x.data),

};
