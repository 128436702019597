
    import { Component, PropSync, Vue } from 'vue-property-decorator';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';

    @Component({
        components: { ButtonTooltip },
    })
    export default class AboutDialog extends Vue {
        @PropSync('visible', { type: Boolean, required: true })
        visibleDialog!: boolean;

        yearNow = new Date().getFullYear();

        close() {
            this.visibleDialog = false;
        }
    }
