import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { ActualPhysicalVolume, PhysicalVolumeHistoryOptions } from '@/components/asbuilt-docs/volume/utils';
import { ActualPhysicalVolumeOptions, getByFilter, PhysicalVolumeHistory } from '@/types/dynamicDoc';

/** Физический объем */
export const physicalVolume = {
    getActual: (options: ActualPhysicalVolumeOptions): Promise<getByFilter<ActualPhysicalVolume>> =>
        dynamicDocInstance.post<getByFilter<ActualPhysicalVolume>>('PhysicalVolume/GetByFilter', options).then(x => x.data),
    getHistory: (options: PhysicalVolumeHistoryOptions): Promise<getByFilter<PhysicalVolumeHistory>> =>
        dynamicDocInstance.post<getByFilter<PhysicalVolumeHistory>>('PhysicalVolumeHistory/GetByFilter', options).then(x => x.data),
};
