import { dynamicDocInstance } from '@/api/dynamic/dynamic-document-instance';
import { DynamicResponse, getByFilter, IdWithTitle, Options, ReftableSchema, ReftableSchemaValidationResult, ReftableSchemaWithNested, ServiceTemplate, TemplateReftable } from '@/types/dynamicDoc';

/** Схемы справочника */
export const reftableSchema = {
    getAll: (): Promise<IdWithTitle[]> =>
        dynamicDocInstance.get<IdWithTitle[]>('reftableSchema').then(x => x.data),

    getByFilter: (options: Options) =>
        dynamicDocInstance.post<getByFilter<IdWithTitle>>('/ReftableSchema/getByFilter', options)
            .then(x => x.data),

    getPage: (page: number, pageSize: number) =>
        dynamicDocInstance.get<DynamicResponse<IdWithTitle>>(`/reftableSchema/page?page=${page}&pageSize=${pageSize}`)
            .then(x => x.data),

    getById: (id: string): Promise<ReftableSchemaWithNested> =>
        dynamicDocInstance.get<ReftableSchemaWithNested>(`reftableSchema/${id}`).then(x => x.data),

    create: (data: ReftableSchema): Promise<IdWithTitle> =>
        dynamicDocInstance.post<IdWithTitle>('reftableSchema', data).then(x => x.data),

    update: (id: string, data: ReftableSchema): Promise<ReftableSchema> =>
        dynamicDocInstance.put<ReftableSchema>(`reftableSchema/${id}`, data).then(x => x.data),

    remove: (id: string): Promise<void> =>
        dynamicDocInstance.delete(`reftableSchema/${id}`).then(x => x.data),

    getTemplates: (): Promise<ServiceTemplate[]> =>
        dynamicDocInstance.get<ServiceTemplate[]>('reftableSchema/GetTemplates').then(x => x.data),

    getTemplateById: (id: string): Promise<TemplateReftable> =>
        dynamicDocInstance.get<TemplateReftable>(`reftableSchema/GetTemplateById/${id}`).then(x => x.data),

    validateReftableSchemaDelete: (id: string): Promise<ReftableSchemaValidationResult[]> =>
        dynamicDocInstance.get<ReftableSchemaValidationResult[]>(`reftableSchema/ValidateReftableSchemaDelete/${id}`).then(x => x.data),

    applyTemplate: (schemaId: string, templateId: string) =>
        dynamicDocInstance.post(`reftableSchema/ApplyTemplate/${schemaId}`, { id: templateId }).then(x => x.data),

};
