import i18n from '@/plugins/i18n';
import { UserItemResponse, UserResponse } from '@/types';
import store from '@/store';

const hasNumber = /\d/;
const hasMaxChar = /^.{0,15}$/;
const hasMinChar = /^.{8,}$/;
const hasUpperChar = /[A-Z]/;
const hasLowerChar = /[a-z]/;
const hasSymbols = /[!@#$%^&*()_+=[{\]};:<>|./?,-]/;

export interface PasswordErrors {
    type: string;
    isValid: boolean;
    message: string;
}

export function validateHandler(value: string): PasswordErrors[] {
    return [
        {
            type: 'HasNumber',
            isValid: hasNumber.test(value),
            message: i18n.t('password.has-number') as string,
        },
        {
            type: 'hasMaxChar',
            isValid: hasMaxChar.test(value),
            message: i18n.t('password.has-max-char') as string,
        },
        {
            type: 'hasMinChar',
            isValid: hasMinChar.test(value),
            message: i18n.t('password.has-min-char') as string,
        },
        {
            type: 'hasUpperChar',
            isValid: hasUpperChar.test(value),
            message: i18n.t('password.has-upper-char') as string,
        },
        {
            type: 'hasLowerChar',
            isValid: hasLowerChar.test(value),
            message: i18n.t('password.has-lower-char') as string,
        },
        {
            type: 'hasSymbols',
            isValid: hasSymbols.test(value),
            message: i18n.t('password.has-symbols') as string,
        },
    ];
}

export function getUserFullName(id: number | string) {
    const user = store.getters['reftables/Users']?.find((x: UserResponse) =>
        typeof id === 'number' ? x.id === id : x.guidId === id) || null;
    return userFullNameObj(user);
}

export function userFullNameObj(user: UserResponse | UserItemResponse) {
    if (!user) return '';
    const notWork = user.isDeleted ? `(${i18n.t('common.not-work')})` : '';
    return `${user.fullName}${notWork}`;
}
