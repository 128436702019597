
    import { Component, Prop } from 'vue-property-decorator';
    import { Notification, Register } from '@/api/model/interfaces';
    import * as Odata from '@/api/odata';
    import NotificationItem from '@/components/common/notification-item.vue';

    @Component
    export default class RegisterReviewCompletionStage extends NotificationItem {
        @Prop({ required: true })
        notification!: Notification;

        register: Register | null = null;

        async mounted() {
            this.register = await Odata.Register.setParams({
                select: [ 'Code', 'Id' ],
            }).getById(this.notification.EntityId.toString());
            this.link = '/asbuilt/registers/';
            if (this.register) {
                this.link += this.register.Id;
                return;
            }
            const registerReview = await Odata.RegisterReview
                .setParams({
                    expand: [ 'Iteration($expand=Register)' ],
                }).getById(this.notification.EntityId);
            this.register = registerReview.Iteration?.Register || null;
        }
    }
