import axios from 'axios';
import { interceptors } from '@/api/helpers';

export const cisInstance = axios.create({
    baseURL: `${process.env.VUE_APP_API_BASE_URL}api/`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') },
});
export const cisOdataInstance = axios.create({
    baseURL: `${process.env.VUE_APP_API_BASE_URL}odata/`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') },
});

// Перехватчик запросов axios
interceptors(cisInstance);
interceptors(cisOdataInstance);
