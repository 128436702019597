
    import { Component, Prop } from 'vue-property-decorator';
    import { DrawingPackageReview, Notification } from '@/api/model/interfaces';
    import * as Odata from '@/api/odata';
    import NotificationItem from '@/components/common/notification-item.vue';

    @Component
    export default class DrawingPackageReviewNew extends NotificationItem {
        @Prop({ required: true })
        notification!: Notification;

        review: DrawingPackageReview | null = null;

        async mounted() {
            this.review = await Odata.DrawingPackageReview.setParams({
                expand: [ 'Reviewer($expand=ReviewedEntity)' ],
            }).getById(this.notification.EntityId.toString());
            this.link = this.review.Reviewer?.ReviewedEntity
                ? `/working-drawings/packages/${this.review.Reviewer.ReviewedEntity.Id}`
                : null;
        }
    }
