
    import { Timer } from '@/types/dynamicDoc';
    import { TranslateResult } from 'vue-i18n';
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import { getOdata } from '../reftables/helper';

    @Component
    export default class ServerAutocomplete extends Vue {
        @Prop([ Number, String, Array ])
        private readonly value!: any;

        @Prop({ type: Boolean, default: false })
        private readonly layout!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly hideDetails!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly dense!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly disabled!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly validate!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly multiple!: boolean;

        @Prop()
        private readonly label!: string | TranslateResult;

        @Prop()
        private readonly placeholder!: string | TranslateResult;

        @Prop({ type: Boolean, default: true })
        private readonly required!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly clearable!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly smallChips!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly chips!: boolean;

        @Prop({ type: String, default: 'Title' })
        private readonly itemText!: string;

        @Prop({ type: String, default: 'Id' })
        private readonly itemValue!: string;

        @Prop({ type: String })
        private readonly odataName!: string;

        @Prop({ type: Array, default: () => [] })
        private readonly filter!: string[];

        loading = false;
        data: any = null;
        fetchItems: any[] = [];
        selectedItems: any[] = [];
        searchText = '';
        timer: Timer = null;
        pageSize = 20;
        hasMore = true;

        @Watch('value', { immediate: true })
        async onChangeValue() {
            if (this.value === this.data) return;

            this.data = this.value || null;
            if (this.data) {
                await this.loadItems(false, true);
                this.updateSelectedItems();
            }
        }

        @Watch('data', { immediate: true })
        async onDataChanged() {
            this.$emit('input', this.data);
            this.$emit('change', this.data);
            this.updateSelectedItems();
        }

        @Watch('searchText', { immediate: true })
        onSearchChanged() {
            if (this.isView
                || this.searchText === null
                || this.items.find(i => this.getItemTitle(i) === this.searchText)) return;
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async() => {
                await this.loadItems(true);
            }, 1000);
        }

        @Watch('isView', { immediate: true })
        onIsViewChange() {
            if (this.isView) return;
            this.hasMore = true;
        }

        clear() {
            this.$emit('input', null);
            this.$emit('clear');
        }

        updateSelectedItems() {
            this.selectedItems = this.selectedIds.length
                ? this.items.filter(x => this.selectedIds.find(d => d === x[this.itemValue]))
                : [];
        }

        async endIntersect(entries: any, observer: any, isIntersecting: boolean) {
            if (this.hasMore && isIntersecting) {
                await this.loadItems();
            }
        }

        async loadItems(updateItems?: boolean, init?: boolean) {
            if (this.isView && !this.selectedIds.length) return;
            //todo: добавить интерфейс для функции поиска по кастомным полям(не Odata)
            try {
                this.loading = true;
                if (updateItems) this.fetchItems = [];

                if (this.odataName) {
                    const filter = this.requestFilter(init);
                    const manager = getOdata(this.odataName);
                    const resp = await manager.setParams({
                        select: [ this.itemValue, ...this.fields ],
                        filter: filter.length ? filter : null,
                        skip: this.skip,
                        top: this.pageSize,
                        orderBy: this.isFormula ? this.itemValue : null,
                    }).getAll();
                    this.hasMore = resp.length === this.pageSize;
                    this.fetchItems = [ ...this.fetchItems, ...resp ];
                }
            } finally {
                this.loading = false;
            }
        }

        get items() {
            return [ ...this.selectedItems, ...this.fetchItems ];
        }

        get isView() {
            return this.readonly || this.layout;
        }

        get skip() {
            return this.fetchItems.length;
        }

        requestFilter(init?: boolean) {
            const selected = `${this.itemValue} in (${this.selectedIds.join(',')})`;
            if (this.selectedIds.length && (this.isView || init)) return [ selected ];
            if (!this.searchText) return this.filter;

            return [
                ...this.filter,
                this.fields.map(x => `contains(tolower(${x}),'${this.searchText.toLowerCase()}')`).join(' or '),
            ];
        }

        get fields() {
            const matches = this.itemText.match(/\{(.*?)\}/g);
            return !matches?.length ? [ this.itemText ] : matches.map(match => match.substring(1, match.length - 1));
        }

        getItemTitle(item: any) {
            if (!this.isFormula) return item[this.itemText];

            let str = this.itemText;
            this.fields.forEach(x => { str = str.replace(`{${x}}`, item[x]); });
            return str;
        }

        get isFormula() {
            return !this.fields.includes(this.itemText);
        }

        get selectedIds() {
            if (!this.data) return [];
            return Array.isArray(this.data) ? this.data : [ this.data ];
        }
    }
