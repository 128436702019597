
    import { Component, Vue } from 'vue-property-decorator';
    import * as api from '@/api/auth';
    import store from '@/store';
    import { AUTH_USER_REQUEST } from '@/store/auth';
    import * as Odata from '@/api/odata';
    import { AgreementSettingsId } from '@/api/constants';
    import { AgreementSettings } from '@/store/settings/types';

    @Component({})
    export default class AgreementUserDialog extends Vue {
        value: number | null = null;
        loading = false;
        agreement = {
            isConsentPersonalData: false,
        };

        settings: AgreementSettings = {
            TextRu: '',
            TextEn: '',
        };

        get isVisible(): boolean {
            if (this.$store.state.auth.status === '') return false;
            return !this.$store.getters.hasAgreementUser;
        }

        get text() {
            return this.$i18n.locale === 'ru' ? this.settings.TextRu : this.settings.TextEn;
        }

        get valid() {
            return this.agreement.isConsentPersonalData;
        }

        async created() {
            this.settings = await Odata.AgreementSettings
                .getById(AgreementSettingsId) as AgreementSettings;
        }

        async save() {
            if (!this.valid) return;
            this.loading = true;
            await api.user.setAgreementUser(this.agreement);
            await store.dispatch(AUTH_USER_REQUEST);
            this.loading = false;
        }
    }
