import { render, staticRenderFns } from "./menu-filter.vue?vue&type=template&id=b4febe6e&scoped=true&lang=pug&"
import script from "./menu-filter.vue?vue&type=script&lang=ts&"
export * from "./menu-filter.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4febe6e",
  null
  
)

export default component.exports