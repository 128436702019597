import { AutocompleteItem } from '@/types';
import {
    AuditType,
    DocumentCategoryTree,
    DocumentFormHandler,
    DocumentPageFormat,
    DynamicJournalFieldSet,
    FieldType,
    RelationType,
} from '@/types/dynamicDoc/types';
import i18n from '@/plugins/i18n';

export const auditTypes: AutocompleteItem[] = [
    { value: AuditType.Create, text: i18n.t('cert-selector.creation') as string },
    { value: AuditType.Update, text: i18n.t('permissions.Update') as string },
    { value: AuditType.Get, text: i18n.t('permissions.View') as string },
    { value: AuditType.Delete, text: i18n.t('permissions.Delete') as string },
    { value: AuditType.Export, text: i18n.t('common.export') as string },
    { value: AuditType.NewVersion, text: i18n.t('common.new-version') as string },
];

export const documentPageFormats: AutocompleteItem[] = [
    { value: DocumentPageFormat.A0, text: 'A0' },
    { value: DocumentPageFormat.A1, text: 'A1' },
    { value: DocumentPageFormat.A2, text: 'A2' },
    { value: DocumentPageFormat.A3, text: 'A3' },
    { value: DocumentPageFormat.A4, text: 'A4' },
    { value: DocumentPageFormat.A5, text: 'A5' },
];
export const relationTypes: AutocompleteItem[] = [
    { value: RelationType.One, text: i18n.t('common.many-to-one') as string },
    { value: RelationType.Many, text: i18n.t('common.one-to-many') as string },
];

export const documentFormHandlers: AutocompleteItem[] = [
    { value: DocumentFormHandler.PackageAuthorSupervisionChangeHandler, text: i18n.t('dynamic.form-handler-package-author-supervision-change') as string },
];

export const useSum = (numbers: number[]): number => {
    return numbers.reduce((accumulator, currentValue) =>
        accumulator + currentValue, 0);
};

export function mergeArraysByUniqueKey<T>(array1: T[], array2: T[], uniqueKey: keyof T): T[] {
    const mergedArray = [ ...array1 ];

    for (const obj2 of array2) {
        const foundIndex = mergedArray.findIndex(obj1 => obj1[uniqueKey] === obj2[uniqueKey]);
        if (foundIndex === -1) {
            mergedArray.push(obj2);
        }
    }

    return mergedArray;
}
export function buildTree(items: DocumentCategoryTree[], rootId: string | null = null): DocumentCategoryTree[] {
    const result = [];
    for (const item of items) {
        if (item.rootId === rootId) {
            const children = buildTree(items, item.id);
            if (children.length > 0) {
                item.children = children;
            }
            result.push(item);
        }
    }
    return result;
}

export function defaultValueField(item: DynamicJournalFieldSet): DynamicJournalFieldSet {
    return {
        ...item,
        Title: i18n.t('common.default-value') as string,
    };
}

export function isDefaultValueVisible(item: DynamicJournalFieldSet): boolean {
    switch (true) {
    case item.Type === FieldType.Table:
    case item.Type === FieldType.PermitDocument:
    case item.Type === FieldType.MultiReftable:
    case item.Type === FieldType.PhysicalVolume: return false;
    default: return true;
    }
}

export function deleteEmptyValue(item: any) {
    for (const key in item) {
        if (!item[key]?.toString()) delete item[key];
    }
}
