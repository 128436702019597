
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import ActualVolume from './actual/index.vue';
    import ProgressVolume from './progress/index.vue';
    import { Volume, VolumeSetting } from './utils';

    @Component({})
    export default class PhysicalVolumes extends Vue {
        @Prop(String)
        readonly subItem!: string | null;

        private readonly menuItems: VolumeSetting[] = [
            {
                type: Volume.Actual,
                actionName: 'actual',
                title: this.$t('volume.actual') as string,
            },
            {
                type: Volume.Progress,
                actionName: 'progress',
                title: this.$t('volume.progress') as string,
            },
        ];

        get currentItem() {
            return this.menuItems.find(x => x.actionName === this.subItem) || null;
        }

        get currentComponent() {
            if (!this.currentItem) return null;

            switch (this.currentItem.type) {
                case Volume.Actual: return ActualVolume;
                case Volume.Progress: return ProgressVolume;
                default:
                    throw new Error('Could not find a component for the specified VolumeType.');
            }
        }
    }
