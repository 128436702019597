
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import DatePicker from 'v-calendar/lib/components/date-picker.umd';
    import VueI18n from 'vue-i18n';

    @Component({
        components: { DatePicker },
    })
    export default class DatePickerSingle extends Vue {
        @Prop({ default: null })
        private readonly value!: number | null;

        @Prop({ type: Boolean, default: false })
        private readonly disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly clearable!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly hideDetails!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly dense!: boolean;

        @Prop({ type: String, default: 'mdi-calendar' })
        private readonly icon!: string;

        @Prop({ default: '' })
        private readonly placeholder!: string | VueI18n.TranslateResult;

        @Prop({ default: '' })
        private readonly label!: string | VueI18n.TranslateResult;

        menu = false;
        date: Date | null = null;

        modelConfig = {
            timeAdjust: '12:00:00',
        };

        @Watch('value', { immediate: true })
        onChangeValue() {
            if (!this.value) {
                this.date = null;
                return;
            }

            this.date = new Date(this.value);
        }

        get formatDate() {
            if (!this.date) return '';
            return this.date.toLocaleDateString();
        }

        onInputDate(date: Date | null) {
            if (!date || !date) return;
            this.menu = false;
            this.$emit('input', date.getDate());
        }

        clear() {
            this.$emit('input', null);
            this.$emit('clear');
        }
    }
