import i18n from '@/plugins/i18n';
import { DrawingPackageStatus, RegisterIssueStatus, RegisterStatus } from '@/api/constants';

interface IssueStatusInfo {
    status: string;
    name: string;
}

const statusSet: IssueStatusInfo[] = [
    { status: RegisterIssueStatus.Draft, name: i18n.t('common.draft') as string },
    { status: RegisterIssueStatus.Issued, name: i18n.t('common.issued') as string },
    { status: RegisterIssueStatus.Approved, name: i18n.t('common.approved') as string },
    { status: RegisterIssueStatus.Fixed, name: i18n.t('common.fixed') as string },
    { status: RegisterIssueStatus.Canceled, name: i18n.t('common.canceled') as string },
];

export const registerIssueStatusName = (status: string): string => {
    return statusSet.find(x => x.status === status)?.name || '';
};

export function timeColorClassName(startDate: Date | null, endDate: Date | null): string {
    if (!endDate || !startDate) return '';
    const endDay = new Date(endDate);
    const startDay = new Date(startDate);
    const allMinutes = getCountMinutes(startDay, endDay);
    const countMinutes = getCountMinutes(new Date(), endDay);
    const percent = countMinutes * 100 / allMinutes;
    if (percent > 30) return 'success--text';
    if (percent > 0) return 'warning--text';
    return 'error--text';
}

export function getCountMinutes(startDay: Date, endDay: Date): number {
    const oneMunite = 1000 * 60;
    return Math.round((endDay.getTime() - startDay.getTime()) / oneMunite) + 1;
}

export function getCountDays(startDay: Date, endDay: Date): number {
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.round((endDay.getTime() - startDay.getTime()) / oneDay) + 1;
}

export function displayDate(date: Date | null) {
    if (!date) return '';

    const t = new Date(date).getTime() - new Date().getTime();
    const days = Math.floor(t / (1000 * 60 * 60 * 24));
    const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (!days) return `${i18n.tc('common.n-hour', hours)}`;
    return `${i18n.tc('common.n-day', days)} ${i18n.tc('common.n-hour', hours)}`;
}

export function statusInfo(statusId: string) {
    switch (statusId) {
    case RegisterIssueStatus.Draft:
        return {
            icon: 'mdi-pencil',
            text: i18n.t('common.draft') as string,
            class: 'dark--text',
        };

    case RegisterIssueStatus.Approved:
        return {
            icon: 'mdi-check',
            text: i18n.t('common.approved') as string,
            class: 'success--text',
        };

    case RegisterIssueStatus.Canceled:
        return {
            icon: 'mdi-delete',
            text: i18n.t('common.canceled') as string,
            class: 'muted--text',
        };

    case RegisterIssueStatus.Fixed:
        return {
            icon: 'mdi-bandage',
            text: i18n.t('common.fixed') as string,
            class: 'info--text',
        };

    case RegisterIssueStatus.Issued:
        return {
            icon: 'mdi-information-outline',
            text: i18n.t('common.issued') as string,
            class: 'warning--text',
        };
    }
}

export const registerStatuses: any = {
    [RegisterStatus.Draft]: { title: i18n.t('common.draft'), icon: 'mdi-cog', color: '#9ca3af' },
    [RegisterStatus.Check]: { title: i18n.t('common.check'), icon: 'mdi-autorenew', color: 'blue' },
    [RegisterStatus.HasIssues]: { title: i18n.t('report.has-issues'), icon: 'mdi-comment-arrow-right', color: 'orange' },
    [RegisterStatus.ResolvingIssues]: { title: i18n.t('report.resolving-issues'), icon: 'mdi-comment-check', color: 'orange' },
    [RegisterStatus.SecondaryCheck]: { title: i18n.t('report.recheck'), icon: 'mdi-autorenew', color: 'blue' },
    [RegisterStatus.Approval]: { title: i18n.t('report.on-approval'), icon: 'mdi-check-decagram', color: '#072974' },
    [RegisterStatus.NotApproved]: { title: i18n.t('report.not-approved'), icon: 'mdi-alert-decagram', color: 'red' },
    [RegisterStatus.WaitingConstruction]: { title: i18n.t('report.waiting-completion'), icon: 'mdi-account-tie', color: 'purple' },
    [RegisterStatus.Signing]: { title: i18n.t('report.signing'), icon: 'mdi-draw-pen', color: 'green' },
    [RegisterStatus.Signed]: { title: i18n.t('report.signed'), icon: 'mdi-draw', color: 'green' },
    [RegisterStatus.Canceled]: { title: i18n.t('common.annul-status'), icon: 'mdi-cancel', color: 'red' },
};

export const drawingPackageStatuses: any = {
    [DrawingPackageStatus.Draft]: { title: i18n.t('common.draft'), icon: 'mdi-cog', color: '#9ca3af' },
    [DrawingPackageStatus.Check]: { title: i18n.t('common.check'), icon: 'mdi-autorenew', color: 'blue' },
    [DrawingPackageStatus.HasIssues]: { title: i18n.t('report.has-issues'), icon: 'mdi-comment-arrow-right', color: 'orange' },
    [DrawingPackageStatus.ResolvingIssues]: { title: i18n.t('report.resolving-issues'), icon: 'mdi-comment-check', color: 'orange' },
    [DrawingPackageStatus.Approval]: { title: i18n.t('report.on-approval'), icon: 'mdi-check-decagram', color: '#072974' },
    [DrawingPackageStatus.Signing]: { title: i18n.t('report.signing'), icon: 'mdi-draw-pen', color: 'green' },
    [DrawingPackageStatus.Archived]: { title: i18n.t('report.archived'), icon: 'mdi-archive', color: 'green' },
};
