
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DocumentField, DynamicDocument, DynamicDocumentSchema, FieldType, IdWithTitle } from '@/types/dynamicDoc';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import { DataTableHeader } from 'vuetify';
    import * as Odata from '@/api/odata';
    import { DrawingPackage, Register, RegisterStatus } from '@/api/model/interfaces';
    import * as api from '@/api/dynamic';
    import { AutocompleteItem, WorkType } from '@/types';
    import { localizeDescription } from '@/util/field-translation';
    import { drawingPackageTitle } from '@/components/drawing-docs/helpers';
    import CustomDialog from '@/components/dynamic/common/custom-dialog.vue';

    interface DocumentGroup {
        documentId: string;
        fieldName: string;
    }

    @Component({
        components: {
            ButtonTooltip,
            CustomDialog,
            Document: () => import('@/components/documents-journals/documents/document.vue'),
        },
    })
    export default class DocumentRelated extends Vue {
        @Prop({ type: Object, required: true })
        document!: DynamicDocument;

        @Prop({ type: Object })
        documentSchema?: DynamicDocumentSchema | null;

        documents: DynamicDocument[] = [];
        registers: Register[] = [];
        drawingPackages: DrawingPackage[] = [];
        isVisibleDocumentDialog = false;
        documentId = '';

        @Watch('relatedDocumentIds', { immediate: true })
        async onRelatedDocumentIds() {
            if (!this.relatedDocumentIds) return;
            await this.getDocuments();
            await this.getRegisters();
            await this.getDrawingPackages();
        }

        get headers(): DataTableHeader[] {
            return [
                { text: this.$t('common.document-number') as string, value: 'Number', groupable: false },
                { text: this.$t('common.document-outline') as string, value: 'Type', groupable: false },
                { text: this.$t('common.work-type') as string, value: 'WorkTypes', groupable: false },
                { text: this.$t('common.drawing-packages') as string, value: 'DrawingPackages', groupable: false },
                { text: this.$t('common.register') as string, value: 'Register', groupable: false },
                { text: this.$t('common.circuit-type') as string, value: 'IsGenerated', groupable: false },
                { text: this.$t('common.field') as string, value: 'FieldName', groupable: true },
                { text: '', value: 'actions', sortable: false, groupable: false, align: 'end' },
            ];
        }

        get registerStatus(): RegisterStatus[] {
            return this.$store.state.reftables.RegisterStatus;
        }

        get workTypes(): AutocompleteItem[] {
            if (!this.$store.state.reftables?.WorkTypes) return [];
            return this.$store.state.reftables.WorkTypes
                .map((x: WorkType) => ({
                    value: x.Id,
                    text: x.Code.length ? `${x.Code}. ${localizeDescription(x)}` : localizeDescription(x),
                }));
        }

        get drawingPackageIds() {
            const ids = this.documents.flatMap(x => x.DrawingPackageIds)
                .filter(x => !!x) as string[];
            return [ ...new Set(ids) ];
        }

        get drawingPackagesFormated(): AutocompleteItem[] {
            return this.drawingPackages.map(x => ({
                value: x.Id.toString(),
                text: drawingPackageTitle(x),
            }));
        }

        get documentsFormated() {
            const fields = this.documentSchema?.FieldSet
                ?.filter((x: DocumentField) => x.Type === FieldType.Document) || [];
            const items = fields.flatMap((field: DocumentField) => {
                const values = this.document.Data[field.TemplateCode];
                return values?.length
                    ? values.flatMap((documentId: string) => ({ documentId, fieldName: field.TemplateCode }))
                    : [];
            }) as DocumentGroup[];

            return items.map(item => {
                const document = this.documents.find(x => x.Id === item.documentId);
                const register = this.registers.find(x => x.Id.toString() === document?.RegisterId);
                const status = this.registerStatus.find(y => y.Id.toString() === register?.StatusId.toString());
                const workTypes: AutocompleteItem[] = [];
                if (document?.WorkTypeIds?.length) {
                    document?.WorkTypeIds.forEach(id => {
                        const workType = this.workTypes.find(w => w.value === id);
                        if (workType) workTypes.push(workType);
                    });
                }

                const drawingPackages: AutocompleteItem[] = [];
                if (document?.DrawingPackageIds?.length) {
                    document?.DrawingPackageIds.forEach(id => {
                        const drawingPackage = this.drawingPackagesFormated.find(w => w.value === id);
                        if (drawingPackage) drawingPackages.push(drawingPackage);
                    });
                }

                return {
                    ...document,
                    FieldName: item.fieldName,
                    Register: register ? `${this.$t('common.register')} ${register?.Code}` : '',
                    RegisterStatus: status ? ` (${localizeDescription(status)})` : '',
                    WorkTypes: workTypes,
                    DrawingPackages: drawingPackages,
                };
            });
        }

        get relatedDocumentIds() {
            const fields = this.documentSchema?.FieldSet
                ?.filter((x: DocumentField) => x.Type === FieldType.Document) || [];
            return fields
                .flatMap((filed: DocumentField) => this.document.Data[filed.TemplateCode])
                .filter((x: string | null | undefined) => !!x) || [];
        }

        get hasNewVersionTooltip() {
            return `<span>${this.$t('common.document-has-new-version')}</span> <br>
            <b>${this.$t('common.press-for-update')}</b>`;
        }

        async updateRelatedDocument(relatedDocumentId: string, templateCode: string) {
            try {
                await api.document.updateRelatedDocument(this.document.Id, templateCode, relatedDocumentId);
                this.$root.$notify.show('success');
                this.$emit('updated');
            } catch (e) {
                this.$root.$notify.show('error', e);
            }
        }

        schemaName(id: string) {
            const schema = this.$store.state.document.documentSchemas.find((x: IdWithTitle) => x.Id === id);
            return schema ? schema.Title : '';
        }

        openDocument(doc: DynamicDocument) {
            this.documentId = doc.Id;
            this.isVisibleDocumentDialog = true;
        }

        async getDocuments() {
            this.documents = await api.document.getByIdRange(this.relatedDocumentIds);
        }

        async getRegisters() {
            const ids = this.documents.map(x => x.RegisterId).filter(x => !!x);
            if (!ids.length) return;
            this.registers = await Odata.Register
                .runAction('GetRegistersByIdRange', null, { ids })
                .then(x => x.value);
        }

        async getDrawingPackages() {
            this.drawingPackages = await Odata.DrawingPackage
                .runAction('GetByIdRange', null, { Ids: this.drawingPackageIds },
                           {
                               select: [ 'Id', 'Code', 'AdditionalCode', 'MainCode' ],
                           })
                .then(x => x.value);
        }
    }
