
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { required } from '@/components/common/validation-rules';
    import * as api from '@/api/dynamic';
    import { ItemWithIdAndTitle } from '@/types/dynamicDoc';
    import TooltipWrapper from '@/components/dynamic/common/tooltip-wrapper.vue';
    @Component({
        components: { TooltipWrapper },
    })
    export default class PermitDocumentSelector extends Vue {
        @Prop({ type: String, required: true })
        private readonly label!: string;

        @Prop([ String, Array ])
        private readonly value!: string | string[];

        @Prop({ type: Boolean, default: false })
        private readonly readonly!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly disabled!: boolean;

        @Prop({ type: Boolean, default: true })
        private readonly validate!: boolean;

        rules = { required }
        searchText = '';
        isLoading = false;
        items: ItemWithIdAndTitle[] = [];
        timer: any = null;

        async getPermitDocuments() {
            if (this.isLoading || !this.searchText) return;
            this.isLoading = true;
            this.items = await api.reftableItem.searchPermitFiles(this.searchText);
            if (!this.items) this.items = [];
            this.isLoading = false;
        }

        searchTimeRequest() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async() => {
                await this.getPermitDocuments();
            }, 2000);
        }
    }
