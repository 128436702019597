
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import { Register, RegisterReview } from '@/api/model/interfaces';
    import { IssueAddFormOptions } from '@/components/asbuilt-docs/issues/interfaces';
    import { ContragentResponse, RegisterIssueRequest, UserResponse, VForm } from '@/types';
    import { required } from '@/components/common/validation-rules';
    import { RegisterReviewStatus } from '@/api/constants';
    import * as cisApi from '@/api/cis';
    import ButtonTooltip from '@/components/common/button-tooltip.vue';
    import * as Odata from '@/api/odata';

    @Component({
        components: {
            ButtonTooltip,
        },
    })
    export default class IssueAddDialog extends Vue {
        @Ref() form!: VForm;

        @Prop({ type: String, required: true })
        readonly registerId!: string;

        options: IssueAddFormOptions = { documentId: null, reviewId: null, dynamicDocumentItemId: null };
        title: string | null = null;
        description: string | null = null;
        isVisible = false;
        valid = true;
        rules = { required };
        files: File[] = [];
        register: Register | null = null;
        loading = false;

        get popupTitle(): string {
            return this.options.documentId
                ? this.$t('issue-add-form.new-issue-to-document') as string
                : this.$t('issue-add-form.new-issue-to-register') as string;
        }

        get user(): UserResponse {
            return this.$store.state.auth.user;
        }

        get reviewsInProgress(): RegisterReview[] {
            if (!this.register?.CurrentIteration?.ReviewSet) return [];
            return this.register.CurrentIteration.ReviewSet
                .filter(x => x.StatusId.toString() === RegisterReviewStatus.InProgress);
        }

        get reviewId(): string | null {
            if (!this.reviewsInProgress.length) return null;
            if (this.user.isAdmin) return this.reviewsInProgress[0].Id.toString();
            const review = this.reviewsInProgress.find(x => x.Reviewer?.RespUserId?.toString() === this.user.guidId);
            return review ? review.Id.toString() : null;
        }

        get attachments() {
            return this.files.map(x => ({
                ContentType: x.type,
                FileName: x.name,
                Length: x.size,
                Name: x.name,
            }));
        }

        get RegisterIssueRequest(): RegisterIssueRequest {
            return {
                DocumentId: null,
                DynamicDocumentItemId: this.options.dynamicDocumentItemId,
                ReviewId: this.options.reviewId || this.reviewId,
                Title: this.title,
                Description: this.description,
                Files: this.files,
            };
        }

        async show(options: IssueAddFormOptions) {
            this.isVisible = true;
            this.options = options;
            await this.getRegister();
        }

        close() {
            this.form.reset();
            this.form.resetValidation();
            this.isVisible = false;
        }

        async getRegister() {
            this.register = await Odata.Register.setParams({
                expand: [ 'CurrentIteration($expand=ReviewSet($expand=Reviewer))' ],
            }).getById(this.registerId);
        }

        async submit() {
            if (!this.form.validate()) {
                this.$emit('error', { text: this.$t('notify.fix-errors'), status: 'warning' });
                return;
            }
            this.loading = true;
            try {
                await cisApi.registerIssue.create(this.RegisterIssueRequest);
                this.$root.$notify.show('success');
                this.$emit('success');
                this.close();
            } catch (e) {
                this.$emit('error', { text: e, status: 'error' });
                this.$root.$notify.show('error');
            } finally {
                this.loading = false;
            }
        }
    }
