import { FilterOptions } from '@/types/common/index';
export enum WidgetType {
    Status,
    Expert,
    Issue,
    Timeout,
    Error,
}
export interface RegisterWidgetResponse {
    Id: string;
    Code: string;
}
export interface ReviewEndDateWidgetResponse extends RegisterWidgetResponse {
    ReviewEndDate: string;
}
export interface RegisterGroupByStatusResponse {
    StatusId: string;
    Registers: RegisterWidgetResponse[];
}
export interface RegisterGroupByReviewerUserIdResponse {
    ReviewerUserId: string;
    Registers: ReviewEndDateWidgetResponse[];
}

export interface RegisterStatusesInfo
{
    SubcontractorId: number;
    Draft: number;
    Check: number;
    ResolvingIssues: number;
    SecondaryCheck: number;
    Approval: number;
    Signing: number;
    Signed: number;
}

export interface RegisterStatusesInfoFilter extends FilterOptions {
    SubcontractorId?: number,
    TitleObjectId?: string | null,
    DocumentCategoryId?: string | null,
}
